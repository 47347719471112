import React, { useEffect, useState } from 'react'
import styles from './Dropdown.module.scss'
import ArrowDropdown from '../../../../img/IconComponents/ArrowDropdown'
import cn from 'classnames'
import Triangle from '../../../../img/IconComponents/Triangle'
import Overlay from '../../../../components/Overlay/Overlay'
import { useTranslation } from 'react-i18next'

const Dropdown = ({ value = 'Select status', onChange, error,disabled }) => {
  const { t } = useTranslation()

  const dropdownData = [
    { country: t('Pending'), id: 2 },
    { country: t('Paid'), id: 3 },
    { country: t('Confirmed'), id: 4 },
    { country: t('Paid Out'), id: 5 },
    { country: t('Paid Partial'), id: 7 },
    { country: t('Refund'), id: 8 },
    { country: t('Completed'), id: 9 }
  ]

  const [isOpen, setIsOpen] = useState(false)

  const setCountry = (country) => {
    setIsOpen(false)
    onChange(country)
  }

  const closeModalHandler = (e) => {
    if (e.target.className === 'overlay') {
      e.stopPropagation()
      setIsOpen(false)
    }
  }

  useEffect(() => {
    if (value) {
      onChange(value)
    }
  }, [value])

  return (
    <div className={styles.dropdown}>
      {isOpen ? <Overlay closeModalHandler={closeModalHandler} /> : null}
      <span className={styles.dropdown__label}>{t("Status")}</span>
      <div
        className={styles.dropdown__inner}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <div>{value && value.length ? value : t('Select status')}</div>
        <ArrowDropdown />
      </div>
      {!disabled&&<>
      <div
        className={cn(
          styles.dropdown__block,
          isOpen ? styles.dropdown__block_active : '',
        )}
      >
        {dropdownData.map((item) => {
          const { country, id } = item
          return (
            <div
              className={styles.dropdown__block_value}
              key={id}
              onClick={() => setCountry(country)}
            >
              {country}
            </div>
          )
        })}
      </div></>}
      {error && error.length && (
        <div className={styles.dropdown__error}>
          <Triangle />
          <div>{error}</div>
        </div>
      )}
    </div>
  )
}

export default Dropdown
