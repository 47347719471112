/** @format */

import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../http';
const CoinbaseRedirects = () => {
  const params = useParams();
  useEffect(() => {
    axios({
      method: 'post',
      url: `${API_URL}/v1/orders/coinbase/${params.orderId}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          'token',
        )}`,
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
    })
      .then((res) => {
        return res;
      })
      .then((data) => {
        if (data.data.coinbaseLink) {
          window.location.href =
            data.data.coinbaseLink;
        }
      });
  }, [params]);
  return <></>;
};

export default CoinbaseRedirects;
