import React, { useState } from "react";
import styles from "./Dropdown.module.scss";
import ArrowDropdown from "../../img/IconComponents/ArrowDropdown";
import cn from "classnames";
import Overlay from "../Overlay/Overlay";

const defaultOptions = ["5", "10", "20", "50"];

const DropdownDigit = ({ value, setValue, options = defaultOptions }) => {
  const [isOpen, setIsOpen] = useState(false);

  const changeHandler = (value) => {
    setValue(value);
    setIsOpen(false);
  };

  const closeModalHandler = (e) => {
    if (e.target.className === "overlay") {
      e.stopPropagation();
      setIsOpen(false);
    }
  };

  return (
    <div className={styles.dropdown}>
      {isOpen ? <Overlay closeModalHandler={closeModalHandler} /> : null}
      <div className={styles.dropdown__inner} onClick={() => setIsOpen((prev) => !prev)}>
        <div>{value}</div>
        <ArrowDropdown />
      </div>
      <div className={cn(styles.dropdown__block, isOpen ? styles.dropdown__block_active : "")}>
        {options?.map((item, i) => {
          return (
            <div className={styles.dropdown__block_value} key={i} onClick={() => changeHandler(item)}>
              {item}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DropdownDigit;
