/** @format */

import React from 'react';
import styles from './Cookie.module.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useState, useEffect } from 'react';
const Cookie = () => {
  const { t } = useTranslation();

  const [isShowCookie, setIsShowCookie] =
    useState(false);

  useEffect(() => {
    const isCookieExists =
      Cookies.get('acceptCookie') === 'true' ||
      localStorage.getItem('acceptCookies') ===
        'false';
    if (isCookieExists) {
      setIsShowCookie(false);
    } else {
      setIsShowCookie(true);
    }
  }, []);

  const setCookie = (isSet) => {
    const oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(
      oneYearFromNow.getFullYear() + 1,
    );
    if (isSet) {
      Cookies.set('acceptCookie', true, {
        expires: oneYearFromNow,
      });
    } else {
      localStorage.setItem(
        'acceptCookies',
        false,
      );
    }
    setIsShowCookie(false);

    window.dataLayer.push({
      event: 'ce.consentUpdate',
      consent: {
        analytics: isSet,
        marketing: isSet,
      },
    });
  };

  return isShowCookie ? (
    <div className={styles.cookie}>
      <div className={styles.cookie__inner}>
        <div className={styles.cookie__title}>
          {t('Cookie preferences')}
        </div>
        <div className={styles.cookie__descr}>
          {t(
            'This website uses cookies to provide the best possible experience.',
          )}
          <Link to='/privacy'>
            {t('More information')}
          </Link>
        </div>
        <div className={styles.cookie__btns}>
          <button onClick={() => setCookie(true)}>
            {t('Accept')}
          </button>
          <button
            onClick={() => setCookie(false)}>
            {t('Decline')}
          </button>
        </div>
      </div>
    </div>
  ) : (
    ''
  );
};

export default Cookie;
