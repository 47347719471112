import React, { useRef, useState } from "react";
import styles from "./Hero.module.scss";
import { useTranslation } from "react-i18next";
import { FRONT_URL } from "../../../../http";
import dagVideo from "../../../../video/DAG.mp4";
import PlayIcon from "../../../../img/IconComponents/PlayIcon";
import cursorIcon from "../../../../img/cursor.svg";
import Cross from "../../../../img/IconComponents/Cross";
import videoImage from "../../../../img/video__prev.png";

const Hero = () => {
  const videoRef = useRef(null);
  const { t } = useTranslation();
  const [isShowModal, setIsShowModal] = useState(false);

  const handlePlayClick = () => {
    setIsShowModal(true);
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const closeModal = () => {
    setIsShowModal(false);
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  return (
    <section className={styles.hero}>
      <div className="container">
        <div className={styles.hero__container}>
          <div className={styles.hero__wrapper}>
            <div className={styles.hero__line}></div>
            <h1 className={styles.hero__title}>
              {t("The largest and most modern cannabis cultivation in Europe")}
            </h1>
            <p className={styles.hero__desc}>
              {t("Secure DECT token now and benefit from the German cannabis market")}
            </p>
            <div className={`${styles.hero__links} ${styles.desktop}`}>
              <a className={styles.button} href="/buy" id="whatwedo">
                {t("Secure token now")}
              </a>
              <a
                className={styles.button}
                href={
                  localStorage.getItem("i18n") !== "DE"
                    ? `${FRONT_URL}/files/Whitepaper - Deutsche Anbaugesellschaft DAG GmbH-2.pdf`
                    : `${FRONT_URL}/files/DECT.pdf`
                }
                target="_blanc"
              >
                {t("Whitepaper")}
              </a>
            </div>
          </div>
          <div className={styles.hero__preview} onClick={handlePlayClick}>
            <picture>
              <source srcSet={videoImage} type="image/webp" />
              <img src={videoImage} alt="preview" />
            </picture>
            <div className={styles.hero__play}>
              <PlayIcon />
              <div className={styles.desktop}>{t("Watch Video")}</div>
              <div className={styles.mobile}>{t("Site video")}</div>
              <picture className={styles.hero__play_cursor}>
                <source srcSet={cursorIcon} type="image/svg" />
                <img src={cursorIcon} alt="cursor" />
              </picture>
            </div>
          </div>
          <div className={`${styles.hero__links} ${styles.mobile}`}>
            <a className={styles.button} href="/register" id="whatwedo">
              {t("Register now with no commitment ")}
            </a>
            {/* <a
              className={styles.button}
              href={
                localStorage.getItem("i18n") != "DE"
                  ? `${FRONT_URL}/files/Whitepaper - Deutsche Anbaugesellschaft DAG GmbH-2.pdf`
                  : `${FRONT_URL}/files/DECT.pdf`
              }
              target="_blanc"
            >
              {t("Whitepaper")}
            </a> */}
          </div>
          {isShowModal ? (
            <div className={styles.hero__modal}>
              <video
                src={dagVideo}
                autoPlay
                ref={videoRef}
                controls={true}
              ></video>
              <button onClick={() => closeModal()}>
                <Cross />
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default Hero;
