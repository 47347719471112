import React from "react";
import styles from "./Reasons.module.scss";

import * as reasonsImages from "../../../../img/reasons";
import { useTranslation } from "react-i18next";

const Reasons = () => {
  const { t } = useTranslation();
  const reasons = [
    {
      image: reasonsImages.reason1,
      title: t("Certified Facility"),
      desc: t("GACP certified (Good Agricultural and Collection Practice)"),
    },
    {
      image: reasonsImages.reason5,
      title: t("Highest safety standards"),
      desc: t("The latest technology and cultivation techniques"),
    },
    {
      image: reasonsImages.reason2,
      title: t("International Team of Experts"),
      desc: t("Specialists with years of experience and know-how"),
    },
    {
      image: reasonsImages.reason3,
      title: t("Use of New Technologies"),
      desc: t("Operating systems with blockchain technology"),
    },

    {
      image: reasonsImages.reason4,
      title: t("Renewable Energies"),
      desc: t("Energy is generated on site by means of photovoltaics"),
    },
    {
      image: reasonsImages.reason6,
      title: t("100% Sustainable"),
      desc: t(
        "State-of-the-art recycling and wastewater treatment plant on the location"
      ),
    },
  ];
  return (
    <section className={styles.reasons} id="benefits">
      <div className="container">
        <h2 className={styles.reasons__title}>{t("Our success strategy")}</h2>
        <div className={styles.reasons__grid}>
          {reasons.map((reason, i) => {
            const { image, title } = reason;
            return (
              <div className={styles.tile} key={i}>
                <picture>
                  <source srcSet={image} type="image/svg+xml" />
                  <img src={image} alt={title} />
                </picture>
                <h4>{title}</h4>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Reasons;
