/** @format */

import React, {
  useEffect,
  useState,
} from 'react';
import HeaderCommon from '../../components/HeaderCommon/HeaderCommon';
import FooterCommon from '../../components/FooterCommon/FooterCommon';
import Newspaper from './Components/Newspaper/Newspaper';
import styles from './MainPage.module.scss';
import queryString from 'query-string';

import {
  Hero,
  Options,
  Stats,
  Timeline,
  MarketSales,
  Reasons,
  Banner,
  Investing,
  Packages,
  Mission,
  Questions,
  Calculator,
  Dect,
  Articles,
  MorePartners,
  News,
  StartNow,
  Module,
  Exhibitors,
} from './Components';
import Cookie from '../../components/Cookie/Cookie';
import Cookies from 'js-cookie';
import Counter from '../../components/Counter/Counter';
import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import Locations from './Components/Locations/Locations';
import Cultivation from './Components/Cultivation/Cultivation';
import { useRef } from 'react';

const MainPage = ({
  currentLang,
  setCurrentLang,
  international = false,
}) => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [inviteCode, setInviteCode] = useState(
    localStorage.getItem('referralCode') ||
      localStorage.getItem('invite_code') ||
      '',
  );

  const changeReferral = () => {
    localStorage.removeItem('intern');
    localStorage.removeItem('ref');
    localStorage.removeItem('role');
    localStorage.removeItem('level');
    localStorage.removeItem('referralCode');
    localStorage.removeItem('invite_code');
  };

  const renderCountRef = useRef(0);

  useEffect(() => {
    if (
      location.pathname.includes('register/ref')
    ) {
      changeReferral();

      localStorage.setItem('role', 'client');

      const registrationIds =
        location.pathname.split('register/');
      if (registrationIds.length > 1) {
        localStorage.setItem(
          'ref',
          registrationIds[1],
        );
        localStorage.removeItem('intern');
      }
    }
    const {
      invite_code,
      referralCode,
      level,
      c: isCalculatorShown,
    } = queryString.parse(location?.search);
    if (level) {
      localStorage.setItem('role', 'sale');
    }

    if (location?.search.length) {
      if (isCalculatorShown) {
        localStorage.setItem('isCalcShown', true);
      } else {
        localStorage.removeItem('isCalcShown');
      }

      if (invite_code) {
        changeReferral();

        setInviteCode(invite_code);

        localStorage.setItem(
          'invite_code',
          invite_code,
        );
        localStorage.setItem('level', 0);
      }

      if (referralCode) {
        changeReferral();

        setInviteCode(referralCode);
        localStorage.setItem(
          'referralCode',
          referralCode,
        );

        // setRoleRegister("sale");
      }

      if (level) {
        localStorage.setItem('level', level);
        localStorage.setItem('role', 'sale');
      } else {
        if (level) {
          localStorage.setItem('level', level);
        }

        localStorage.setItem('role', 'sale');
      }

      if (inviteCode) {
        renderCountRef.current += 1;
      }
    }
  }, []);

  useEffect(() => {
    if (international) {
      if (!localStorage.getItem('intern')) {
        localStorage.setItem('intern', id);
        localStorage.setItem('role', 'CLIENT');
        localStorage.removeItem('inviteRefCode');
        localStorage.removeItem('ref');
        localStorage.removeItem('invite_code');
        localStorage.removeItem('referralCode');
      }
    }

    if (['dect', 'dect1', 'start'].includes(id)) {
      if (
        location.pathname.includes('register')
      ) {
        navigate('/register');
      }
    }
  }, []);

  return (
    <>
      <HeaderCommon
        currentLang={currentLang}
        setCurrentLang={setCurrentLang}
      />

      <Hero />
      <Newspaper />
      {/* <Articles /> */}
      <StartNow />
      <Dect />
      {/* {localStorage
        .getItem('intern')
        .includes('dect') && ( */}
        <Calculator currentLang={currentLang} />
      {/* )} */}
      <Module />
      <Timeline />
      <Investing />
      <Stats />
      {/* {localStorage.getItem("i18n") === "DE" ? <Explanation /> : null} */}
      {/* ttmmmppp <Return /> */}
      {/* <Prognose /> */}

      {/* <Cultivation /> */}
      {/* <Options /> */}
      <section className={styles.countersWrapper}>
        <div className='container'>
          <div className={styles.counters}>
            <Counter
              number='400'
              title='Annual Consumption In Germany'
              suffix='t'
            />
            <Counter
              number='35'
              title='Of All Have Ever Smoked Cannabis'
              suffix='%'
            />

            <Counter
              number='4.5'
              decimals='1'
              title='Consumers In Germany'
              suffix=' Million'
            />

            <Counter
              number='10'
              title='Total Market In Germany'
              suffix=' Billion'
            />
          </div>
        </div>
      </section>
      {/* <MarketSales /> */}
      <Reasons />
      {/* <Ai /> */}
      {/* <NewsSystem /> */}
      {/* <Partners /> */}
      <Banner />
      <Exhibitors />
      <Locations />
      <MorePartners />
      {/* <Functions /> */}
      {/* tttmmmppp <Founders /> */}
      <Mission />
      {/* <Insured /> */}
      <Questions />
      {/* <Banner /> */}
      <News currentLang={currentLang} />
      <FooterCommon />
    </>
  );
};

export default MainPage;
