/** @format */

import React from 'react';
import styles from './Main.module.scss';
import SectionTitle from '../../../../components/SectionTitle/SectionTitle';
import { useTranslation } from 'react-i18next';
import HarvestCalculatorMock from '../../../../components/HarvestCalculator/HarvestCalculatorMock';
const Main = ({
  currentLang,
  reloadData,
  harvest,
  max,
}) => {
  const { t } = useTranslation();

  return (
    <section className={styles.marketplace}>
      <SectionTitle text={t('Harvest yields ')} />
      <div
        className={styles.marketplace__container}>
        <HarvestCalculatorMock
          harvest={harvest}
          max={max}
          reloadData={reloadData}
          currentLang={currentLang}
        />
      </div>
    </section>
  );
};

export default Main;
