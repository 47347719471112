import React, { useEffect, useState, useRef } from "react";
import "/node_modules/flag-icons/css/flag-icons.min.css";

import styles from "./Dropdown.module.scss";
import ArrowDropdown from "../../img/IconComponents/ArrowDropdown";
import cn from "classnames";
import Triangle from "../../img/IconComponents/Triangle";
import Overlay from "../Overlay/Overlay";
import { useTranslation } from "react-i18next";
import { registerLocale } from "i18n-iso-countries";
import { getAllCountries,getFilteredCountries } from "../../helpers/getCountries";
import en from "i18n-iso-countries/langs/en.json";
import de from "i18n-iso-countries/langs/de.json";

const Dropdown = ({
  value = "Germany",
  onChange,
  error,
  disabled,
  greenBorder = false,
}) => {
  const { t } = useTranslation();
  registerLocale(localStorage.getItem("i18n") != "EN" ? de : en);

  const searchRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const setCountry = (country) => {
    setIsOpen(false);
    onChange(country);
  };

  const closeModalHandler = (e) => {
    if (e.target.className === "overlay") {
      e.stopPropagation();
      setIsOpen(false);
    }
  };

  // useEffect(() => {
  //   if (value) {
  //     onChange(value);
  //   }
  // }, [value]);

  useEffect(() => {

    if (isOpen && searchRef.current) {
      searchRef.current.focus();
    }
    if (!isOpen) {
      setSearchValue("");
    }
  }, [isOpen]);

  return (
    <div className={styles.dropdown}>
      {isOpen ? <Overlay closeModalHandler={closeModalHandler} /> : null}
      <span className={styles.dropdown__label}>{t("Country")}</span>
      <div
        className={cn(
          styles.dropdown__inner,
          styles.dropdown__inner_border,
          error ? styles.dropdown__inner_border_red : ""
        )}
        onClick={() => {
          setIsOpen((prev) => !prev);
        }}
      >
        <div>{value && value.length ? t(`${value}`) : t("Germany")}</div>
        <ArrowDropdown />
      </div>
      {isOpen && (
        <div className={styles.dropdown__search}>
          <input
            ref={searchRef}
            type="search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
      )}
      {!disabled && (
        <>
          <div
            className={cn(
              styles.dropdown__block,
              isOpen ? styles.dropdown__block_active : ""
            )}
          >
            {getFilteredCountries(searchValue).length !== 0 ? getFilteredCountries(searchValue)?.map((item) => {
              const { code, name } = item;
              return (
                <div
                  className={styles.dropdown__block_value}
                  key={`${code} country`}
                  onClick={() => setCountry(name)}
                >
                  <span className={`fi fi-${code.toLowerCase()}`}></span>
                  {name}
                </div>
              );
            }) : (
              <p className={styles.dropdown__block_notFound}>{t("No countries found")}</p>
            )}
          </div>
        </>
      )}
      {error && error.length && (
        <div className={styles.dropdown__error}>
          <Triangle />
          <div>{error}</div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
