import React from 'react'
import styles from './Table.module.scss'
import cn from 'classnames'
import Status from '../../../../components/Status/Status'
import { useTranslation } from 'react-i18next'

const Table = ({ data }) => {
  const { t } = useTranslation()
  return (
    <div className={styles.table}>
      <div className={cn(styles.table__header, styles.table__row)}>
        <div className={styles.table__date}>{t('Date')}</div>
        <div className={styles.table__amount}>{t('Amount')}</div>
        <div className={styles.table__result}>{t('Result')}</div>
        <div className={styles.table__iban}>IBAN</div>
      </div>
      <>
        {data.map((item) => {
          const { created_at: request_date, amount, status, iban, id } = item
          return (
            <div className={styles.table__row} key={id}>
              <div className={cn(styles.table__date, styles.table__value)}>
                {`${new Date(request_date).getDate()}/${
                  new Date(request_date).getMonth() + 1
                }/${new Date(request_date).getFullYear()}`}
              </div>
              <div className={cn(styles.table__amount, styles.table__value)}>
                {amount}
              </div>
              <div className={styles.table__result}>
                <Status text={status} />
              </div>
              <div className={cn(styles.table__iban, styles.table__value)}>
                {iban}
              </div>
            </div>
          )
        })}
      </>
    </div>
  )
}

export default Table
