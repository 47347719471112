import React from "react";
import styles from "./AsideLink.module.scss";
import { Link } from "react-router-dom";
import cn from "classnames";
import { useLocation } from "react-router-dom";

const AsideLink = ({
  path,
  text,
  icon: Icon,
  disabled = false,
  isMob = false,
  active = false,
  admin = false,
  bold = false,
}) => {
  const location = useLocation();
  return (
    <Link
      to={path}
      state={{ from: location.pathname }}
      className={cn(
        styles.link,
        disabled ? styles.link_disabled : "",
        admin ? styles.link__admin : "",
        location.pathname === active
          ? isMob
            ? styles.link__activeMob
            : styles.link__active
          : ""
      )}
    >
      {admin ? (
        <>
          {Icon ? (
            <Icon
              color={
                disabled
                  ? "rgba(60, 60, 60, 0.249)"
                  : location.pathname === path || active
                  ? "#0F131E"
                  : "rgba(60, 60, 60, 0.249)"
              }
            ></Icon>
          ) : null}
        </>
      ) : (
        <>
          {Icon ? (
            <Icon
              color={
                disabled
                  ? "rgba(60, 60, 60, 0.249)"
                  : location.pathname === path || active
                  ? "#FFFFFF"
                  : "#D7D3C1"
              }
            ></Icon>
          ) : null}
        </>
      )}
      <div className={styles.link__text}>{text}</div>
    </Link>
  );
};

export default AsideLink;
