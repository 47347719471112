import React from "react"
import ContentLoader from "react-content-loader"

const Loader = (props) => (
  <ContentLoader 
    speed={1}
    width={322}
    height={90}
    viewBox="0 0 322 90"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <circle cx="591" cy="250" r="20" /> 
    <rect x="8" y="-1" rx="0" ry="0" width="330" height="103" />
  </ContentLoader>
)

export default Loader

