import { useState, useRef } from "react";
import styles from "./Main.module.scss";
import SectionTitle from "../../../../components/SectionTitle/SectionTitle";
import { useTranslation } from "react-i18next";
import { FRONT_URL } from "../../../../http";
import Cross from "../../../../img/IconComponents/Cross";

import prasentation from "../../../../img/Prasentation.png";
import Projektubersicht from "../../../../img/Projektubersicht.png";
import Leitfaden from '../../../../img/leitfaden.png'
import Pressestimmen from '../../../../img/Pressestimmen.png'
import explainBg from "../../../../img/explainVideo.webp";

import video from "../../../../video/videoExp.mp4";
import cloud from "../../../../img/cloud.svg";
import cn from "classnames";

const Main = ({ role }) => {
  const { t } = useTranslation();
  const [isShowModal, setIsShowModal] = useState(false);
  const videoRef = useRef(null);

  const handlePlayVideo = () => {
    setIsShowModal(true);
    if (videoRef.current) {
      videoRef.current.play();
    }
  };
  const closeModal = () => {
    setIsShowModal(false);
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };
  console.log('test')
  return (
    <section className={styles.documents}>
      <SectionTitle text={t("Sales Documents")} />
      <div
        className={cn(
          styles.documents__empty,
          role === "MAIN_SALE" ? styles.documents__empty_wrap : ""
        )}
      >
        <a
          href={`${FRONT_URL}/files/Prasentation.pdf`}
          className={styles.documents__block}
          download
        >
          <div className={styles.documents__block_wrapper}>
            <div className={styles.documents__short}>
              <img src={prasentation} alt="Präsentation" />
            </div>
            <div className={styles.documents__download}>
              <img src={cloud} alt="cloud" />
            </div>
          </div>
          <div className={styles.documents__name}>Präsentation.pdf</div>
          <div className={styles.documents__date}>
            {t("Status from:")} 13.09.2024{" "}
          </div>
        </a>
        <a
          href={`${FRONT_URL}/files/Projektubersicht.pdf`}
          className={styles.documents__block}
          download
        >
          <div className={styles.documents__block_wrapper}>
            <div className={styles.documents__short}>
              <img src={Projektubersicht} alt="Projektübersicht" />
            </div>
            <div className={styles.documents__download}>
              <img src={cloud} alt="cloud" />
            </div>
          </div>
          <div className={styles.documents__name}>Projektübersicht.pdf</div>
          <div className={styles.documents__date}>
            {t("Status from:")} 13.09.2024{" "}
          </div>
        </a>
        <a
          href={`${FRONT_URL}/files/Leitfaden.pdf`}
          className={styles.documents__block}
          download
        >
          <div className={styles.documents__block_wrapper}>
            <div className={styles.documents__short}>
              <img src={Leitfaden} alt="Leitfaden" />
            </div>
            <div className={styles.documents__download}>
              <img src={cloud} alt="cloud" />
            </div>
          </div>
          <div className={styles.documents__name}>Leitfaden.pdf</div>
          <div className={styles.documents__date}>
            {t("Status from:")} 13.09.2024{" "}
          </div>
        </a>

        <a
          href={`${FRONT_URL}/files/Pressestimmen.pdf`}
          className={styles.documents__block}
          download
        >
          <div className={styles.documents__block_wrapper}>
            <div className={styles.documents__short}>
              <img src={Pressestimmen} alt="pdf" />
            </div>
            <div className={styles.documents__download}>
              <img src={cloud} alt="cloud" />
            </div>
          </div>
          <div className={styles.documents__name}>Pressestimmen.pdf</div>
          <div className={styles.documents__date}>
            {t("Status from:")} 13.09.2024{" "}
          </div>
        </a>

        <div className={styles.documents__block2}>
          <div className={styles.documents__block_wrapper} onClick={handlePlayVideo}>
            <div className={styles.documents__short}>
              <img src={explainBg} alt="pdf" />
            </div>
          </div>
          <a
            href={`${FRONT_URL}/files/Erklaervideo_DAG.mp4`}
            download
            className={styles.cloud}
          >
            <div className={styles.documents__download}>
              <img src={cloud} alt="cloud" />
            </div>
          </a>
          <div className={styles.documents__name}>Erklaervideo_DAG.mp4</div>
          <div className={styles.documents__date}>
            {t("Status from:")} 13.04.2024{" "}
          </div>
        </div>
      </div>
      {isShowModal && (
        <div className={styles.modal}>
          <video src={video} autoPlay ref={videoRef} controls={true}></video>
          <button onClick={() => closeModal()}>
            <Cross color={"#ffffff"} />
          </button>
        </div>
      )}
    </section>
  );
};

export default Main;
