import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './Tile.module.scss'
const Tile = ({ qm, desc, className = '' }) => {
  const { t } = useTranslation()
  return (
    <div className={`${styles.tile} ${className}`}>
      <h4>
        {qm} {t('sqm')}
      </h4>
      <p>{desc}</p>
    </div>
  )
}

export default Tile
