import React, { useState, useEffect } from "react";
import styles from "./Order.module.scss";
import { useParams } from "react-router-dom";
import Aside from "../../../../../../components/Aside/Aside";
import Header from "../../../../../../components/Header/Header";
import SectionTitle from "../../../../../../components/SectionTitle/SectionTitle";
import getRequest from "../../../../../../helpers/getRequest";
import moment from "moment";
import Button from "../../../../../../components/Button/Button";
import { useTranslation } from "react-i18next";
import FooterClient from "../../../../../../components/FooterClient/FooterClient";

const Order = ({ currentLang, setCurrentLang }) => {
  const [order, setOrder] = useState({});
  const [paymentDetails, setPaymentDetails] = useState();
  const { id } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    getRequest(`v1/orders/${id}`).then(async (response) => {
      setOrder(response.data?.data);
      console.log(response.data?.data);
      if (!order) {
        window.location = "/dashboard";
      }
    });
  }, []);

  useEffect(() => {
    getRequest("v1/user/payment-details")
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setPaymentDetails(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  console.log(order.is_coinbase);

  return (
    <div className={styles.wrapper}>
      <Aside />
      <div className={styles.container}>
        <Header currentLang={currentLang} setCurrentLang={setCurrentLang} />
        <section className={styles.order}>
          <div className={styles.order__head}>
            <Button text={t("Back")} to="/finances" />
            <SectionTitle
              text={`${t("Order")} #${order?.representational_id}`}
            />
          </div>
          {order.is_coinbase ? (
            <p>
              {t(
                "Thank you for purchasing our Deutscher Cannabis Token (DECT)"
              )}
            </p>
          ) : (
            <p>
              {t(
                "Thank you for your purchase of Deutscher Cannabis Token (DECT)"
              )}
            </p>
          )}
          {order.is_coinbase ? (
            <>
              <p>
                {t(
                  "We have successfully received your payment and your order is now complete"
                )}
              </p>
              <p>
                {t(
                  "The DECT tokens will be visible in your user account in a few minutes"
                )}
              </p>
            </>
          ) : (
            <p>
              {t(
                "Your order has been successfully processed and will be confirmed as"
              ) + " "}
              {t("soon as payment is received.")}
            </p>
          )}
          {order.is_coinbase ? null : (
            <>
              <h4>
                {t("Please transfer the amount of")}{" "}
                {localStorage.getItem("i18n") === "DE"
                  ? order?.total_amount
                      ?.toLocaleString("de-DE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                      .replace(".", ",")
                  : order?.total_amount?.toLocaleString("de-DE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                € {t("to the following") + " "}
                {t("bank account:")}
              </h4>
              <div className={styles.order__transfer}>
                <div>
                  <div>{t("Account holder")}</div>
                  <div>{t("Deutsche Anbaugesellschaft DAG GmbH")}</div>
                </div>
                <div>
                  <div>IBAN</div>
                  <div>DE51 2004 0000 0130 3494 00</div>
                </div>
                <div>
                  <div>BIC</div>
                  <div>COBADEFFXXX</div>
                </div>
                <div>
                  <div>{t("Intended purpose")}</div>
                  <div>{order?.representational_id}</div>
                </div>
              </div>
            </>
          )}
          <div className={styles.order__details}>
            <h4>{t("The details of your order are as follows:")}</h4>
            <div className={styles.order__details_inner}>
              <div>
                <div>{t("Tokens")}:</div>
                <div>
                  {order?.plants_count} {t("tokens")}
                </div>
              </div>
              <div>
                <div>{t("Total amount:")}</div>
                <div>
                  {localStorage.getItem("i18n") === "DE"
                    ? order?.total_amount
                        ?.toLocaleString("de-DE", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                        .replace(".", ",")
                    : order?.total_amount?.toLocaleString("de-DE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                  €{" "}
                </div>
              </div>
              <div>
                <div>{t("Date of purchase:")}</div>
                <div>
                  {moment(order?.created_at)
                    .format("HH:mm, DD.MM.YYYY")
                    .replace(",", t(","))}
                </div>
              </div>
              {order?.is_coinbase ? (
                <div>
                  <div>{t("Payment method:")}</div>
                  <div>Coinbase</div>
                </div>
              ) : null}
            </div>
          </div>
          {order?.is_coinbase ? (
            <p>
              {t("Pay now:")}<br/>
              <a target="_blank" href={order.coinbaseLink}>{order.coinbaseLink}</a><br/>
              {t(
                "Thank you for your trust in our project and feel free to contact us for any further questions or information."
              )}
            </p>
          ) : (
            <p>
              {t(
                "As soon as we record the receipt of your payment, we will finalize your order and inform you about it by e-mail."
              )}
            </p>
          )}
        </section>
        <div className={styles.footer}>
          <FooterClient />
        </div>
      </div>
    </div>
  );
};

export default Order;
