import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './Status.module.scss'

const Status = ({ text }) => {
  const {t} =useTranslation()

  const {color, background} = useMemo(() => {
    switch(text){
      case t('Planting'):
        return {color: '#CBA10D', background: '#F8E9B6'}
      case t('Harvest'):
        return {color: '#FA8A07', background: '#F6D4AD'}
      case t('Growth'):
        return {color: '#509c5b', background: '#E9F1C5'}
      case t('Drying'):
        return {color: '#3B4CE5', background: '#BDC2F0'}
      case t('Flowering'):
        return {color: '#079EA8', background: '#ABE9ED'}
      case t('Processing'):
        return {color: '#067BE7', background: '#ADD0F0'}
      case t('Successful'):
        return {color: '#509c5b', background: '#E9F1C5'}
      case t('Pending'):
        return {color: '#CBA10D', background: '#F8E9B6'}
      case t('Failed'):
        return {color: '#CA4B15', background: '#FFC9B1'}
      case t('PLANTING'):
        return {color: '#CBA10D', background: '#F8E9B6'}
      case t('HARVEST'):
        return {color: '#FA8A07', background: '#F6D4AD'}
      case t('GROWTH'):
        return {color: '#509c5b', background: '#E9F1C5'}
      case t('DRYING'):
        return {color: '#3B4CE5', background: '#BDC2F0'}
      case t('FLOWERING'):
        return {color: '#079EA8', background: '#ABE9ED'}
      case t('PROCESSING'):
        return {color: '#067BE7', background: '#ADD0F0'}
      case t('SUCCESSFUL'):
        return {color: '#509c5b', background: '#E9F1C5'}
      case t('PENDING'):
        return {color: '#CBA10D', background: '#F8E9B6'}
      case t('Confirmed'):
        return {color: '#CA4B15', background: '#FFC9B1'}
      case t('CONFIRMED'):
        return {color: 'rgb(80, 156, 91)', background: 'rgb(233, 241, 197)'}
      default:
        return {color: '#ffffff', background: '#000000'}
    }
  }, [text])

  return (
    <div
      className={styles.status}
      style={{color, background}}
    >
      
      {text}
    </div>
  )
}

export default Status;