import React, { useEffect, useState } from "react";
import style from "./PaymentTable.module.scss";
import { useTranslation } from "react-i18next";
import Row from "./Components/Row/Row";
import getRequest from "../../../../helpers/getRequest";

const PaymentTable = ({statuses, title}) => {
  const { t } = useTranslation();
  const [payments, setPayments] = useState(null);

  useEffect(() => {
    // console.log(statuses.map((s) => 'statuses='+s).join("&") );
    getRequest(`v1/orders?${statuses.map((s) => 'statuses='+s).join("&")}`).then((response) => {
      setPayments(response.data.data);
    });
  }, []);

  return payments && payments.length ? (
    <>
      <h2 className={style.pending__title}>{title}</h2>
      <div className={style.pending__table}>
        <div className={style.pending__table_header}>
          <div>{t("Order Date")}</div>
        </div>
        {payments.map((item) => {
          return (
            <Row
              key={item.id}
              id={item.id}
              representational_id={item.representational_id}
              orderDate={item.order_creation_date}
              amount={item.total_amount}
              tokens={item.plants_count}
              is_coinbase={item.is_coinbase}
            />
          );
        })}
      </div>
    </>
  ) : null;
};

export default PaymentTable;
