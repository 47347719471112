import React from "react";
import AdminAside from "../../components/AdminAside/AdminAside";
import AdminHeader from "../../components/AdminHeader/AdminHeader";
import styles from "./AdminTokens.module.scss";
import Main from "./Components/Main/Main";

const AdminTokens = ({
  role,
  openModal,
  setIsOpenModal,
  referalValue,
  setReferalValue,
  currentLang,
  setCurrentLang,
}) => {
  return (
    <div className={styles.wrapper}>
      <AdminAside setIsOpenModal={setIsOpenModal} isOpenModal={openModal}  role={role} />
      <div className={styles.container}>
        <AdminHeader
          openModal={openModal}
          setIsOpenModal={setIsOpenModal}
          referalValue={referalValue}
          currentLang={currentLang}
          setCurrentLang={setCurrentLang}
          role={role}
        />
        <Main />
      </div>
    </div>
  );
};

export default AdminTokens;
