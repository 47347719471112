import $api from "../http";
import moment from "moment";

export default class AuthService {
  static async verifyUser(id) {
    return $api.get(`/v1/auth/verify-user/${id}`);
  }

  static async login(email, password, screen_height, screen_width, browser) {
    return $api.post("/v1/auth/login", {
      email,
      password,
      screen_height,
      screen_width,
      browser,
    });
  }

  static async check2fa(twoFactorCode, screen_height, screen_width, browser) {
    return $api.post("v1/auth/two-factor-authenticate", {
      twoFactorCode,
      screen_height,
      screen_width,
      browser,
    });
  }

  static async signup(
    first_name,
    last_name,
    email,
    password,
    repeat_password,
    phone_number,
    inviteCode,
    referalId,
    lang,
    isMale,
    street,
    appartment,
    postCode,
    city,
    country,
    dob,
    companyName,
    mlm_code,
    client_id,
    advert_id,
    referral_code_for_client
  ) {
    const data = {
      first_name,
      last_name,
      email,
      password,
      repeat_password,
      phone_number,
      ...(companyName && companyName.length && { company_name: companyName }),
      ...(inviteCode && inviteCode.length && { invite_code: inviteCode }),
      ...(referalId && referalId.length && { referral_code: referalId }),
      gender: isMale ? "MALE" : "FEMALE",
      street,
      door_no: appartment,
      postcode: postCode,
      city,
      country,
      date_of_birth: dob ? moment(dob).format("YYYY-MM-DD") : null,
      mlm_code: mlm_code !== null ? mlm_code : undefined,
      client_id,
      advert_id,
      referral_code_for_client
    };
    return $api.post("/v1/auth/register", data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        ...(lang && { "Content-Language": lang }),
      },
    });
  }

  static async logout() {
    return $api.get("/v1/auth/logout");
  }
}
