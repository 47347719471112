import styles from "./DectGrow.module.scss";

import FooterCommon from "../../components/FooterCommon/FooterCommon";
import HeaderCommon from "../../components/HeaderCommon/HeaderCommon";
import { Banner } from "../MainPage/Components";

import { useTranslation } from "react-i18next";

import dectgrow1 from "../../img/dectgrow1.svg";
import dectgrow2 from "../../img/dectgrow2.svg";
import dectgrow3 from "../../img/dectgrow3.svg";
import dectgrow4 from "../../img/dectgrow4.svg";
import dectGrowDe from "../../img/dect-grow-de.webp";
import dectGrowEn from "../../img/dect-grow-en.webp";

const DectGrow = ({ currentLang, setCurrentLang }) => {
  const { t } = useTranslation();

  return (
    <>
      <HeaderCommon currentLang={currentLang} setCurrentLang={setCurrentLang} />
      <main className={styles.dectGrow}>
        <section className={styles.hero}>
          <div className="container">
            <div className={styles.hero__inner}>
              <h1>{t("DECT-Grow Cultivation Management System")}</h1>
              <p>
                {t(
                  "The ‘DECT-Grow’ cultivation management system offers cannabis social clubs and cultivation companies the opportunity to not only manage cultivation from A to Z, but also to ensure maximum efficiency by using a unique AI model. By integrating sensor data, RFID data, manual data input and visual data, the system enables precise control of elementary processes such as lighting, irrigation, fertilization and climate control. In view of the high energy costs and other financial challenges, efficient use of resources and profitable cultivation are of crucial importance. Cannabis social clubs or home growers can also access the data from DECT-Grow via interfaces in order to retrieve the cultivation technology configurations recommended by the AI."
                )}
              </p>
            </div>
          </div>
        </section>
        <section className={styles.info}>
          <div className="container">
            <div className={styles.info__inner}>
              <div className={styles.info__inner_block}>
                <div>
                  <img src={dectgrow1} alt="dectgrow1" height={100} />
                </div>
                <div>
                  <h3>{t("Maximized Yields Through Precision Control")}</h3>
                  <p>
                    {t(
                      "DECT-Grow ensures healthier plants and significantly increased yields by precisely controlling the light spectrum at every growth phase."
                    )}
                  </p>
                </div>
              </div>
              <div className={styles.info__inner_block}>
                <div>
                  <img src={dectgrow2} alt="dectgrow2" height={100} />
                </div>
                <div>
                  <h3>
                    {t(
                      "Efficient Resource Utilization with Automated Irrigation"
                    )}
                  </h3>
                  <p>
                    {t(
                      "DECT-Grow optimizes water usage with its automatic irrigation system, tailored to plant needs and soil moisture, promoting sustainable resource management."
                    )}
                  </p>
                </div>
              </div>
              <div className={styles.info__inner_block}>
                <div>
                  <img src={dectgrow3} alt="dectgrow3" height={100} />
                </div>
                <div>
                  <h3>{t("Climate Optimization for Energy Savings")}</h3>
                  <p>
                    {t(
                      "DECT-Grow's intelligent climate control reduces reliance on air conditioning technologies, leading to substantial energy savings through optimized ventilation."
                    )}
                  </p>
                </div>
              </div>
              <div className={styles.info__inner_block}>
                <div>
                  <img src={dectgrow4} alt="dectgrow4" width={100} />
                </div>
                <div>
                  <h3>{t("Informed Decision-Making with AI Recommendations")}</h3>
                  <p>
                    {t(
                      "DECT-Grow's AI algorithms provide precise recommendations for crop management, empowering growers with informed decision-making and proactive measures."
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={styles.image}>
          <div className="conatainer">
            <div className={styles.image__inner}>
              {currentLang.lang === "DE" ? (
                <img src={dectGrowDe} alt="dectgrow" width={1024} />
              ) : (
                <img src={dectGrowEn} alt="dectgrow" width={1024} />
              )}
            </div>
          </div>
        </section>
        <section className={styles.problem}>
          <div className="container">
            <h2 className={styles.title}>{t("Problem Statement and Scope")}</h2>
            <div className={styles.problem__inner}>
              <div>
                <p>
                  {t(
                    "The “DECT-Grow” system is an innovative and AI-supported cultivation management solution that helps cannabis social clubs and cultivation companies to plan, manage and organize the cultivation of cannabis plants more efficiently. The system addresses various challenges in the cultivation process and offers a wide range of features to improve cultivation management. These include functions such as monitoring environmental conditions, automatic and AI-controlled lighting, irrigation, nutrient management and climate control, as well as disease and pest control and harvest time prediction."
                  )}
                </p>
                <br />
                <p>
                  {t(
                    "By integrating AI algorithms, the system can analyze complex data and provide precise recommendations for crop management. DECT-Grow has integrated a self-learning AI algorithm that uses sensor data from various sources such as soil moisture sensors, humidity and temperature sensors and imaging sensors, and actively uses and learns from the data. The collected sensor data is continuously recorded and transmitted to DECT-Grow. This sensor data is processed in real time and stored in a central database. The database enables long-term storage and analysis of the cultivation information. This allows trends to be recognized, patterns to be identified and cultivation management to be continuously improved."
                  )}
                </p>
              </div>
              <p>
                {t(
                  "The implementation of artificial intelligence (AI) plays a crucial role in the DECT-Grow solution. By using machine learning and data analysis algorithms, the system can learn from the collected data and automatically make adjustments to the cultivation parameters. This leads to continuous optimization of the cultivation process by improving harvest results through higher productivity and efficiency, because those responsible are more precise and better informed with the help of DECT-Grow. By detecting problems such as diseases or nutrient deficiencies at an early stage, measures can be taken in good time to minimize crop losses. DECT-Grow also enables better planning and resource allocation, as cultivation decisions are based on sound data and AI-supported recommendations."
                )}
              </p>
            </div>
          </div>
        </section>
        <section className={styles.target}>
          <div className="container">
            <h2 className={styles.title}>{t("Target Group and Benefits")}</h2>
            <div className={styles.target__inner}>
              <p>
                {t(
                  "The DECT-Grow system is being developed specifically for professional growers in the emerging cannabis industry. Especially for new cannabis social clubs and cultivation companies in Germany that want to benefit from the legalization of recreational cannabis, DECT-Grow will prove to be highly relevant and beneficial. Given the increasing demand and high costs associated with cannabis cultivation, the system represents a technological solution to optimize cultivation management and increase profitability. The target audience for DECT-Grow includes cannabis social clubs as well as larger commercial operations. For new growers, the system provides an effective way to establish efficient and sustainable cannabis production from the outset. Experienced growers can benefit from the advanced automation technology to further increase their yields and utilize their resources more efficiently. In addition, DECT-Grow can be integrated into existing IT infrastructures and connected to other systems via the interface function."
                )}
              </p>
              <p>
                {t(
                  "The benefit of DECT-Grow lies in its ability to maximize yields while optimizing the use of energy, water and other resources. By precisely controlling the lighting, the system ensures that the plants receive the ideal light spectrum for their respective growth phase, resulting in healthier growth and higher yields. Automatic irrigation control based on the specific needs of the cannabis plants and soil moisture enables efficient water use while reducing water consumption. Along with this, DECT-Grow helps to optimize ventilation to create the ideal climate for cannabis plant growth. As a result, the use of air conditioning technologies can be reduced, leading to significant energy savings. By combining all these functions, the system enables a more sustainable and cost-efficient production of high-quality cannabis."
                )}
              </p>
            </div>
          </div>
        </section>
        <Banner />
      </main>
      <FooterCommon />
    </>
  );
};

export default DectGrow;
