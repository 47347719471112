// import mokupNFT from "../../img/mokupNFT.webp";
import plantVector from "../../img/plantVector.png";
import mokupToken from "../../img/mokupToken.webp";
import styles from "./DashboardBanner.module.scss";
import Button from "../Button/Button";
import { useTranslation } from "react-i18next";

const benefits = [
  {
    id: 0,
    title: "We plant & harvest",
  },
  {
    id: 1,
    title: "4 harvests per year ",
  },
  {
    id: 2,
    title: "You receive 200g per year",
  },
  {
    id: 3,
    title: "Unlimited duration",
  },
];

const DashboardBanner = ({ button = true }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.banner}>
      <div className={styles.banner__left}>
        <h2>{t("German Cannabis Token ")}</h2>
        <div>
          <img src={mokupToken} alt="mockup token" width={276} />
          {button && (
            <Button to="/buy" text={t("Buy Token Now")} color="green" />
          )}
        </div>
      </div>
      <div className={styles.banner__right}>
        <div className={styles.banner__right_inner}>
          <div>
            <picture>
              <img src={plantVector} alt="plant" width={120} height={210} />
            </picture>
            <div className={styles.banner__grid}>
              <h3>{t("Your plant")}</h3>
              {benefits.map((b) => (
                <div key={b.id}>
                  <span></span>
                  {b.isLink ? (
                    <a href="/shop">
                      <h4>{t(b.title)}</h4>
                    </a>
                  ) : (
                    <h4>{t(b.title)}</h4>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardBanner;
