import React from 'react'
import styles from './Tab.module.scss';
import cn from 'classnames';

const Tab = ({ text, active, action }) => {
  return (
    <div 
      className={cn(styles.tab, active ? styles.tab__active : '')}
      onClick={action}
    >
      {text}
    </div>
  )
}

export default Tab;