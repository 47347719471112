import React from 'react'
import styles from './AnchorLink.module.scss'
import cn from 'classnames'
import { useLocation } from 'react-router-dom'

const AnchorLink = ({ path, text, icon: Icon, isMob = false, active }) => {
  const location = useLocation()
  return (
    <a
      href={path}
      className={cn(
        styles.link,
        location.pathname === path || active
          ? isMob
            ? styles.link__activeMob
            : styles.link__active
          : '',
      )}
    >
      {Icon ? (
        <Icon
          color={location.pathname === path || active ? '#0F131E' : 'red'}
        ></Icon>
      ) : null}
      {isMob ? (
        location.pathname.includes(path) && (
          <div className={styles.link__text}>{text}</div>
        )
      ) : (
        <div className={styles.link__text}>{text}</div>
      )}
    </a>
  )
}

export default AnchorLink
