import React, { useEffect, useContext } from "react";
import styles from "./Logout.module.scss";
import { Context } from "../../index";
import { useObserver } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Logout = () => {
  const navigate = useNavigate();
  const { store } = useContext(Context);
  const { t } = useTranslation();

  const { showMainPage } = useObserver(() => ({
    isAuth: store.isAuth,
    registred: store.registred,
    showMainPage: store.showMainPage,
  }));

  useEffect(() => {
    if (showMainPage) {
      navigate("/login");
    }
  }, [showMainPage]);

  return (
    <button className={styles.logout} onClick={() => store.logout()}>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.64706 21H5.64706C5.11662 21 4.60792 20.7893 4.23284 20.4142C3.85777 20.0391 3.64706 19.5304 3.64706 19V5C3.64706 4.46957 3.85777 3.96086 4.23284 3.58579C4.60792 3.21071 5.11662 3 5.64706 3H9.64706"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.6471 17L21.6471 12L16.6471 7"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.6471 12H9.64706"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <span>{t("Log out")}</span>
    </button>
  );
};

export default Logout;
