/** @format */

import React from 'react';
import styles from './ModalNotBlured.module.scss';
import Button from '../Button/Button';

const ModalNotBlured = ({
  onClose,
  children,
  text
}) => {
  return (
    <div className={styles.modal}>
      <div className={styles.modal__inner}>
        {children}
        
      </div>
    </div>
  );
};

export default ModalNotBlured;
