import React from "react";
import styles from "./Return.module.scss";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
ChartJS.defaults.color = "#000000cc";

export const options = {
  indexAxis: "y",
  plugins: {
    legend: {
      display: false,
    },
  },
  responsive: true,
  scales: {
    y: {
      grid: {
        display: false,
      },
    },
  },
  barPercentage: 1,
};

const Return = () => {
  const { t } = useTranslation();
  const labels = [
    t("Deutsche Anbaugesellschaft DAG"),
    t("Gold"),
    t("German share index"),
    t("Life insurance"),
    t("Call money"),
    t("Euro"),
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Mlrd $",
        data: [20, 9.5, 7.5, 2.5, 1.25, 0],
        backgroundColor: "#509C5B",
      },
      {
        label: "Mlrd $ 2",
        data: [0, 0, 0, 0, 0, -9],
        backgroundColor: "#CA4B15",
      },
    ],
  };
  return (
    <section className={styles.return}>
      <div className="container">
        <h2>{t("Market comparison")}</h2>
        <div className={styles.return__inner}>
          <div>
            <Bar options={options} data={data} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Return;
