/** @format */

import React, { useEffect, useState } from "react";
import styles from "./AdminAside.module.scss";
import logo from "../../img/logo.png";
import admin from "../../img/admin.png";
import AsideLink from "./components/AsideLink/AsideLink"
import DashboardIcon from "../../img/IconComponents/DashboardIcon";
import FinancesIcon from "../../img/IconComponents/FinancesIcon";
import GearIcon from "../../img/IconComponents/GearIcon";
import NewsIcon from "../../img/IconComponents/NewsIcon";
import HouseIcon from "../../img/house.svg";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import getRequest from "../../helpers/getRequest";
import account from "../../img/account.png";
import axios from "axios";
import { API_URL } from "../../http";
import { copyText } from "../../helpers/copyText";
import copyIcon from "../../img/copy.svg";
import cn from "classnames";
import { FRONT_URL } from "../../http";
import InfoIcon from "../../img/IconComponents/Info";
import { Link } from "react-router-dom";
import Button from "../Button/Button";
import postRequest from "../../helpers/postRequest";

const AdminAside = ({ setIsOpenModal, isOpenModal, role }) => {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [isCopied, setIsCopied] = useState();
  const [linkCopied, setLinkCopied] = useState();
  const [email, setEmail] = useState();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 840);
  const [salesLevel, setSalesLevel] = useState(null);

  const [createSaleLink, setCreateSaleLink] = useState();
  const [createClientLink, setCreateClientLink] = useState();


  const handleCopy = () => {
    copyText(createSaleLink);
    setIsCopied(true);
    localStorage.removeItem('inviteRefCode')
    const timer = setTimeout(() => {
      setIsCopied(false);
    }, [2000]);

    return () => {
      clearTimeout(timer);
    };
  };

  const handleCopyLink = () => {
    copyText(createClientLink);

    setLinkCopied(true);
    const timer = setTimeout(() => {
      setLinkCopied(false);
    }, [2000]);

    return () => {
      clearTimeout(timer);
    };
    // setIsCopied(true)
    // const timer = setTimeout(() => {
    // setIsCopied(false)
    // }, [2000])

    // return () => {
    //   clearTimeout(timer)
    // }
  };

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 840);
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    getRequest("v1/auth/whoami")
      .then((response) => {
        setFirstName(response.data?.first_name);
        setLastName(response.data?.last_name);
        setEmail(response.data?.email);
      })
      .catch((error) => {
        console.log(error);
      });

    getRequest("v1/local-files/profile-picture", true).then((response) => {
      setProfilePhoto(response.data);
    });
    getRequest("v1/statistics/dashboard/sale").then((res) =>
      setSalesLevel(res.data.level)
    );
  }, []);

  useEffect(() => {
    if (role === "SALE") {
      getRequest("v1/referrals/my-code")
        .then((response) => {
          setCreateSaleLink(response.data.invite_link);
          setCreateClientLink(response.data.invite_client_link);
        })
        .catch((error) => console.log(error));
    }
    if (role === "HEAD_OF_SALES") {
      if (!localStorage.getItem("inviteRefCode")) {
        postRequest("v1/referral-invites")
          .then((response) => {
            setCreateSaleLink(response.data.invite_link);
            localStorage.setItem("inviteRefCode", response.data.invite_link);
          })
          .catch((error) => console.log(error));
      } else{
        setCreateSaleLink(localStorage.getItem("inviteRefCode"));
      }
    }
  
  }, [role]);

  return (
    <aside className={styles.aside}>
      <Link to={"/"}>
        <img src={logo} alt="logo" />
      </Link>
      <div className={styles.aside__overflow}>
        <div className={styles.aside__info_wrapper}>
          <div className={styles.aside__info}>
            <div className={styles.aside__info_photo}>
              {profilePhoto?.size ? (
                <img src={URL.createObjectURL(profilePhoto)} alt="" />
              ) : (
                <img src={account} alt="admin" />
              )}
            </div>
            <div className={styles.aside__info_name}>
              {firstName} {lastName}
            </div>
            {role === "HEAD_OF_SALES" || role === "SALE" ? (
              <div className={styles.aside__info_status}>
                <div className={styles.aside__info_status_title}>Status:</div>
                <div className={styles.aside__info_status_status}>
                  {role === "HEAD_OF_SALES" && "Head of Sales"}
                  {role === "SALE" &&
                    t("Sale") + " (" + t("Level ") + salesLevel + ")"}
                </div>
              </div>
            ) : null}
            <Button to="/" text="To Home Page" color="home" icon={HouseIcon} />
          </div>
          <div className={styles.aside__links}>
            <AsideLink
              icon={DashboardIcon}
              path="/admin/dashboard"
              text={t("Dashboard")}
              isMob={isMobile}
              admin={true}
            />
            <AsideLink
              icon={FinancesIcon}
              path="/admin/finances"
              text={t("Finances")}
              isMob={isMobile}
              admin={true}
            />
            <AsideLink
              icon={NewsIcon}
              path="/admin/documents"
              text={t("Sales Documents")}
              isMob={isMobile}
              admin={true}
            />
            <AsideLink
              icon={GearIcon}
              path={"/admin/settings"}
              text={t("Settings")}
              isMob={isMobile}
              admin={true}
            />
            {(role == "HEAD_OF_SALES" ||
              (role == "MAIN_SALE" &&
                email === "top@anbaugesellschaft.de")) && (
              <>
                <button
                  className={styles.aside__button}
                  onClick={() => setIsOpenModal(true)}
                >
                  {t("Generate referral")}
                </button>
                <div className={styles.aside__head_note}>
                  <InfoIcon color="#0F131E" />
                  {t(
                    "Each time you click on the button, a new invitation link will be generated."
                  )}
                </div>
              </>
            )}

            {(role === "SALE") && (
              <>
                {createClientLink && (
                  <>
                    <div className={styles.aside__ref}>
                      <div className={styles.aside__ref_title}>
                        {t("Invite Clients")}
                      </div>
                      <div className={styles.aside__ref_link}>
                        {createClientLink}
                      </div>
                      <button
                        className={styles.aside__ref_copy}
                        onClick={() => handleCopyLink()}
                      >
                        <img src={copyIcon} alt="copy" />
                      </button>
                    </div>
                    <div className={styles.aside__copy_container}>
                      <span
                        className={cn(
                          styles.aside__copy_state,
                          linkCopied ? styles.aside__copy_state_active : ""
                        )}
                      >
                        {t("Copied to clipboard")}
                      </span>
                    </div>
                  </>
                )}
                {createSaleLink && (
                  <div className={styles.aside__inner_wrapper}>
                    <div className={styles.aside__inner_code}>
                      <div className={styles.aside__inner_code_text}>
                        {t("Invite Sales")} <br />
                      </div>
                      <div className={styles.aside__inner_code_wrapper}>
                        <div>{createSaleLink}</div>

                        <button
                          className={styles.aside__copy}
                          onClick={() => handleCopy()}
                        >
                          <img src={copyIcon} alt="copy" />
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                <div className={styles.aside__copy_container}>
                  <span
                    className={cn(
                      styles.aside__copy_state,
                      isCopied ? styles.aside__copy_state_active : ""
                    )}
                  >
                    {t("Copied to clipboard")}
                  </span>
                </div>

                {/* <div className={styles.aside__sale_tip}>
                  <InfoIcon color="#0F131E" />
                  <div>
                    {t(
                      "The following Referral ID is used for the technical registration of the mediated orders.A token purchase without Referral ID is not possible."
                    )}
                  </div>
                </div> */}
              </>
            )}
            <br />
            <br />
          </div>
        </div>
      </div>
    </aside>
  );
};

export default AdminAside;
