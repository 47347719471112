import React from "react";
import styles from "./ModalPolicy.module.scss";
import { useTranslation } from "react-i18next";
import { FRONT_URL } from "../../http/";
import { RxCross2 } from "react-icons/rx";
const ModalFirstEnter = ({ setOpen, setIsConfirmed }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.modal}>
      <div className={styles.modal__inner}>
        <div className={styles.modal__title}>
          {t("Cancellation Policy for Consumers")}
        </div>
        <div className={styles.modal__content}>
          <div className={styles.modal__content_text}>
            {t(
              "Consumers, i.e., any natural person who enters into a contract for a purpose that is predominantly neither commercial nor their independent professional activity (§ 13 BGB), have a statutory right of withdrawal in the case of a distance selling transaction, about which information is provided below according to the statutory model."
            )}
          </div>
          <div className={styles.modal__content_title}>
            {t("Right of withdrawal")}
          </div>
          <div className={styles.modal__content_text}>
            {t(
              "You have the right to withdraw from this contract within fourteen days without giving any reason."
            )}
          </div>
          <div className={styles.modal__content_text}>
            {t(
              "The withdrawal period is fourteen days from the day on which you have received the token."
            )}
          </div>
          <div className={styles.modal__content_text}>
            {t("To exercise your right of withdrawal, you must inform us.")}
          </div>
          <div className={styles.modal__content_text}>
            Deutsche Anbaugesellschaft DAG GmbH
          </div>
      
          <div className={styles.modal__content_title}>Libnower Landstr. 1</div>
          <div className={styles.modal__content_text}>17390 Murchin</div>
          <div className={styles.modal__content_text}>{t("Germany")}</div>
          <div className={styles.modal__content_text}>
            {t("Phone: ")} +49 40 5247 4001
          </div>
          <div className={styles.modal__content_text}>
            {t("Email: ")}
            <a href="mailto:info@anbaugesellschaft.de">
              info@anbaugesellschaft.de
            </a>
          </div>
          <div className={styles.modal__content_text}>
            {t(
              "by means of a clear declaration (e.g., a letter sent by post or email) of your decision to withdraw from this contract."
            )}
          </div>
          <div className={styles.modal__content_text}>
            {t(
              "You may use the attached model withdrawal form, but it is not obligatory."
            )}
          </div>
          <div className={styles.modal__content_text}>
            {t(
              "To meet the withdrawal deadline, it is sufficient for you to send your communication concerning your exercise of the right of withdrawal before the withdrawal period has expired."
            )}
          </div>
          <div className={styles.modal__content_title}>
            {t("Consequences of Withdrawal")}
          </div>
          <div className={styles.modal__content_text}>
            {t(
              "If you withdraw from this contract, we shall reimburse to you all payments received from you, including the costs of delivery (with the exception of the supplementary costs resulting from your choice of a type of delivery other than the least expensive type of standard delivery offered by us) without undue delay and in any event not later than fourteen days from the day on which we are informed about your decision to withdraw from this contract. We will carry out such reimbursement using the same means of payment as you used for the initial transaction unless you have expressly agreed otherwise; in any event, you will not incur any fees as a result of such reimbursement."
            )}
          </div>
          <div className={styles.modal__content_text}>
            {t(
              "We may withhold reimbursement until we have received the token back, or you have supplied evidence of having sent back the token, whichever is the earliest. You must transfer the token back to us without undue delay and in any event not later than 14 days from the day on which you communicate your withdrawal from this contract to us."
            )}
          </div>
          <div className={styles.modal__content_text}>
            {t(
              "The deadline is met if you carry out the transaction for the return transfer of the token before the period of 14 days has expired. You will bear the direct transaction costs for returning the token to us."
            )}
          </div>
          <div className={styles.modal__content_text}>
            {localStorage.getItem("i18n") !== "DE" ? (
              <a href={`${FRONT_URL}/files/Revocation Form.docx`}>
                Model Withdrawal Form
              </a>
            ) : (
              <a href={`${FRONT_URL}/files/Widerrufsformular.docx`}>
                Muster-Widerrufsformular
              </a>
            )}
          </div>
          <div className={styles.modal__content_text}>
            {t(
              "(If you want to withdraw from the contract, please fill out this form and send it back.)"
            )}
          </div>
          <div className={styles.modal__content_text}>{t("to ")}</div>
          <div>{t("Deutsche Anbaugesellschaft DAG GmbH")}</div>
         
          <div>Libnower Landstr. 1</div>
          <div>17390 Murchin</div>
          <div>
            {t("Email: ")}
            <a href="mailto:info@deutsche-anbaugesellschaft.de">
              info@deutsche-anbaugesellschaft.de
            </a>
          </div>
          <div className={styles.modal__footer}>
            <div className={styles.modal__footer_btns}>
              <button
                className={styles.modal__footer_btn}
                onClick={setIsConfirmed}
              >
                {t("Accept")}
              </button>
            </div>
          </div>
        </div>
        <div className={styles.modal__close} onClick={() => setOpen(false)}><RxCross2 /></div>
      </div>
    </div>
  );
};

export default ModalFirstEnter;
