import React from 'react'
import styles from './SectionTitle.module.scss'

const SectionTitle = ({ text, inactive = false, onClick }) => {
  return (
    <h2 className={`${styles.title} ${inactive && styles.inactive}`} onClick={onClick}>
      {text}
    </h2>
  )
}

export default SectionTitle;