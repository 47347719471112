/** @format */

import React from "react";
import styles from "./Banner.module.scss";
// import cursorIcon from "../../../../img/cursor.svg";
import Button from "../../../../components/Button/Button";
import { useTranslation } from "react-i18next";
import mokupToken from "../../../../img/mokupToken.webp";
const Banner = () => {
  const { t } = useTranslation();
  return (
    <section className={styles.banner}>
      <div className="fullContainer">
        <div className={styles.banner__wrapper}>
          <div className={styles.banner__image}>
            <img src={mokupToken} alt="token" />
          </div>
          <div className={styles.banner__right}>
            <h3>{t("Access to your own plant with harvest yields")}</h3>
            <div className={styles.banner__right_down}>
              <Button color="buy" text={t("Secure token now")} to="/buy" />
              {/* <img src={cursorIcon} alt="cursor" className={styles.cursor} /> */}
            </div>
          </div>
          {/* <div className={`${styles.banner__image} ${styles.desktop}`}>
            <img src={money} alt="token" />
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default Banner;
