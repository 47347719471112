import React from "react";
import styles from "./MenuLink.module.scss";
import { Link } from "react-router-dom";
import cn from "classnames";

const MenuLink = ({
  path,
  text,
  icon: Icon,
  disabled = false,
  active = false,
  setIsHeaderMobileOpen,
  additional = false,
  ...props
}) => {
  return (
    <Link
      to={path}
      className={cn(
        styles.link,
        disabled ? styles.disabled : "",
        active ? styles.active : "",
        additional ? styles.additional : ""
      )}
      onClick={() => setIsHeaderMobileOpen(false)}
      {...props}
    >
      {Icon && <Icon></Icon>}
      <span>{text}</span>
    </Link>
  );
};

export default MenuLink;
