import React, { useEffect, useState } from "react";
import styles from "./AdminDashboard.module.scss";
import AdminAside from "../../components/AdminAside/AdminAside";
import AdminHeader from "../../components/AdminHeader/AdminHeader";
import Main from "./Components/Main";
import getRequest from "../../helpers/getRequest";
import postRequest from "../../helpers/postRequest";
import ModalFirstEnterSale from "../../components/ModalFirstEnterSale/ModalFirstEnterSale";
import ModalSaleUnderHack from "../../components/ModalSaleUnderHack/ModalSaleUnderHack";

const AdminDashboard = ({
  openModal,
  role,
  setIsOpenModal,
  referalValue,
  currentLang,
  setCurrentLang,
}) => {
  // const [isShowModal, setIsShowModal] = useState(false);
  const [isShowSaleModal, setIsShowSaleModal] = useState(false);
  const [salesLevel, setSalesLevel] = useState(null);
  const [showContract, setShowContract] = useState(false);

  const sendAccept = () => {
    postRequest("v1/auth/read-contract")
      .then((response) => {
        console.log(response);
        window.location.reload();
        setShowContract(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getRequest("v1/auth/whoami")
      .then((response) => {
        // if (
        //   role === "HEAD_OF_SALES" &&
        //   response.data?.is_rules_accepted !== true
        // ) {
        //   setIsShowModal(true);
        // } else {
        //   setIsShowModal(false);
        // }
        console.log("RESPONSE\n",response.data)
        if (response.data.show_contract === true) {
          setShowContract(false);
        } else {
          setShowContract(true);
        }

        // if (role === "SALE" && response.data?.show_contract === true) {
        //   setIsShowSaleModal(true);
        // } else {
        //   setIsShowSaleModal(false);
        // }
      })
      .catch((error) => {
        console.log(error);
      });
    getRequest("v1/statistics/dashboard/sale").then((res) =>
      setSalesLevel(res.data.level)
    );
  }, []);

  return (
    <div className={styles.wrapper}>
      <AdminAside
        setIsOpenModal={setIsOpenModal}
        isOpenModal={openModal}
        role={role}
      />
      <div className={styles.container}>
        <AdminHeader
          openModal={openModal}
          setIsOpenModal={setIsOpenModal}
          referalValue={referalValue}
          currentLang={currentLang}
          setCurrentLang={setCurrentLang}
          role={role}
        />
        <Main
          isOpenModal={openModal}
          currentLang={currentLang}
          salesLevel={salesLevel}
          role={role}
          setIsOpenModal={setIsOpenModal}
        />
      </div>
      {/* {isShowModal ? <ModalFirstEnter sendAccept={sendAccept} /> : null} */}
      {isShowSaleModal ? <ModalFirstEnterSale sendAccept={sendAccept} /> : null}

      {showContract && (
        <ModalSaleUnderHack
          lvl={salesLevel}
          sendAccept={sendAccept}
        />
      )}
    </div>
  );
};

export default AdminDashboard;
