import React from "react";
import styles from "./BalanceBlock.module.scss";

const BalanceBlock = ({ label, value, dimension }) => {
  return (
    <div className={styles.block}>
      <div className={styles.block__label}>{label}</div>
      <div className={styles.block__wrapper}>
        <div className={styles.block__value}>
          {parseFloat(value).toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
        <div className={styles.block__dimension}>{dimension}</div>
      </div>
    </div>
  );
};

export default BalanceBlock;
