import React, { useEffect } from "react";
import styles from "./CheckEmail.module.scss";
import HeaderCommon from "../../components/HeaderCommon/HeaderCommon";
import FooterCommon from "../../components/FooterCommon/FooterCommon";
import confirm from "../../img/confirm.svg";
import Button from "../../components/Button/Button";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

const CheckEmail = ({ text, currentLang, setCurrentLang }) => {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <HeaderCommon currentLang={currentLang} setCurrentLang={setCurrentLang} />
      <section className={styles.check}>
        <img
          src={confirm}
          alt="confirm"
          data-aos="fade-up"
          data-aos-duration="1000"
        />
        <h2 className={styles.check__title}>{text}</h2>
        <div className={styles.check__descr}>
          {t("Please check you mailbox.")}
        </div>
        <Button to="/" text={t("Go to Main page")} color="green" />
      </section>
      <FooterCommon />
    </>
  );
};

export default CheckEmail;
