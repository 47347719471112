import React, { useEffect, useRef, useState } from "react";
import styles from "./PhoneCountrySelect.module.scss";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import ArrowDropdown from "../../img/IconComponents/ArrowDropdown";
import cn from "classnames";
import Element from "./Components/Element/Element";
import Overlay from "../Overlay/Overlay";
import { useTranslation } from "react-i18next";
import { countries as countriesList } from "countries-list";
import { getAllCountries } from "../../helpers/getCountries";

const PhoneCountrySelect = ({
  currentLang,
  setCurrentLang,
  setCountryCode,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const menuRef = useRef();

  const setLang = (lang) => {
    console.log(lang)
    setIsOpen(false);
    setCurrentLang(lang);
    setCountryCode(`+${lang.phoneCode}`)  
  };

  const closeModalHandler = (e) => {
    if (e.target.className === "overlay") {
      e.stopPropagation();
      setIsOpen(false);
    }
  };

  useEffect(() => {
    menuRef.current.scrollTo(0, 0);
  }, [currentLang]);

  return (
    <div className={cn(styles.lang, isOpen ? styles.lang__open : "")}>
      {isOpen ? <Overlay closeModalHandler={closeModalHandler} /> : null}
      <div
        className={styles.lang__info}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <span className={`fi fi-${currentLang.code?.toLowerCase()}`}></span>
        <ArrowDropdown rotate={isOpen ? "180deg" : "0deg"} />
      </div>
      <div className={styles.lang__menu} ref={menuRef}>
        {getAllCountries()?.map((lang) => {

          return (
            <Element
              text={lang.name}
              key={`phone ${lang.code}`}
              code={lang.code}
              phoneCode={lang.phoneCode}
              action={() =>setLang(lang)}
              active={currentLang === lang}
            />
          );
        })}
      </div>
    </div>
  );
};

export default PhoneCountrySelect;
