import React from 'react'
import styles from './Notification.module.scss'

const Notification = ({ icon, description, color }) => {
  return (
    <div className={color === 'red' ? styles.notificationRed:styles.notificationGreen}>
      <img
        className={styles.notificationRed__icon}
        src={icon}
        alt="notification"
      />
      <div className={color === 'red' ?  styles.notificationRed__description:styles.notificationGreen__description}>
        {description}
      </div>
    </div>
  )
}

export default Notification
