import React, { useEffect, useMemo, useState } from "react";
import styles from "./Main.module.scss";
import SectionTitle from "../../../../components/SectionTitle/SectionTitle";
import square from "../../../../img/square.svg";
import camplant from "../../../../img/camplant.webp";
import camera1 from "../../../../img/camera1.webp";
import camera2 from "../../../../img/camera2.webp";
import camera3 from "../../../../img/camera3.webp";
import camera4 from "../../../../img/camera4.webp";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "../../../../styling/swiper.css";
import standort1 from "../../../../img/standort1.webp";
import standort2 from "../../../../img/standort2.webp";
import standort3 from "../../../../img/standort3.webp";
import standort4 from "../../../../img/standort4.webp";
import getRequest from "../../../../helpers/getRequest";
import { useTranslation } from "react-i18next";
import cn from "classnames";

const Main = () => {
  const { t } = useTranslation();
  const camsPlaceholders = [
    {
      id: t("Unavailable"),
      camera_url: camera1,
      isOnline: false,
    },
    {
      id: t("Unavailable"),
      camera_url: camera2,
      isOnline: false,
    },
    {
      id: t("Unavailable"),
      camera_url: camera3,
      isOnline: false,
    },
    {
      id: t("Unavailable"),
      camera_url: camera4,
      isOnline: false,
    },
  ];

  const [activeBuilding, setActieBuilding] = useState(0);
  const [buildings, setBuildings] = useState(null);
  const [activeCamera, setActiveCamera] = useState(1);

  const { building_images, building_size, building_description, halls } =
    useMemo(
      () => buildings?.[activeBuilding + 1] || {},
      [buildings, activeBuilding]
    );

  const cams = useMemo(() => halls?.[1].cameras || camsPlaceholders, [halls]);
  const {
    camera_url,
    id: cameraId,
    updated_at,
    isOnline = true,
  } = useMemo(() => cams?.[activeCamera] || {}, [cams, activeCamera]);

  useEffect(() => {
    getRequest("v1/locations/buildings")
      .then((response) => {
        if (response.status === 200) {
          setBuildings(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <section className={styles.location}>
      {buildings?.length ? (
        <div className={styles.location__buildings}>
          <div className={styles.location__buildings_title}>
            <Swiper
              spaceBetween={16}
              slidesPerView={"auto"}
              scrollbar={{ draggable: true }}
              modules={[Scrollbar]}
              // breakpoints={{
              //   // when window width is >= 320px
              //   320: {
              //     slidesPerView: 2,
              //     spaceBetween: 20,
              //   },
              //   // when window width is >= 480px
              //   768: {
              //     slidesPerView: 3,
              //     spaceBetween: 30,
              //   },
              //   // when window width is >= 640px
              //   991: {
              //     slidesPerView: 4,
              //     spaceBetween: 16,
              //   },
              //   1250: {
              //     slidesPerView: 5,
              //     spaceBetween: 16,
              //   },
              //   1500: {
              //     slidesPerView: 6,
              //     spaceBetween: 16,
              //   },
              // }}
            >
              {buildings?.map((el, i) => (
                <SwiperSlide key={i}>
                  <SectionTitle
                    text={`${t("Building")} #${i + 1}`}
                    inactive={activeBuilding !== i}
                    onClick={() => setActieBuilding(i)}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className={styles.location__buildings_carousel}>
            <Swiper
              spaceBetween={16}
              slidesPerView={"auto"}
              pagination={{ clickable: true }}
              modules={[Pagination]}
              // breakpoints={{
              //   // when window width is >= 320px
              //   320: {
              //     slidesPerView: 2,
              //     spaceBetween: 20,
              //   },
              //   // when window width is >= 480px
              //   768: {
              //     slidesPerView: 2,
              //     spaceBetween: 30,
              //   },
              //   // when window width is >= 640px
              //   991: {
              //     slidesPerView: 2,
              //     spaceBetween: 16,
              //   },
              //   1330: {
              //     slidesPerView: 2,
              //     spaceBetween: 16,
              //   },
              // }}
            >
              {building_images?.map((el) => {
                const { id, image_url } = el;
                return (
                  <SwiperSlide key={id}>
                    <div
                      className={styles.img_swiper}
                      style={{ backgroundImage: `url(${image_url})` }}
                    ></div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      ) : null}
      <div className={styles.location__webcams}>
        <SectionTitle
          text={`${t("Building")} #${activeBuilding + 1} ${t("webcams")}`}
        />
        <div className={styles.location__webcams_inner}>
          <div className={styles.location__webcams_one}>
            <div className={styles.location__webcams_one__image}>
              {!isOnline ? (
                <>
                  <div className={styles.location__webcams_one__offline}>
                    {t("Live Camera will be activated soon")}
                  </div>
                </>
              ) : null}
              <img
                className={styles.location__mainCam}
                src={camera_url || camplant}
                alt="camplant"
              />
              <div className={styles.location__webcams_one__info}>
                <div>
                  {t("Webcam")} {cameraId || t("Unavailable")}
                </div>
                <div>
                  {updated_at ? t("local time") : ""}{" "}
                  {updated_at ? new Date(updated_at).toLocaleTimeString() : ""}
                </div>
              </div>
            </div>
            <div className={styles.location__webcams_one__desc}>
              {building_size ? (
                <>
                  <img src={square} alt="square" />
                  <div>
                    <h4>
                      {building_size} {t("Square Meters")}
                    </h4>
                    <p>{building_description}</p>
                  </div>
                </>
              ) : null}
            </div>
          </div>
          <div className={styles.location__webcams_other}>
            {cams.map((cam, i) => {
              const { camera_url, id, isOnline = true } = cam;
              return (
                <div
                  className={styles.cam}
                  key={i}
                  onClick={() => (isOnline ? setActiveCamera(i) : {})}
                  style={{ cursor: isOnline ? "pointer" : "default" }}
                >
                  <div className={styles.cam__image}>
                    {isOnline ? (
                      <div className={styles.cam__online}>
                        <div></div> {t("online")}
                      </div>
                    ) : (
                      <div className={styles.cam__offline}>
                        {t("Live Camera will be activated soon")}
                      </div>
                    )}
                    <img src={camera_url} alt={`cam-${id}`} />
                  </div>
                  <p>
                    {t("Webcam")} {id}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className={styles.location__standort}>
        <div className={styles.location__standort_title}>{t("Our location")}</div>
        <div className={styles.location__standort_wrapper}>
          <div className={styles.location__standort_btext}>
            {t("Deutsche Anbaugesellschaft DAG GmbH will start growing recreational cannabis in Germany at the site in Mecklenburg-Vorpommern, provided that the legal foundations have been laid in Germany. The site covers a total area of 630,000 square meters, of which 120,000 square meters is hall space. More than 35 closed halls are available to DAG for the cultivation of recreational cannabis.")}
          </div>
          <div className={styles.location__standort_collect}>
            <div className={styles.location__standort_block}>
              <div className={styles.location__standort_block_main}>
                630.000 {t("sqm")}
              </div>
              <div className={styles.location__standort_block_sub}>
                {t("Total area")}
              </div>
            </div>
            <div className={styles.location__standort_block}>
              <div className={styles.location__standort_block_main}>
                120.000 {t("sqm")}
              </div>
              <div className={styles.location__standort_block_sub}>
              {t("spread over 35 halls")}
              </div>
            </div>
            <div className={styles.location__standort_block}>
              <div className={styles.location__standort_block_main}>35</div>
              <div className={styles.location__standort_block_sub}>
                {t("covered halls")}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.location__standort_subtitle}>
          {t("Power generation via own photovoltaic system")}
        </div>
        <div className={styles.location__standort_text}>
          {t("Photovoltaics refers to the conversion of sunlight into electrical energy using solar cells. For a long time, photovoltaics was considered the most expensive form of electricity generation. This has changed drastically over the years, largely due to the sharp reduction in the cost of system components. Photovoltaic systems installed in locations with favorable conditions are now the cheapest form of electricity generation. Even in Germany, the electricity generation costs of newly constructed large-scale photovoltaic systems are lower than for any other fossil or renewable energy source.")}
        </div>
        <div className={styles.location__standort_text_wrapper}>
          <div className={styles.location__standort_text}>
            {t("A large-scale photovoltaic system has been installed at our site. The photovoltaic system is installed both on the roof and on the open spaces. This allows us to use the maximum available area for energy generation using photovoltaics. The entire photovoltaic modules will be replaced in 2023, so that the system will be up to date again in the short term and can generate the maximum amount of electrical energy. The system installed at the site will then have a total output of 55 MW peak, which corresponds to 55 million kWh. With this output, the entire cannabis production can be covered 100% by renewable energy. Due to the intensive lighting of the plants, energy consumption in indoor cannabis production is extremely high. This makes it important for us, especially in these times of energy crisis, when prices have risen sharply and are not stable, to be independent of the major energy suppliers and to be able to plan for the long term with cheap and clean energy. Our major price advantage for the large quantities of energy required gives us an enormous production advantage over other companies.")}
          </div>
          <div>
            <img src={standort1} alt="standort" />
          </div>
        </div>
        <div className={styles.location__standort_subtitle}>
          {t("Recycling facility")}
        </div>
        <div
          className={cn(
            styles.location__standort_text_wrapper,
            styles.location__standort_text_wrapper_row
          )}
        >
          <div className={styles.location__standort_text_wrapper_image}>
            <img src={standort2} alt="standort" />
          </div>
          <div className={styles.location__standort_text}>
            {t("The specially developed recycling plant installed at the site enables agricultural waste (biomass) and sewage sludge to be converted into valuable and limited raw materials such as carbon and phosphorus in an environmentally friendly process. This process is known as hydrothermal carbonization.")}
            <br />
            <br />
            {t("Indoor cannabis cultivation produces a large amount of organic waste as almost exclusively the flowers of the plants are processed. The recycling plant can convert this organic waste into valuable activated carbon, which can later be used to filter water in sewage treatment plants, among other things. The raw materials obtained by recycling sewage sludge, such as phosphorus, are further processed into plant fertilizer. Phosphorus is one of the most important nutrients for cannabis plants.")}
            <br /> <br />
            {t("Recycling converts all types of waste into valuable raw materials, which can then be reintroduced into the natural cycle. For Deutsche Anbaugesellschaft DAG GmbH, the sustainable management of the entire site is one of the company's key issues.")}
          </div>
        </div>
        <div className={styles.location__standort_subtitle}>
          {t("Self-sufficient water supply")}
        </div>
        <div className={styles.location__standort_text_wrapper}>
          <div className={styles.location__standort_text}>
            {t("The site has seven of its own wells and thus a self-sufficient water supply. The water quality is ideal for cannabis cultivation and is constantly checked by analysis.")}
            <br />
            <br />
            {t("Cannabis cultivation requires large quantities of water of the right quality. The cannabis plants must be supplied with sufficient water, especially during the short and rapid growth phase. A lot of water is also needed for the large quantities of fertilizer required, which are mixed directly on site. Few things are more important for proper cannabis cultivation than the right water quality and irrigation technique. It is the water that is responsible for ensuring that the required nutrients and oxygen are transported through the plants. So if something is wrong with the water quality, this can have a negative effect on overall plant growth.")}
            <br />
            <br />
            {t("The pH value is particularly important for cannabis cultivation. Cannabis can only grow well in a very narrow pH range. This is between pH 6 and pH 7. Only in this range can the plants absorb all nutrients such as nitrogen, phosphorus and calcium well. If the pH value of the water deviates only slightly, the plants can no longer absorb certain nutrients, which immediately has a negative effect on plant growth. If the pH value of the water is too high, for example, the plants can no longer absorb iron, while a pH value that is too low means that the plants are not sufficiently supplied with calcium and magnesium.")}
            <br />
            <br />
            {t("A self-sufficient water supply ensures a constant supply of the right water suitable for cannabis cultivation.")}
          </div>
          <div>
            <img src={standort3} alt="standort" />
          </div>
        </div>
        <div className={styles.location__standort_subtitle}>{t("Sewage treatment facility")}</div>
        <div className={styles.location__standort_text}>
          {t("The site also has its own wastewater treatment plant to ensure complete self-sufficiency. This is state of the art (02/2023).")}
        </div>
        <div className={styles.location__standort_label}>
          {t("There are four different stages of water treatment.")}
        </div>
        <div className={styles.location__standort_wrap}>
          <div className={styles.location__standort_wrap_block}>
            <div className={styles.location__standort_wrap_block_title}>
              {t("Mechanical water treatment")}
            </div>
            <div className={styles.location__standort_wrap_block_text}>
              {t("In a first step, the completely untreated wastewater is mechanically cleaned, which removes around 20 - 30% of the solids it contains.")}
            </div>
          </div>
          <div className={styles.location__standort_wrap_block}>
            <div className={styles.location__standort_wrap_block_title}>
              {t("Chemical wastewater treatment")}
            </div>
            <div className={styles.location__standort_wrap_block_text}>
              {t("In this stage, chemical processes are used for wastewater treatment. Chemical compounds are used to produce the legally prescribed water standard values. Chemical wastewater treatment in sewage treatment plants includes neutralization, disinfection, phosphate precipitation, nitrogen elimination, deferrization and demanganization.")}
            </div>
          </div>
        </div>
        <div className={styles.location__standort_wrap}>
          <div className={styles.location__standort_wrap_block}>
            <div className={styles.location__standort_wrap_block_title}>
              {t("Biological purification")}
            </div>
            <div className={styles.location__standort_wrap_block_text}>
              {t("The water that has been pre-cleaned in the mechanical treatment stage now enters the so-called aeration tanks, which are often designed as recirculation tanks. This is where biological purification takes place.")}
            </div>
          </div>
          <div className={styles.location__standort_wrap_block}>
            <div className={styles.location__standort_wrap_block_title}>
              {t("Nanofiltration")}
            </div>
            <div className={styles.location__standort_wrap_block_text}>
              {t("Membrane and filter processes are used in the fourth and final purification stage. In some cases, this purification stage is combined with the chemical processes of precipitation and flocculation. One example of this is the flocculation filtration method. Precipitants and flocculants are added to the wastewater, which leads to the formation of flocs of the substances to be separated. The wastewater with the flocculated material is then passed through a cloth or sand filter. It slowly seeps through the filter layer. Even the smallest organic suspended solids are removed.")}
            </div>
          </div>
        </div>
        <div className={styles.location__standort_text_wrapper}>
          <div>
            <img src={standort4} alt="standort" />
          </div>
          <div className={styles.location__standort_text}>
            {t("After passing through all stages, water samples are taken again and the water quality is tested. Only when the legally prescribed parameters are met is the purified water returned to the water cycle. Large quantities of fertilizer are added to the plants during cannabis cultivation. By commissioning our own wastewater treatment plant, the contaminated water can be treated and reused immediately on site. Treating the water ourselves is also an important prerequisite for the self-sufficient and sustainable cultivation of the site")}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Main;
