import React from "react";
import styles from "./Reasons.module.scss";

import * as reasonsImages from "../../../../img/reasons";
import { useTranslation } from "react-i18next";

const Reasons = () => {
  const { t } = useTranslation();
  const reasons = [
    {
      image: reasonsImages.reason3,
      title: t("Technology & Software"),
      desc: t("Thanks to the latest technologies, which we develop directly on site, we are able to make cultivation extremely safe and transparent."),
    },
    
    {
      image: reasonsImages.reason5,
      title: t("Highest safety standards"),
      desc: t("Thanks to the technologies we have developed, we are setting new safety and quality standards for cannabis cultivation."),
    },
    {
      image: reasonsImages.reason2,
      title: t("Broad know-how"),
      desc: t("With our team, which includes experts from Europe and the USA, we have a broad range of know-how that we incorporate directly into our developments."),
    },
    {
      image: reasonsImages.reason1,
      title: t("Research and development"),
      desc: t("We operate a research cluster and work closely with recognized research centers and universities."),
    },

    {
      image: reasonsImages.reason4,
      title: t("Renewable energies"),
      desc: t("We only use renewable energy that is generated directly on site. Cannabis cultivation is particularly energy-intensive."),
    },
    {
      image: reasonsImages.reason6,
      title: t("Zero waste & circular economy"),
      desc: t(
        "All raw materials are processed or recycled. Surplus energy in the form of heat is converted into electricity and used."
      ),
    },
  ];
  return (
    <section className={styles.reasons} id="benefits">
      <div className="container">
        <h2 className={styles.reasons__title}>{t("Our success strategy")}</h2>
        <div className={styles.reasons__grid}>
          {reasons.map((reason, i) => {
            const { image, title, desc } = reason;
            return (
              <div className={styles.tile} key={i}>
                <picture>
                  <source srcSet={image} type="image/svg+xml" />
                  <img src={image} alt={title} />
                </picture>
                <h4>{title}</h4>
                <p>{desc}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Reasons;
