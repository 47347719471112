import React, { memo } from "react";

const HomeIcon = memo(({ color = "#FFFFF8" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 1.94556L11.4608 2.46081L1.71075 12.2108L2.79 13.2901L3.75 12.3278V21.0001H10.5V13.5001H13.5V21.0001H20.25V12.3278L21.2108 13.2893L22.2893 12.2108L12.5393 2.46081L12 1.94556ZM12 4.07856L18.75 10.8286V19.5001H15V12.0001H9V19.5001H5.25V10.8278L12 4.07856Z"
        fill="currentColor"
      />
      <path
        d="M21.5821 12.2108L21.2109 12.5821L20.6037 11.9744L19.75 11.12V12.3278V20.5001H14V13.5001V13.0001H13.5H10.5H10V13.5001V20.5001H4.25V12.3278V11.1187L3.39604 11.9747L2.78959 12.5825L2.41786 12.2108L11.8103 2.81837L12 2.63711L12.1897 2.81837L21.5821 12.2108ZM12.3536 3.725L12 3.37147L11.6465 3.72498L4.89647 10.4742L4.75 10.6207V10.8278V19.5001V20.0001H5.25H9H9.5V19.5001V12.5001H14.5V19.5001V20.0001H15H18.75H19.25V19.5001V10.8286V10.6214L19.1036 10.475L12.3536 3.725Z"
        stroke="currentColor"
      />
    </svg>
  );
});

export default HomeIcon;
