import React, { useState, useEffect, useContext } from "react";
import styles from "./Login.module.scss";
import "../../styling/common.css";
import { Link } from "react-router-dom";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import login from "../../img/login.webp";
import HeaderCommon from "../../components/HeaderCommon/HeaderCommon";
import FooterCommon from "../../components/FooterCommon/FooterCommon";
import AOS from "aos";
import "aos/dist/aos.css";
import { checkEmail } from "../../helpers/validation";
import { Context } from "../../index";
import { useObserver } from "mobx-react-lite";
import Loader from "../../components/Loader/Loader";
import { useTranslation } from "react-i18next";

const Login = ({ currentLang, setCurrentLang }) => {
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [allowSubmit, setAllowSubmit] = useState("");
  const [errors, setErrors] = useState([]);
  const [twoFactorCode, setTwoFactorCode] = useState("");
  const [loader, setLoader] = useState(false);
  const [error2fa, setError2fa] = useState("");

  const { t } = useTranslation();

  const { store } = useContext(Context);

  const { loginError, show2fa, isLoadSignUp, code2faError, isAuth } =
    useObserver(() => ({
      loginError: store.loginError,
      show2fa: store.show2fa,
      isLoadSignUp: store.isLoadSignUp,
      code2faError: store.code2faError,
      isAuth: store.isAuth,
    }));

  useEffect(() => {
    store.checkAuth("login");
  }, []);

  useEffect(() => {
    if (loginError.length) {
      setErrors(loginError);
    } else {
      setErrors([]);
    }
  }, [loginError]);

  useEffect(() => {
    if (code2faError.length) {
      setError2fa(code2faError);
    } else {
      setError2fa("");
    }
  }, [code2faError]);

  useEffect(() => {
    if (isLoadSignUp) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [isLoadSignUp]);

  useEffect(() => {
    if (
      email !== "" &&
      !errorEmail.length &&
      password !== "" &&
      !errorPassword.length
    ) {
      setAllowSubmit(true);
    } else {
      setAllowSubmit(false);
    }
  }, [email, errorEmail, password, errorPassword]);

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      {loader ? <Loader /> : null}
      <HeaderCommon currentLang={currentLang} setCurrentLang={setCurrentLang} />
      <section className={styles.login}>
        <div className="container">
          <div className={styles.login__wrapper}>
            <div className={styles.login__left}>
              <h2 className={styles.login__title}>{t("Welcome back!")}</h2>
              <div className={styles.login__info}>
                <div>{t("Don’t have an account yet?")}</div>
                <Link to="/register">{t("Register")}</Link>
              </div>
              <form className={styles.login__form}>
                <Input
                  label={t("Email")}
                  type="email"
                  name="email"
                  placeholder={`${
                    localStorage.getItem("i18n") == "DE" ? "Ihre" : ""
                  } ${t("Email")}${
                    localStorage.getItem("i18n") == "DE" ? "-Adresse " : ""
                  }`}
                  value={email}
                  onChange={(e) =>
                    checkEmail(e.target.value, setEmail, setErrorEmail)
                  }
                  error={errorEmail}
                />
                <Input
                  label={t("Password")}
                  type="password"
                  name="password"
                  placeholder="••••••••••"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  error={errorPassword}
                />
                <div className={styles.login__form_buttons}>
                  <Button
                    text={t("Log in")}
                    color="green"
                    disabled={!allowSubmit}
                    onClick={() =>
                      store.login(
                        email,
                        password,
                        window.innerHeight,
                        window.innerWidth,
                        navigator.userAgent
                      )
                    }
                  />
                  <Button text={t("Reset password")} to="/reset-password" />
                </div>
              </form>
              {errors && errors.length > 0 ? (
                <div className={styles.login__form_error}>
                  {errors?.map((item, index) => {
                    return (
                      <div className={styles.login__form_errors} key={index}>
                        {item === "Invalid email or password!"
                          ? t("Invalid email or password!")
                          : t(item)}
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
            <div
              className={styles.login__right}
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <img src={login} alt="login" />
            </div>
          </div>
        </div>
        {show2fa ? (
          <div className={styles.login__2fa}>
            <div className={styles.login__2fa_inner}>
              <div className={styles.login__2fa_inner_title}>
                {t("Enter code from email")}
              </div>
              <Input
                label="Code"
                type="text"
                name="code"
                placeholder="code"
                value={twoFactorCode}
                onChange={(e) => setTwoFactorCode(e.target.value)}
              />
              {error2fa && error2fa.length > 0 ? (
                <div className={styles.login__2fa_error}>{t(error2fa)}</div>
              ) : null}
              <div
                className={styles.login__2fa_btn}
                onClick={() =>
                  store.check2fa(
                    twoFactorCode,
                    window.innerHeight,
                    window.innerWidth,
                    navigator.userAgent
                  )
                }
              >
                {t("Submit")}
              </div>
            </div>
          </div>
        ) : null}
      </section>
      <FooterCommon />
    </>
  );
};

export default Login;
