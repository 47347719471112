import React, { useState, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Main.module.scss";

import classNames from "classnames/bind";
import Banner from "../../MainPage/Components/Banner/Banner";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import { FRONT_URL } from "../../../http";

let cx = classNames.bind(styles);

export const Main = () => {
  const { t } = useTranslation();
  const menuItems = [
    "Preamble",
    "Applicability of the General Terms and Conditions",
    "Technical Requirements for the Use of the Platform and Communication Path",
    "Registration and Activation of Platform Functionalities",
    "Data Protection and Confidentiality",
    "Liability",
    "Changes to the General Terms and Conditions",
    "Termination of the User Contract",
    "Contract Language",
    "Final Provisions",
    "The Issuance, Quantity, and Price of DECT-Tokens",
    "Functions of the DECT-Token",
    "The Offer and the Conclusion of the Contract",
    "Declarations and Assurances of the Buyer",
    "Buyer's Responsibility for Storage Medium and Access Data",
    "Taxation of DECT and Taxation in Connection with the Token Sale",
    "Warranty",
    "Exclusion of Warranties",
    "Limitation and Waiver of Liability",
    "Cancellation Policy for Consumers",
    "Risk Notices"
  ];

  const riskInformationParagraphs = [
    {
      id: 1,
      text: t(
        "High Risk in Participation: Participation in the ICO is associated with significant risks for the buyers. In particular, there is the risk of a total loss, i.e., all amounts of money spent in the context of the ICO can be lost. Therefore, in the event of participation in the ICO, the buyer should not use a significant portion of his assets to purchase DECT-Tokens. Rather, the loss of the expended amounts of money should be financially manageable by the buyer. It is strongly advised against financing the purchase of DECT-Tokens through a loan. The obligations to pay interest and principal remain, even if the acquired DECT-Token becomes worthless. Buyers are expressly advised to participate in the ICO only if they already have experience with cryptocurrencies and blockchain technology. Also, buyers should be able to understand both the business activities of Anbaugesellschaft.de and the DECT-Token and its functionality. If a buyer does not have relevant experience, this does not constitute increased disclosure obligations of Anbaugesellschaft.de."
      ),
    },
    {
      id: 2,
      text: t(
        "Token is Not a Financial Instrument and Does Not Convey Ownership or Management Rights in Anbaugesellschaft.de: The DECT-Token is not a security, capital investment product, or financial instrument. The DECT-Token also does not have a payment function on the platform of Anbaugesellschaft.de. It does not convey ownership or management rights in the issuer or voting rights in the shareholders' meeting of the issuer. Also, the holder of the token is not involved in any way in the profit or loss or in the asset development of the issuer."
      ),
    },
    {
      id: 3,
      text: t(
        "Whitepaper is Neither a Prospectus Nor an Offer: The management of the issuer has created the Whitepaper made accessible on its website at its discretion and has not followed any specifications. There are no specific legal requirements for the content of such a whitepaper. Moreover, it has not been reviewed or approved by any authority or other official body. The whitepaper is descriptive in nature, is purely informative, and has no legal effect. It is intended to explain the project understandably and outline its peculiarities and advantages. Accordingly, the whitepaper does not constitute a securities and investment prospectus. Similarly, the whitepaper is not an offer for securities, capital investment products, or other financial instruments."
      ),
    },
    {
      id: 4,
      text: t(
        "Forward-Looking Statements are Non-Binding: The calculations presented in the whitepaper are based on forecasts about future development. They cannot be assured. Rather, the buyer bears the risk of deviating developments. Forward-looking statements are associated with uncertainties and risks. They are essentially based on the management's assessments of the issuer regarding future development. Moreover, they may include perception or assessment errors and prove to be inaccurate. It is also not excluded that events or developments that were not considered in the forecasts lead to significant deviations from the predicted development."
      ),
    },
    {
      id: 5,
      text: t(
        "Self-Responsible Risk Assessment: Furthermore, the whitepaper does not replace legal, tax, or other advice. Therefore, each buyer should check the associated risks independently before participating in the ICO and seek advice from an expert if necessary. This is particularly recommended concerning the supervisory and tax law consequences of purchasing DECT-Tokens. The issuer expressly assumes no liability for economic endeavors pursued with the purchase of DECT-Tokens."
      ),
    },
    {
      id: 6,
      text: t(
        "Regulatory Note on DECT-Tokens: The company takes the position that the issuance of DECT-Tokens by it is not subject to any specific regulation. Since the legal classification of tokens, blockchain technology, cryptocurrencies, smart contracts, and their applications is not yet sufficiently clarified, regulatory frameworks are also in flux. Therefore, it cannot be ruled out that due to court decisions, legislative changes, or regulatory measures, the use of tokens may be wholly or partially prohibited or only permitted under certain conditions. Such a development could have significant negative consequences for the business activities of the issuer; the DECT-Token could lose its utility, and in the worst case, the insolvency of the issuer could be threatened."
      ),
    },
    {
      id: 7,
      text: t(
        "No Exchange Right for the Buyer, Buyer Bears Usage Risk: A refund of the purchase price in any form by the issuer is excluded. The buyer bears the risk that he can continue to use the purchased DECT-Token as intended. He cannot argue that he cannot resell the DECT-Token or only at a different price than he planned. The buyer also cannot claim that the purpose of use has ceased to exist. The utility of the DECT-Token is limited exclusively to granting access to the functionalities of the platform, so it has no value for the buyer outside of this platform. Regardless, it cannot be excluded that the value of the DECT-Token changes due to the value development of other cryptocurrencies (e.g., BTC or ETH). Such value development can occur independently of the business development of the issuer. In addition, the buyer must consider that trading acquired DECT-Tokens will likely involve costs that the buyer must additionally bear, further burdening the value of acquired or traded DECT-Tokens."
      ),
    },
    {
      id: 8,
      text: t(
        "Risk of Loss of Access to DECT due to Loss of Private Keys, Custodial Errors, or Buyer's Errors: The buyer is liable for the security of the private keys and the security of the wallets necessary for holding DECT-Tokens, insofar as these are technically transferred from the user account to an ERC-20 wallet. Moreover, the buyer is liable for losses of DECT that can occur due to receiving or sending DECT if incorrect wallet addresses for sending or receiving DECT are provided that are not ERC-20 compatible. This can lead to the total loss of your investment."
      ),
    },
    {
      id: 9,
      text: t(
        "Risks Associated with the Ethereum Network: DECT is technically based on Ethereum, so any disruption, failure, or cessation of the Ethereum network can have significant negative impacts on the issuer's projects or on DECT. This can cause substantial damage up to the total loss of your investment."
      ),
    },
    {
      id: 10,
      text: t(
        "Risk of Mining Attacks: Like other cryptographic tokens based on the Ethereum network, DECT may be susceptible to attacks by miners in the course of validating DECT transactions on the Ethereum blockchain, including but not limited to double-spend attacks, majority mining power attacks, and selfish mining attacks. Successful attacks pose a risk to the issuer's projects and DECT, including but not limited to the correct execution and recording of transactions with DECT. This can cause substantial damage up to the total loss of your investment."
      ),
    },
    {
      id: 11,
      text: t(
        "Risk of Hacker Attacks and Security Vulnerabilities: Hackers or other malicious groups or organizations may try to disrupt the issuer's projects or DECT in various ways, including but not limited to malware attacks, denial-of-service attacks (DDOS), consensus-based attacks, Sybil attacks, smurfing, and spoofing. This can cause substantial damage up to the total loss of your investment."
      ),
    },
    {
      id: 12,
      text: t(
        "Risks Related to DECT Markets: If secondary trading of tokens is facilitated by third-party exchanges, such exchanges may be new and subject to little or no regulatory oversight, making them more susceptible to fraud or manipulation. Moreover, if third parties attribute an external exchange value to DECT (e.g., in the form of digital or fiat currency), this value may also be highly volatile. This can cause substantial damage up to the total loss of your investment."
      ),
    },
    {
      id: 13,
      text: t(
        "Risk of Uninsured Losses: Unlike bank accounts or accounts at other financial institutions, DECT are not insured unless you explicitly take out private insurance to insure them. In the event of a loss or a reduction in utility value, there is therefore no public insurer and no private insurance taken out by the issuer that you could fall back on. This can cause substantial damage up to the total loss of your investment."
      ),
    },
    {
      id: 14,
      text: t(
        "Risks Associated with the Development of the Software Solutions DECT-S2S (Blockchain-based Seed-to-Sale System) and DECT-Grow (AI-based Cultivation Management System): The software solutions described, to which the DECT-Token is intended to provide access, are currently still in the development and testing phase. If technical or other problems occur in the development of the software solutions, there is a risk that the intended system solutions can only be used with delays, only to a limited extent, or possibly not at all."
      ),
    },
    {
      id: 15,
      text: t(
        "Tax Risks: The tax classification of DECT is unclear. The acquisition and/or possession and/or sale of DECT may entail tax liabilities and be declarable. The buyer should seek tax advice in connection with the purchase and/or possession and/or sale of DECT."
      ),
    },
    {
      id: 16,
      text: t(
        "Risk of Competing Platforms: It is possible that companies will be founded that pursue the same concept underlying the issuer. Therefore, there is a risk that these companies offer services or products similar to those of the issuer. This can lead to the devaluation of DECT and the total loss of your investment."
      ),
    },
    {
      id: 17,
      text: t(
        "Risks Related to Legalization: The business model of DECT and Deutsche Anbaugesellschaft DAG GmbH is heavily dependent on the legalization and regulation of cannabis cultivation and consumption in Germany. If complete legalization does not occur or regulations are stricter than expected, this could negatively impact the business model of the issuer and the DECT-Token or impair or completely exclude the feasibility of the projects to be realized with the token. There is a risk that demand for DECT-Tokens and the associated services will be impaired by legal restrictions and/or the value of DECT could completely depreciate, leading to the total loss of your investment."
      ),
    },
    {
      id: 18,
      text: t(
        "Restrictions on Cannabis Cultivation: The issuer is dependent on a favorable regulatory environment for cannabis cultivation in Germany. If there are restrictions or changes in cultivation conditions, such as limitations on cultivation quantities, cultivation areas, or cultivation methods, this could negatively impact cannabis cultivation and thus the business model of the issuer and DECT. An unfavorable regulatory environment can lead to the devaluation of DECT and the total loss of your investment."
      ),
    },
    {
      id: 19,
      text: t(
        "Risks Related to Licenses: The issuer relies on obtaining certain licenses and permissions to fully implement its business model. There is a risk that the issuer will not receive all required licenses or that the issuance and maintenance of licenses will be associated with delays, costs, or other challenges. The lack of required licenses could impair the issuer's business operations and lead to the devaluation of DECT and the total loss of your investment."
      ),
    },
    {
      id: 20,
      text: t(
        "Consequences of Insufficient Funding of the Issuer: Due to its position as a young company in the market, 'Anbaugesellschaft.de' has financing needs. Its progress depends on receiving sufficient funding. The starting point of its financing is the revenue from the issuance of the DECT-Token. If the DECT-Token ICO does not raise sufficient funds for financing, it is not unlikely that Anbaugesellschaft.de will not be able to commence its business operations as planned and may have to declare insolvency. This would also have negative implications for the usability of the DECT-Token. If Anbaugesellschaft.de fails to establish the platform it envisions in the market, the DECT-Token will be worthless for the buyer."
      ),
    },
    {
      id: 21,
      text: t(
        "Unforeseen Risks: Crypto-tokens like DECT are a new and untested technology. In addition to the risks described in this section, there are further risks associated with the purchase, possession, use, and sale of DECT that cannot currently be foreseen. Each of the aforementioned risks, including unforeseen risks, can lead to the devaluation of DECT and the total loss of your investment."
      ),
    },
    {
      id: 22,
      text: t(
        "Cumulation of Risks: The risks presented in this chapter can occur individually or cumulatively. The risks could each lead to the project not being feasible and/or the assumptions made no longer occurring or not occurring as described. The maximum risk of acquiring the DECT-Token is therefore the total loss of the purchase price paid."
      ),
    },
  ];

  const [selectedMenuItem, setSelectedMenuItem] = useState(menuItems[0]);

  const navigate = useNavigate();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  // const [scrollPosition, setScrollPosition] = useState(0);

  // const menuRef = useRef(null);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     setScrollPosition(window.scrollY);
  //   };
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  // const handleScrollClass = (id) => {
  //   const section = document.getElementById(id);
  //   if (window.scrollY >= section.offsetTop) {
  //     menuRef.current.classList.add(styles.sticky);
  //   } else {
  //     menuRef.current.classList.remove(styles.sticky);
  //   }
  // };

  // useEffect(() => {
  //   handleScrollClass("section2");
  // }, [scrollPosition]);

  return (
    <div className={styles.main__wrapper}>
      <main className={styles.main}>
        <div className="container">
          <div className={styles.main__header}>
            <div className={styles.header__image}></div>
            <div className={styles.title__wrapper}>
              <h1 className={styles.title}>
                {t("General Terms and Conditions")}
              </h1>
            </div>
          </div>

          <div className={styles.main__body}>
            <div className={styles.asideMenu__wrapper}>
              <nav
              // ref={menuRef}
              >
                <ul className={styles.asideMenu}>
                  {menuItems &&
                    menuItems.map((item, i) => {
                      return (
                        <li
                          key={i}
                          className={cx({
                            asideMenu__item: true,
                            asideMenu__item_active: item === selectedMenuItem,
                          })}
                        >
                          <Link
                            to={`section${i}`}
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            onClick={() => {
                              setSelectedMenuItem(item);
                            }}
                          >
                            {t(item)}
                          </Link>
                        </li>
                      );
                    })}
                </ul>
              </nav>
            </div>

            <div className={styles.body}>
              <div className={styles.paragraphs}>
                <div className={styles.paragraph} id="section0">
                  <div className={styles.paragraph__title_wrapper}>
                    <div className={styles.paragraph__title}>
                      {t("Preamble")}
                    </div>
                  </div>

                  <div className={styles.paragraph__text}>
                    <p>
                      {t(
                        `Deutsche Anbaugesellschaft DAG GmbH (hereinafter referred to as 'Anbaugesellschaft.de' and 'Issuer') is a company specialized in the production, distribution, and trade of utility plants and pleasure products.`
                      )}
                    </p>
                    <p>
                      {t(
                        `Under 'https://Anbaugesellschaft.de', Deutsche Anbaugesellschaft DAG GmbH operates an internet platform with an integrated online shop (hereinafter referred to as 'Platform') under the brand 'Anbaugesellschaft.de', where, in particular, cultivation sets or usage rights for the software DECT-S2S (Blockchain-based Seed-to-Sale System) and DECT-Grow (AI-supported Cultivation Management System) can be acquired. These offers are exclusively for holders of DECT-Tokens. Access to the online shop is therefore only available to members of the DAG corporate ecosystem.`
                      )}
                    </p>
                    <p>
                      {t(
                        `To provide this access to interested parties, 'Anbaugesellschaft.de', through the Platform, conducts the issuance and public offering of the so-called German Cannabis Token (DECT-Token) in an Initial Coin Offering (ICO) and offers it for purchase to individuals and companies (hereinafter referred to as 'Buyers') based on the provisions contained in these General Terms and Conditions in Part 2: Conditions for the Offer and Issuance of DECT.`
                      )}
                    </p>
                    <p>
                      {t(
                        `'Anbaugesellschaft.de' expressly points out that the purchase of DECT-Token is associated with considerable risks, which are described in detail in the risk notices in the annex to these General Terms and Conditions, and for which 'Anbaugesellschaft.de' can neither provide security nor assume these risks. You are strongly advised to inform yourself about crypto assets and the associated risks before purchasing the DECT-Token – taking into account your financial situation and risk tolerance – and to critically engage with them.`
                      )}
                    </p>
                  </div>
                </div>
                <h2>{t("Part 1 General Provisions")}</h2>
                <div className={styles.paragraph} id="section1">
                  <div className={styles.paragraph__title_wrapper}>
                    <div className={styles.paragraph__title}>
                      1.{" "}
                      {t("Applicability of the General Terms and Conditions")}
                    </div>
                  </div>

                  <div className={styles.paragraph__text}>
                    <p>
                      {t(
                        `1.1. These General Terms and Conditions (hereinafter 'GTC') apply to the use of the Platform, the distribution of the products and services offered on the Platform, and the issuance of the DECT-Token via the Platform by Anbaugesellschaft.de., which is regulated in detail in Part 2: Conditions for the Offer and Issuance of DECT in these GTC.`
                      )}
                    </p>
                    <p>
                      {t(
                        `1.2. They exclusively regulate the contractual relationship between - Deutsche Anbaugesellschaft DAG GmbH located at Libnower Landstr. 1, 17390 Murchin, Germany, registered in the commercial register of the Murchin District Court under number HRB 180258, (hereinafter 'Anbaugesellschaft.de' and 'Issuer') legally represented by the managing director Christian Tonn, Phone: +49 040 2999 6808, Email: info@Anbaugesellschaft.de and - the users. These are natural persons, groups of persons, or legal entities.`
                      )}
                    </p>
                    <p>
                      {t(
                        `1.3. Special conditions that contain deviations or additions to these GTC may apply to individual business relationships. If relevant, these special conditions are agreed upon separately with the user.`
                      )}
                    </p>
                    <p>
                      {t(
                        `1.4. Other General Terms and Conditions ('GTC') that deviate from these, especially those of the user, are expressly not part of the contract.`
                      )}
                    </p>
                  </div>
                </div>
                <div className={styles.paragraph} id="section2">
                  <div className={styles.paragraph__title_wrapper}>
                    <div className={styles.paragraph__title}>
                      2.{" "}
                      {t(
                        "Technical Requirements for the Use of the Platform and Communication Path"
                      )}
                    </div>
                  </div>
                  <div className={styles.paragraph__text}>
                    <p>
                      {t(
                        `2.1. Access to the Platform is only possible via an internet browser (e.g., Chrome, Firefox) on a computer or mobile device with an internet connection. Other access methods do not guarantee the proper functionality of the Platform and are therefore not supported.`
                      )}
                    </p>
                    <p>
                      {t(
                        `2.2. Upon user registration on the Platform, communication between Anbaugesellschaft.de and the user regarding the acquisition of DECT-Token is exclusively electronic via the Platform and by email, unless expressly specified otherwise in these General Terms and Conditions. Statements are not sent to the user in paper form unless there is a legal obligation to do so.`
                      )}
                    </p>
                    <p>
                      {t(
                        `2.3. The user is aware that the display and printout of the data shown on the Platform may differ from the screen display due to individual hardware or software configurations. Anbaugesellschaft.de has no influence on the functionality and configuration of the user's devices or on the availability and reliability of their internet connection with the server of Anbaugesellschaft.de. Anbaugesellschaft.de is therefore not liable for damages resulting from these or other technical problems on the user's side.`
                      )}
                    </p>
                  </div>
                </div>
                <div className={styles.paragraph} id="section3">
                  <div className={styles.paragraph__title_wrapper}>
                    <div className={styles.paragraph__title}>
                      3.{" "}
                      {t(
                        "Registration and Activation of Platform Functionalities"
                      )}
                    </div>
                  </div>
                  <div className={styles.paragraph__text}>
                    <p>
                      {t(
                        `3.1. Both natural and legal persons are allowed on the Platform, provided they are holders of DECT-Tokens and can prove their eligibility to Anbaugesellschaft.de. To provide this access to interested parties, 'Anbaugesellschaft.de' operates via the Platform the issuance and public offering of the so-called German Cannabis Token (DECT-Token) as part of an Initial Coin Offering (ICO) and offers it for purchase to individuals and companies (hereinafter 'Buyers') based on the provisions in Part 2: Conditions for the Offer and Issuance of DECT of these GTC.`
                      )}
                    </p>
                    <p>
                      {t(
                        `3.2. To use the Platform, user registration is required. Natural persons may only register if they are European citizens, have their usual residence in Germany, have reached the age of 18, and are legally competent. If the user acts on behalf of a legal entity, they must be duly authorized to do so.`
                      )}
                    </p>
                    <p>
                      {t(
                        `3.3. Multiple registrations by one person are not permitted. The user must provide complete and truthful data collected by Anbaugesellschaft.de at the time of contract conclusion. Should further information obligations arise from the Money Laundering Act, the user is obligated to provide them and to provide the necessary evidence for identity verification. In this case, the use of the services and other services is excluded until identity verification has taken place.`
                      )}
                    </p>
                    <p>
                      {t(
                        `3.4. Registration takes place in several steps. Initially, the user provides their gender, first and last name, address ('master data'), phone number, and a valid email address. To confirm the registration, the user automatically sends this data to Anbaugesellschaft.de by clicking on 'Create account'. By clicking on the confirmation link in the email received, the user confirms the identity of their email address. With the confirmation of their registration, the user accepts the General Terms and Conditions listed here and takes note of the status-related information from Anbaugesellschaft.de. With the receipt of the confirmation at Anbaugesellschaft.de, a user contract according to these General Terms and Conditions comes into effect between Anbaugesellschaft.de and the investor.`
                      )}
                    </p>
                  </div>
                </div>
                <div className={styles.paragraph} id="section4">
                  <div className={styles.paragraph__title_wrapper}>
                    <div className={styles.paragraph__title}>
                      4. {t("Data Protection and Confidentiality")}
                    </div>
                  </div>
                  <div className={styles.paragraph__text}>
                    <p>
                      {t(
                        `4.1. When registering and setting up a user account on the Platform, users must provide complete and correct data and update them in case of changes. Anbaugesellschaft.de may make the admission of certain users dependent on suitable proof of identity, as far as this is justified in individual cases to protect the legitimate interests of the users.`
                      )}
                    </p>
                    <p>
                      {t(
                        `4.2. The collection, use, and processing of user data are exclusively according to the data protection provisions of Anbaugesellschaft.de available at https://Anbaugesellschaft.de/privacy.`
                      )}
                    </p>
                    <p>
                      {t(
                        `4.3. Anbaugesellschaft.de provides registered users with partly confidential company-related data and information. Users and buyers are obliged to treat this data and information confidentially and must not pass it on to third parties, reproduce it, or publish it in any way.`
                      )}
                    </p>
                  </div>
                </div>
                <div className={styles.paragraph} id="section5">
                  <div className={styles.paragraph__title_wrapper}>
                    <div className={styles.paragraph__title}>
                      5. {t("Liability")}
                    </div>
                  </div>
                  <div className={styles.paragraph__text}>
                    <p>
                      {t(
                        `5.1. Anbaugesellschaft.de strives to ensure continuous access to the Platform but cannot guarantee unrestricted availability. Due to maintenance, security, or capacity reasons, as well as events beyond the control of Anbaugesellschaft.de, there may be temporary interruptions of the services of Anbaugesellschaft.de and accessibility to the Platform. Anbaugesellschaft.de reserves the right to temporarily restrict access to the Platform if necessary due to capacity limits, security measures, or technical requirements.`
                      )}
                    </p>
                    <p>
                      {t(
                        `5.2. The contents provided on the Platform do not claim to be complete, accurate, or up-to-date, and their use is at the user's own risk.`
                      )}
                    </p>
                    <p>
                      {t(
                        `5.3. Anbaugesellschaft.de is not liable for damages incurred by the user through the use of the services or the content published on the Platform, unless there is intentional or gross negligence on the part of Anbaugesellschaft.de or a vicarious agent. However, this exclusion of liability does not apply to damages resulting from injury to life, body, or health, or from a breach of duty by Anbaugesellschaft.de or a vicarious agent. Anbaugesellschaft.de's liability for the violation of obligations, the fulfillment of which is essential for the proper execution of the contract and on the observance of which the user can regularly rely, remains unaffected.`
                      )}
                    </p>
                  </div>
                </div>
                <div className={styles.paragraph} id="section6">
                  <div className={styles.paragraph__title_wrapper}>
                    <div className={styles.paragraph__title}>
                      6. {t("Changes to the General Terms and Conditions")}
                    </div>
                  </div>
                  <div className={styles.paragraph__text}>
                    <p>
                      {t(
                        `6.1. Anbaugesellschaft.de reserves the right to change these GTC and the risk notices in Annex 1 at any time with effectiveness also within the existing contractual relationship, provided this is necessary for valid reasons, especially due to a change in legal situation or supreme court jurisprudence, technical changes or developments, new organizational requirements of mass traffic, gaps in the GTC, changes in market conditions, or other equivalent reasons, and does not unreasonably disadvantage the user, and the changes do not alter the essential business characteristics of the contract, particularly the services owed by Anbaugesellschaft.de.`
                      )}
                    </p>
                    <p>
                      {t(
                        `6.2. Anbaugesellschaft.de will inform the user in good time before the planned entry into force of the changes, but at least two (2) months in advance. The user can either agree to or reject the changes before their planned entry into force. The user's consent is deemed to have been given if he has not indicated his rejection before the planned entry into force of the changes. Anbaugesellschaft.de will specifically point out this approval effect in its offer.`
                      )}
                    </p>
                    <p>
                      {t(
                        `6.3. If the user rejects the changes, both parties have the right to terminate the contractual relationship according to the following provisions: - the user with immediate effect, i.e., without observing a deadline; - Anbaugesellschaft.de while respecting a reasonable period. Anbaugesellschaft.de will specifically point out these possibilities in its offer.`
                      )}
                    </p>
                  </div>
                </div>
                <div className={styles.paragraph} id="section7">
                  <div className={styles.paragraph__title_wrapper}>
                    <div className={styles.paragraph__title}>
                      7. {t("Termination of the User Contract")}
                    </div>
                  </div>
                  <div className={styles.paragraph__text}>
                    <p>
                      {t(
                        `7.1. Anbaugesellschaft.de reserves the right to terminate the business relationship with the user at any time, observing a reasonable notice period, which takes into account the legitimate interests of the user. The notice period will generally not be less than two weeks.`
                      )}
                    </p>
                    <p>
                      {t(
                        `7.2. Anbaugesellschaft.de will particularly make use of this right of termination if the buyer objects to a change of the General Terms and Conditions offered by Anbaugesellschaft.de according to clause 10.3 of these General Terms and Conditions.`
                      )}
                    </p>
                    <p>
                      {t(
                        `7.3. Anbaugesellschaft.de may terminate the business relationship without notice if there is an important reason which, even considering the legitimate interests of the user, makes the continuation of the business relationship unreasonable for Anbaugesellschaft.de. An important reason exists in particular in case of a repeated violation of these General Terms and Conditions. An important reason may also exist in case of a single serious violation of these General Terms and Conditions, e.g., deception or attempted deception of Anbaugesellschaft.de by intentionally false statements of a buyer.`
                      )}
                    </p>
                  </div>
                </div>
                <div className={styles.paragraph} id="section8">
                  <div className={styles.paragraph__title_wrapper}>
                    <div className={styles.paragraph__title}>
                      8. {t("Contract Language")}
                    </div>
                  </div>
                  <div className={styles.paragraph__text}>
                    <p>
                      {t(
                        `The contract language is German. The English translation or translation into another language of these GTC is for information purposes only. In case of ambiguities or deviations, only the German version is authoritative.`
                      )}
                    </p>
                  </div>
                </div>
                <div className={styles.paragraph} id="section9">
                  <div className={styles.paragraph__title_wrapper}>
                    <div className={styles.paragraph__title}>
                      9. {t("Final Provisions")}
                    </div>
                  </div>
                  <div className={styles.paragraph__text}>
                    <p>
                      {t(
                        `9.1. German law applies to the contractual relationship between the user and Anbaugesellschaft.de, excluding the UN Sales Convention. If the user is a consumer and had his usual residence in a country other than Germany at the time of conclusion of the contract, the application of mandatory legal provisions of this country remains unaffected by the choice of law made in sentence 1. This applies to all legal disputes arising from this contractual relationship, including those concerning its existence or non-existence.`
                      )}
                    </p>
                    <p>
                      {t(
                        `9.2. If the user is a merchant, a legal entity under public law, or a special fund under public law, the exclusive place of jurisdiction is the seat of Anbaugesellschaft.de, Murchin.`
                      )}
                    </p>
                    <p>
                      {t(
                        `9.3. Should any provision of these General Terms and Conditions be wholly or partially ineffective or unenforceable, this shall not affect the validity of the remaining provisions. The same applies if this agreement contains a gap. In this case, the parties will replace the gap or the ineffective or void provision with a regulation that corresponds to the economic purpose of the agreement and comes as close as possible to what the parties intended.`
                      )}
                    </p>
                  </div>
                </div>
                <h2>
                  {t("Part 2 Conditions for the Offer and Issuance of DECT")}
                </h2>
                <div className={styles.paragraph} id="section10">
                  <div className={styles.paragraph__title_wrapper}>
                    <div className={styles.paragraph__title}>
                      10.{" "}
                      {t("The Issuance, Quantity, and Price of DECT-Tokens")}
                    </div>
                  </div>
                  <div className={styles.paragraph__text}>
                    <p>
                      {t(
                        `10.1. The issuance will be carried out by the Issuer on its own. A public offer outside the countries mentioned on the Platform is not intended.`
                      )}
                    </p>
                    <p>
                      {t(
                        `10.2. The issuance of DECT-Tokens will take place only once. An issuance of 'new' DECT-Tokens is not planned.`
                      )}
                    </p>
                    <p>
                      {t(
                        `10.3. The number of DECT-Tokens to be issued will be limited to a maximum of 200,000 pieces and will be carried out using the Ethereum network (ERC-20 standard). Of the total 200,000 DECT-Tokens, 90% (180,000) are to be publicly offered for purchase in exchange for Euros, BNB (Binance Coin), ETH (Ether), BTC (Bitcoin), or other cryptocurrencies.`
                      )}
                    </p>
                    <p>
                      {t(
                        `10.4. The purchase price will be 2,500 Euros per DECT-Token. This is a gross amount including statutory VAT.`
                      )}
                    </p>
                    <p>
                      {t(
                        `10.5. The price must be paid to Anbaugesellschaft.de within two weeks after acceptance of the offer.`
                      )}
                    </p>
                  </div>
                </div>
                <div className={styles.paragraph} id="section11">
                  <div className={styles.paragraph__title_wrapper}>
                    <div className={styles.paragraph__title}>
                      11. {t("Functions of the DECT-Token")}
                    </div>
                  </div>
                  <div className={styles.paragraph__text}>
                    <p>
                      {t(
                        `11.1. The holder of a DECT-Token receives exclusive access to the Platform and its functionalities via the website. This includes, among others: - Access to the online shop, where exclusively the usage rights to the systems DECT-Grow and DECT-S2S and other products and services specifically for home growers can be purchased, - Access to home-growing and cannabis club systems, - Location access, - Special product offers and discounts, - Access to exclusive events and activities, - Activation of the live camera function, and - The exclusive right to participate in further token emissions (e.g., Plant Space NFT issuance).`
                      )}
                    </p>
                    <p>
                      {t(
                        `11.2. The DECT-Token acts as an access key to the Platform. DECT-Token does not have a payment function. The products and services offered in the online shop, including the software DECT-S2S and DECT-Grow, are payable exclusively in Euros. Payment is possible by bank transfer or in cryptocurrencies via Coinbase Commerce. Interest payments or a cash settlement for the temporary provision of capital are not associated with the DECT. It is intended to allow the DECT-Token to be traded on crypto trading exchanges.`
                      )}
                    </p>
                    <p>
                      {t(
                        `11.3. The DECT-Tokens are kept by the holders in their own wallets. The holders of the DECT-Token are also responsible for the safekeeping of the associated cryptographic keys.`
                      )}
                    </p>
                    <p>
                      {t(
                        `11.4. The purchase of the DECT-Token does not involve the acquisition of other rights. In particular, but not exclusively, the buyer does not acquire any rights or shares in the Issuer or the companies associated with it by purchasing DECT-Tokens.`
                      )}
                    </p>
                  </div>
                </div>
                <div className={styles.paragraph} id="section12">
                  <div className={styles.paragraph__title_wrapper}>
                    <div className={styles.paragraph__title}>
                      12. {t("The Offer and the Conclusion of the Contract")}
                    </div>
                  </div>
                  <div className={styles.paragraph__text}>
                    <p>
                      {t(
                        `12.1. Anbaugesellschaft.de offers registered users the opportunity to purchase DECT-Tokens on the Platform.`
                      )}
                    </p>
                    <p>
                      {t(
                        `12.2. For this purpose, Anbaugesellschaft.de provides a digital service that allows the purchased tokens to be viewed.`
                      )}
                    </p>
                    <p>
                      {t(
                        `12.3. The public offer for the purchase of DECT-Tokens by Anbaugesellschaft.de on the Platform is directed exclusively at well-informed, experienced, and self-determined individuals and companies based on the information published in a whitepaper provided.`
                      )}
                    </p>
                    <p>
                      {t(
                        `12.4. The user must thoroughly familiarize themselves with the issuer's statements and information before purchasing and be aware of the circumstances and risks of the token. The user must independently inform themselves about the ICO based on the information provided by the issuer. By clicking the button 'I have read the whitepaper and understood its contents' and by clicking the button 'I have read the risk notices contained in the annex to these GTC and understood their contents,' he accepts them as the basis for concluding the contract. By clicking the 'Order now with obligation to pay' button, he completes the digital purchasing process.`
                      )}
                    </p>
                    <p>
                      {t(
                        `12.5. The purchase of DECT-Tokens takes place exclusively based on the information provided on our official website and in our whitepaper. It is expressly pointed out that information spread by external persons about the DECT project or published on external websites does not represent a binding basis for registration on the Platform and the purchase of DECT-Tokens. Any decisions based on information from external sources are made at the user's own risk and are beyond our responsibility and liability.`
                      )}
                    </p>
                    <p>
                      {t(
                        `12.6. If the interested user wishes to accept the offer at the prices presented on the Platform, they first select how many tokens they want to purchase. After entering all other information required for the purchasing process, the buyer declares acceptance of the offer by clicking the 'Order now with obligation to pay' button.`
                      )}
                    </p>
                    <p>
                      {t(
                        `12.7. The buyer's declaration of intent directed towards concluding the contract cannot be reversed, resulting in the purchase contract not being unwound and a refund of the purchase price not being possible, unless this is expressly prescribed by mandatory legal regulations. The Issuer will not take back once-sold DECT-Tokens nor exchange them in a fiat currency or crypto value.`
                      )}
                    </p>
                    <p>
                      {t(
                        `12.8. If the buyer is a consumer, they have a right of withdrawal according to statutory provisions. The regulations for the right of withdrawal are detailed in the Cancellation Policy for Consumers. A consumer is a natural person who places the order for a purpose that can neither be attributed to their commercial nor their independent professional activity.`
                      )}
                    </p>
                    <p>
                      {t(
                        `12.9. Immediately after acceptance of the offer by Anbaugesellschaft.de, the buyer receives a confirmation on the screen, which is also sent by email. The technical transfer of the DECT-Tokens takes place after payment of the purchase price following the end of the ICO and will be announced on the Platform. If no payment is received after two weeks, the user will receive a payment reminder. One week after the payment reminder, the order is automatically canceled.`
                      )}
                    </p>
                    <p>
                      {t(
                        `12.10. Anbaugesellschaft.de offers the user further free ICO-related and -associated services on the Platform, especially for communication with Anbaugesellschaft.de, for maintaining master data, for viewing personal documents, for managing newsletters and notifications, for an overview of acquired DECT-Tokens, for an overview of project-specific details such as yields and harvests in agricultural projects, for an overview and participation in eligible NFT emissions, and for the use of webcams showing or intended to show investment objects. Anbaugesellschaft.de is not responsible for the contents published by buyers. Moreover, the user has the opportunity to manage their investments and buyer profile and to view and download documents provided by the Platform. The services offered by Anbaugesellschaft.de are continuously evolving and can therefore change from time to time. Anbaugesellschaft.de may, for example, add or remove individual functions or features for technical or legal reasons or temporarily or permanently discontinue services. There is no claim to the smooth and error-free operation of the Platform and the associated services.`
                      )}
                    </p>
                  </div>
                </div>
                <div className={styles.paragraph} id="section13">
                  <div className={styles.paragraph__title_wrapper}>
                    <div className={styles.paragraph__title}>
                      13. {t("Declarations and Assurances of the Buyer")}
                    </div>
                  </div>
                  <div className={styles.paragraph__text}>
                    <p>
                      {t(
                        `By purchasing a DECT, you accept the following conditions and assure that the following information truthfully and accurately represents the basis on which you acquire the DECT:`
                      )}
                    </p>
                    <p>
                      {t(
                        `13.1. Neither the Issuer nor a member of the DECT team has given a personal recommendation to the buyer to purchase the DECT-Token in connection with the acquisition of the DECT. In particular, Anbaugesellschaft.de does not provide investment advice to the buyer. To the extent Anbaugesellschaft.de provides information about the DECT-Token, these are of a general nature and not individual advice. The whitepaper provided by Anbaugesellschaft.de on its website is also for informational purposes only. It is neither a prospectus nor an offer for investment products.`
                      )}
                    </p>
                    <p>
                      {t(
                        `13.2. You alone have decided that DECT is a suitable investment for you, consistent with your knowledge and experience.`
                      )}
                    </p>
                    <p>
                      {t(
                        `13.3. You have sufficient understanding of the functionality, use, storage, transmission mechanisms, and other specifications and functionalities associated with cryptographic tokens and blockchain-based systems and projects.`
                      )}
                    </p>
                    <p>
                      {t(
                        `13.4. You are legally authorized to receive, hold, and use DECT in your and any other relevant jurisdiction.`
                      )}
                    </p>
                    <p>
                      {t(
                        `13.5. You will provide the Issuer with all relevant information, documents, or copies of documents on first request and will promptly inform it of any changes to the information that the Issuer should need to accept your purchase of DECT and allocate the purchased DECT to you.`
                      )}
                    </p>
                    <p>
                      {t(
                        `13.6. You have not made any inaccurate or misleading statements to the Issuer regarding your acquisition of DECT or otherwise provided inaccurate or misleading information.`
                      )}
                    </p>
                    <p>
                      {t(
                        `13.7. If you are not a company or legal entity, you are of legal age, legally competent, and legally capable of effectively concluding contracts to lawfully acquire DECT.`
                      )}
                    </p>
                    <p>
                      {t(
                        `13.8. You assume full and sole responsibility for all risks associated with or that may be associated with the acquisition, receipt, and possession of DECT. We refer here, among other things – but not exclusively - to the notes in the section 'Risks.'`
                      )}
                    </p>
                    <p>
                      {t(
                        `13.9. You do not buy and use DECT for an illegal purpose and will not use DECT for illegal purposes in the future.`
                      )}
                    </p>
                    <p>
                      {t(
                        `13.10. By participating in the token sale, buyers acknowledge that neither the Issuer nor its affiliated companies, directors, employees, or shareholders are obligated to provide a refund or compensation for any reason. If the Issuer, at its discretion, believes that persons or entities owning DECT pose substantial regulatory or other legal risks or adverse effects for the Issuer and/or DECT, the Issuer reserves the right to buy all DECT from such DECT owners at then-current market prices and/or sell all cryptocurrency values of the Issuer.`
                      )}
                    </p>
                    <p>
                      {t(
                        `13.11. You waive the assertion of claims against the Issuer for any damages arising from or in connection with the acquisition of DECT.`
                      )}
                    </p>
                    <p>
                      {t(
                        `13.12. Your purchase of DECT does not involve the acquisition or receipt of shares, ownership, or participations in the Issuer or companies that may be established by the Issuer for the implementation of its projects DECT-Grow or DECT S2S.`
                      )}
                    </p>
                    <p>
                      {t(
                        `13.13. You accept that the Issuer does not expressly or implicitly provide any warranty, promise, or guarantee for the future success of DECT and or the technical underlying Ethereum blockchain network.`
                      )}
                    </p>
                    <p>
                      {t(
                        `13.14. You accept that DECT is created based on the current 'as-is' state described in this whitepaper and thus on a 'developmental' basis. Therefore, you accept that the Issuer provides DECT without warranties regarding any rights associated with DECT, such as property rights, marketability, or suitability for a particular purpose.`
                      )}
                    </p>
                    <p>
                      {t(
                        `13.15. You accept that the acquisition, allocation, use, or ownership of DECT could have tax implications, including the potential appreciation or depreciation of DECT over time, and potentially the sale and purchase of DECT and/or any other action or transaction related to DECT, and it is your responsibility to determine these tax implications in your respective jurisdiction and comply with any resulting obligations.`
                      )}
                    </p>
                  </div>
                </div>
                <div className={styles.paragraph} id="section14">
                  <div className={styles.paragraph__title_wrapper}>
                    <div className={styles.paragraph__title}>
                      14.{" "}
                      {t(
                        "Buyer's Responsibility for Storage Medium and Access Data"
                      )}
                    </div>
                  </div>
                  <div className={styles.paragraph__text}>
                    <p>
                      {t(
                        `The buyer is solely responsible for implementing adequate technical and organizational measures to secure the storage medium (wallet, vaults, etc.) used to manage their acquired tokens. This also applies to the access data enabling access to the storage medium, especially the private key. In case of loss of these access data, there is no guarantee that access to the acquired tokens can be granted again. The buyer must therefore bear the costs or other expenses incurred in connection with the loss of the access data.`
                      )}
                    </p>
                  </div>
                </div>
                <div className={styles.paragraph} id="section15">
                  <div className={styles.paragraph__title_wrapper}>
                    <div className={styles.paragraph__title}>
                      15.{" "}
                      {t(
                        "Taxation of DECT and Taxation in Connection with the Token Sale"
                      )}
                    </div>
                  </div>
                  <div className={styles.paragraph__text}>
                    <p>
                      {t(
                        `It is the sole responsibility of the buyer to determine whether the purchase of DECT with Euros, BNB, ETH, BTC, or the potential appreciation or depreciation of DECT over time has tax implications for the buyer in their home country. By purchasing DECT and to the extent legally permissible, the buyer agrees not to hold the Issuer, its affiliated companies, directors, shareholders, or advisors liable for any tax liabilities associated with or arising from the purchase of DECT.`
                      )}
                    </p>
                  </div>
                </div>
                <div className={styles.paragraph} id="section16">
                  <div className={styles.paragraph__title_wrapper}>
                    <div className={styles.paragraph__title}>
                      16. {t("Warranty")}
                    </div>
                  </div>
                  <div className={styles.paragraph__text}>
                    <p>
                      {t(
                        `16.1. The Issuer is liable for defects according to the applicable legal provisions.`
                      )}
                    </p>
                    <p>
                      {t(
                        `16.2. The whitepaper provided by the Issuer on its website does not constitute a quality agreement.`
                      )}
                    </p>
                    <p>
                      {t(
                        `16.3. The DECT-Tokens are used on the respective blockchain and are therefore subject to the regulations applicable there. The Issuer cannot influence or control the blockchain technology or the processes taking place on it. Therefore, it is particularly not liable for the loss of functionality or applicability of the DECT-Tokens due to changes, especially technical changes, or the dissolution of the respective blockchain.`
                      )}
                    </p>
                    <p>
                      {t(
                        `16.4. The buyer's wallet outside the Anbaugesellschaft.de platform and the services of an external third-party provider associated with it fall comprehensively and exclusively within the responsibility of this third-party provider, which provides the wallet to the buyer.`
                      )}
                    </p>
                  </div>
                </div>
                <div className={styles.paragraph} id="section17">
                  <div className={styles.paragraph__title_wrapper}>
                    <div className={styles.paragraph__title}>
                      17. {t("Exclusion of Warranties")}
                    </div>
                  </div>
                  <div className={styles.paragraph__text}>
                    <p>
                      {t(
                        `The buyer expressly agrees to acquire DECT at their own risk. Any warranty in connection with the purchase and subsequent use of DECT is excluded to the extent permitted by law. The Issuer makes no warranty regarding DECT. Without limiting the foregoing, neither the Issuer nor the DECT team guarantees that the process of purchasing DECT will proceed uninterrupted or error-free.`
                      )}
                    </p>
                  </div>
                </div>
                <div className={styles.paragraph} id="section18">
                  <div className={styles.paragraph__title_wrapper}>
                    <div className={styles.paragraph__title}>
                      18. {t("Limitation and Waiver of Liability")}
                    </div>
                  </div>
                  <div className={styles.paragraph__text}>
                    <p>
                      {t(
                        `18.1. Liability for losses or damages incurred by the buyer due to the acquisition of DECT is excluded to the extent permitted by law.`
                      )}
                    </p>
                    <p>
                      {t(
                        `18.2. The buyer fully acknowledges the liability disclaimers contained herein to the full extent of applicable law for any and all damages or injuries of any kind related to the use of, or the inability to use, DECT.`
                      )}
                    </p>
                    <p>
                      {t(
                        `18.3. The buyer further acknowledges that neither the Issuer nor the DECT team can be held liable for indirect, incidental, special, exemplary, or consequential damages, including loss of profit, goodwill, or data, arising in any way from the use or inability to use or purchase or inability to purchase DECT or from interaction with the smart contracts implemented in relation to DECT.`
                      )}
                    </p>
                    <p>
                      {t(
                        `18.4. Furthermore, the buyer expressly acknowledges that the Issuer is not liable for the behavior of third parties, including other buyers of DECT, and that the risk of purchasing and using DECT lies entirely with the buyer.`
                      )}
                    </p>
                    <p>{t(`Date: October 15, 2023`)}</p>
                  </div>
                </div>
                <h2 id="section19">{t("Cancellation Policy for Consumers")}</h2>
                <div className={styles.paragraph}>
                  <div className={styles.paragraph__text}>
                    <p>
                      {t(
                        `Consumers, i.e., any natural person who enters into a contract for a purpose that is predominantly neither commercial nor their independent professional activity (§ 13 BGB), have a statutory right of withdrawal in the case of a distance selling transaction, about which information is provided below according to the statutory model.`
                      )}
                    </p>
                  </div>
                </div>
                <div className={styles.paragraph}>
                  <div className={styles.paragraph__title_wrapper}>
                    <div className={styles.paragraph__title}>
                      {t("Right of Withdrawal")}
                    </div>
                  </div>
                  <div className={styles.paragraph__text}>
                    <p>
                      {t(
                        `You have the right to withdraw from this contract within fourteen days without giving any reason.`
                      )}
                    </p>
                    <p>
                      {t(
                        `The withdrawal period is fourteen days from the day on which you have received the token.`
                      )}
                    </p>
                    <p>
                      {t(
                        `To exercise your right of withdrawal, you must inform us.`
                      )}
                    </p>
                    <p>Deutsche Anbaugesellschaft DAG GmbH</p>
                    
                    <p>Libnower Landstr. 1</p>
                    <p>17390 Murchin</p>
                    <p>{t("Germany")}</p>
                    <p>{t("Phone: 040 / 2999 6808")}</p>
                    <p>{t("Email: ")}info@anbaugesellschaft.de</p>
                    <p>
                      {t(
                        `by means of a clear declaration (e.g., a letter sent by post or email) of your decision to withdraw from this contract.`
                      )}
                    </p>
                    <p>
                      {t(
                        `You may use the attached model withdrawal form, but it is not obligatory.`
                      )}
                    </p>
                    <p>
                      {t(
                        `To meet the withdrawal deadline, it is sufficient for you to send your communication concerning your exercise of the right of withdrawal before the withdrawal period has expired.`
                      )}
                    </p>
                  </div>
                </div>
                <div className={styles.paragraph}>
                  <div className={styles.paragraph__title_wrapper}>
                    <div className={styles.paragraph__title}>
                      {t("Consequences of Withdrawal")}
                    </div>
                  </div>
                  <div className={styles.paragraph__text}>
                    <p>
                      {t(
                        `If you withdraw from this contract, we shall reimburse to you all payments received from you, including the costs of delivery (with the exception of the supplementary costs resulting from your choice of a type of delivery other than the least expensive type of standard delivery offered by us) without undue delay and in any event not later than fourteen days from the day on which we are informed about your decision to withdraw from this contract. We will carry out such reimbursement using the same means of payment as you used for the initial transaction unless you have expressly agreed otherwise; in any event, you will not incur any fees as a result of such reimbursement.`
                      )}
                    </p>
                    <p>
                      {t(
                        `We may withhold reimbursement until we have received the token back, or you have supplied evidence of having sent back the token, whichever is the earliest. You must transfer the token back to us without undue delay and in any event not later than 14 days from the day on which you communicate your withdrawal from this contract to us.`
                      )}
                    </p>
                    <p>
                      {t(
                        `The deadline is met if you carry out the transaction for the return transfer of the token before the period of 14 days has expired. You will bear the direct transaction costs for returning the token to us.`
                      )}
                    </p>
                  </div>
                </div>
                {localStorage.getItem("i18n") !== "DE" ? (
                  <a href={`${FRONT_URL}/files/Revocation Form.docx`}>
                    Model Withdrawal Form
                  </a>
                ) : (
                  <a href={`${FRONT_URL}/files/Widerrufsformular.docx`}>
                    Muster-Widerrufsformular
                  </a>
                )}
                <div className={styles.paragraph}>
                  <div className={styles.paragraph__text}>
                    <p>
                      {t(
                        `(If you want to withdraw from the contract, please fill out this form and send it back.)`
                      )}
                    </p>
                    <p>{t(`to `)}</p>
                    <p>Deutsche Anbaugesellschaft DAG GmbH</p>
                    <p>Libnower Landstr. 1</p>
                    <p>17390 Murchin</p>
                    <p>{t("Email: ")}info@deutsche-anbaugesellschaft.de</p>
                  </div>
                </div>
                <h2 id="section20">{t("Risk Notices")}</h2>
                <div className={styles.paragraph}>
                  <div className={styles.paragraph__text}>
                    <p>
                      {t(
                        `PLEASE READ THESE IMPORTANT RISK NOTICES CAREFULLY AND ATTENTIVELY BEFORE PARTICIPATING IN THE DECT-TOKEN INITIAL COIN OFFERING (ICO). IF YOU HAVE QUESTIONS ABOUT THE ICO, THE DECT-TOKEN, THE BUSINESS MODEL OF THE DEUTSCHE ANBAUGESELLSCHAFT DAG GMBH (HEREINAFTER REFERRED TO AS 'Anbaugesellschaft.de' AND ISSUER) OR OTHER RELEVANT ASPECTS IN THIS CONTEXT, YOU SHOULD SEEK ADVICE FROM AN EXPERT (LAWYER, TAX ADVISOR, ETC.). IF THERE ARE DOUBTS OR UNCERTAINTIES, YOU SHOULD NOT PARTICIPATE IN THE ICO.`
                      )}
                    </p>
                  </div>
                </div>
                <div className={styles.paragraph}>
                  <div className={styles.paragraph__title_wrapper}>
                    <div className={styles.paragraph__title}>
                      {t(
                        "By purchasing DECT, you as a buyer expressly acknowledge that you have read and understood the following risk notices and that you are willing to bear them:"
                      )}
                    </div>
                  </div>
                  <div className={styles.paragraph__text}>
                    {riskInformationParagraphs?.map((item) => {
                      const { id, text } = item;
                      return (
                        <p key={id}>
                          {`${id}. `}
                          {text}
                        </p>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Banner />
      </main>
    </div>
  );
};
