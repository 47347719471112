import React from "react";
import styles from "./Mission.module.scss";

import { useTranslation } from "react-i18next";

const Mission = () => {
  const { t } = useTranslation();
  return (
    <>
      <span id="mission"></span>
      <section className={styles.mission}>
        {/* <div className="container"> */}
        <div className={styles.mission__left}>
          <div className="container">
          <div className={styles.mission__left_inner}>
            <h2>{t("Mission & Philosophy")}</h2>
            <div>
            <p>
              {t(
                "Germany has legalized cannabis. It is estimated that the market in Germany has a volume of over 400 tons. This quantity is to be cultivated in Germany step by step in the future. A large proportion of this is to be accounted for by the growers' associations. With our cultivation and technology park in Mecklenburg-Vorpommern, we are able to provide professionally developed and optimally secured cultivation areas for up to 300 growers' associations. Thanks to our own wells and photovoltaic system, the entire site is self-sufficient and powered by renewable energy."
              )}
            </p>
            <p>
              {t(
                "Cannabis is one of the oldest crops in the world and has been cultivated and used by humans for 2,500 years. It is increasingly being used in medicine and has already been approved for recreational use in the first countries. We have been campaigning for many years for the legalization of cannabis in Germany, which is now becoming a reality. We are firmly convinced that cannabis has many positive properties and helps many people. It is therefore all the more important that people have access to pure and controlled cannabis. This is what we as the Deutsche Anbaugesellschaft DAG stand for."
              )}
            </p>
            </div>
          </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Mission;
