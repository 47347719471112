import React from "react";
import styles from "./AdminStatistics.module.scss";
import AdminAside from "../../components/AdminAside/AdminAside";
import Main from "./Components/Main/Main";
import AdminHeader from "../../components/AdminHeader/AdminHeader";

const AdminStatistics = ({
  title,
  openModal,
  role,
  setIsOpenModal,
  referalValue,
  currentLang,
  setCurrentLang,
}) => {
  return (
    <div className={styles.wrapper}>
      <AdminAside setIsOpenModal={setIsOpenModal} isOpenModal={openModal}  role={role} />
      <div className={styles.container}>
        <AdminHeader
          openModal={openModal}
          setIsOpenModal={setIsOpenModal}
          referalValue={referalValue}
          currentLang={currentLang}
          setCurrentLang={setCurrentLang}
          role={role}
        />
        <Main title={title} role={role} />
      </div>
    </div>
  );
};

export default AdminStatistics;
