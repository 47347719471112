import React, { memo } from "react";

const PlayIcon = memo(({ color = "#fff" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="53"
      height="62"
      viewBox="0 0 53 62"
      fill="none"
    >
      <path
        d="M10.8381 7.69931L41.1846 30.7972L10.8381 53.8952V7.69931Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export default PlayIcon;
