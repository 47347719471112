import React from 'react';
import styles from './Checkbox.module.scss'
import Triangle from '../../img/IconComponents/Triangle'
import cn from 'classnames';

const Checkbox = ({ text, value, onChange, error,disabled, className = '', type = "checkbox", name = "" }) => {

  return (
  <label className={cn(styles.container, styles[className])}>
    <div className={styles.container__text}>{ text }</div>
    <input disabled={disabled} type={type} checked={value} onChange={onChange} name={name}/>
    <span className={styles.checkmark}></span>
    {
      (error && error.length) &&
      <div className={styles.error}>
        <Triangle />
        <div>{error}</div>
      </div>
    }
  </label>
  )
}

export default Checkbox;