import React, { useEffect, useState } from "react";
import styles from "./Verification.module.scss";
import "../../styling/common.css";
import HeaderCommon from "../../components/HeaderCommon/HeaderCommon";
import FooterCommon from "../../components/FooterCommon/FooterCommon";
import Kyc from "../../img/IconComponents/Kyc";
import Button from "../../components/Button/Button";
import login from "../../img/login.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import postRequest from "../../helpers/postRequest";

const Verification = ({ currentLang, setCurrentLang }) => {
  const { t } = useTranslation();
  const [id, setId] = useState(null);
  const [isLoad, setIsLoad] = useState(false);

  const startKYC = () => {
    setIsLoad(true);
    postRequest("v1/user/kyc", {})
      .then((response) => {
        setId(response.data?.id);
        setIsLoad(false);
      })
      .catch((error) => {
        setIsLoad(false);
        console.log(error);
      });
  };

  useEffect(() => {
    if (id && id.length) {
      window.location.href = `https://go.test.idnow.de/${id}`;
    }
  }, [id]);

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <HeaderCommon currentLang={currentLang} setCurrentLang={setCurrentLang} />
      <section className={styles.verify}>
        <div className="container">
          <div className={styles.verify__info}>
            <img className={styles.verify__image} src={login} alt="login" />
            <div
              className={styles.verify__circle}
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              <Kyc />
            </div>
            <h2 className={styles.verify__title}>
              {t("You need to verify your identity with our KYC procedure.")}
            </h2>
            <p className={styles.verify__desc}>
              {t("Click on the button below to verify your identity.")}
            </p>
            <Button text={t("Verify")} color="green" onClick={startKYC} />
          </div>
        </div>
      </section>
      <FooterCommon />
    </>
  );
};

export default Verification;
