import React from "react";
import styles from "./Finances.module.scss";
import Header from "../../components/Header/Header";
import Aside from "../../components/Aside/Aside";
import Main from "./Components/Main/Main";
import FooterClient from "../../components/FooterClient/FooterClient";

const Finances = ({ currentLang, setCurrentLang }) => {
  return (
    <div className={styles.wrapper}>
      <Aside />
      <div className={styles.container}>
        <Header currentLang={currentLang} setCurrentLang={setCurrentLang} />
        <Main />
        <div className={styles.footer}>
          <FooterClient />
        </div>
      </div>
    </div>
  );
};

export default Finances;
