import React from "react";
import styles from "./AdminCommision.module.scss";
import tooltipImg from "../../img/tooltip.svg";
import canabis from "../../img/canabis1.svg";
import { useTranslation } from "react-i18next";
const AdminCommision = ({ text, percent, tooltip = false }) => {
  const {t} = useTranslation();
  return (
    <div className={styles.title}>
      {text} <span>{percent ? Number(percent).toFixed(0) : 0}%</span>
      {tooltip && (
        <div className={styles.tooltip}>
          <img src={tooltipImg} alt="!" />
          <div className={styles.tooltip__inner}>
            <h4>
              {
                t("Get a higher percentage of your commission for increasing sales:")
              }
            </h4>
            <div className={styles.tooltip__inner_list}>
              <div>
                <img src={canabis} alt="canabis" />
                {
                  t("If your customers bought 1-499 tokens - your commission is 15%")
                }
              </div>
              <div>
                <img src={canabis} alt="canabis" />
                {
                  t("If your customers bought 500 and more tokens - your commission is 20%")
                }
              </div>
            </div>
            <div className={styles.tooltip__inner_amount}>
              <div className={styles.tooltip__inner_amount__line}>
                <div style={{ width: `${tooltip/500*100}%` }}>
                  <div className={styles.ball}>
                    <div style={{marginLeft: tooltip/500*100 < 10 ? '-4px' : tooltip/500*100 >= 10 && tooltip/500*100 <= 90 ? '-42px' : '-86px'}}>{tooltip} Token</div>
                  </div>
                </div>
              </div>
              <div>
                <p>0</p>
                <p>500</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminCommision;
