import React from 'react'
import styles from './BackButton.module.scss'
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const BackButton = ({ path }) => {
  const navigate = useNavigate();
  const {t} =useTranslation()
  
  return (
    <button 
      onClick={() => navigate(-1)}
      className={styles.back}
    >
      {t("Back")}
    </button>
  )
}

export default BackButton;