import { regEmail, regPhone } from "./regexp";
import checkPassword from "./checkPassword";
import { t } from "i18next";
import moment from "moment";

export const checkEmail = (value, setValue, setError, message) => {
  if (value) {
    setValue(value.trim());
  } else {
    setValue("");
  }
  setError("");
  if (!value.length) setError(message ? message : t("Email cannot be empty"));
  if (!regEmail.test(value))
    setError(message ? message : t("Enter correct email"));
};

export const checkTextarea = (value, setValue, setError) => {
  if (value) {
    setValue(value.trim());
  } else {
    setValue("");
  }
  setError("");
  if (value.length < 30)
    setError(t("Your message should contain at least 30 symbols"));
};

export const checkIban = (value, setValue, setError) => {
  if (value) {
    setValue(value.trim());
  } else {
    setValue("");
  }
  setError("");
  if (value && value.length > 34) setError(t("Wrong IBAN"));
};
export const checkWalletId = (value, setValue, setError, isSending) => {
  if (isSending) {
    if (value) {
      setValue(value?.trim());
      setError(t(""));
    } else {
      setValue("");
      setError(t("Empty Wallet ID"));
    }
  } else {
    setError(t("Agree to send token after launch, to save this"));
  }
};

export const checkSwift = (value, setValue, setError) => {
  if (value) {
    setValue(value.trim());
  } else {
    setValue("");
  }
  setError("");
  if (value && (value.length < 8 || value.length > 11))
    setError(t("Wrong BIC/SWIFT"));
};

export const checkPass = (value, setValue, setError) => {
  if (value) {
    setValue(value.trim().replace(/\s/g, ""));
  } else {
    setValue("");
  }
  setError("");
  checkPassword(value, setError);
};

export const checkFirstName = (value, setValue, setError) => {
  if (value) {
    setValue(value);
  } else {
    setValue("");
  }
  setError("");
  if (!value) setError(t("First name cannot be empty"));
};

export const checkLastName = (value, setValue, setError) => {
  setValue(value);
  setError("");
  if (!value.length) setError(t("Last name cannot be empty"));
};
export const checkName = (value, setValue, setError) => {
  setValue(value);
  setError("");
  if (!value.length) setError(t("Name cannot be empty"));
};

export const checkSubject = (value, setValue, setError) => {
  setValue(value);
  setError("");
  if (!value.length) setError(t("Subject cannot be empty"));
};

export const checkAccountOwner = (value, setValue) => {
  setValue(value);
};

export const checkPhone = (value, setValue, setError) => {
  if (value) {
    setValue(value);
  } else {
    setValue("");
  }
  setError("");
  if (!regPhone.test(value)) setError(t("Enter correct phone number"));
};

export const checkReferalId = (value, inviteCode, setValue, setError) => {
  if (value) {
    setValue(value);
  } else {
    setValue("");
  }
  setValue(value.trim());
  setError("");
  if (!value.length && inviteCode == null) {
    setError(t("Enter referral code and try again!"));
  }
  if (inviteCode && inviteCode.length && value.length) {
    setError(t("Provide either referral code or invite code"));
  }
  // if(!value.length){
  //   setError(t("Provide either referral code or invite code"));
  //   console.log('err her 2')
  // }
  if(/^[a-zA-Z]+$/.test(value)){
    setError(t("Enter correct referral code"))
  }
};

export const checkPasswordAgain = (value, password, setValue, setError) => {
  if (value) {
    setValue(value.trim().replace(/\s/g, ""));
  } else {
    setValue("");
  }
  setValue(value.trim().replace(/\s/g, ""));
  setError("");
  if (value.trim().replace(/\s/g, "") !== password)
    setError(t("Passwords must match"));
};

export const checkEmailAgain = (value, email, setValue, setError) => {
  if (value) {
    setValue(value.trim().replace(/\s/g, ""));
  } else {
    setValue("");
  }
  setValue(value.trim().replace(/\s/g, ""));
  setError("");
  if (value.trim().replace(/\s/g, "") !== email)
    setError(t("Email must match"));
};

export const checkAge = (value, setValue, setError) => {
  setValue(value);
  setError("");
  if (!value) setError(t("You must accept these terms"));
};

export const checkPrivacy = (value, setValue, setError) => {
  setValue(value);
  setError("");
  if (!value) setError(t("You must accept these terms"));
};

export const checkCity = (value, setValue, setError) => {
  setValue(value);
  setError("");
  if (!value) setError(t("City cannot be empty"));
};

export const checkStreet = (value, setValue, setError) => {
  setValue(value);
  setError("");
  if (!value) setError(t("Street cannot be empty"));
};

export const checkCompanyName = (value, setValue, setError) => {
  setValue(value);
  setError("");
  if (!value) setError(t("Company name cannot be empty"));
};

export const checkPost = (value, setValue, setError) => {
  setValue(value);
  setError("");
  if (!value) setError(t("Postcode cannot be empty"));
};

export const checkDoor = (value, setValue, setError) => {
  setValue(value);
  setError("");
  if (!value) setError(t("Apartment/Door cannot be empty"));
};

export const checkCountryCode = (value, setValue, setError) => {
  setValue(value);
  setError("");
  if (!value) setError(t("Select country"));
};
export const checkCountry = (value, setValue, setError) => {
  setValue(value);
  setError("");
  if (!value) setError(t("Country cannot be empty"));
  if (value === "Select country") setError(t("Country cannot be empty"));
};

export const checkDOB = (value, setValue, setError) => {
  setValue(value);
  setError("");
  const date = moment(value);
  const yearsDiff = moment().diff(date, "years");
  if (value == "") setError(t("Date of birth cannot be empty"));
  if (yearsDiff < 18)
    setError(t("You must be over 18 years old to register on the platform"));
};

export const checkYear = (value, setValue, setError) => {
  setValue(value);
  setError("");
  const date = moment(value);
  const yearsDiff = moment().diff(date, "years");
  if (value == "") setError(t("Date of birth cannot be empty"));
  if (yearsDiff < 18)
    setError(t("You must be over 18 years old to register on the platform"));
};
