import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useObserver } from "mobx-react-lite";
import styles from "./Header.module.scss";
import Stats from "./Components/Stats/Stats";
import { API_URL } from "../../http";
import { useLocation } from "react-router-dom";
// import Error from "./Components/Error/Error";
import LangSwitcher from "../LangSwitcher/LangSwitcher";
import Logout from "../Logout/Logout";
import getRequest from "../../helpers/getRequest";
import { Link } from "react-router-dom";
import { Context } from "../..";
import wallet from "../../img/wallet.svg";
import HomeIcon from "../../img/IconComponents/HomeIcon";
import weed from "../../img/weed.svg";
import logo from "../../img/logo.png";
import cn from "classnames";
import HelpIcon from "../../img/IconComponents/HelpIcon";
import GearIcon from "../../img/IconComponents/GearIcon";
import Bullhorn from "../../img/IconComponents/Bullhorn";
import Loader from "../Loader/Loader";
import Cross from "../../img/IconComponents/Cross";
import { useTranslation } from "react-i18next";
import account from "../../img/account.png";
import de from "../../img/de.svg";
import en from "../../img/en.svg";
import ArrowDropdown from "../../img/IconComponents/ArrowDropdown";
import DashboardIcon from "../../img/IconComponents/DashboardIcon";
import FinancesIcon from "../../img/IconComponents/FinancesIcon";
import MallIcon from "../../img/IconComponents/MallIcon";
import ShopIcon from "../../img/IconComponents/ShopIcon";
import menu from "../../img/menu.svg";
import ViewPlantsIcon from "../../img/IconComponents/ViewPlantsIcon";
import LocationIcon from "../../img/IconComponents/LocationIcon";
import NewsIcon from "../../img/IconComponents/NewsIcon";
import BuyPlantsIcon from "../../img/IconComponents/BuyPlantsIcon";
import AOS from "aos";
import MenuLink from "./Components/MenuLink/MenuLink";

const Header = ({ currentLang, setCurrentLang, harvest, profit }) => {
  const [role, setRole] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isHeaderMobileOpen, setIsHeaderMobileOpen] = useState(false);
  const [profilePhotoUser, setProfilePhotoUser] = useState("");
  const [userName, setUserName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userData, setUserData] = useState({});

  const [isOpenClientMenu, setIsOpenClientMenu] = useState(false);

  const [isMarketPlaceVisible, setIsMarketPlaceVisible] = useState(false);

  const [money, setMoney] = useState(0);
  const [weedValue, setWeed] = useState(0);

  const { store } = useContext(Context);
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const [headerData, setHeaderData] = useState([
    { icon: wallet, value: "0.00", type: "EUR", id: 1 },
    { icon: weed, value: "0.00", type: "g", id: 2 },
  ]);

  const changeLanguageDe = () => {
    i18n.changeLanguage("DE");
    setCurrentLang({ lang: "DE", image: de });
    localStorage.setItem("i18n", "DE");
  };

  const changeLanguageEn = () => {
    i18n.changeLanguage("EN");
    setCurrentLang({ lang: "EN", image: en });
    localStorage.setItem("i18n", "EN");
  };
  // const { questionnaireDone } = useObserver(() => ({
  //   questionnaireDone: store.questionnaireDone,
  // }));
  useEffect(() => {
    setIsLoading(true);
    getRequest("v1/auth/whoami").then((response) => {
      setIsMarketPlaceVisible(response.data.is_user_under_hack);
      setRole(response.data?.user_role);
      setUserData(response.data);
      console.log('DATA\n',response.data)
    });
    setIsLoading(false);
  }, []);

  useEffect(() => {
    setIsLoading(true);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    axios({
      method: "get",
      url: `${API_URL}/v1/harvest/available`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then(async (response) => {
      setIsLoading(false);
      setMoney(+response.data.profit);
      setWeed(+response.data.unsold_harvest);
      setHeaderData([
        {
          icon: wallet,
          value: (response.data.unsold_harvest * 2.5 + 3).toFixed(2) || "0.00",
          type: "EUR",
          id: 1,
        },
        {
          icon: weed,
          value: response.data.unsold_harvest.toFixed(2) || "0.00",
          type: "g",
          id: 2,
        },
      ]);
    });

    // getRequest(`v1/finances/${role === 'CLIENT' ? '' : 'sales-area/'}data`)
    //   .then((response) => {
    //     setHeaderData([
    //       {
    //         icon: wallet,
    //         value:
    //           role === 'CLIENT'
    //             ? response.data?.euro_balance?.toFixed(2) || '0.00'
    //             : response.data.current_balance?.toFixed(2) || '0.00',
    //         type: 'EUR',
    //         id: 1,
    //       },
    //       {
    //         icon: weed,
    //         value: response.data?.harvest_balance?.toFixed(2) || '0.00',
    //         type: 'g',
    //         id: 2,
    //       },
    //     ])
    //   })
    //   .catch((error) => {
    //     console.log(error)
    //   })
  }, []);

  useEffect(() => {
    getRequest("v1/user/billing-details").then((response) => {
      setUserName(response.data?.first_name);
      setUserLastName(response.data?.last_name);
    });
    getRequest("v1/local-files/profile-picture", true).then((response) => {
      setProfilePhotoUser(response.data);
    });
  }, []);
  return (
    <header className={styles.header}>
      {/* {
        role && role != "ADMIN" && role != "MAIN_SALE" && role !="HEAD_OF_SALES" && role !="SALE" ?
        <>
          {!questionnaireDone && <Error />}
        </>
        :
        null
      } */}
      <div className={styles.header__wrapper}>
        <div className={styles.header__stats}>
          <div className={styles.header__stats_info}>
            {isLoading ? (
              <Loader />
            ) : (
              headerData.map((item) => {
                const { icon, value, type, id } = item;
                if (role !== "CLIENT" && type === "g") return null;
                // return <Stats key={id} icon={icon} value={value} type={type} />
              })
            )}
            {/* <Stats key={1} icon={wallet} value={location.pathname.includes('market')?Number(profit).toFixed(2): money.toFixed(2)} type={'EUR'} /> */}
            <Stats
              key={2}
              icon={weed}
              value={
                location.pathname.includes("market")
                  ? Number(harvest).toFixed(2)
                  : weedValue.toFixed(2)
              }
              type={"g"}
            />
          </div>
          <div className={styles.header__stats_btns}>
            <Link to="/settings">
              <GearIcon color="#B3B3B3" />
            </Link>
            <Link to="/help">
              <HelpIcon color="#B3B3B3" />
            </Link>
          </div>
        </div>
        <div className={styles.header__actions}>
          <Link to={"/"} className={styles.header__actions_logo}>
            <img src={logo} alt="logo" />
          </Link>
          <div className={styles.header__actions_langLogout}>
            <LangSwitcher
              currentLang={currentLang}
              setCurrentLang={setCurrentLang}
            />
            <div className={styles.desktop}>
              <Logout />
            </div>
            <div
              onClick={() => setIsHeaderMobileOpen(true)}
              className={styles.header__mobileBtn}
            >
              <img src={menu} alt="menu" />
            </div>
          </div>
        </div>
      </div>
      {isHeaderMobileOpen && (
        <div className={styles.header__mobile}>
          <div className={cn(styles.header__menu)}>
            <div className={styles.header__menu_overflow}>
              <div className={styles.header__menu_header}>
                <div className={styles.header__menu_header_logo}>
                  <img src={logo} alt="logo" width={163} />
                </div>

                <button
                  className={styles.header__menu_header_button}
                  onClick={() => setIsHeaderMobileOpen(false)}
                >
                  <Cross />
                </button>
              </div>

              <div className={styles.header__menu_client}>
                <div className={styles.header__menu_client_header}>
                  <div>
                    <div className={styles.header__menu_client_header_wrapper}>
                      <div className={styles.header__menu_client_image}>
                        {profilePhotoUser && profilePhotoUser.size ? (
                          <img
                            src={URL.createObjectURL(profilePhotoUser)}
                            alt="profile"
                          />
                        ) : (
                          <img
                            className={
                              styles.settings__account_info_pict_uploaded
                            }
                            src={account}
                            alt="profile"
                          />
                        )}
                      </div>
                      <div>
                        <div className={styles.header__menu_client_name}>
                          {userName} {userLastName}
                        </div>
                        {/* <div className={styles.header__menu_client_status}>
                          {userRole === "SALE" && t("Sale")}
                          {userRole === "HEAD_OF_SALES" && "Head of Sales"}
                          {userRole === "MAIN_SALE" && t("Top sale")}
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className={styles.header__menu_client_right}>
                    <div className={styles.header__menu_switcher}>
                      <div onClick={() => changeLanguageEn()}>
                        <img src={en} alt="en" />
                      </div>
                      <div onClick={() => changeLanguageDe()}>
                        <img src={de} alt="de" />
                      </div>
                    </div>
                    <div
                      onClick={() => setIsOpenClientMenu((prev) => !prev)}
                      className={cn(
                        styles.header__menu_client_btn,
                        isOpenClientMenu
                          ? styles.header__menu_client_btn_active
                          : ""
                      )}
                    >
                      <ArrowDropdown />
                    </div>
                  </div>
                </div>
                <div
                  className={cn(
                    styles.header__menu_add,
                    isOpenClientMenu && role != "CLIENT"
                      ? styles.header__menu_add_active_sale
                      : "",
                    isOpenClientMenu && role === "CLIENT"
                      ? styles.header__menu_add_active
                      : ""
                  )}
                >
                  <MenuLink
                    setIsHeaderMobileOpen={setIsHeaderMobileOpen}
                    path="/settings"
                    icon={GearIcon}
                    text={t("Settings")}
                    active={location.pathname.includes("/settings")}
                  />
                  {/* <MenuLink
                    setIsHeaderMobileOpen={setIsHeaderMobileOpen}
                    path="/help"
                    icon={HelpIcon}
                    text={t("Help")}
                    active={location.pathname.includes("/help")}
                  /> */}
                  <Logout />
                </div>
              </div>

              <div className={styles.header__menu_menu}>
                <MenuLink
                  setIsHeaderMobileOpen={setIsHeaderMobileOpen}
                  path="/"
                  text={t("Start page")}
                  icon={HomeIcon}
                />

                {/* Personal Area category */}
                <p className={styles.header__menu_subheader}>
                  {t("Personal area")}
                </p>
                <MenuLink
                  setIsHeaderMobileOpen={setIsHeaderMobileOpen}
                  path="/dashboard"
                  text={t("Overview")}
                  icon={DashboardIcon}
                  active={location.pathname.includes("/dashboard")}
                />
                <MenuLink
                  setIsHeaderMobileOpen={setIsHeaderMobileOpen}
                  path="/view"
                  text={t("My tokens")}
                  disabled={userData.tokens_paid == 0}
                  icon={ViewPlantsIcon}
                  active={location.pathname.includes("/view")}
                />
                <MenuLink
                  setIsHeaderMobileOpen={setIsHeaderMobileOpen}
                  path="/finances"
                  text={t("My finances")}
                  icon={FinancesIcon}
                  active={location.pathname.includes("/finances")}
                />
                {/* Buy/Sell Category */}
                <p className={styles.header__menu_subheader}>{t("Buy/Sell")}</p>
                <MenuLink
                  setIsHeaderMobileOpen={setIsHeaderMobileOpen}
                  path="/buy"
                  text={t("Buy Tokens")}
                  icon={BuyPlantsIcon}
                  active={
                    location.pathname === "/checkout" ||
                    location.pathname === "/buy"
                  }
                />
                {isMarketPlaceVisible && (
                  <MenuLink
                    setIsHeaderMobileOpen={setIsHeaderMobileOpen}
                    path="/marketplace"
                    icon={MallIcon}
                    text={t("Marketplace")}
                    active={location.pathname.includes("/marketplace")}
                  />
                )}
                <MenuLink
                  setIsHeaderMobileOpen={setIsHeaderMobileOpen}
                  path="/shop"
                  icon={ShopIcon}
                  text={t("Shop")}
                  active={location.pathname.includes("/shop")}
                />
                {/* Information Category */}
                <p className={styles.header__menu_subheader}>
                  {t("Information")}
                </p>
                <MenuLink
                  setIsHeaderMobileOpen={setIsHeaderMobileOpen}
                  path="/news"
                  icon={NewsIcon}
                  text={t("Recent news")}
                  active={location.pathname.includes("/news")}
                />
                <MenuLink
                  setIsHeaderMobileOpen={setIsHeaderMobileOpen}
                  path="/press"
                  icon={Bullhorn}
                  text={t("Press")}
                  active={location.pathname.includes("/press")}
                />
                <MenuLink
                  setIsHeaderMobileOpen={setIsHeaderMobileOpen}
                  path="/help"
                  icon={HelpIcon}
                  text={t("Help and FAQ")}
                  active={location.pathname.includes("/help")}
                />
                <MenuLink
                  setIsHeaderMobileOpen={setIsHeaderMobileOpen}
                  path="/location"
                  icon={LocationIcon}
                  text={t("Location")}
                  active={location.pathname.includes("/location")}
                />
              </div>
              <div className={styles.header__menu_footer}>
                <div className={styles.header__menu_footer_divider}></div>
                <MenuLink
                  setIsHeaderMobileOpen={setIsHeaderMobileOpen}
                  path="/imprint"
                  text={t("Imprint")}
                  active={location.pathname.includes("/imprint")}
                  additional
                />
                <MenuLink
                  setIsHeaderMobileOpen={setIsHeaderMobileOpen}
                  path="/risk-information"
                  text={t("Risk information")}
                  active={location.pathname.includes("/risk-information")}
                  additional
                />
                <MenuLink
                  setIsHeaderMobileOpen={setIsHeaderMobileOpen}
                  path="/agb"
                  text={t("AGB")}
                  active={location.pathname.includes("/agb")}
                  additional
                />
                <MenuLink
                  setIsHeaderMobileOpen={setIsHeaderMobileOpen}
                  path="/privacy"
                  text={t("Privacy Policy")}
                  active={location.pathname.includes("/privacy")}
                  additional
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
