import React, { memo } from "react";

const Triangle = memo(({ color = "#CA4B15" }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.865 9L6.86496 2C6.77775 1.8461 6.65127 1.7181 6.49843 1.62904C6.34559 1.53998 6.17186 1.49305 5.99496 1.49305C5.81807 1.49305 5.64434 1.53998 5.4915 1.62904C5.33866 1.7181 5.21218 1.8461 5.12496 2L1.12496 9C1.03681 9.15268 0.990579 9.32595 0.990969 9.50226C0.99136 9.67856 1.03835 9.85163 1.12719 10.0039C1.21602 10.1562 1.34354 10.2823 1.49681 10.3694C1.65009 10.4565 1.82367 10.5016 1.99996 10.5H9.99996C10.1754 10.4998 10.3477 10.4535 10.4996 10.3656C10.6515 10.2778 10.7776 10.1515 10.8652 9.99956C10.9529 9.84757 10.999 9.6752 10.9989 9.49975C10.9989 9.3243 10.9527 9.15195 10.865 9Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 4.5V6.5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 8.5H6.005"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export default Triangle;
