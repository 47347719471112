import React, { useEffect } from "react";
import styles from "./CheckEmailReset.module.scss";
import HeaderCommon from "../../components/HeaderCommon/HeaderCommon";
import FooterCommon from "../../components/FooterCommon/FooterCommon";
import Button from "../../components/Button/Button";
import confirm from "../../img/confirm.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

const CheckEmailReset = ({ currentLang, setCurrentLang }) => {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <HeaderCommon currentLang={currentLang} setCurrentLang={setCurrentLang} />
      <section className={styles.check}>
        <div className={styles.check__wrapper}>
          <img
            src={confirm}
            alt="confirm"
            data-aos="fade-up"
            data-aos-duration="1000"
          />
          <div className={styles.check__title}>
            {t("Reset link was sent to your email address.")}
          </div>
          <div className={styles.check__subtitle}>
            {t("Please check you mailbox.")}
          </div>
          <Button text={t("Go to Main page")} to="/" color="green" />
        </div>
      </section>
      <FooterCommon />
    </>
  );
};

export default CheckEmailReset;
