import React, { memo } from "react";

const ArrowIcon = memo(({ color = "#509c5b", rotate="90deg" }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{rotate, transition: '.2s ease-in-out'}}
    >
      <path
        d="M3.33331 8H12.6666"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 3.33334L12.6667 8.00001L8 12.6667"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export default ArrowIcon;
