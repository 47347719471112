export const setMonth = () => {
  const date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth()
  const firstDay = new Date(y, m, 1)
  const lastDay = new Date(y, m + 1, 0)

  return { firstDay, lastDay }
}

export const setWeek = () => {
  const curr = new Date()
  const first = curr.getDate() - curr.getDay()+1
  const last = first + 6
  const firstDay = new Date(curr.setDate(first))
  const lastDay = new Date(curr.setDate(last))

  return { firstDay, lastDay }
}
