import React, { useState, useEffect } from "react";
import styles from "./Main.module.scss";
import SectionTitle from "../../../../components/SectionTitle/SectionTitle";
import ArrowDropdown from "../../../../img/IconComponents/ArrowDropdown";
import Input from "../../../../components/Input/Input";
import Button from "../../../../components/Button/Button";
import { useTranslation } from "react-i18next";
import Textarea from "../../../../components/Textarea/Textarea";
import { checkEmail, checkTextarea } from "../../../../helpers/validation";
import Modal from "../../../../components/Modal/Modal";
import getRequest from "../../../../helpers/getRequest";
import postRequest from "../../../../helpers/postRequest";

const Main = () => {
  const [collapseQuestions, setCollapseQuestions] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [modal, setModal] = useState(false);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [messageSent, setMessageSent] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const { t } = useTranslation();
  const lang = localStorage.getItem("i18n");
  const checkFields = () => {
    const isError = [];

    checkEmail(
      email,
      () => {},
      (value) => {
        isError.push(Boolean(value));
        setErrorEmail(value);
      },
      t("Please enter correct email address and try again")
    );

    checkTextarea(
      message,
      () => {},
      (value) => {
        isError.push(Boolean(value));
        setErrorText(value);
      }
    );

    return isError.some((el) => el);
  };

  useEffect(() => {
    try {
      getRequest(`v1/questions/faq/${lang === "DE" ? "de" : "en"}`).then(
        async (response) => {
          const data = await response.data;
          setQuestions(data.faq);
        }
      );
    } catch (err) {
      console.log(err);
    }
  }, [lang]);

  const handleQuestions = (i) => {
    if (collapseQuestions === i) {
      setCollapseQuestions(null);
    } else {
      setCollapseQuestions(i);
    }
  };

  const handleSubmit = (e) => {
    if (checkFields()) return;
    e.preventDefault();
    try {
      postRequest("v1/questions/send-question", {
        email: email,
        message: message,
      }).then((response) => {
        if (response.status === 201) {
          setMessageSent(true);
          setEmail("");
          setMessage("");
          setTimeout(() => setMessageSent(false), 2000);
        }
      });
      setModal(true);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <section className={styles.help}>
      <SectionTitle text={t("Frequently Asked Questions")} />
      <div className={styles.help__inner}>
        <div className={styles.help__questions}>
          {questions.map((q, i) => {
            const { question, answer } = q;
            return (
              <div
                className={`${styles.question} ${
                  collapseQuestions === i ? styles.opened : ""
                }`}
                key={i}
                onClick={() => handleQuestions(i)}
              >
                <div className={styles.question_title}>
                  <h4>{t(question)}</h4>
                  <ArrowDropdown />
                </div>
                <p>{t(answer)}</p>
              </div>
            );
          })}
        </div>
        <div className={styles.help__form}>
          <SectionTitle text={t("Didn’t find an answer? Send us an email!")} />
          <form className={styles.help__form_inner} onSubmit={handleSubmit}>
            <Input
              type="email"
              label={t("Your Email")}
              placeholder={t("Email")}
              value={email}
              error={errorEmail}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label className={styles.textarea}>
              <div className={styles.textarea__title}>{t("Your Message")}</div>
              <Textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                name="message"
                id="message"
                cols="10"
                rows="10"
                placeholder="text"
                error={errorText}
              />
            </label>
            <Button text={t("Send")} color="green" onClick={handleSubmit} />
            {messageSent && <p>{t("Message Sent!")}</p>}
          </form>
        </div>
      </div>
      {modal && (
        <Modal
          onClose={setModal}
          text={t(
            "We received your message. We’ll respond as soon as possible."
          )}
        />
      )}
    </section>
  );
};

export default Main;
