/** @format */

import React, {
  useState,
  useEffect,
} from 'react';
import styles from './Table.module.scss';
import { useTranslation } from 'react-i18next';
import TableRow from '../TableRow/TableRow';
import getRequest from '../../../../helpers/getRequest';
import Pagination from '../../../../components/Pagination/Pagination';

const Table = ({
  statistic,
  selectedOption,
  page,
  setPage,
  totalClientsProp,
  user_role,
}) => {
  const { t } = useTranslation();
  const [role, setRole] = useState(null);

  useEffect(() => {
    getRequest('v1/auth/whoami').then(
      (response) =>
        setRole(response.data?.user_role),
    );
  }, []);

  return user_role === 'sale' ? (
    <div className={styles.table__overflow}>
      <div className={styles.table__header}>
        <div className={styles.table__order}>
          {t('Order ID')}
        </div>
        <div className={styles.table__order}>
          {t('Client ID')}
        </div>
        <div className={styles.table__date}>
          {t('Date')}
        </div>
        <div className={styles.table__name}>
          {t('Client Name')}
        </div>
        <div className={styles.table__token}>
          {t('Tokens')}
        </div>
        <div className={styles.table__status}>
          {t('Status')}
        </div>
        <div className={styles.table__amount}>
          {t('Amount')}
        </div>
        <div className={styles.table__amount}>
          {t('Tippgeber')}
        </div>
        <div className={styles.table__amount}>
          {t('Sales Level')}
        </div>
      </div>
      {statistic &&
        statistic.length &&
        statistic.map((item) => {
          const {
            id,
            created_at,
            first_name,
            last_name,
            plants_count,
            status,
            sale_from_first_name,
            sale_from_last_name,
            amount_sold_by_referrals,
            sale_from_level,
            user_id
            
           
          } = item;
          {console.log(item)}
          return (
            <TableRow
            user_id={user_id}
              key={id}
              orderId={id}
              date={created_at}
              name={`${first_name} ${last_name}`}
              token={plants_count}
              status={status}
              amount={amount_sold_by_referrals}
              role={role}
             
              s_first_name={sale_from_first_name}
              s_last_name={sale_from_last_name}
              s_level={sale_from_level}
            />
          );
        })}
      <Pagination
        page={page}
        perPage={selectedOption.value}
        setPage={setPage}
        totalItems={totalClientsProp}
      />
    </div>
  ) : (
    <div className={styles.table__overflow}>
      <div className={styles.table__header}>
        <div className={styles.table__order}>
          {t('Order ID')}
        </div>
        <div className={styles.table__date}>
          {t('Date')}
        </div>
        <div className={styles.table__name}>
          {t('Client Name')}
        </div>
        <div className={styles.table__token}>
          {t('Tokens')}
        </div>
        <div className={styles.table__status}>
          {t('Status')}
        </div>
        <div className={styles.table__amount}>
          {t('Amount')}
        </div>
      </div>
      {statistic &&
        statistic.length &&
        statistic.map((item) => {
          const {
            o_id,
            o_created_at,
            o_first_name,
            o_last_name,
            o_plants_count,
            o_status,
            o_total_amount,
            h_first_name,
            h_last_name,
            s_first_name,
            s_last_name,
          } = item;
          return (
            <TableRow
              key={o_id}
              orderId={o_id}
              date={o_created_at}
              name={`${o_first_name} ${o_last_name}`}
              token={o_plants_count}
              status={o_status}
              amount={o_total_amount}
              role={role}
              h_first_name={h_first_name}
              h_last_name={h_last_name}
              s_first_name={s_first_name}
              s_last_name={s_last_name}
            />
          );
        })}
      <Pagination
        page={page}
        perPage={selectedOption.value}
        setPage={setPage}
        totalItems={totalClientsProp}
      />
    </div>
  );
};

export default Table;
