import React, { useState } from "react";
import styles from "./Input.module.scss";
import eyeOff from "../../img/eyeOff.svg";
import eyeOn from "../../img/eyeOn.svg";
import Triangle from "../../img/IconComponents/Triangle";
import cn from "classnames";

const Input = ({
  label,
  type,
  placeholder,
  name,
  value,
  onChange,
  error,
  disabled,
  className = "",
}) => {
  const [showPass, setShowPass] = useState(false);

  return (
    <label className={cn(styles.input, styles[className])}>
      <div className={styles.input__title}>{label}</div>
      {type === "textarea" ? (
        <>
          <textarea
            className={cn(
              styles.input__input,
              error && error.length ? styles.input__input_error : ""
            )}
            style={{ paddingTop: "1rem", height: "100px", resize: "none" }}
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={onChange}
          ></textarea>
          {error && error.length && (
            <div className={styles.input__error}>
              <Triangle />
              <div>{error}</div>
            </div>
          )}
        </>
      ) : (
        <>
          <input
            disabled={disabled}
            className={cn(
              styles.input__input,
              error && error.length ? styles.input__input_error : ""
            )}
            type={showPass ? "text" : type}
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={onChange}
          />
          {error && error.length && (
            <div className={styles.input__error}>
              <Triangle />
              <div>{error}</div>
            </div>
          )}
          {type === "password" && (
            <button
              className={styles.input__toggle}
              onClick={() => setShowPass((prev) => !prev)}
              type="button"
            >
              {showPass ? (
                <img src={eyeOn} alt="on" />
              ) : (
                <img src={eyeOff} alt="off" />
              )}
            </button>
          )}
        </>
      )}
    </label>
  );
};

export default Input;
