import styles from "./Main.module.scss";
import { useState, useEffect } from "react";
import { Banner } from "../../MainPage/Components";
import { useTranslation } from "react-i18next";
import getRequest from "../../../helpers/getRequest";

const Main = ({ currentLang, setCurrentLang, user }) => {
  const { t } = useTranslation();
  const [press, setPress] = useState([]);
  const [pressWithoutDates, setPressWithoutDates] = useState([]);

  useEffect(() => {
    try {
      getRequest("v1/press", null, "en").then(async (response) => {
        const data = await response.data;

        console.log("data\n", data.data);

        const formatedPress = [];
        data.data.forEach((item) => {
          if (new Date(item.created_at).getFullYear() == 2020) {
            setPressWithoutDates((prev) => [item]);
          } else {
            const date = new Date(item.created_at).toLocaleString("en-GB", {
              month: "long",
              year: "numeric",
            });

            let group = formatedPress.find((el) => el.date === date);

            if (group) {
              const isDuplicate = group.articles.some(
                (article) => article.id === item.id
              );
              if (!isDuplicate) {
                console.log("puch");
                group.articles.push(item);
              }
            } else {
              formatedPress.push({
                date: date,
                articles: [item],
              });
            }
          }
        });
        console.log(formatedPress)
        setPress(formatedPress);
      });
    } catch (err) {
      console.log(err);
    }
  }, [currentLang]);

  return (
    <>
      <main className={styles.press}>
        <div className={user ? styles.pressContainer : "container"}>
          <h1 className={styles.press__title}>{t("Press reviews")}</h1>
          <div className={styles.press__inner}>
            <div className={styles.press__group}>
              <h3 style={{ opacity: 0 }}>{t("April")} 2024</h3>

              <ul style={{ flex: user ? 3 : 2 }}>
                {pressWithoutDates.map((article, index) => (
                  <li key={index} className={styles.press__group_item}>
                    <h5>{article.title}</h5>
                    <a href={article.url} target="_blank" rel="noreferrer">
                      {article.url}
                    </a>
                    <span>
                      {/* {new Date(article.created_at)
                            .toISOString()
                            .slice(0, 10)
                            .split("-")
                            .reverse()
                            .join(".")} */}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            {press.map((group, index) => (
              <div key={index} className={styles.press__group}>
                <h3>{t(group.date.split(' ')[0])} {group.date.split(' ')[1]}</h3>
                <ul style={{ flex: user ? 3 : 2 }}>
                  {group.articles.map((article, index) => (
                    <li key={index} className={styles.press__group_item}>
                      <h5>{article.title}</h5>
                      <a href={article.url} target="_blank" rel="noreferrer">
                        {article.url}
                      </a>
                      <span>
                        {new Date(article.created_at)
                          .toISOString()
                          .slice(0, 10)
                          .split("-")
                          .reverse()
                          .join(".")}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
        {!user && <Banner />}
      </main>
    </>
  );
};

export default Main;
