// import axios from "axios";
// import { API_URL } from "../http";
import $api from "../http";

const postRequest = (url, data, content = 'application/json', header = null) => {
  return $api({
    method: 'post',
    url: `/${url}`,
    headers: {
      // 'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': content,
      "Accept": "application/json", 
      ...(header && {"Content-Language": header})
    },
    data
  })
}


export default postRequest;