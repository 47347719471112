import React, { useState, useEffect, useRef, useContext } from "react";
import styles from "./ModalFirstEnterSale.module.scss";
import { useTranslation } from "react-i18next";
import ArrowDropdown from "../../img/IconComponents/ArrowDropdown";
import cn from "classnames";
import Checkbox from "../Checkbox/Checkbox";
import { Context } from "../../index";
import { FRONT_URL } from "../../http";
import { useNavigate } from "react-router-dom";

const ModalFirstEnterSale = ({ sendAccept }) => {
  const { store } = useContext(Context);
  const { t, i18n } = useTranslation();
  const [isEndReached, setIsEndReached] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const navigate = useNavigate();
  const bottomRef = useRef(null);

  const scrollToBottom = () => {
    bottomRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;

    if (scrollTop + clientHeight >= scrollHeight - 1) {
      setIsEndReached(true);
    } else {
      setIsEndReached(false);
    }
  };

  useEffect(() => {
    const scrollContainer = document.getElementById("scroll-container");
    scrollContainer.addEventListener("scroll", handleScroll);
    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={styles.modal}>
      <div className={styles.modal__inner}>
        {/* <div className={styles.modal__title}>
          {t("We have updated our contract for Sales.")}
        </div> */}
        <div className={styles.modal__content} id="scroll-container">
          <div className={styles.modal__content_bold} style={{textAlign:'left'}}>
          Anhang
          </div>
          <div className={styles.modal__content_bold}>
          Zusätzliche Regelung zur Provisionierung
          </div>
          <div className={styles.modal__content_bold}>
          Im Rahmen des Tippgebervertrags zur Nutzung des Tippgeberprogramms auf der
Internetpräsenz "www.Anbaugesellschaft.de" der Deutsche Anbaugesellschaft DAG
GmbH (nachstehend: "DAG") wird folgende ergänzende Bestimmung bezüglich der
Provisionierung durch die "Freunde werben Freunde"-Funktion aufgenommen:
          </div>
          <div className={styles.modal__content_text}>
          Zusätzliche Provisionierung für die "Freunde werben Freunde"-Funktion
           
          </div>
          {/* <div className={styles.modal__note}>
            <div>*</div>
            {t(
              "Please review the updated contract and agree to the contents. Once you confirm the new contract, the current contract will be terminated. You will then be able to use our platform again as usual."
            )}
          </div> */}
          <div className={styles.modal__content_title}>1. Gegenstand der zusätzlichen Provisionierung:</div>
          <div className={styles.modal__content_text}>
          Tippgeber erhalten für alle Verkäufe, die durch die Nutzung der "Freunde werben
Freunde"-Funktion in Ihrem Netzwerk generiert werden, eine zusätzliche Provision in
Höhe von 4 Prozent auf den Nettoumsatz.  </div>

          <div className={styles.modal__content_title}>
          2. Berechnung und Abrechnung:
          </div>
          <div className={styles.modal__content_text}>
          Die zusätzliche Provision wird vom Nettoumsatz berechnet und wie jede andere
Provision gemäß den Bedingungen dieses Tippgebervertrags behandelt.
Die Abrechnung der zusätzlichen Provision erfolgt zusammen mit der regulären Provision
gemäß den in Ziffer 9 des Tippgebervertrags festgelegten Bedingungen und Zeitplänen.
          </div>
         
          <div className={styles.modal__content_title}>
          3. Voraussetzungen für die Provisionierung:
          </div>
          <div className={styles.modal__content_text}>
          Die Voraussetzungen für die Entstehung des Anspruchs auf die zusätzliche Provision
sind identisch mit den in Ziffer 8.2 des Tippgebervertrags genannten Voraussetzungen
(erfolgter Zahlungseingang des Neukunden, Ablauf der Widerrufsfrist von zwei Wochen
ohne Widerruf des Neukunden).</div>

         
          <div className={styles.modal__content_title}>
          4. Kündigung und Änderung der Regelung:
          </div>
          <div className={styles.modal__content_text}>
          Die DAG behält sich das Recht vor, diese Regelung jederzeit zu ändern oder zu
widerrufen. Änderungen werden gemäß Ziffer 12 des Tippgebervertrags bekannt
gegeben. </div>
          
          <div className={styles.modal__content_title}>
          5. Sonstige Bestimmungen:
          </div>
          <div className={styles.modal__content_text}>
          Alle sonstigen Bestimmungen des Tippgebervertrags bleiben unberührt und gelten auch
für die zusätzliche Provisionierung durch die "Freunde werben Freunde"-Funktion.
          </div>
          
        
          <div className={styles.modal__content_text}>
          Murchin, den 09.07.2024
          </div>
          <div className={styles.modal__content_text}>
          Deutsche Anbaugesellschaft DAG GmbH




        
          </div>

          <label className={styles.modal__check}>
            <Checkbox
              value={isCheck}
              onChange={(e) => setIsCheck(e.target.checked)}
            />
            <div>
              {t(
                "I have read, understood and fully accepted the updated Tipster Agreement."
              )}
            </div>
          </label>
          <div ref={bottomRef}></div>
        </div>
        <div className={styles.modal__footer}>
          <div className={styles.modal__footer_btns}>
            <button
              className={cn(
                styles.modal__footer_btn,
                styles.modal__footer_btn_accept,
                isCheck ? styles.modal__footer_btn_accept_active : ""
              )}
              onClick={sendAccept}
            >
              {t("Accept")}
            </button>
            <button
              className={styles.modal__footer_btn}
              onClick={() => store.logout()}
            >
              {t("Decline")}
            </button>
          </div>
        </div>
        <div className={styles.modal__footer_row}>
          <a
            href={`${FRONT_URL}/files/Anhang zum Tippgebervertrag - Anbaugesellschaft.de.pdf`}
            className={styles.modal__download}
            download
          >
            {t("Download contract")}
          </a>

          <div
            className={cn(
              styles.modal__footer_scroll,
              isEndReached ? styles.modal__footer_scroll_hide : ""
            )}
            onClick={scrollToBottom}
          >
            {t("Scroll to the bottom")}
            <ArrowDropdown color="#509c5a" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalFirstEnterSale;
