import React, { useMemo } from 'react'
import styles from './Status.module.scss'
import { useTranslation } from 'react-i18next'

const Status = ({title}) => {
  const {t} = useTranslation();
  const {color, background} = useMemo(() => {
    switch(title){
      case t('Pending'):
        return {color: '#FA8A07', background: '#F6D4AD'}
      case t('Confirmed'):
        return {color: '#509C5B', background: '#E9F1C5'}
      case t('Paid'):
        return {color: '#CBA10D', background: '#F8E9B6'}
      case t('Paid Out'):
        return {color: '#3B4CE5', background: '#BDC2F0'}
      case t('PENDING'):
        return {color: '#FA8A07', background: '#F6D4AD'}
      case t('CONFIRMED'):
        return {color: '#509C5B', background: '#E9F1C5'}
      case t('PAID'):
        return {color: '#CBA10D', background: '#F8E9B6'}
      case t('PAID OUT'):
        return {color: '#3B4CE5', background: '#BDC2F0'}
      case t('Completed'):
        return {color: '#509C5B', background: '#E9F1C5'}
      case t('COMPLETED'):
        return {color: '#509C5B', background: '#E9F1C5'}
      default:
        return {color: '#ffffff', background: '#000000'}
    }
  }, [title])
  return (
    <div
      className={styles.status}
      style={{color, background}}
    >
      {t(title.charAt(0).toUpperCase() + title.slice(1).toLowerCase())}
    </div>
  )
}

export default Status;