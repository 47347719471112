import React, { useEffect } from "react";
import styles from "./CheckContractEmail.module.scss";
import HeaderCommon from "../../components/HeaderCommon/HeaderCommon";
import FooterCommon from "../../components/FooterCommon/FooterCommon";
import envelop from "../../img/mail-envelop.svg";
import cannabis from "../../img/canabis-green.svg";
import Button from "../../components/Button/Button";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const CheckContract = ({
  text,
  smallText,
  buttonText,
  currentLang,
  setCurrentLang,
}) => {
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <HeaderCommon currentLang={currentLang} setCurrentLang={setCurrentLang} />
      <section className={styles.check}>
        <div className={styles.check__container}>
          <div className={styles.check__container__img1}>
            <img
              src={cannabis}
              alt="confirm"
              data-aos="fade-up"
              data-aos-duration="1000"
            />
          </div>
          <div className={styles.check__container__img2}>
            <img
              src={envelop}
              alt="confirm"
              data-aos="fade-up"
              data-aos-duration="1000"
            />
          </div>
        </div>
        <h2 className={styles.check__title}>{text}</h2>
        <div className={styles.check__email}>
          {location.state ? location.state.email : ""}
        </div>
        <div className={styles.check__descr}>{t(smallText)}</div>
        <Button to="/" text={t(buttonText)} color="green" />
      </section>
      <FooterCommon />
    </>
  );
};

export default CheckContract;
