import React, { useState, useEffect } from "react";
import styles from "./Statistics.module.scss";
import Aside from "../../../../../../components/Aside/Aside";
import Header from "../../../../../../components/Header/Header";
import SectionTitle from "../../../../../../components/SectionTitle/SectionTitle";
import postRequest from "../../../../../../helpers/postRequest";
import { useTranslation } from "react-i18next";
import FooterClient from "../../../../../../components/FooterClient/FooterClient";
import Loader from "../../../../../../components/Loader/Loader";
import cn from "classnames";
import ArrowDropdown from "../../../../../../img/IconComponents/ArrowDropdown";

const Statistics = ({ currentLang, setCurrentLang }) => {
  const perPage = 10;
  const [clients, setClients] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [totalOrders, setTotalOrders] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    setIsLoading(true);
    postRequest(`v1/orders/referral-orders`, {
      page: 1,
      perPage: perPage,
    })
      .then(async (response) => {
        setClients(response.data?.data);
        setTotalOrders(response.data?.total);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [page]);

  return (
    <div className={styles.wrapper}>
      <Aside />
      <div className={styles.container}>
        <Header currentLang={currentLang} setCurrentLang={setCurrentLang} />
        <section className={styles.statistics}>
          <SectionTitle text={t("Detailed Statistics")} />
          {isLoading && <Loader />}
          <div className={styles.table}>
            <div className={cn(styles.table__row, styles.table__header)}>
              <div className={styles.table__client}>{t("Client Name")}</div>
              <div className={styles.table__tokens}>
                {t("Total Tokens Purchased")}
              </div>
              <div className={styles.table__priceper}>
                {t("Price per token")}
              </div>
              <div className={styles.table__cost}>{t("Total Cost")}</div>
              <div className={styles.table__commission}>
                {t("My Commission")}
              </div>
            </div>
            <div className={styles.table__body}>
              {clients && clients.length ? (
                clients.map((item, index) => {
                  const {
                    first_name,
                    last_name,
                    plants_count,
                    total_amount,
                    plants_count_commission,
                  } = item;
                  return (
                    <div className={styles.table__row} key={index}>
                      <div className={styles.table__client}>
                        <p>{t("Client Name")}</p>
                        <div>
                          {first_name} {last_name}
                        </div>
                      </div>
                      <div className={styles.table__tokens}>
                        <p>{t("Total Tokens Purchased")}</p>
                        <div>
                          {(plants_count||0)}{" "}
                          {(plants_count||0) > 1 ? t("tokens") : t("token")}
                        </div>
                      </div>
                      <div className={styles.table__priceper}>
                        <p>{t("Price per token")}</p>
                        <div>
                          {((total_amount&&plants_count)?(total_amount / plants_count):0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}{" "}
                          EUR
                        </div>
                      </div>
                      <div className={styles.table__cost}>
                        <p>{t("Total Cost")}</p>
                        <div>
                          {(total_amount||0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}{" "}
                          EUR
                        </div>
                      </div>
                      <div className={styles.table__commission}>
                        <p>{t("My Commission")}</p>
                        <div>
                          {localStorage.getItem('i18n') !== 'DE' ? (plants_count_commission ||0) : (plants_count_commission||0).toString().replace('.',',')} {t("tokens")}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
              <div className={styles.empty}>{t("No referrals yet")}</div>
              )}
            </div>
          </div>
          {clients.length < totalOrders ? (
            <div className={styles.pagination}>
              <div
                className={styles.pagination__arrows}
                onClick={() => {
                  if (page !== 1) {
                    setPage((prev) => prev - 1);
                  }
                }}
              >
                <ArrowDropdown
                  color={page !== 1 ? "#509C5B" : "#ccc"}
                  rotate="90deg"
                />
              </div>
              <div className={styles.pagination__pages}>
                <div>{page}</div>
              </div>
              <div
                className={styles.pagination__arrows}
                onClick={() => {
                  if (page !== Math.round(totalOrders / perPage)) {
                    setPage((prev) => prev + 1);
                  }
                }}
              >
                <ArrowDropdown
                  color={
                    page !== Math.round(totalOrders / perPage)
                      ? "#509C5B"
                      : "#ccc"
                  }
                  rotate="270deg"
                />
              </div>
            </div>
          ) : null}
        </section>
        <div className={styles.footer}>
          <FooterClient />
        </div>
      </div>
    </div>
  );
};

export default Statistics;
