import React from "react";
import styles from "./CurrentPlant.module.scss";
import Aside from "../../components/Aside/Aside";
import Header from "../../components/Header/Header";
import Main from "./Components/Main/Main";

const CurrentPlant = ({ currentLang, setCurrentLang }) => {
  return (
    <div className={styles.wrapper}>
      <Aside />
      <div className={styles.container}>
        <Header currentLang={currentLang} setCurrentLang={setCurrentLang} />
        <Main />
      </div>
    </div>
  );
};

export default CurrentPlant;
