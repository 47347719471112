/** @format */

import { useState, useRef } from "react";
import styles from "./Investing.module.scss";
import explainBg from "../../../../img/explainVideo.webp";
import Cross from "../../../../img/IconComponents/Cross";
import video from "../../../../video/videoExp.mp4";
import videoImage from "../../../../img/video__prev.png";
import dagVideo from "../../../../video/DAG.mp4";
// import dagVideoEn from "../../../../video/DAG_en.mp4";
import { useTranslation } from "react-i18next";

const Investing = () => {
  const { t } = useTranslation();
  const [isShowModalFirst, setIsShowModalFirst] = useState(false);
  // const [isShowModalSecond, setIsShowModalSecond] = useState(false);
  const videoRef = useRef(null);

  const handlePlayClickFirst = () => {
    setIsShowModalFirst(true);
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const closeModalFirst = () => {
    setIsShowModalFirst(false);
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };
  // const handlePlayClickSecond = () => {
  //   setIsShowModalSecond(true);
  //   if (videoRef.current) {
  //     videoRef.current.play();
  //   }
  // };

  // const closeModalSecond = () => {
  //   setIsShowModalSecond(false);
  //   if (videoRef.current) {
  //     videoRef.current.pause();
  //   }
  // };
  return (
    <section className={styles.investing} id="whyinvest">
      <div className="container">
        <h2>{t("How it works:")}</h2>
        <p>
          {t(
            "How to buy DECT tokens and find out what benefits you will enjoy."
          )}
        </p>
        <div className={styles.investing__inner}>
          <div
            className={styles.investing__video}
            onClick={handlePlayClickFirst}
          >
            <picture>
              <source srcSet={explainBg} type="image/webp" />
              <img src={explainBg} alt="bg" />
            </picture>
            <button>
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.9997 36.6668C29.2044 36.6668 36.6663 29.2049 36.6663 20.0002C36.6663 10.7954 29.2044 3.3335 19.9997 3.3335C10.7949 3.3335 3.33301 10.7954 3.33301 20.0002C3.33301 29.2049 10.7949 36.6668 19.9997 36.6668Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16.667 13.3335L26.667 20.0002L16.667 26.6668V13.3335Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>

          {/* <div
            className={styles.investing__video}
            onClick={handlePlayClickSecond}
          >
            <picture>
              <source srcSet={videoImage} type="image/webp" />
              <img src={videoImage} alt="preview" />
            </picture>
            <button>
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.9997 36.6668C29.2044 36.6668 36.6663 29.2049 36.6663 20.0002C36.6663 10.7954 29.2044 3.3335 19.9997 3.3335C10.7949 3.3335 3.33301 10.7954 3.33301 20.0002C3.33301 29.2049 10.7949 36.6668 19.9997 36.6668Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16.667 13.3335L26.667 20.0002L16.667 26.6668V13.3335Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div> */}
        </div>
      </div>
      {isShowModalFirst ? (
        <div className={styles.modal}>
          <video src={video} autoPlay ref={videoRef} controls={true}></video>
          <button onClick={() => closeModalFirst()}>
            <Cross color={"#ffffff"} />
          </button>
        </div>
      ) : null}
      {/* {isShowModalSecond ? (
        <div className={styles.modal}>
          {localStorage.getItem("i18n") === "EN" ? (
            <video
              src={dagVideo}
              autoPlay
              ref={videoRef}
              controls={true}
            ></video>
          ) : (
            <video
              src={dagVideo}
              autoPlay
              ref={videoRef}
              controls={true}
            ></video>
          )}
          <button onClick={() => closeModalSecond()}>
            <Cross color={"#ffffff"} />
          </button>
        </div>
      ) : null} */}
    </section>
  );
};

export default Investing;
