import React, { useEffect, useState } from 'react'
import styles from './Modal.module.scss'
import Cross from '../../../../img/IconComponents/Cross'
import DatePicker from '../../../../components/DatePicker/DatePicker'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import getRequest from '../../../../helpers/getRequest'
import cn from 'classnames'
import Dropdown from '../Dropdown/Dropdown'

const Modal = ({ closeAction }) => {
  const { t } = useTranslation()
  const [country, setCountry] = useState('')
  const [dateOne, setDateOne] = useState(() =>
    moment().startOf('isoWeek').toISOString(),
  )
  const [dateTwo, setDateTwo] = useState(() => moment().toISOString())
  const [disableBtn, setDisableBtn] = useState(true)

  const getExel = () => {
    let url
  
    if (country == t('All Statuses')) {
      url = `v1/statistics/orders/xlsx?startDate=${moment(dateOne).format(
        'YYYY-MM-DD',
      )}&endDate=${moment(dateTwo).format('YYYY-MM-DD')}`
    } else {
      url = `v1/statistics/orders/xlsx?startDate=${moment(dateOne).format(
        'YYYY-MM-DD',
      )}&endDate=${moment(dateTwo).format(
        'YYYY-MM-DD',
      )}&orderStatus=${country===t('Paid')?"PAID_OUT":country===t('Paid Out')?"PAID":country.toUpperCase().replace(/\s+/g, '_')}`
    }
    getRequest(url, 'blob').then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `${moment(dateOne).format('DD-MM-YYYY')}-${moment(dateTwo).format(
          'DD-MM-YYYY',
        )}.xlsx`,
      )
      document.body.appendChild(link)
      link.click()
      closeAction()
    })
  }

  useEffect(() => {
    if (moment(dateOne).isBefore(moment(dateTwo))) {
      setDisableBtn(false)
    } else {
      setDisableBtn(true)
    }
  }, [dateOne, dateTwo])

  return (
    <div className={styles.modal}>
      <div className={styles.modal__inner}>
        <button className={styles.modal__close} onClick={closeAction}>
          <Cross />
        </button>
        <div className={styles.modal__title}>
          {t('Export Order Statistics')}
        </div>
        <div className={styles.modal__header}>
          <div className={styles.modal__header_semi}>
            <DatePicker
              value={dateOne}
              datePickAction={setDateOne}
              title={t('From')}
              options={{ dateFormat: 'd.m.Y' }}
            />
          </div>
          <div className={styles.modal__header_semi}>
            <DatePicker
              value={dateTwo}
              datePickAction={setDateTwo}
              title={t('To')}
              options={{ dateFormat: 'd.m.Y' }}
            />
          </div>
        </div>
        <Dropdown value={country} onChange={(country) => setCountry(country)} />
        <button
          className={cn(
            styles.modal__btn,
            !country ? styles.modal__btn_disabled : '',
            disableBtn ? styles.modal__btn_disabled : '',
          )}
          onClick={() => getExel()}
        >
          {t('Get Started')}
        </button>
      </div>
    </div>
  )
}

export default Modal
