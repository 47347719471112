import React from 'react'
import styles from './Modal.module.scss'
import Button from '../Button/Button'

const Modal = ({ onClose,text }) => {
  return (
    <div className={styles.modal}>
      <div className={styles.modal__inner}>
        {text}
        <Button
          text="Ok"
          onClick={() => onClose((state) => !state)}
          color="green"
        />
      </div>
    </div>
  )
}

export default Modal
