/** @format */

import React from "react";
import { useTranslation } from "react-i18next";
import Date from "./Components/Date/Date";
import styles from "./Timeline.module.scss";

const Timeline = () => {
  const { t } = useTranslation();
  const timelineDates = [
    {
      text: t("Planning at the site & first activities"),
      date: "Q4 2022",
      position: "up",
    },
    {
      text: t(
        "Concept of the DECT token and legal drafting & company foundation"
      ),
      date: "Q1/Q2 2023",
      position: "down",
    },
    {
      text: t("Launch of the DECT sales stand at DKM 2023."),
      date: "Q3/Q4 2023",
      position: "down",
    },
    {
      text: t("Approval of the Cannabis Act in the Bundestag."),
      date: "Q1 2024",
      position: "up",
    },
    {
      text: t(
        "Expansion of the first hall & exhibition stand at Mary Jane 2024."
      ),
      date: "Q2 2024",
      position: "down",
    },
    {
      text: t(
        "Completion of the first cultivation module & conclusion of contracts with cannabis clubs."
      ),
      date: "Q3 2024",
      position: "up",
    },
    {
      text: t(
        "Start of cultivation at the site & launch of the 'DECT-Grow' system."
      ),
      date: "Q4 2024",
      position: "down",
    },
    {
      text: t(
        "Completion of another 10 cultivation modules & further club settlements."
      ),
      date: "Q1 2025",
      position: "up",
    },
    {
      text: t(
        "Construction of further cultivation modules. Initial preparations for the cultivation of medicinal cannabis"
      ),
      date: "Q2 2025",
      position: "down",
    },
  ];

  return (
    <section id="legalization" className={styles.timeline}>
      <h2 className={styles.timeline__header}>{t("Our roadmap")}</h2>

      <div className={styles.timeline__mobile}>
        {timelineDates.map((timelineDate, index) => {
          const { text, date } = timelineDate;
          return <Date text={text} date={date} key={index} />;
        })}
      </div>
      <div className={styles.timeline__wrapper}>
        <ul className={styles.timeline__inner}>
          <li>
            <hr />
            <div className={`${styles.timelineBlock} ${styles.timelineStart}`}>
              <p>{timelineDates[0].text}</p>
              <h5>{timelineDates[0].date}</h5>
            </div>
            <div className={styles.timelineMiddle}></div>
            {/* <hr /> */}
          </li>
          <li>
            <hr />
            <div className={styles.timelineMiddle}></div>
            <div className={`${styles.timelineBlock} ${styles.timelineEnd}`}>
              <h5>{timelineDates[1].date}</h5>
              <p>{timelineDates[1].text}</p>
            </div>
            {/* <hr /> */}
          </li>
          <li>
            <hr />
            <div className={`${styles.timelineBlock} ${styles.timelineStart}`}>
              <p>{timelineDates[2].text}</p>
              <h5>{timelineDates[2].date}</h5>
            </div>
            <div className={styles.timelineMiddle}></div>
            {/* <hr /> */}
          </li>
          <li>
            <hr />
            <div className={styles.timelineMiddle}></div>
            <div className={`${styles.timelineBlock} ${styles.timelineEnd}`}>
              <h5>{timelineDates[3].date}</h5>
              <p>{timelineDates[3].text}</p>
            </div>
            {/* <hr /> */}
          </li>
          <li>
            <hr />
            <div className={`${styles.timelineBlock} ${styles.timelineStart}`}>
              <p>{timelineDates[4].text}</p>
              <h5>{timelineDates[4].date}</h5>
            </div>
            <div className={styles.timelineMiddle}></div>
            {/* <hr /> */}
          </li>
          <li>
            <hr />
            <div className={styles.timelineMiddle}></div>
            <div className={`${styles.timelineBlock} ${styles.timelineEnd}`}>
              <h5>{timelineDates[5].date}</h5>
              <p>{timelineDates[5].text}</p>
            </div>
            {/* <hr /> */}
          </li>
          <li>
            <hr />
            <div className={`${styles.timelineBlock} ${styles.timelineStart}`}>
              <p>{timelineDates[6].text}</p>
              <h5>{timelineDates[6].date}</h5>
            </div>
            <div className={styles.timelineMiddle}></div>
            {/* <hr /> */}
          </li>
          <li>
            <hr />
            <div className={styles.timelineMiddle}></div>
            <div className={`${styles.timelineBlock} ${styles.timelineEnd}`}>
              <h5>{timelineDates[7].date}</h5>
              <p>{timelineDates[7].text}</p>
            </div>
            {/* <hr /> */}
          </li>
          <li>
            <hr />
            <div className={`${styles.timelineBlock} ${styles.timelineStart}`}>
              <p>{timelineDates[8].text}</p>
              <h5>{timelineDates[8].date}</h5>
            </div>
            <div className={styles.timelineMiddle}></div>
            {/* <hr /> */}
          </li>
          {/* <li>
            <hr />
            <div className={`${styles.timelineBlock} ${styles.timelineEnd}`}>
              <h5>{timelineDates[9].date}</h5>
              <p>{timelineDates[9].text}</p>
            </div>
            <div className={styles.timelineMiddle}></div>
          </li> */}
        </ul>
      </div>
    </section>
  );
};

export default Timeline;
