import React, { memo } from "react";

const FinancesIcon = memo(({ color = "rgba(60, 60, 60, 0.8)" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.09 10.37C19.0353 10.7224 19.8765 11.3075 20.5357 12.0712C21.195 12.8349 21.651 13.7524 21.8617 14.7391C22.0724 15.7257 22.0309 16.7495 21.741 17.7158C21.4512 18.6822 20.9223 19.5598 20.2034 20.2676C19.4845 20.9754 18.5987 21.4905 17.6279 21.7652C16.6572 22.04 15.6329 22.0655 14.6496 21.8395C13.6664 21.6134 12.7561 21.1431 12.0028 20.472C11.2495 19.8009 10.6776 18.9507 10.34 18"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 6H8V10"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.71 13.88L17.41 14.59L14.59 17.41"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export default FinancesIcon;
