import React, { useState, useEffect } from "react";
import styles from "./ResetPassword.module.scss";
import HeaderCommon from "../../components/HeaderCommon/HeaderCommon";
import FooterCommon from "../../components/FooterCommon/FooterCommon";
import "../../styling/common.css";
import Input from "../../components/Input/Input";
import passReset from "../../img/passReset.webp";
import Button from "../../components/Button/Button";
import AOS from "aos";
import "aos/dist/aos.css";
import { checkPass, checkPasswordAgain } from "../../helpers/validation";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { useTranslation } from "react-i18next";
import postRequest from "../../helpers/postRequest";

const ResetPassword = ({ currentLang, setCurrentLang }) => {
  const [password, setPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [repeatPassword, setPereatPassword] = useState("");
  const [errorRepeatPassword, setErrorRepeatPassword] = useState("");
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [errorForm, setErrorForm] = useState("");
  const [loader, setLoader] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const { t } = useTranslation();

  const { id } = useParams();
  const navigate = useNavigate();

  const setNewPassword = () => {
    setIsLoad(true);
    postRequest(`v1/auth/reset-password/${id}`, { password: password })
      .then((response) => {
        setIsLoad(false);
        navigate("/password-changed");
      })
      .catch((error) => {
        setIsLoad(false);
        setErrorForm(t("Try to reopen link in your email"));
      });
  };

  useEffect(() => {
    if (
      password !== "" &&
      !errorPassword.length &&
      repeatPassword !== "" &&
      !errorRepeatPassword.length
    ) {
      setAllowSubmit(true);
    } else {
      setAllowSubmit(false);
    }
  }, [password, errorPassword, repeatPassword, errorRepeatPassword]);

  useEffect(() => {
    if (isLoad) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [isLoad]);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    if (
      password !== repeatPassword &&
      repeatPassword.length > 0 &&
      !(password === repeatPassword)
    ) {
      setErrorRepeatPassword("Passwords must match");
    } else {
      setErrorRepeatPassword("");
    }
  }, [password, repeatPassword]);

  return (
    <>
      {loader ? <Loader /> : null}
      <HeaderCommon currentLang={currentLang} setCurrentLang={setCurrentLang} />
      <section className={styles.reset}>
        <div className="container">
          <div className={styles.reset__wrapper}>
            <div className={styles.reset__left}>
              <>
                <h2 className={styles.reset__title}>{t("Reset password")}</h2>
                <form className={styles.reset__form}>
                  <Input
                    label={t("New password")}
                    type="password"
                    name="pass"
                    placeholder="••••••••••"
                    value={password}
                    onChange={(e) => {
                      checkPass(e.target.value, setPassword, setErrorPassword);
                    }}
                    error={t(errorPassword)}
                  />
                  <Input
                    label={t("Confirm password")}
                    type="password"
                    name="pass"
                    placeholder="••••••••••"
                    value={repeatPassword}
                    onChange={(e) =>
                      checkPasswordAgain(
                        e.target.value,
                        password,
                        setPereatPassword,
                        setErrorRepeatPassword
                      )
                    }
                    error={t(errorRepeatPassword)}
                  />
                  <div className={styles.reset__form_buttons}>
                    <Button
                      text={t("Submit")}
                      color="green"
                      disabled={!allowSubmit}
                      onClick={setNewPassword}
                    />
                  </div>
                  {errorForm.length ? (
                    <div className={styles.reset__formError}>{errorForm}</div>
                  ) : null}
                </form>
              </>
            </div>
            <div
              className={styles.reset__right}
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <img src={passReset} alt="passReset" />
            </div>
          </div>
        </div>
      </section>
      <FooterCommon />
    </>
  );
};

export default ResetPassword;
