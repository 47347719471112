import styles from "./AdminFinances.module.scss";
import AdminAside from "../../components/AdminAside/AdminAside";
import Main from "./Components/Main/Main";
import React, { useState, useEffect } from "react";
import getRequest from "../../helpers/getRequest";
import AdminHeader from "../../components/AdminHeader/AdminHeader";

const AdminFinances = ({
  openModal,
  role,
  setIsOpenModal,
  referalValue,
  currentLang,
  setCurrentLang,
}) => {
  const [financeData, setFinanceData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (role && role !== "HEAD_OF_SALES") {
      setIsLoading(true);
      getRequest("v1/finances/sales-area/data")
        .then((response) => {
          setFinanceData(response.data);
          setIsLoading(false);
        })
        .catch((error) => setIsLoading(false));
    }
  }, [role]);

  useEffect(() => {
    if (role && role === "HEAD_OF_SALES") {
      setIsLoading(true);
      getRequest("v1/statistics/dashboard/head-of-sale")
        .then((response) => {
          setFinanceData({
            current_balance: response.data?.my_current_commission,
            pending_balance: response.data?.my_pending_commission,
            total_balance: response.data?.my_total_commission,
          });
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  }, [role]);

  return (
    <div className={styles.wrapper}>
      <AdminAside setIsOpenModal={setIsOpenModal} isOpenModal={openModal}  role={role} />
      <div className={styles.container}>
        <AdminHeader
          openModal={openModal}
          setIsOpenModal={setIsOpenModal}
          referalValue={referalValue}
          currentLang={currentLang}
          setCurrentLang={setCurrentLang}
          role={role}
        />
        <Main data={financeData} isLoad={isLoading} role={role} />
      </div>
    </div>
  );
};

export default AdminFinances;
