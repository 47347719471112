import { regPass } from "./regexp";
import {t} from 'i18next'
const checkPassword = (value, setter) => {
  if(value.length < 8) setter("The minimum password value must be 8 characters");
  let countUpper = 0;
  let countLower = 0;
  value?.split('')?.forEach(item => {
    if(item === item.toUpperCase()) countUpper++;
    if(item === item.toLowerCase()) countLower++;
  })

  // if(countUpper === 0) setter(t("Password must contain at least one uppercase letter"));

  // if(countLower === 0) setter(t("Password must contain at least one lowercase letter"));

  // if(countLower === 0 && countUpper === 0) setter(t("Password must contain at least one lowercase and one uppercase letter"));

  if(!value.length) setter(t("Password cannot be empty"));

  if(!regPass.test(value)) setter(t("Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character, and minimum length of 8 characters"));
}


export default checkPassword;