/** @format */

import React, { useState, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactDOMServer from "react-dom/server";
import styles from "./Main.module.scss";

import classNames from "classnames/bind";
import Banner from "../../MainPage/Components/Banner/Banner";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";

let cx = classNames.bind(styles);

export const Main = () => {
  const { t } = useTranslation();
  const menuItems = [
    t("Data protection notices and obligation information").toUpperCase(),
    t("Data collection on our website cookies").toUpperCase(),
    t("Requirements for the use of the platform").toUpperCase(),
    t("MAINTENANCE"),
    t("Copyright").toUpperCase(),
    t("SCOPE OF APPLICATION OF THE PRIVACY POLICY"),
    t("MODIFICATIONS"),
  ];

  const paragraphItems = [
    {
      id: 1,
      title: t("Data protection notices and obligation information"),
      sections: [
        <b>{t("PRIVACY POLICY")}</b>,
        t(
          `The protection of your personal data is very important to us. We treat your personal data confidentially and in accordance with the applicable data protection regulations and within the framework of this privacy policy.`
        ) + " ",
        t(
          `When you use this website, various personal data is collected from you. Personal data is information that can be used to identify you personally. In this privacy statement, we explain what data we collect, how we use it and for what purpose we process it. We would like to point out that security gaps can occur during the transmission of data on the Internet (e.g. by e-mail) and that complete protection of data against access by third parties is not possible.`
        ) + " ",
        t(
          "If you have any questions about the data protection declaration or the processing of your personal data, please contact the responsible office named below."
        ) + " ",
        <b>{t("RESPONSIBLE ENTITY")}</b>,
        t(
          "The processing of your data on this website is carried out by Deutsche Anbaugesellschaft DAG GmbH, located in Murchin, Libnower Landstr. 1."
        ) + " ",
        t(
          "You can reach Deutsche Anbaugesellschaft DAG GmbH as listed below:Phone: +49 40 5247 4001 E-mail: datenschutz@anbaugesellschaft.de"
        ) + " ",
        t(
          "Deutsche Anbaugesellschaft DAG GmbH is the responsible party according to Art. 4 Para. 7 DSGVO and according to § 12 ff. in conjunction with. § 2 No. 1 TMG service provider."
        ) + " ",
        <b>{t("BASIS FOR DATA PROCESSING")}</b>,
        t(
          `The processing of your personal data is based on: i) your consent, ii) the fulfillment of a contractual relationship, iii) the controller's legitimate interest in processing the data, or iv) the fulfillment of a legal mandate or in the public interest.`
        ) + " ",
        <b>{t("REVOCATION OF YOUR CONSENT TO DATA PROCESSING")}</b>,
        t(
          `If your data is processed based on your express consent, you can revoke this consent at any time without giving reasons and with effect for the future. An informal communication by e-mail is sufficient for this purpose. The legality of the data processing carried out until the revocation remains unaffected by the revocation.`
        ) + " ",
        <b>
          {t("RIGHT TO OBJECT TO DATA PROCESSING IN SPECIAL CASES AND DIRECT") +
            " "}
          {t("MARKETING (ARTICLE 21 DSGVO)")}
        </b>,
        t(
          `If we process your personal data on the basis of our legitimate interest or public interest (pursuant to Article 6(1)(e) or (f) DSGVO), you have the right to object to the processing of your personal data, including any related profiling, at any time on grounds relating to your particular situation. You can find the exact legal basis for the processing of your personal data in this privacy statement. If you object, we will no longer process your personal data concerned unless there are compelling legitimate grounds which override your interests, rights and freedoms, or the processing is necessary to assert, exercise or defend legal claims (objection under Article 21(1) DSGVO).`
        ) + " ",
        t(
          `If we use your personal data for direct marketing, you have the right to object at any time to the processing of your personal data for direct marketing purposes, including any related profiling. If you object, we will no longer use your personal data for direct marketing (objection pursuant to Article 21(2) DSGVO).`
        ) + " ",
        <b className={styles.moreMargin}>{t("DATA SUBJECT RIGHTS")}</b>,
        t(
          `Due to the legal data protection regulations, you are entitled to extensive rights concerning your data. You can exercise these rights by sending an e-mail to datenschutz@anbaugesellschaft.de. These include in particular:`
        ) + " ",
        t("Right to information") + " ",
        t(
          "You can obtain from Anbaugesellschaft.de comprehensive information about all data stored by Anbaugesellschaft.de that concerns you. This information includes, in addition to the personal data, the purpose of processing and the nature of the processing."
        ) + " ",
        t("Right to rectification"),
        t(
          "You have the right to correct, amend or modify your personal data at Anbaugesellschaft.de at any time. You may need to prove your identity before Anbaugesellschaft.de can fulfill this right."
        ) + " ",
        t("Right to data portability"),
        t(
          "As far as technically possible, you have the right to have all data stored about you by Anbaugesellschaft.de transferred to another company."
        ) + " ",
        t("Right to deletion"),
        t(
          "Upon your request, Anbaugesellschaft.de will delete all personal data concerning you that is stored by Anbaugesellschaft.de within the period provided by law. If there is a legal obligation to retain data, the data will only be deleted after this period has expired. This data will be blocked internally for further use."
        ) + " ",
        t("Right to restriction of processing"),
        t(
          "You have the right to request the restriction of the processing of your personal data. The right exists in the following cases:"
        ) + " ",
        t(
          "(1) If you dispute the accuracy of your personal data stored by us, you have the right to request the restriction of the processing of your personal data while we verify this."
        ) + " ",
        t(
          "(2) If the processing of your personal data has been or is being carried out unlawfully, you may request the restriction of data processing instead of erasure."
        ) + " ",
        t(
          "(3) If we no longer need your personal data, but you need it to exercise, defend or assert legal claims, you have the right to request restriction of the processing of your personal data instead of erasure."
        ) + " ",
        t(
          "(4) If you have lodged an objection pursuant to Art. 21(1) DSGVO, we must weigh your interests against ours. As long as it has not yet been determined whose interests prevail, you have the right to request the restriction of the processing of your personal data."
        ) + " ",
        t(
          "If you have restricted the processing of your personal data, this data - apart from its storage - may only be processed with your express consent or for the purpose of asserting, exercising or defending legal claims or protecting the rights of another natural or legal person or for reasons of an important public interest of the European Union or a Member State."
        ) + " ",
        t("Right to complain to the competent supervisory authority"),
        t(
          "Data subjects have the right to lodge a complaint with a supervisory authority in the event of violations of the General Data Protection Regulation (GDPR). In particular, the complaint may be filed in the member state of the habitual residence, place of work or alleged infringement. The right of appeal applies independently of any other administrative or judicial remedies."
        ) + " ",
        <b>{t("DATA SECURITY")}</b>,
        t("SSL or TLS encryption:") + " ",
        t(
          "To protect the transmission of confidential information, such as orders or inquiries, this site uses SSL or TLS encryption. If SSL or TLS encryption is activated, this is indicated by the lock symbol in the browser line and by the change of the address line from `http://` to `https://`. The encryption ensures that third parties have no possibility to read the data."
        ) + " ",
        t("Encrypted payment transactions on this website:"),
        t(
          "If you have to transmit your payment data (e.g. account number in case of direct debit authorization) after conclusion of a contract with costs, this data is required for payment processing. Payment transactions are processed exclusively via an encrypted SSL or TLS connection. Here, too, the encryption is indicated by the lock symbol in the browser line as well as by the change of the address line from `http://` to `https://`. Due to the encrypted communication, your payment data cannot be read by third parties."
        ) + " ",
      ],
      anchor: "section1",
    },
    {
      id: 2,
      title: t("Data collection on our website cookies"),
      sections: [
        t(
          " The Internet pages partly use so-called cookies. Cookies do not cause any damage to your computer and do not contain viruses. Cookies serve to make our offer more user-friendly, more effective and safer. Cookies are small text files that are stored on your computer and saved by your browser."
        ) + " ",
        t("There are 2 categories of cookies:") + " ",
        t(
          "- Essential, necessary cookies, to ensure basic website functions."
        ) + " ",
        t("- Functional cookies, to ensure performance of the website.") + " ",
        t(
          "Most of the cookies we use are so-called 'session cookies'. They are automatically deleted after the end of your visit. Other cookies remain stored on your terminal device until you delete them. These cookies allow us to recognize your browser on your next visit."
        ) + " ",
        t(
          "You can set your browser so that you are informed about the setting of cookies and only allow cookies in individual cases, exclude the acceptance of cookies for certain cases or in general and activate the automatic deletion of cookies when closing the browser. If cookies are deactivated, the functionality of this website may be limited."
        ) + " ",
        t(
          "Cookies that are required to carry out the electronic communication process or to provide certain functions that you have requested (e.g. shopping cart function) are stored on the basis of the legitimate interest of Anbaugesellschaft.de (Art. 6 para. 1 lit. f DSGVO). The website operator has a legitimate interest in storing cookies for the technically error-free and optimized provision of its services. Insofar as other cookies (e.g. cookies for the analysis of your surfing behavior) are stored, these are treated separately in this privacy policy."
        ) + " ",

        <b>{t("‍SERVER LOG FILES")}</b>,
        t(
          "The provider of the pages automatically collects and stores information in so-called server log files, which your browser automatically transmits to us. These are:"
        ) + " ",
        <ul>
          <li>{t("browser type and browser version")}</li>
          <li>{t("operating system used")}</li>
          <li>{t("referrer URL")}</li>
          <li>{t("host name of the accessing computer")}</li>
          <li>{t("time of the server request")}</li>
          <li>{t("IP address")}</li>
        </ul>,
        t(
          "There is no linking of this data with other data sources. The data collection is carried out in accordance with Art. 6 para. 1 lit. f DSGVO. The operator of the website has a legitimate interest in a technically flawless presentation and optimization of its website, for which the collection of server log files is necessary."
        ),
        <b>{t("CONTACT FORM")}</b>,
        t(
          "If you contact us via the contact form, we store the information you provide in the inquiry form as well as your contact data in order to process your inquiry and to contact you in case of any follow-up questions. We do not share this data without your consent."
        ) + " ",
        t(
          "Your data will only be processed based on your consent (Art. 6 para. 1 lit. a DSGVO). You have the right to revoke your consent at any time by sending us an informal email. The lawfulness of the processing of your data before the revocation remains unaffected."
        ) + " ",
        t(
          "Your data will remain stored by us until you request us to delete it, revoke your consent or the purpose of the data storage no longer applies (e.g. after processing your request). Legal retention periods remain unaffected by this."
        ) + " ",
        t(
          "If you contact us by e-mail, telephone or fax, we store and process your inquiry and all related personal data (name, inquiry) in order to process your request. We do not pass on this data without your consent."
        ) + " ",
        t(
          "The processing of this data is based on Art. 6 (1) lit. b DSGVO if your request is related to the performance of a contract or requires pre-contractual measures. Otherwise, the processing is based on your consent (Art. 6 para. 1 lit. a DSGVO) and / or our legitimate interests (Art. 6 para. 1 lit. f DSGVO), as we have a legitimate interest in the effective processing of requests."
        ) + " ",
        t(
          "Your data will remain stored by us until you request us to delete it, revoke your consent or the purpose of the data storage no longer applies (e.g. after processing your request). Legal retention periods remain unaffected by this."
        ) + " ",
        <b>{t("REGISTRATION ON OUR WEBSITE")}</b>,
        t(
          "In order to use additional functions of our website, you can register with us. Your phone number will also be collected, processed, and used if necessary for the use of our services or the fulfillment of our contract. The data you provide will only be used for the purpose for which you have registered, be it for an offer or a service. Please note that all mandatory fields must be filled in during registration, otherwise registration will be rejected."
        ) + " ",
        t(
          "The processing of your data entered during registration is based on your consent (Art. 6 para. 1 lit. a DSGVO). You can revoke your consent at any time by sending us an informal e-mail. The revocation has no effect on data processing that has already taken place and was carried out lawfully up to the time of the revocation."
        ) + " ",
        t(
          "Your data collected during registration will be stored until you cancel your registration on our website. After that, they will be deleted, unless there are legal retention periods."
        ) + " ",
        <b>{t("DATA PROCESSING (CUSTOMER AND CONTRACT DATA)")}</b>,
        t(
          "We collect, process and use personal data only if they are necessary for the establishment, implementation or modification of a contractual relationship (inventory data). This is done on the basis of Art. 6 para. 1 lit. b DSGVO, which allows the processing of data for the fulfillment of a contract or pre-contractual measures. Personal data about the use of our website (usage data) is only collected, processed and used if this is necessary for the use of the service or for billing purposes. Your phone number will also be collected, processed, and used if necessary for the use of our DECT Token or the fulfillment of our contract."
        ) + " ",
        t(
          "The customer data that we collect will be deleted after the conclusion of the contractual relationship or after the termination of the business relationship. Statutory retention periods remain unaffected."
        ),
        <b>
          {t(
            "DATA TRANSMISSION WHEN CONCLUDING A SERVICE CONTRACT OR FOR DIGITAL"
          ) + " "}
          {t("CONTENT")}
        </b>,
        t(
          "We transmit personal data to third parties only if this is necessary for the processing of the contract, for example to the service provider responsible for identification or payment processing."
        ) + " ",
        t(
          "Further transmission of data will only take place with your express consent or if this is necessary in the context of a legitimate interest. We do not pass on your data for advertising purposes without your express consent."
        ) + " ",
        t(
          "The processing of your data is based on Art. 6 (1) lit. b DSGVO, which allows the processing of data for the performance of a contract or pre-contractual measures.,"
        ) + " ",
      ],
      anchor: "section2",
    },
    {
      id: 3,
      title: t("Requirements for the use of the platform "),
      sections: [
        <b>{t("GOOGLE TAG MANAGER")}</b>,
        <>
          {t(
            "Google Tag Manager is a solution with which we can manage so-called"
          ) + " "}
          {t(
            "website tags via an interface (and thus, for example, integrate Google"
          ) + " "}
          {t("Analytics and other Google marketing services into our online")}
          {t(
            "offering). The tag manager itself (which implements the tags) does not"
          ) + " "}
          {t(
            "process any personal data of the users. With regard to the processing"
          ) + " "}
          {t(
            "of users' personal data, please refer to the following information on"
          ) + " "}
          {t("Google services. Usage guidelines:")}{" "}
          <a
            href="https://www.google.com/intl/de/tagmanager/use-policy.html"
            target="_blank"
            rel="noreferrer"
          >
            https://www.google.com/intl/de/tagmanager/use-policy.html
          </a>
        </>,
        <b>{t("USAGE OF GOOGLE ANALYTICS")}</b>,
        t(
          "We use Google Analytics, a web analytics service provided by Google Ireland Ltd, Gordon House, Barrow Street, D04 E5W5, Dublin, Ireland (hereinafter: Google)."
        ) + " ",
        t("1. Scope of personal data processing"),
        t(
          "Google Analytics examines, among other things, the origin of visitors, the time they spend on individual pages, and the use of search engines, thus allowing better monitoring of the success of advertising campaigns. Google sets a cookie on your computer. This allows personal data to be stored and analyzed, in particular the activity of the user (especially which pages have been visited and which elements have been clicked on), device and browser information (especially the IP address and operating system), data about the advertisements displayed (especially which advertisements were displayed and whether the user clicked on them) and also data from advertising partners (especially pseudonymized user IDs)."
        ) + " ",
        t(
          "The information generated by the cookie about your use of this online presence will be transmitted to and stored by Google on servers in the United States. In the event that IP anonymization is activated on this online presence, however, your IP address will be truncated beforehand by Google within member states of the European Union or in other contracting states to the Agreement on the European Economic Area. Only in exceptional cases will the full IP address be transmitted to a Google server in the USA and shortened there."
        ) + " ",
        <>
          {t(
            "On behalf of the operator of this website, Google will use this"
          ) + " "}
          {t(
            "information for the purpose of evaluating your use of the website,"
          ) + " "}
          {t(
            "compiling reports on website activity and providing other services"
          ) + " "}
          {t("relating to website activity and internet usage to the website") +
            " "}
          {t(
            "operator. The IP address transmitted by your browser as part of Google"
          ) + " "}
          {t(
            "Analytics will not be merged with other data from Google. You may"
          ) + " "}
          {t(
            "refuse the use of cookies by selecting the appropriate settings on"
          ) + " "}
          {t(
            "your browser, however please note that if you do this you may not be"
          ) + " "}
          {t(
            "able to use the full functionality of our online presence. Further"
          ) + " "}
          {t(
            "information on the processing of data by Google can be found here:"
          )}{" "}
          <a
            href=" https://policies.google.com/privacy?gl=DE&hl=de"
            target="_blank"
          >
            https://policies.google.com/privacy?gl=DE&hl=de
          </a>
        </>,
        t("2. Purpose of data processing"),
        t(
          "The purpose of processing personal data is to specifically address a target group that has already expressed an initial interest by visiting the site."
        ),
        t("3. Legal basis for the processing of personal data"),
        t(
          "The legal basis for the processing of the personal data of the users is basically the consent of the user according to Art. 6 para. 1 p.1 lit. a DSGVO."
        ),
        t("4. Duration of the storage"),
        t(
          "Your personal information will be retained for as long as necessary to fulfill the purposes described in this Privacy Policy or as required by law. Advertising data in server logs is anonymized by Google deleting parts of the IP address and cookie information after 9 and 18 months, respectively, according to Google."
        ),
        t("5. Revocation and removal possibility"),
        t(
          "You have the right to revoke your declaration of consent under data protection law at any time. The revocation of consent does not affect the lawfulness of the processing carried out on the basis of the consent until the revocation."
        ),
        <>
          {t(
            "You can prevent the collection as well as the processing of your"
          ) + " "}
          {t(
            "personal data by Google by preventing the storage of third-party"
          ) + " "}
          {t(
            "cookies on your computer, using the 'Do Not Track' function of a"
          ) + " "}
          {t(
            "supporting browser, disabling the execution of script code in your"
          ) + " "}
          {t("browser or installing a script blocker such as NoScript") + " "} (
          <a href="https://noscript.net/" target="_blank" rel="noreferrer">
            https://noscript.net/
          </a>
          ) {t("or Ghostery")} (
          <a href="https://www.ghostery.com" target="_blank" rel="noreferrer">
            https://www.ghostery.com
          </a>
          ) {t("in your browser.")}
        </>,
        <>
          {t(
            "You can also prevent the collection of data generated by the cookie"
          ) + " "}
          {t(
            "and related to your use of the online presence (including your IP"
          ) + " "}
          {t(
            "address) to Google and the processing of this data by Google by"
          ) + " "}
          {t("downloading and installing the browser plugin available at the") +
            " "}
          {t("following link:") + " "}{" "}
          <a
            href="https://tools.google.com/dlpage/gaoptout?hl=de"
            target="_blank"
            rel="noreferrer"
          >
            https://tools.google.com/dlpage/gaoptout?hl=de
          </a>
        </>,
        <>
          {t(
            "You can deactivate the use of your personal data by Google using the"
          ) + " "}
          {t("following link:")}{" "}
          <a
            href="https://adssettings.google.de"
            target="_blank"
            rel="noreferrer"
          >
            https://adssettings.google.de
          </a>
        </>,
        <>
          {t(
            "For more information on opt-out and removal options vis-à-vis Google,"
          ) + " "}
          {t("please visit:")}{" "}
          <a
            href="https://policies.google.com/privacy?gl=DE&hl=de"
            target="_blank"
            rel="noreferrer"
          >
            https://policies.google.com/privacy?gl=DE&hl=de
          </a>
        </>,
        <b>{t("GOOGLE WEB FONTS")}</b>,
        t(
          "This page uses so-called web fonts provided by Google for the uniform display of fonts. When you call up a page, your browser loads the required web fonts into its browser cache in order to display texts and fonts correctly."
        ) + " ",
        t(
          `For this purpose, the browser you are using must connect to Google's servers. This gives Google knowledge that our website has been accessed via your IP address. Google Web Fonts are used in the interest of a uniform and appealing presentation of our online offers. This represents a legitimate interest within the meaning of Art. 6 (1) lit. f DSGVO.`
        ) + " ",
        t(
          "If your browser does not support web fonts, a standard font will be used by your computer."
        ) + " ",
        <>
          {t("You can find more information about Google Web Fonts at") + " "}
          {t(
            "https://developers.google.com/fonts/faq   and in Google's privacy"
          ) + " "}
          {t("policy:")}{" "}
          <a
            href="https://policies.google.com/privacy?hl=de"
            target="_blank"
            rel="noreferrer"
          >
            https://policies.google.com/privacy?hl=de
          </a>
        </>,
        <b>{t("MICROSOFT CLARITY")}</b>,
        <>
          {t(
            "We use Microsoft Clarity, a tool provided by Microsoft, to analyze user behavior on our website. Clarity tracks user interactions, such as mouse movements, clicks, and scrolls, to help us improve our services. The data is processed based on our legitimate interest in optimizing our online offerings (Art. 6 Abs. 1 lit. f DSGVO). Clarity anonymizes IP addresses, and no personal data is directly linked unless required for service improvements. For more details, refer to "
          ) + " "}
          <a
            href="https://privacy.microsoft.com/en-us/privacystatement"
            target="_blank"
            rel="noreferrer"
          >
            {t("Microsoft's Privacy Policy")}
          </a>
        </>,
        <b>{t("CORPORATE WEBSITES")}</b>,
        t("Use of company presences in social networks." + " "),
        t("Instagram:" + " "),
        t(
          "Instagram, Part of Facebook Ireland Ltd., 4 Grand Canal Square Grand Canal Harbour, Dublin 2 Ireland"
        ) + " ",
        t(
          "On our company page, we provide information and offer Instagram users the opportunity to communicate. If you perform an action on our Instagram company page (e.g. comments, posts, likes, etc.), it is possible that you will make personal data (e.g. clear name or photo of your user profile) public. However, since we generally or to a large extent have no influence on the processing of your personal data by Instagram, which is jointly responsible for the Deutsche Anbaugesellschaft DAG GmbH corporate presence, we cannot provide any binding information on the purpose and scope of the processing of your data."
        ) + " ",
        t(
          "Our corporate presence in social networks is used for communication and information exchange with (potential) customers. In particular, we use the corporate presence for: The corporate presence is used to provide information about products, projects and company-related and industry-specific news. In this context, publications via the corporate presence may contain the following content:"
        ) + " ",
        <ul>
          <li>{t("Information about products")}</li>
          <li>{t("Information about services")}</li>
          <li>{t("Advertising")}</li>
          <li>{t("Customer contact")}</li>
          <li>{t("Latest industry news")}</li>
        </ul>,
        t(
          `Every user is free to publish personal data through activities. The legal basis for data processing is Art. 6 para.1 p.1 lit. a DSGVO.`
        ) + " ",
        t(
          "The data generated by the corporate presence is not stored in our own systems. You can object at any time to the processing of your personal data that we collect in the course of your use of our Instagram - company presence and assert your data subject rights mentioned under IV. of this privacy policy. To do so, send us an informal email to datenschutz@anbaugesellschaft.de."
        ) + " ",
        t(
          "For the processing of your personal data by Instagram and the corresponding objection options, you can find more information here:"
        ) + " ",
        <>
          {t("Instagram:")}{" "}
          <a
            href="https://help.instagram.com/519522125107875"
            target="_blank"
            rel="noreferrer"
          >
            https://help.instagram.com/519522125107875
          </a>
        </>,
        t("Twitter:") + " ",
        t(
          `Twitter International Company, One Cumberland Place, Fenian Street, Dublin 2, IrelandOn our company website, we provide information and offer Twitter users the opportunity to communicate. If you carry out an action on our Twitter company website (e.g. comments, posts, likes, etc.), you may make personal data (e.g. clear name or photo of your user profile) public. However, since we generally or to a large extent have no influence on the processing of your personal data by Twitter, the company jointly responsible for the Deutsche Anbaugesellschaft DAG GmbH corporate presence, we cannot provide any binding information on the purpose and scope of the processing of your data. Our corporate presence in social networks is used for communication and information exchange with (potential) customers. In particular, we use the corporate presence for: The corporate presence is used to provide information about products, projects and company-related and industry-specific news. In this context, publications via the corporate presence may contain the following content:`
        ),
        <ul>
          <li>{t("Information about products")}</li>
          <li>{t("Information about services")}</li>
          <li>{t("Advertising")}</li>
          <li>{t("Customer contact")}</li>
          <li>{t("Latest industry news")}</li>
        </ul>,
        t(
          `Every user is free to publish personal data through activities. The legal basis for data processing is Art. 6 para.1 p.1 lit. a DSGVO. The data generated by the corporate presence is not stored in our own systems.`
        ) + " ",
        t(
          "You can object at any time to the processing of your personal data that we collect in the course of your use of our Twitter corporate presence and assert your data subject rights listed under IV. of this data protection declaration. To do so, send us an informal email to datenschutz@anbaugesellschaft.de."
        ) + " ",
        t(
          "You can find more information about the processing of your personal data by Twitter and the corresponding objection options here:"
        ) + " ",
        <>
          Twitter:{" "}
          <a
            href="https://twitter.com/de/privacy"
            target="_blank"
            rel="noreferrer"
          >
            https://twitter.com/de/privacy
          </a>
        </>,
        t("YouTube:") + " ",
        t("YouTube LLC, 901 Cherry Ave., San Bruno, CA 94066, United States") +
          " ",
        t(
          "On our company page, we provide information and offer YouTube users the opportunity to communicate. If you carry out an action on our YouTube corporate site (e.g. comments, posts, likes, etc.), it is possible that you will make personal data (e.g. clear name or photo of your user profile) public. However, since we generally or to a large extent have no influence on the processing of your personal data by YouTube, the company jointly responsible for the Deutsche Anbaugesellschaft DAG GmbH corporate presence, we cannot provide any binding information on the purpose and scope of the processing of your data. Our corporate presence in social networks is used for communication and information exchange with (potential) customers. In particular, we use the corporate presence for: The corporate presence is used to provide information about products, projects and company-related and industry-specific news. In this context, publications via the corporate presence may contain the following content:"
        ),
        <ul>
          <li>{t("Information about products")}</li>
          <li>{t("Information about services")}</li>
          <li>{t("Advertising")}</li>
          <li>{t("Customer contact")}</li>
          <li>{t("Latest industry news")}</li>
        </ul>,
        t(
          `Every user is free to publish personal data through activities. The legal basis for data processing is Art. 6 para.1 p.1 lit. a DSGVO. The data generated by the corporate presence is not stored in our own systems. You can object at any time to the processing of your personal data that we collect in the course of your use of our YouTube corporate presence and assert your data subject rights as stated under IV. of this data protection declaration. To do so, send us an informal email to datenschutz@anbaugesellschaft.de`
        ),
        <>
          {t("For the processing of your personal data by YouTube and the") +
            " "}
          {t(
            "corresponding objection options, you can find more information here:"
          ) + " "}
          {t("YouTube:") + " "}{" "}
          <a
            href="https://policies.google.com/privacy?gl=DE&hl=de"
            target="_blank"
            rel="noreferrer"
          >
            https://policies.google.com/privacy?gl=DE&hl=de
          </a>
        </>,
        <b>{t("USE OF COMPANY PRESENCES IN JOB-ORIENTED NETWORKS")}</b>,
        t(
          "We use the possibility of company appearances on profession-oriented networks. We maintain a company presence on the following job-oriented networks:"
        ) + " ",
        t("LinkedIn:") + " ",
        t(`LinkedIn, Unlimited Company Wilton Place, Dublin 2, Ireland`) + " ",
        t(`XING:`) + " ",
        t(`XING SE, Dammtorstraße 30, 17390 Murchin, Deutschland`) + " ",
        t(
          `On our site we provide information and offer users the possibility of communication. The company website is used for job applications, information/PR and active sourcing. We do not have any information on the processing of your personal data by the companies jointly responsible for the corporate presence. For more information, please refer to the privacy policy of:`
        ) + " ",
        <>
          LinkedIn:{" "}
          <a
            href="https://www.linkedin.com/legal/privacy-policy?trk=hb_ft_priv"
            target="_blank"
            rel="noreferrer"
          >
            https://www.linkedin.com/legal/privacy-policy?trk=hb_ft_priv
          </a>
        </>,
        <>
          XING:{" "}
          <a
            href="https://privacy.xing.com/de/datenschutzerklaerung"
            target="_blank"
            rel="noreferrer"
          >
            https://privacy.xing.com/de/datenschutzerklaerung
          </a>
        </>,
        t(
          `If you carry out an action on our company website (e.g. comments, posts, likes, etc.), you may make personal data (e.g. clear name or photo of your user profile) public. Part of LinkedIn's terms of use are so-called standard data protection clauses (Art. 46 para. 2 p. 1 lit. c DSGVO). These are to be classified as an appropriate guarantee for the protection of the transfer and processing of personal data outside the EU.`
        ),
        t(`Legal basis for data processing`) + " ",
        t(
          `The legal basis for the processing of your data in connection with the use of our corporate presence is Art.6 Abs.1 S.1 lit. f DSGVO.`
        ),
        t(`Purpose of data processing`) + " ",
        t(
          `Our company website serves to inform users about our services. In doing so, every user is free to publish personal data through activities.`
        ),
        t(`Duration of storage`) + " ",
        t(
          `We store your activities and personal data published via our corporate website until you revoke your consent.`
        ),
        t(`In addition, we comply with the statutory retention periods.`) + " ",
        t(`Possibility of objection and elimination`) + " ",
        t(
          `You can object at any time to the processing of your personal data that we collect in the course of your use of our company website and assert your data subject rights as stated in section 1 of this data protection declaration. To do so, send us an informal email to the email address stated in this data protection declaration. You can find more information on objection and removal options here:`
        ),
        <>
          LinkedIn:{" "}
          <a
            href="https://www.linkedin.com/legal/privacy-policy?trk=hb_ft_priv"
            target="_blank"
            rel="noreferrer"
          >
            https://www.linkedin.com/legal/privacy-policy?trk=hb_ft_priv
          </a>
        </>,
        <>
          XING:{" "}
          <a
            href="https://privacy.xing.com/de/datenschutzerklaerung"
            target="_blank"
            rel="noreferrer"
          >
            https://privacy.xing.com/de/datenschutzerklaerung
          </a>
        </>,
        t(`Hosting`),
        t(
          `We host our website with Hetzner. The provider is Hetzner Online GmbH, Industriestr. 25, 91710 Gunzenhausen (hereinafter: Hetzner).`
        ),
        <>
          {t("For details, please refer to Hetzner's privacy policy:")}{" "}
          <a
            href="https://www.hetzner.com/de/rechtliches/datenschutz"
            target="_blank"
            rel="noreferrer"
          >
            https://www.hetzner.com/de/rechtliches/datenschutz
          </a>
        </>,
        t(
          `The use of Hetzner is based on Art. 6 para. 1 lit. f DSGVO. We have a legitimate interest in the most reliable presentation of our website. If a corresponding consent was requested, the processing is based exclusively on Art. 6 para. 1 lit. a DSGVO; the consent can be revoked at any time.`
        ) + " ",
        t(`Order processing`) + " ",
        t(
          `We have concluded a contract on order processing (AVV) with the above-mentioned provider. This is a contract required by data protection law, which ensures that the provider only processes the personal data of our website visitors in accordance with our instructions and in compliance with the GDPR.`
        ) + " ",
        t(
          `The location of the website's server is geographically in Germany.`
        ) + " ",
        <b className={styles.moreMargin}>{t("GEOTARGETING")}</b>,
        t(
          `We use the IP address and other information provided by the user (in particular zip code in the context of registration or ordering) for regional targeting (so-called 'geotargeting'). Regional targeting is used, for example, to automatically show you regional offers or advertising that are often more relevant to users. The legal basis for the use of the IP address and, if applicable, other information provided by the user (in particular zip code) is Art. 6 (1) lit. f DSGVO, based on our interest in ensuring more precise targeting and thus providing offers and advertising with higher relevance for users. In this context, a part of the IP address as well as the additional information provided by the user (in particular zip code) are only read and not stored separately. You can prevent geotargeting by using, for example, a VPN or proxy server that prevents precise localization. In addition, depending on the browser used, you can also deactivate a location localization in the corresponding browser settings (insofar as this is supported by the respective browser).`
        ),
        t(`We use geotargeting on our website for the following purposes:`) +
          " ",
        <ul>
          <li>{t("Customer targeting")}</li>
          <li>{t("Advertising purposes‍")}</li>
        </ul>,
      ],
      anchor: "section3",
    },
    {
      id: 4,
      title: t("MAINTENANCE"),
      sections: [
        t(
          `Your data will be stored for as long as is necessary to provide the services. Should a longer storage be necessary due to legal regulations, the data will be stored until the expiry of this period.`
        ),
      ],
      anchor: "section4",
    },
    {
      id: 5,
      title: t("COPYRIGHT"),
      sections: [
        t(
          `The contents of our website, in particular the products presented and data provided, are protected by copyright. The contents may not be reproduced and/or published in whole or in part or stored in an information system without prior written permission. The presentation of this website in external frames is only permitted after express written consent`
        ),
      ],
      anchor: "section5",
    },
    {
      id: 6,
      title: t("SCOPE OF APPLICATION OF THE PRIVACY POLICY"),
      sections: [
        t(
          `The data protection declaration applies exclusively to the website of Deutsche Anbaugesellschaft DAG GmbH.`
        ) + " ",
        t(
          `The Privacy Policy does not apply to services offered by other companies or individuals, this includes products or websites displayed to you in search results, websites that may include Anbaugesellschaft.de services, or other websites linked to Anbaugesellschaft.de services. The privacy policy does not cover the handling of information by other companies or organizations that advertise services and may use cookies, pixel tags and other technologies to provide and offer relevant ads.`
        ) + " ",
      ],
      anchor: "section6",
    },
    {
      id: 7,
      title: t("MODIFICATIONS"),
      sections: [
        t(
          `The Privacy Policy may change from time to time. Any changes to the Privacy Policy will be posted on this website.`
        ) + " ",
      ],
      anchor: "section7",
    },
  ];
  const [selectedMenuItem, setSelectedMenuItem] = useState(menuItems[0]);

  const navigate = useNavigate();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  return (
    <div className={styles.main__wrapper}>
      <main className={styles.main}>
        <div className="container">
          <div className={styles.main__header}>
            <div className={styles.header__image}></div>
            <div className={styles.title__wrapper}>
              <h1 className={styles.title}>
                {t("Privacy policy & Data processing")}
              </h1>
            </div>
            <p>
              {t(
                "Anbaugesellschaft.de is a platform of Deutsche Anbaugesellschaft"
              ) + " "}
              {t(
                "DAG GmbH. The web presence of Deutsche Anbaugesellschaft DAG GmbH"
              ) + " "}
              {t(
                "is the platform Anbaugesellschaft.de. In the following, company"
              ) + " "}
              {t("name and platform are partly used synonymously.") + " "}
            </p>
            <div className={styles.header__status}>
              {t("Status:")} <span>03.05.2023</span>
            </div>
          </div>

          <div className={styles.main__body}>
            <div className={styles.asideMenu__wrapper}>
              <nav>
                <ul className={styles.asideMenu}>
                  {menuItems &&
                    menuItems.map((item, i) => {
                      return (
                        <li
                          key={i}
                          className={cx({
                            asideMenu__item: true,
                            asideMenu__item_active: item === selectedMenuItem,
                          })}
                        >
                          <Link
                            to={`section${i + 1}`}
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            onClick={() => {
                              setSelectedMenuItem(item);
                            }}
                          >
                            {t(item)}
                          </Link>
                        </li>
                      );
                    })}
                </ul>
              </nav>
            </div>
            <div className={styles.body}>
              <div className={styles.paragraphs}>
                {paragraphItems?.map((item) => {
                  const { id, title, sections, anchor } = item;

                  return (
                    <div key={id} className={styles.paragraph} id={anchor}>
                      <div className={styles.paragraph__title_wrapper}>
                        <div className={styles.paragraph__title}>
                          {`${id}. ${t(title)}`}
                        </div>
                      </div>

                      <div className={styles.paragraph__text}>
                        {sections?.map((section, i) => {
                          console.log(section);
                          return (
                            <p
                              key={i}
                              dangerouslySetInnerHTML={{
                                __html: ReactDOMServer.renderToString(section),
                              }}
                            />
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <Banner />
      </main>
    </div>
  );
};
