import React, { useState } from "react";
import styles from "./Select.module.scss";
import ArrowDropdown from "../../img/IconComponents/ArrowDropdown";
import cn from "classnames";
const Select = ({ options, value, onChange, placeholder, width = "auto"}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option) => {
    onChange(option);
    setIsOpen(false);
  };
  return (
    <div className={styles.select} style={{width: width}}>
      <div className={styles.select__header} onClick={handleClick}>
        <span>{value ? value.label : placeholder}</span>
        <ArrowDropdown />
      </div>
      {isOpen && (
        <div className={styles.select__options}>
          {options.map((option) => (
            <div
              key={option.value}
              className={cn(
                styles.option,
                value === option ? styles.selected : ""
              )}
              onClick={() => handleSelect(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Select;
