import React from 'react'
import styles from './Subheader.module.scss'
import DropdownDigit from '../../../../../../components/DropdownDigit/DropdownDigit'
import { useTranslation } from 'react-i18next'

const Subheader = ({ value, setValue, options }) => {
  const { t } = useTranslation()
  return (
    <div className={styles.subheader}>
      <div>{t('Show')}</div>
      <DropdownDigit value={value} setValue={setValue} options={options} />
      <div>{t('Entries')}</div>
    </div>
  )
}

export default Subheader
