const MallIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.66653 23C4.09034 23 3.59708 22.7948 3.18677 22.3845C2.77645 21.9742 2.57129 21.481 2.57129 20.9048V8.33333C2.57129 7.75714 2.77645 7.26389 3.18677 6.85357C3.59708 6.44325 4.09034 6.2381 4.66653 6.2381H6.76177C6.76177 4.78889 7.27248 3.55357 8.29391 2.53214C9.31534 1.51071 10.5507 1 11.9999 1C13.4491 1 14.6844 1.51071 15.7058 2.53214C16.7272 3.55357 17.238 4.78889 17.238 6.2381H19.3332C19.9094 6.2381 20.4026 6.44325 20.813 6.85357C21.2233 7.26389 21.4284 7.75714 21.4284 8.33333V20.9048C21.4284 21.481 21.2233 21.9742 20.813 22.3845C20.4026 22.7948 19.9094 23 19.3332 23H4.66653ZM4.66653 20.9048H19.3332V8.33333H4.66653V20.9048ZM8.857 6.2381H15.1427C15.1427 5.36508 14.8372 4.62302 14.2261 4.0119C13.6149 3.40079 12.8729 3.09524 11.9999 3.09524C11.1268 3.09524 10.3848 3.40079 9.77367 4.0119C9.16256 4.62302 8.857 5.36508 8.857 6.2381ZM11.9737 14.619C13.2657 14.619 14.4356 14.1913 15.4832 13.3357C16.5308 12.4802 17.0459 11.5198 17.0284 10.4548C17.0284 10.1579 16.9324 9.90476 16.7403 9.69524C16.5483 9.48571 16.3038 9.38095 16.007 9.38095C15.7626 9.38095 15.5443 9.45952 15.3522 9.61667C15.1602 9.77381 15.0205 10.0095 14.9332 10.3238C14.7411 10.9873 14.3657 11.5198 13.807 11.9214C13.2483 12.323 12.6372 12.5238 11.9737 12.5238C11.3102 12.5238 10.6947 12.323 10.1272 11.9214C9.55978 11.5198 9.18875 10.9873 9.01415 10.3238C8.92684 9.99206 8.79589 9.75198 8.62129 9.60357C8.44669 9.45516 8.23716 9.38095 7.99272 9.38095C7.69589 9.38095 7.44708 9.48571 7.24629 9.69524C7.0455 9.90476 6.9451 10.1579 6.9451 10.4548C6.9451 11.5198 7.46018 12.4802 8.49034 13.3357C9.5205 14.1913 10.6816 14.619 11.9737 14.619Z"
      fill="currentColor"
    />
  </svg>
);

export default MallIcon;
