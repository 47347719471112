import React from "react";
import "flatpickr/dist/flatpickr.css";
import Calendar from "../../img/IconComponents/Calendar";
import Flatpickr from "react-flatpickr";
import styles from "./DatePicker.module.scss";
import { useTranslation } from "react-i18next";
import 'flatpickr/dist/l10n/de';

function DatePicker({ value, datePickAction, placeholder, title, options,type}) {
  const {t} =useTranslation()
  const flatPickerLocale={months: {
    shorthand: `${t("Jan")}_${t("Feb")}_${t("Mar")}_${t("Apr")}_${t("May")}_${t("Jun")}_${t("Jul")}_${t("Aug")}_${t("Sep")}_${t("Oct")}_${t("Nov")}_${t("Dec")}`.split('_'),
    longhand: `${t("January")}_${t("February")}_${t("March")}_${t("April")}_${t("May")}_${t("June")}_${t("July")}_${t("August")}_${t("September")}_${t("October")}_${t("November")}_${t("December")}`.split('_')}
  };  return (
    <label className={styles.datepicker}>
      <span className={styles.datepicker__title}>{title}</span>
      {type !== 'settings' && (
        <div className={styles.datepicker__icon}>
          <Calendar />
        </div>
      )}

      <Flatpickr
        value={value}
        onChange={(date) => {
          datePickAction(new Date(date).getTime())
        }}
        className={
          type === 'settings'
            ? styles.datepicker__input_settings
            : styles.datepicker__input
        }
        placeholder={placeholder}
        options={{
          ...options,
          dateFormat: 'd.m.Y',
          // locale:{...flatPickerLocale}
          locale: localStorage.getItem('i18n') == 'DE' ? 'de' : 'en',
        }}
      />
      {type === 'settings' && (
        <div className={styles.datepicker__icon_settings}>
          <Calendar color="black" />
        </div>
      )}
    </label>
  )
}

export default DatePicker;
