import React from "react";
import styles from "./Date.module.scss";
const Date = ({ text, date }) => {
  return (
    <div className={styles.date}>
      <div className={styles.date__text}>
        <h5>{date}</h5>
        <p>{text}</p>
      </div>
      <div className={styles.date__bottom}>
        <div className={styles.date__bottom_dot}></div>
        <div className={styles.date__bottom_line}></div>
      </div>
    </div>
  );
};

export default Date;
