import React, { useState, useEffect } from "react";
import styles from "./Main.module.scss";
import Button from "../../../../components/Button/Button";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import SectionTitle from "../../../../components/SectionTitle/SectionTitle";
import Select from "../../../../components/Select/Select";
import Table from "../Table/Table";
import Status from "../Status/Status";
import Upload from "../../../../img/IconComponents/Upload";
import Modal from "../Modal/Modal";
import empty from "../../../../img/emptyFinances.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import getRequest from "../../../../helpers/getRequest";

const selectOptions = [
  { label: 1, value: 1 },
  { label: 5, value: 5 },
  { label: 10, value: 10 },
  { label: 15, value: 15 },
  { label: 20, value: 20 },
];

const statuses = [
  {
    title: "Pending",
    color: "#FA8A07",
    bg: "#F6D4AD",
    text: "Order was placed, but not paid yet",
    id: 1,
  },
  {
    title: "Paid",
    color: "#CBA10D",
    bg: "#F8E9B6",
    text: "Order was paid",
    id: 2,
  },
  {
    title: "Confirmed",
    color: "#509C5B",
    bg: "#E9F1C5",
    text: "Order passed 2-week cancellation period",
    id: 3,
  },
  
  {
    title: "Paid Out",
    color: "#3B4CE5",
    bg: "#BDC2F0",
    text: "Commission for this order is paid out",
    id: 4,
  },
];

const Main = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();


  const [selectedOption, setSelectedOption] = useState({
    label: 10,
    value: 10,
  });
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [clientsStatistics, setClientsStatistics] = useState(null);
  const [salesStatistics, setSalesStatistics] = useState(null);

  const [page, setPage] = useState(1);
  const [totalClients, setTotalClients] = useState(0);
  const [totalSales, setTotalSales] = useState(0);


  const handleChange = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    if(location.pathname.includes('sales')){
      getRequest(
        `v1/statistics/orders/sale?page=${page}&take=${selectedOption.value}`
      ).then((response) => {
        console.log(response);
        setSalesStatistics(response.data?.data);
        setTotalSales(response.data?.total);
      });
    }else{
    getRequest(
      `v1/statistics/orders?page=${page}&take=${selectedOption.value}`
    ).then((response) => {
      console.log(response);
      setClientsStatistics(response.data?.orderStatistics);
      setTotalClients(response.data?.total);
    });}
  }, [page, selectedOption]);

  return (
    <section className={styles.main}>
      <Button
        text={t("Back")}
        color="lightGreen"
        onClick={() => navigate(-1)}
      />
      <br />
      <br />
      <SectionTitle text={t("Order Statistics")} />
      <br />
      {clientsStatistics && clientsStatistics.length ? (
        <div>
          <div className={styles.main__header}>
            <div className={styles.main__sort}>
              <p>{t("Show")}</p>
              <Select
                options={selectOptions}
                value={selectedOption}
                onChange={handleChange}
                placeholder={t("Select")}
              />
              <p>{t("Entries")}</p>
              <div className={styles.main__sort_amount}>
                {t("Showing")}{" "}
                {page === 1 ? 1 : (page - 1) * selectedOption?.value} to{" "}
                {page * selectedOption?.value > totalClients
                  ? totalClients
                  : page * selectedOption?.value}{" "}
                of {totalClients} {t("entries")}
              </div>
            </div>
            <button
              className={styles.main__export}
              onClick={() => setIsOpenModal(true)}
            >
              {t("Export")}
              <Upload />
            </button>
          </div>
          <br />

          <Table
            statistic={clientsStatistics}
            selectedOption={selectedOption}
            page={page}
            setPage={setPage}
            totalClientsProp={totalClients}
          />
          <div className={styles.main__statuses}>
            {statuses.map((item) => {
              const { title, text, id } = item;
              return (
                <div className={styles.main__statuses_row} key={id}>
                  <div className={styles.main__statuses_row_status}>
                    <Status title={t(title)} />
                  </div>
                  <div>{t(text)}</div>
                </div>
              );
            })}
          </div>
          {isOpenModal && <Modal closeAction={() => setIsOpenModal(false)} />}
        </div>
      ): salesStatistics && salesStatistics.length ? (
        <div>
          <div className={styles.main__header}>
            <div className={styles.main__sort}>
              <p>{t("Show")}</p>
              <Select
                options={selectOptions}
                value={selectedOption}
                onChange={handleChange}
                placeholder={t("Select")}
              />
              <p>{t("Entries")}</p>
              <div className={styles.main__sort_amount}>
                {t("Showing")}{" "}
                {page === 1 ? 1 : (page - 1) * selectedOption?.value} to{" "}
                {page * selectedOption?.value > totalClients
                  ? totalClients
                  : page * selectedOption?.value}{" "}
                of {totalClients} {t("entries")}
              </div>
            </div>
            <button
              className={styles.main__export}
              onClick={() => setIsOpenModal(true)}
            >
              {t("Export")}
              <Upload />
            </button>
          </div>
          <br />
          {console.log(salesStatistics)}
          <Table
            statistic={salesStatistics}
            selectedOption={selectedOption}
            page={page}
            setPage={setPage}
            totalClientsProp={totalSales}
            user_role={'sale'}
          />
          <div className={styles.main__statuses}>
            {statuses.map((item) => {
              const { title, text, id } = item;
              return (
                <div className={styles.main__statuses_row} key={id}>
                  <div className={styles.main__statuses_row_status}>
                    <Status title={t(title)} />
                  </div>
                  <div>{t(text)}</div>
                </div>
              );
            })}
          </div>
          {isOpenModal && <Modal closeAction={() => setIsOpenModal(false)} />}
        </div>
      ) : (
        <div
          className={styles.main__empty}
          data-aos="fade-top"
          data-aos-duration="1000"
        >
          <img src={empty} alt="empty" />
          <div className={styles.main__empty_title}>
            {t("Order Statistics is empty now")}
          </div>
        </div>
      )}
    </section>
  );
};

export default Main;
