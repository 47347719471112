import React, { useState, useEffect } from "react";
import styles from "./TableElement.module.scss";
import ArrowDropdown from "../../../../img/IconComponents/ArrowDropdown";
import cn from "classnames";
import { useTranslation } from "react-i18next";

const TableElement = ({
  role,
  clients_first_name,
  clients_last_name,
  plants,
  total_costs,
  sale_commissions,
  sales_first_name,
  sales_last_name,
  hs_commissions,
  hs_first_name,
  hs_last_name,
  my_commission,
  clients_id,
  clients_country,
  clients_city,
  clients_postcode,
  hs_id,
  hs_country,
  hs_city,
  hs_postcode,
  sales_id,
  sales_country,
  sales_city,
  sales_postcode,
  pricePerPlant,
  clients_street,
  clients_door_no,
  clients_email,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  // const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

  // useEffect(() => {
  //   function handleResize() {
  //     setIsMobile(window.innerWidth <= 1024);
  //   }

  //   window.addEventListener("resize", handleResize);

  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);
  const { t } = useTranslation();
  return (
    <div className={styles.row} onClick={() => setIsOpen((prev) => !prev)}>
      <div className={styles.row__row}>
        <div className={styles.name}>
          <ArrowDropdown rotate={isOpen ? "180deg" : ""} />
          <p>
            {clients_first_name} {clients_last_name}
          </p>
        </div>
        <div className={styles.plants}>
          <div className={styles.mobileInfo}>{t("Total tokens purchased")}</div>
          {plants && (plants > 0 || plants.length) ? (
            <>
              {plants} {t("tokens")}
            </>
          ) : (
            "-"
          )}
        </div>
        <div className={styles.perPrice}>
          <div className={styles.mobileInfo}>{t("Price per Token")}</div>
          {pricePerPlant ? (
            <>
              {parseFloat(pricePerPlant).toLocaleString("de-DE", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              EUR
            </>
          ) : (
            "-"
          )}
        </div>
        <div className={styles.total}>
          <div className={styles.mobileInfo}>{t("Total Cost")}</div>
          {total_costs ? (
            <>
              {parseFloat(total_costs).toLocaleString("de-DE", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              EUR
            </>
          ) : (
            "-"
          )}
        </div>
        {role && role !== "SALE" ? (
          <div className={styles.salesName}>
            <div className={styles.mobileInfo}>{t("Sales Name")}</div>
            {sales_first_name || sales_last_name ? (
              <>
                {sales_first_name} {sales_last_name}
              </>
            ) : (
              "-"
            )}
          </div>
        ) : null}
        {role && role !== "SALE" ? (
          <div className={styles.sales}>
            <div className={styles.mobileInfo}>{t("Sales Commision")}</div>
            {sale_commissions && sale_commissions.length ? (
              <>
                {parseFloat(sale_commissions).toLocaleString("de-DE", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                EUR
              </>
            ) : (
              "-"
            )}
          </div>
        ) : null}
        {role && role === "MAIN_SALE" ? (
          <div className={styles.salesNameHead}>
            <div className={styles.mobileInfo}>{t("Head of Sales Name")}</div>
            {hs_first_name || hs_last_name ? (
              <>
                {hs_first_name} {hs_last_name}
              </>
            ) : (
              "-"
            )}
          </div>
        ) : null}
        {role && role === "MAIN_SALE" ? (
          <div className={styles.salesHead}>
            <div className={styles.mobileInfo}>
              {t("Head of Sales Commision")}
            </div>
            {hs_commissions && hs_commissions.length ? (
              <>{hs_commissions} EUR</>
            ) : (
              "-"
            )}
          </div>
        ) : null}
        {role && role !== "HEAD_OF_SALES" && (
          <div className={styles.comission}>
            <div className={styles.mobileInfo}>{t("My Comission")}</div>
            {my_commission && my_commission.length ? (
              <>
                {parseFloat(my_commission).toLocaleString("de-DE", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                EUR
              </>
            ) : (
              "-"
            )}
          </div>
        )}
      </div>
      {isOpen && (
        <div className={styles.row__more}>
          {sales_id && sales_id != "" ? (
            <div className={styles.row__more_wrapper}>
              <div className={styles.row__more_label}>{t("Sales")}</div>
              <div className={styles.row__more_name}>
                {sales_first_name || sales_last_name ? (
                  <>
                    {sales_first_name} {sales_last_name}
                  </>
                ) : (
                  "-"
                )}
              </div>
              <div
                className={styles.row__more_id}
                style={{ flex: role === "HEAD_OF_SALES" ? 3 : 2 }}
              >
                {sales_id && sales_id != "" ? <>ID {sales_id}</> : "-"}
              </div>

              <div
                className={styles.row__more_place}
                style={{ marginLeft: role === "HEAD_OF_SALES" ? 0 : "30px" }}
              >
                {sales_country && sales_country.length
                  ? `${sales_country}, `
                  : ""}
                {sales_city && sales_city.length ? `${sales_city}, ` : ""}
                {sales_postcode && sales_postcode.length
                  ? `${sales_postcode}`
                  : ""}
              </div>
            </div>
          ) : null}
          {hs_id && hs_id != "" ? (
            <div className={styles.row__more_wrapper}>
              <div className={styles.row__more_label}>{t("Head of Sales")}</div>
              <div className={styles.row__more_name}>
                {hs_first_name || hs_last_name ? (
                  <>
                    {hs_first_name} {hs_last_name}
                  </>
                ) : (
                  "-"
                )}
              </div>
              <div className={styles.row__more_id}>
                {hs_id && hs_id != "" ? <>ID {hs_id}</> : "-"}
              </div>
              <div className={styles.row__more_place}>
                {hs_country && hs_country.length ? `${hs_country}, ` : ""}
                {hs_city && hs_city.length ? `${hs_city}, ` : ""}
                {hs_postcode && hs_postcode.length ? `${hs_postcode}` : ""}
              </div>
            </div>
          ) : null}
          <div className={styles.row__more_wrapper}>
            <div className={styles.row__more_name}>
              <div className={styles.row__more_label}>{t("Name:")}</div>
              <div>
                {clients_first_name || clients_last_name ? (
                  <>
                    {clients_first_name} {clients_last_name}
                  </>
                ) : (
                  "-"
                )}
              </div>
              <div className={styles.row__more_name_id}>
                {clients_id && clients_id != "" ? <>ID {clients_id}</> : "-"}
              </div>
            </div>
            <div className={styles.row__more_email}>
              <div className={styles.row__more_label}>{t("E-mail:")}</div>
              <div>
                {clients_email && clients_email.length
                  ? `${clients_email}`
                  : ""}
              </div>
            </div>
            <div className={styles.row__more_address}>
              <div className={styles.row__more_label}>{t("Address:")}</div>
              <div>
                {clients_country && clients_country.length
                  ? `${clients_country}, `
                  : ""}
                {clients_city && clients_city.length ? `${clients_city}, ` : ""}
                {clients_postcode && clients_postcode.length
                  ? `${clients_postcode}`
                  : ""}
              </div>
            </div>

            <div className={styles.row__more_place}> </div>
            <div className={styles.row__more_place}> </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableElement;
