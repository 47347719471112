import React, { useState, useEffect, useContext } from "react";
import styles from "./Aside.module.scss";
import logo from "../../img/logoWhite.png";
// import logoWhite from "../../img/logoWhite.svg";
import AsideLink from "./components/AsideLink/AsideLink";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardIcon from "../../img/IconComponents/DashboardIcon";
import ViewPlantsIcon from "../../img/IconComponents/ViewPlantsIcon";
import BuyPlantsIcon from "../../img/IconComponents/BuyPlantsIcon";
import LocationIcon from "../../img/IconComponents/LocationIcon";
import FinancesIcon from "../../img/IconComponents/FinancesIcon";
import MallIcon from "../../img/IconComponents/MallIcon";
import CannabisIcon from "../../img/IconComponents/CannabisIcon";
import NewsIcon from "../../img/IconComponents/NewsIcon";
import GearIcon from "../../img/IconComponents/GearIcon";
import HelpIcon from "../../img/IconComponents/HelpIcon";
import HomeIcon from "../../img/IconComponents/HomeIcon";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Context } from "../..";
import { useObserver } from "mobx-react-lite";
import ShopIcon from "../../img/IconComponents/ShopIcon";
import { useParams } from "react-router-dom";
import Button from "../Button/Button";
import getRequest from "../../helpers/getRequest";
import { FRONT_URL } from "../../http";
import Cross from "../../img/IconComponents/Cross";
import copyIcon from "../../img/copy.svg";
import cn from "classnames";
import Bullhorn from "../../img/IconComponents/Bullhorn";

const Aside = () => {
  const location = useLocation();
  const [mlmLink, setMlmLink] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [userData, setUserData] = useState({});
  const [plantBalance, setPlantBalance] = useState(null);

  const [id, setId] = useState("");
  const [isMarketPlaceVisible, setIsMarketPlaceVisible] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { store } = useContext(Context);

  const { isSettingsChanged } = useObserver(() => ({
    isSettingsChanged: store.isSettingsChanged,
  }));

  useEffect(() => {
    getRequest("v1/referrals/my-mlm")
      .then((response) => setMlmLink(response.data?.mlm_link))
      .catch((error) => console.log(error));

    getRequest("v1/auth/whoami")
      .then((res) => {
        if (
          res.data.advertise_id?.includes("dect") ||
          res.data.advertise_id?.includes("welt") ||
          res.data.advertise_id?.includes("spiegel") ||
          res.tokens_paid ||
          res.data.is_user_under_hack
        ) {
          setIsMarketPlaceVisible(true);
        }
        setId(res.data?.id);
        setUserData(res.data);
        console.log("whoami", res.data);
      })
      .catch((err) => console.log(err));

    getRequest("v1/finances/data").then((res) => {
      setPlantBalance(+res.data.plants_count);
    });
  }, []);

  const changepageHandler = (path) => {
    if (isSettingsChanged) {
      store.setDestinationPath(path);
    } else navigate(path, { from: window.location.pathname });
  };

  const copyToClipboard = () => {
    const url = `${FRONT_URL}/register/ref/${id}`;
    navigator.clipboard.writeText(url);
    setIsCopied(true);
    const timer = setTimeout(() => {
      setIsCopied(false);
    }, [2000]);

    return () => {
      clearTimeout(timer);
    };
  };

  return (
    <>
      <aside className={styles.aside}>
        <div className={styles.aside__links}>
          <div
            className={styles.aside__logo}
            onClick={() => changepageHandler("/")}
          >
            <Link>
              <img src={logo} alt="logo" width={190} />
            </Link>
          </div>
          <div className={styles.aside__list}>
            <div onClick={() => changepageHandler("/")}>
              <AsideLink icon={HomeIcon} text={t("Start page")} />
            </div>

            {/* Personal area Category */}
            <p className={styles.aside__list_subtitle}>{t("Personal area")}</p>
            <div onClick={() => changepageHandler("/dashboard")}>
              <AsideLink
                icon={DashboardIcon}
                active={location.pathname.includes("/dashboard")}
                text={t("Overview")}
              />
            </div>
            <div
              onClick={() =>
                plantBalance > 0 ? changepageHandler("/view") : ""
              }
            >
              <AsideLink
                icon={ViewPlantsIcon}
                text={t("My tokens")}
                disabled={userData.tokens_paid == 0}
                active={location.pathname.includes("/view")}
              />
            </div>
            <div onClick={() => changepageHandler("/finances")}>
              <AsideLink
                icon={FinancesIcon}
                active={location.pathname.includes("/finances")}
                text={t("My finances")}
              />
            </div>

            {/* Buy/Sell Category */}
            <p className={styles.aside__list_subtitle}>{t("Buy/Sell")}</p>
            <div onClick={() => changepageHandler("/buy")}>
              <AsideLink
                icon={BuyPlantsIcon}
                text={t("Buy Tokens")}
                active={
                  location.pathname === "/checkout" ||
                  location.pathname === "/buy"
                }
              />
            </div>
            {/* <div onClick={() => console.log("/harvest")}>
              <AsideLink
                icon={CannabisIcon}
                text={t("My harvest")}
                disabled={true}
                active={location.pathname.includes("/harvest")}
              />
            </div> */}
            {isMarketPlaceVisible && (
              <div onClick={() => changepageHandler("/marketplace")}>
                <AsideLink
                  icon={MallIcon}
                  active={location.pathname.includes("/marketplace")}
                  text={t("Marketplace")}
                />
              </div>
            )}

            <div onClick={() => changepageHandler("/shop")}>
              <AsideLink
                icon={ShopIcon}
                active={location.pathname.includes("/shop")}
                text={t("Shop")}
              />
            </div>

            {/* Information Category */}
            <p className={styles.aside__list_subtitle}>{t("Information")}</p>

            <div onClick={() => changepageHandler("/news")}>
              <AsideLink
                icon={NewsIcon}
                active={location.pathname.includes("/news")}
                text={t("Recent news")}
              />
            </div>

            <div onClick={() => changepageHandler("/press")}>
              <AsideLink
                icon={Bullhorn}
                active={location.pathname.includes("/press")}
                text={t("Press")}
              />
            </div>

            <div onClick={() => changepageHandler("/help")}>
              <AsideLink
                icon={HelpIcon}
                active={location.pathname.includes("/help")}
                text={t("Help and FAQ")}
              />
            </div>

            <div onClick={() => changepageHandler("/location")}>
              <AsideLink
                icon={LocationIcon}
                active={location.pathname.includes("/location")}
                text={t("Location")}
              />
            </div>
          </div>
          {mlmLink && (
            <div className={styles.aside__dect}>
              <p>{t("Sell DECT-Tokens now and generate passive income")}</p>
              <Button
                to={mlmLink}
                text="To DECT Distribution Website"
                color="dect"
                blank
              />
            </div>
          )}

          <div className={styles.aside__referral}>
            <p>{t("Invite friends and receive 0.05 DECT worth €125.")}</p>
            <Button
              text="Invite Now"
              color="referral"
              onClick={() => setOpenModal(true)}
            />
          </div>
        </div>
        <div className={styles.aside__adds}>
          <div onClick={() => changepageHandler("/settings")}>
            <AsideLink
              icon={GearIcon}
              active={location.pathname.includes("/settings")}
              text={t("Settings")}
            />
          </div>
        </div>
      </aside>
      {openModal ? (
        <div className={styles.modal}>
          <div className={styles.modal__inner}>
            <button
              className={styles.modal__inner_close}
              onClick={() => setOpenModal(false)}
            >
              <Cross />
            </button>
            <div className={styles.modal__inner_title}>
              {t("Your referral")}
            </div>
            <div className={styles.modal__inner_wrapper}>
              <div
                className={styles.modal__inner_code}
              >{`${FRONT_URL}/register/ref/${id}`}</div>
              <button
                className={styles.modal__copy}
                onClick={() => copyToClipboard()}
              >
                <img src={copyIcon} alt="copy" />
              </button>
            </div>
            <div className={styles.modal__copy_container}>
              <div
                className={cn(
                  styles.modal__copy_state,
                  isCopied ? styles.modal__copy_state_active : ""
                )}
              >
                {t("Copied to clipboard")}
              </div>
            </div>
            <div
              className={styles.modal__btn}
              onClick={() => copyToClipboard()}
            >
              {t("Copy referral link and share it with friends")}
            </div>
            <p className={styles.modal__inner_helper}>
              {t(
                "Refer new customers and receive 0.05 DECT, valued at €125, for each full DECT token purchased by the referred customer. The reward is only granted if the order is not canceled or refunded."
              )}
            </p>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Aside;
