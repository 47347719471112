import React from "react";
import styles from "./Button.module.scss";
import { Link } from "react-router-dom";
import cn from "classnames";
import { useTranslation } from 'react-i18next'

const Button = ({
  to = "",
  text,
  color = "default",
  disabled = false,
  icon = null,
  onClick,
  blank = false
}) => {
  const { t } = useTranslation()
  return (
    <Link
      to={to}
      className={cn(
        styles.button,
        styles[color],
        disabled ? styles.button__disabled : ""
      )}
      onClick={onClick} 
      state={{from:window.location.pathname}}
      target={blank ? '_blank' : '_self'}
    >
      {icon !== null && <img src={icon} alt="icon" />}
      {t(text)}
    </Link>
  );
};

export default Button;
