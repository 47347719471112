import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './Table.module.scss'

const Table = ({ currentTab, tableData }) => {
  const { t } = useTranslation()
  return (
    <div className={styles.table}>
      <div className={styles.table__header}>
        <div className={styles.table__date}>{t('Date')}</div>
        {currentTab === 1 ? (
          <>
            <div className={styles.table__harvest}>{t('Harvest Amount')}</div>
            <div className={styles.table__amount}>{t('EUR Amount')}</div>
          </>
        ) : (
          <>
            <div className={styles.table__amount__euro}>{t('Result')}</div>
            <div className={styles.table__iban}>IBAN</div>
          </>
        )}
      </div>
      {currentTab === 1
        ? tableData?.map((item, i) => {
            const { sale_date, sale_price, grams_sold, id } = item
            return (
              <div className={styles.table__row} key={id}>
                <div className={styles.table__date}>
                  {new Date(sale_date).toLocaleDateString()}
                </div>
                <div className={styles.table__harvest}>{grams_sold} g</div>
                <div className={styles.table__amount}>
                  {(grams_sold * sale_price).toString()}
                </div>
              </div>
            )
          })
        : tableData?.map((item, i) => {
            const { request_date, iban, amount, id } = item
            return (
              <div className={styles.table__row} key={id}>
                <div className={styles.table__date}>
                  {new Date(request_date).toLocaleDateString()}
                </div>
                <div className={styles.table__amount__euro}>{amount} EUR</div>
                <div className={styles.table__iban}>{iban}</div>
              </div>
            )
          })}
    </div>
  )
}

export default Table
