/** @format */

import React, {
  useEffect,
} from 'react';
import styles from './BuyPlants.module.scss';
import Header from '../../components/Header/Header';
import Aside from '../../components/Aside/Aside';
import Main from './Components/Main/Main';
import FooterClient from '../../components/FooterClient/FooterClient';
import { useLocation } from 'react-router-dom';

const BuyPlants = ({
  amountPlants,
  setAmountPlants,
  sumOfPlants,
  setSumOfPlants,
  enableHarvestSelling,
  setEnableHarvestSelling,
  setPeriodicityHarvestSelling,
  periodicityHarvestSelling,
  currentLang,
  setCurrentLang,
}) => {
  const location = useLocation();
  
  useEffect(() => {
    if (
      ['/buy', '/ad/dect','/ad/start', '/checkout'].includes(
        location.pathname,
      )
    ) {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js',
        });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src =
          'https://www.googletagmanager.com/gtm.js?id=' +
          i +
          dl;
        f.parentNode.insertBefore(j, f);
      })(
        window,
        document,
        'script',
        'dataLayer',
        'GTM-KZ9PHQ59',
      );
    }
  });

  return (
    <>
      <div className={styles.wrapper}>
        <Aside />
        <div className={styles.block}>
          <Header
            currentLang={currentLang}
            setCurrentLang={setCurrentLang}
          />
          <Main
            amountPlants={amountPlants}
            setAmountPlants={setAmountPlants}
            sumOfPlants={sumOfPlants}
            setSumOfPlants={setSumOfPlants}
            enableHarvestSelling={
              enableHarvestSelling
            }
            setEnableHarvestSelling={
              setEnableHarvestSelling
            }
            setPeriodicityHarvestSelling={
              setPeriodicityHarvestSelling
            }
            periodicityHarvestSelling={
              periodicityHarvestSelling
            }
          />
          <div className={styles.footer}>
            <FooterClient />
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyPlants;
