import React from "react";
import styles from "./AsideLink.module.scss";
import { Link } from "react-router-dom";
import cn from "classnames";
import { useLocation } from "react-router-dom";

const AsideLink = ({
  path,
  text,
  icon: Icon,
  disabled = false,
  active = false,
  ...props
}) => {
  const location = useLocation();
  return (
    <Link
      to={path}
      state={{ from: location.pathname }}
      className={cn(
        styles.link,
        disabled ? styles.disabled : "",
        active ? styles.active : ""
      )}
      {...props}
    >
      {Icon && <Icon></Icon>}
      <span>{text}</span>
    </Link>
  );
};

export default AsideLink;
