import React from "react";
import styles from "./Press.module.scss";
import Header from "../../components/Header/Header";
import HeaderCommon from "../../components/HeaderCommon/HeaderCommon";
import Aside from "../../components/Aside/Aside";
import Main from "./components/Main";
import FooterClient from "../../components/FooterClient/FooterClient";
import FooterCommon from "../../components/FooterCommon/FooterCommon";

const Press = ({ currentLang, setCurrentLang, user }) => {
  return (
    <div className={styles.wrapper}>
      {user ? <Aside /> : null}
      <div className={styles.container}>
        {user ? (
          <Header currentLang={currentLang} setCurrentLang={setCurrentLang} />
        ) : (
          <HeaderCommon
            currentLang={currentLang}
            setCurrentLang={setCurrentLang}
          />
        )}
        {user ? (
          <div className={styles.mainWrapper}>
            <Main currentLang={currentLang} user={user} />
          </div>
        ) : (
          <Main currentLang={currentLang} user={user} />
        )}
        {user ? (
          <div className={styles.footer}>
            <FooterClient />
          </div>
        ) : (
          <FooterCommon />
        )}
      </div>
    </div>
  );
};

export default Press;
