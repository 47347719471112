import React, { useState, useEffect } from "react";
import styles from "./PasswordReset.module.scss";
import "../../styling/common.css";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import passReset from "../../img/passReset.webp";
import HeaderCommon from "../../components/HeaderCommon/HeaderCommon";
import FooterCommon from "../../components/FooterCommon/FooterCommon";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";

import { checkEmail } from "../../helpers/validation";
import { useTranslation } from "react-i18next";
import postRequest from "../../helpers/postRequest";

const PasswordReset = ({ currentLang, setCurrentLang }) => {
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [allowSubmit, setAllowSubmit] = useState("");
  const [errorForm, setErrorForm] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const sendEmailToRecovery = () => {
    setIsLoad(true);
    postRequest(
      "v1/auth/forgot-password",
      { email: email },
      "application/json",
      localStorage.getItem("i18n").toLowerCase()
    )
      .then((response) => {
        setIsLoad(false);
        navigate("/check-email-reset");
      })
      .catch((e) => {
        setIsLoad(false);
        if (e.response?.status === 400) {
          setErrorForm([t("Link was already sent to users account")]);
        }
        if (e.response?.status === 401) {
          setErrorForm([e.response?.data.message]);
        }
        if (e.response?.status === 404) {
          setErrorForm([t("User with this email not found")]);
        }
      });
  };

  useEffect(() => {
    if (email !== "" && !errorEmail.length) {
      setAllowSubmit(true);
    } else {
      setAllowSubmit(false);
    }
  }, [email, errorEmail]);

  useEffect(() => {
    if (isLoad) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [isLoad]);

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      {loader ? <Loader /> : null}
      <HeaderCommon currentLang={currentLang} setCurrentLang={setCurrentLang} />
      <section className={styles.reset}>
        <div className="container">
          <div className={styles.reset__wrapper}>
            <div className={styles.reset__left}>
              <>
                <h2 className={styles.reset__title}>{t("Reset password")}</h2>
                <form className={styles.reset__form}>
                  <Input
                    label={t("Your registration email")}
                    type="email"
                    name="email"
                    placeholder={t("email")}
                    value={email}
                    onChange={(e) =>
                      checkEmail(e.target.value, setEmail, setErrorEmail)
                    }
                    error={errorEmail}
                  />
                  <div className={styles.reset__form_buttons}>
                    <Button text={t("Back")} onClick={() => navigate(-1)} />
                    <Button
                      text={t("Submit")}
                      color="green"
                      onClick={() => sendEmailToRecovery()}
                      disabled={!allowSubmit}
                    />
                  </div>
                </form>
                {errorForm && errorForm.length ? (
                  <div className={styles.reset__form_errors}>
                    {errorForm?.map((item, index) => {
                      return (
                        <div className={styles.reset__form_error} key={index}>
                          {item === "Unauthorized"
                            ? t("Current email is doesn't exist")
                            : t(item)}
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </>
            </div>
            <div
              className={styles.reset__right}
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <img src={passReset} alt="passReset" />
            </div>
          </div>
        </div>
      </section>
      <FooterCommon />
    </>
  );
};

export default PasswordReset;
