import styles from "./Modules.module.scss";
import { useTranslation } from "react-i18next";
import moduleInfo1 from "../../../../img/module-info1.webp";
import moduleInfo2 from "../../../../img/module-info2.webp";
import moduleMain from "../../../../img/module-main.webp";
import module1 from "../../../../img/module1.svg";
import module2 from "../../../../img/module2.svg";
import module3 from "../../../../img/module3.svg";
import module4 from "../../../../img/module4.svg";
import module5 from "../../../../img/module5.svg";

const modules = [
  {
    id: 1,
    icon: module1,
    color: "#BD1522",
  },
  {
    id: 2,
    icon: module2,
    color: "#0C8E38",
  },
  {
    id: 3,
    icon: module3,
    color: "#0C8E38",
  },
  {
    id: 4,
    icon: module4,
    color: "#0C8E38",
  },
  {
    id: 5,
    icon: module5,
    color: "#BD1522",
  },
];

const Module = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.module}>
      <h2 className={styles.module__title}>
        {t("Our cultivation module")}
      </h2>
      <p className={styles.module__subtitle}>
        {t("Use of the latest technology: efficient and safe")}
      </p>
      <div className={styles.module__wrapper}>
        <div className="container">
          <div className={styles.module__inner}>
            <div className={styles.module__inner_info}>
              <picture>
                <source srcSet={moduleInfo1} type="image/webp" />
                <img src={moduleInfo1} alt="info1" width={200} />
              </picture>
              <picture>
                <source srcSet={moduleInfo2} type="image/webp" />
                <img src={moduleInfo2} alt="info2" width={200} />
              </picture>
            </div>
            <picture>
              <source srcSet={moduleMain} type="image/webp" />
              <img src={moduleMain} alt="main" height={366} />
            </picture>
            <div className={styles.module__inner_modules}>
              {modules.map((module) => (
                <div key={module.id}>
                  <img src={module.icon} alt={`module-${module.id}`} />
                  <div style={{ backgroundColor: module.color }}></div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Module;
