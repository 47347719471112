import React, { useState, useEffect } from "react";
import styles from "./Main.module.scss";
import SectionTitle from "../../../../components/SectionTitle/SectionTitle";
import cn from "classnames";

import { useTranslation } from "react-i18next";
import NoPlants from "../../../../components/NoPlants/NoPlants";
import getRequest from "../../../../helpers/getRequest";
import Table from "../../../Finances/Components/Table/Table";
import PaymentTable from "../../../Finances/Components/PaymentTable/PaymentTable";
import Webcam from "../../../Dashboard/Components/Webcam/Webcam";

const Main = () => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(1);
  const [plants, setPlants] = useState([]);

  useEffect(() => {
    getRequest("v1/finances/data")
      .then((response) => {
        setPlants(response.data.plantsDetails);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 840);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 840);
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <section className={styles.main}>
      {/* <div className={styles.main__tabs}>
        <button
          className={cn(
            styles.main__tabs_tab,
            tab === 1 ? styles.main__tabs_tab_active : '',
          )}
          onClick={() => setTab(1)}
        >
          <ListIcon color={tab === 1 ? '#0F131E' : '#B3B3B3'} />
        </button>
        <button
          className={cn(
            styles.main__tabs_tab,
            tab === 2 ? styles.main__tabs_tab_active : '',
          )}
          onClick={() => setTab(2)}
        >
          <SquaresIcon color={tab === 2 ? '#0F131E' : '#B3B3B3'} />
        </button>
      </div> */}

      {/* {plants && plants.length ? (
        isMobile ? (
          <div
            className={cn(styles.main__wrapper, styles.main__wrapper_blocksMob)}
          >
            {plants.map((item) => (
              <PlantCard
                key={item.id}
                smallCard={true}
                mobile={true}
                id={item.id}
                info={item.section}
              />
            ))}
            <Table data={plants} isView/>
          </div>
        ) : (
          <div
            className={cn(
              styles.main__wrapper,
              tab === 2 ? styles.main__wrapper_blocks : ""
            )}
          >
            <Table data={plants} isView/>
            {plants.map((item) => (
              <PlantCard
                key={item.id}
                smallCard={tab === 2 ? true : false}
                id={item.id}
                info={item.section}
              />
            ))}
          </div>
        )
      ) : ( */}
      <div className={styles.main__noplants_container}>
        {/* <NoPlants /> */}
        <Webcam />
      </div>
      {/* )} */}
      <br />
      <br />

      <PaymentTable statuses={["PENDING"]} title={t("Open orders")} />
      <PaymentTable statuses={["PAID_OUT", "COMPLETED"]} title={t("Completed orders")} />
    </section>
  );
};

export default Main;
