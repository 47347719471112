/** @format */

import React, {
  useState,
  useEffect,
  useRef,
} from 'react';
import styles from './HarvestCalculator.module.scss';
import AOS from 'aos';
import 'aos/dist/aos.css';
import nft from '../../img/nftDesign2.webp';
import WaitBoyIcon from '../../img/loading/waitBOY.png';
import SuccessImage from '../../img/loading/Group 1.png';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '../..//img/close.svg';
import { API_URL } from '../../http';
import axios from 'axios';

import ModalNotBlured from '../../components/ModalNotBlured/ModalNotBlured';
const HarvestCalculatorMock = ({
  reloadData,
  harvest,
  max,
  currentLang,
}) => {
  const { t } = useTranslation();
  const [amount, setAmount] = useState(200);

  const [min, setMin] = useState(25);
  const [
    isCancelModalOpen,
    setIsCancelModalOpen,
  ] = useState(false);
  const modalRef = useRef(isCancelModalOpen);
  modalRef.current = isCancelModalOpen;
  const [
    isSuccessModalOpen,
    setIsSuccessModalOpen,
  ] = useState(false);

  const sellHarvest = () => {
    if (modalRef.current) {
      axios({
        method: 'post',
        url: `${API_URL}/v1/harvest/sell`,
        data: { amount },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem(
            'token',
          )}`,
        },
      }).then(async () => {
        reloadData();

        setIsSuccessModalOpen(true);
        setTimeout(() => {
          setIsSuccessModalOpen(false);
        }, 2000);
      });
    }
    setIsCancelModalOpen(false);
  };

  useEffect(() => {
    reloadData();

    AOS.init();
  }, []);
  return (
    <section
      className={styles.calculate}
      id='rechner'>
      <div className={styles.calculator}>
        <div
          className='fullContainer'
          style={{ paddingInline: '1rem' }}>
          <div
            className={styles.calculator__inner}>
            <div
              className={styles.calculator__body}>
              <div className={styles.blur}></div>

              <div
                className={
                  styles.calculator__body_amount
                }
                data-aos='fade-left'
                data-aos-duration='1000'>
                <div
                  className={
                    styles.calculator__body_amount__leaf
                  }>
                  <img src={nft} alt='token' />
                </div>

                <h3>
                  {t(
                    'Select how much harvest you want to sell',
                  )}
                </h3>
              </div>

              <div
                className={
                  styles.calculator__body_slider
                }>
                <div
                  className={
                    styles.calculator__body_slider__value
                  }>
                  <div
                    onClick={() => {
                      setAmount((prev) =>
                        Number(
                          +prev <= min
                            ? +prev
                            : +prev - 25,
                        ).toFixed(1),
                      );
                    }}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'>
                      <circle
                        cx='12'
                        cy='12'
                        r='11.5'
                        stroke='#509C5A'
                      />
                      <rect
                        x='5.48572'
                        y='11.6572'
                        width='13.7143'
                        height='1.37143'
                        fill='#509C5A'
                      />
                    </svg>
                  </div>
                  <div
                    className={
                      styles.calculator__body_total
                    }>
                    <input
                      value={amount}
                      onChange={(e) => {
                        setAmount(
                          +e.target.value,
                        );
                      }}
                    />
                    <> g</>
                  </div>
                  <div
                    onClick={() => {
                      setAmount((prev) =>
                        Number(
                          +prev >= 500
                            ? +prev
                            : +prev + 25,
                        ).toFixed(1),
                      );
                    }}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'>
                      <circle
                        cx='12'
                        cy='12'
                        r='11.5'
                        stroke='#509C5A'
                      />
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M13.0286 11.6571V5.48571L11.6571 5.48571V11.6571H5.48571V13.0286H11.6571V19.2H13.0286V13.0286H19.2V11.6571H13.0286Z'
                        fill='#509C5A'
                      />
                    </svg>
                  </div>
                </div>

                <p
                  className={
                    styles.calculator__harvest
                  }>
                  {t('Your harvest: ')}
                  {currentLang.lang === 'DE'
                    ? (0)
                        .toFixed(1)
                        .toString()
                        .replace('.', ',')
                    : (0).toFixed(1)}{' '}
                  g
                </p>

                <div
                  className={
                    styles.calculator__body_slider__container
                  }>
                  <input
                    type='range'
                    min={min}
                    max={500}
                    value={Number(amount).toFixed(
                      1,
                    )}
                    step='25'
                    onChange={(e) => {
                      setAmount(
                        Number(
                          +e.target.value,
                        ).toFixed(1),
                      );
                    }}
                    className={styles.slider}
                  />
                  <div
                    className={styles.max}
                    onClick={() => {
                      setAmount(
                        Number(500).toFixed(1),
                      );
                    }}>
                    max
                  </div>
                </div>
              </div>

              <div
                className={
                  styles.calculator__body_scenario
                }>
                <div
                  className={
                    styles.calculator__body_scenario__info
                  }>
                  <div>
                    <p
                      className={
                        styles.calculator__body_scenario__info_header
                      }>
                      {t('Proceeds of your harvest:')}
                    </p>
                    <p
                      className={
                        styles.calculator__body_scenario_amount
                      }>
                      {amount * 2.5} €
                    </p>
                    <p
                      style={
                        {marginTop:'12px'}
                      }
                      className={
                        styles.calculator__body_scenario_price
                      }>
                      {t(
                        'Price per 1 gram: 2.50 €',
                      )}
                    </p>
                  </div>
                </div>
              </div>

              <div
                className={
                  styles.calculator__body_power
                }>
                {t('Sell harvest')}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isCancelModalOpen && (
        <ModalNotBlured
          text={'Cancel'}
          onClose={() => {
            setIsCancelModalOpen(false);
          }}>
          <div className={styles.modal}>
            <img
              onClick={() => {
                setIsCancelModalOpen(false);
              }}
              className={styles.modal_close}
              src={CloseIcon}
            />

            <h3>{t('In progress...')}</h3>
            <p>
              {t(
                'Please wait while we check your harvest data',
              )}
            </p>
            <img src={WaitBoyIcon} />
            <button
              className={styles.button}
              onClick={() => {
                setIsCancelModalOpen(false);
              }}>
              {t('Cancel')}
            </button>
          </div>
        </ModalNotBlured>
      )}
      {isSuccessModalOpen && (
        <ModalNotBlured
          onClose={() => {
            setIsSuccessModalOpen(false);
          }}>
          <div className={styles.modal}>
            <img
              onClick={() => {
                setIsSuccessModalOpen(false);
              }}
              className={styles.modal_close}
              src={CloseIcon}
            />
            <h3>{t('Success!')}</h3>
            <p>
              {t(
                'Our deal is done. Now you can check your balance!',
              )}
            </p>
            <img src={SuccessImage} />
            {/* <button
              className={styles.button_success}
              onClick={() => {
                setIsSuccessModalOpen(false);
              }}>
              Check your balance
            </button> */}
          </div>
        </ModalNotBlured>
      )}
    </section>
  );
};

export default HarvestCalculatorMock;
