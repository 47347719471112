import styles from "./DectToken.module.scss";
import HeaderCommon from "../../components/HeaderCommon/HeaderCommon";
import FooterCommon from "../../components/FooterCommon/FooterCommon";

import tokenNew from "../../img/tokenNew.webp";
import checkCircle from "../../img/check-circle.svg";
import advantages1 from "../../img/advantages1.svg";
import advantages2 from "../../img/advantages2.svg";
import advantages3 from "../../img/advantages3.svg";
import advantages4 from "../../img/advantages4.svg";
import advantages5 from "../../img/advantages5.svg";
import canabis from "../../img/canabis.svg";

import { useTranslation } from "react-i18next";
import { Banner } from "../MainPage/Components";
const DectToken = ({ currentLang, setCurrentLang }) => {
  const { t } = useTranslation();

  return (
    <>
      <HeaderCommon currentLang={currentLang} setCurrentLang={setCurrentLang} />
      <main className={styles.dectToken}>
        <section className={styles.hero}>
          <div className="container">
            <div className={styles.hero__inner}>
              <div className={styles.hero__left}>
                <h1>{t("DECT-Token")}</h1>
                <p>
                  {t(
                    "The “Deutscher Cannabis Token” is the world's first crypto asset linked to real cannabis plants. As soon as cannabis cultivation starts at our location, DECT holders can benefit from the harvest yields of the cannabis plants through issued NFT."
                  )}
                </p>
                <ul>
                  <li>
                    <img src={checkCircle} alt="checkmark" width={32} />
                    <p>{t("Link with real cannabis plants")}</p>
                  </li>
                  <li>
                    <img src={checkCircle} alt="checkmark" width={32} />
                    <p>{t("Limited to 200,000 DECT tokens")}</p>
                  </li>
                  <li>
                    <img src={checkCircle} alt="checkmark" width={32} />
                    <p>{t("No team tokens, no sales pressure")}</p>
                  </li>
                </ul>
              </div>
              <img src={tokenNew} alt="token" width={486} />
            </div>
          </div>
        </section>
        <section className={styles.stats}>
          <div className="container">
            <div className={styles.stats__inner}>
              <h2 className={styles.title}>
                {t("The only Cannabis Token with Real Values")}
              </h2>
              <p className={styles.subtitle}>
                {t(
                  "The German Cannabis Token (DECT) has a direct link to real cannabis plants and thus to the real economy. The real added value of the cannabis plants gives the DECT a unique value that will continue to correlate positively with market developments in the future."
                )}
              </p>
              <div className={styles.stats__inner_blocks}>
                <div>
                  <h3>200K</h3>
                  <span>{t("Total Supply")}</span>
                </div>
                <div>
                  <h3>180K</h3>
                  <span>{t("Public Sale")}</span>
                </div>
                <div>
                  <h3>$530M+</h3>
                  <span>{t("Initial Market Capitalization")}</span>
                </div>
                <div>
                  <h3>€2500</h3>
                  <span>{t("Initial Token Price")}</span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={styles.advantages}>
          <div className="container">
            <div className={styles.advantages__inner}>
              <h2 className={styles.title}>
                {t("The Main Advantages of DECT")}
              </h2>
              <p className={styles.subtitle}>
                {t(
                  "The German Cannabis Token (DECT) is the world's first token linked to real cannabis plants. Here you can see an overview of the unique advantages"
                )}
              </p>
              <div className={styles.advantages__inner_list}>
                <div>
                  <img src={advantages1} alt="advantages-1" width={70} />
                  <h3>{t("Link to Real Cannabis Plants")}</h3>
                  <p>
                    {t(
                      "Each DECT is assigned directly to a cannabis plant via an NFT. DECT token holders benefit permanently from the yields of the cannabis plants."
                    )}
                  </p>
                </div>
                <div>
                  <img src={advantages2} alt="advantages-2" width={70} />
                  <h3>{t("Harvest Yields for Token Holders")}</h3>
                  <p>
                    {t(
                      "DECT holders receive cannabis plant yields via NFTs. Harvest yields in grams can be collected at local cannabis clubs or can be used in another way. The plants are harvested 4 times a year."
                    )}
                  </p>
                </div>
                <div>
                  <img src={advantages3} alt="advantages-3" width={70} />
                  <h3>{t("Limited to 200k")}</h3>
                  <p>
                    {t(
                      "DECT is limited to 200,000 tokens, 180,000 of which will be sold in the ICO. No team, marketing or advisors token! No selling pressure! The actual use and buybacks will increase the scarcity."
                    )}
                  </p>
                </div>
              </div>
              <div className={styles.advantages__inner_list}>
                <div>
                  <img src={advantages4} alt="advantages-4" width={70} />
                  <h3>{t("Legalization")}</h3>
                  <p>
                    {t(
                      "Germany has legalized cannabis in 2024 and we have already positioned ourselves as the largest infrastructure provider for cannabis clubs. Our unique concept adds value to the DECT."
                    )}
                  </p>
                </div>
                <div>
                  <img src={advantages5} alt="advantages-5" width={70} />
                  <h3>{t("Listings")}</h3>
                  <p>
                    {t(
                      "DECT will be listed on international crypto exchanges with the launch. We will announce the first CEX listings during the ICO. We are targeting at least t2 exchanges, but primarily t1 exchange listings."
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={styles.mission}>
          <div className="container">
            <div className={styles.mission__inner}>
              <div className={styles.mission__inner_left}>
                <h2 className={styles.title}>{t("What is our mission?")}</h2>
                <p>
                  {t(
                    "We are Germany's largest infrastructure provider for cannabis social clubs. With our unique location, we can accommodate many clubs and provide them with the necessary infrastructure for cannabis cultivation. In the long term, we will also be active in the medical cannabis sector and will apply for model projects to be able to grow cannabis commercially. This will give our community the opportunity to participate in this gigantic market. Once the grow site is fully developed, DECT holders will receive the yields of their cannabis plants through their NFTs - with no term or end. The DECT is intended to be the link between cannabis cultivation and the international crypto market."
                  )}
                </p>
              </div>
              <ul>
                <li>
                  <img src={canabis} alt="cannabis" width={24} height={24} />
                  <p>{t("DECT corresponds to a real cannabis plant")}</p>
                </li>
                <li>
                  <img src={canabis} alt="cannabis" width={24} height={24} />
                  <p>{t("Unique and community-friendly tokenomics")}</p>
                </li>
                <li>
                  <img src={canabis} alt="cannabis" width={24} height={24} />
                  <p>{t("180,000 DECT = 180,000 real cannabis plants")}</p>
                </li>
                <li>
                  <img src={canabis} alt="cannabis" width={24} height={24} />
                  <p>{t("Listing on international crypto exchanges")}</p>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className={styles.skills}>
          <div className="container">
            <div className={styles.skills__inner}>
              <div className={styles.skills__inner_left}>
                <div>
                  <h3>{t("Blockchain experience and expertise")}</h3>
                  <span>100%</span>
                </div>
                <div>
                  <h3>{t("Production of CBD and cannabis products")}</h3>
                  <span>100%</span>
                </div>
                <div>
                  <h3>{t("Cannabis cultivation")}</h3>
                  <span>100%</span>
                </div>
                <div>
                  <h3>{t("AI experience and expertise")}</h3>
                  <span>95%</span>
                </div>
              </div>
              <div className={styles.skills__inner_right}>
                <h2 className={styles.title}>{t("Our Skills")}</h2>
                <p>
                  {t(
                    "We have years of experience in the trading and production of CBD and cannabis as well as blockchain technology. In addition, CeBiol Blockchain Solutions GmbH is already developing blockchain-based systems with CeBioLabs, which we will also use for our company. This experience will be fully integrated into our company and make the DECT a leading crytoproject in the cannabis sector."
                  )}
                </p>
              </div>
            </div>
          </div>
        </section>
        <Banner />
      </main>
      <FooterCommon />
    </>
  );
};

export default DectToken;
