import React from "react";
import styles from "./Pagination.module.scss";
import ArrowDropdown from "../../img/IconComponents/ArrowDropdown";
import ReactPagination from "react-js-pagination";

const Pagination = ({ page, perPage, totalItems, setPage }) => {
  return (
    <div className={styles.pagination}>
      <ReactPagination
        activePage={page}
        itemsCountPerPage={parseInt(perPage)}
        totalItemsCount={totalItems ? parseInt(totalItems) : 0}
        pageRangeDisplayed={5}
        onChange={setPage}
        hideFirstLastPages={true}
        prevPageText={<ArrowDropdown color="#509C5B" rotate="90deg" />}
        nextPageText={<ArrowDropdown color="#509C5B" rotate="270deg" />}
        itemClass={styles.pagination__button}
        linkClass={styles.pagination__button__text}
        activeClass={styles.pagination__button__active}
        disabledClass={styles.pagination__button__disabled}
      />
    </div>
  );
};

export default Pagination;
