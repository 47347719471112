import React from 'react'
import styles from './Notification.module.scss'
import Noty from '../../../../img/IconComponents/Noty';
import cn from 'classnames';

const Notification = ({ text, className }) => {
  return (
    <div className={styles.notification}>
      <Noty />
      <div className={cn(styles.notification__text, styles[className])}>
        {text}
      </div>
    </div>
  )
}

export default Notification;