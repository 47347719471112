
import React, {
  useState,
  useEffect,
} from 'react';
import styles from './ResetPassModal.module.scss';
import Cross from '../../../../img/IconComponents/Cross';
import Button from '../../../../components/Button/Button';
import Input from '../../../../components/Input/Input';
import {
  checkPass,
  checkPasswordAgain,
} from '../../../../helpers/validation';
import axios from 'axios';
import { API_URL } from '../../../../http';
import Check from '../../../../img/IconComponents/Check';
import Triangle from '../../../../img/IconComponents/Triangle';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import postRequest from '../../../../helpers/postRequest';
import { useContext } from 'react';
import { Context } from '../../../..';

const ResetPassModal = ({ close }) => {
  const [oldPassword, setOldPassword] =
    useState('');
  const [errorOldPassword, setErrorOldPassword] =
    useState('');
  const [newPassword, setNewPassword] =
    useState('');
  const [errorNewPassword, setErrorNewPassword] =
    useState('');
  const [
    repeatNewPassword,
    setRepeatNewPassword,
  ] = useState('');
  const [
    errorRepeatNewPassword,
    setErrorRepeatNewPassword,
  ] = useState('');
  const [isSuccessChanged, setIsSuccessChanged] =
    useState(false);
  const [isErrorChanged, setIsErrorChanged] =
    useState(false);
  const [serverError, setServerError] = useState(
    [],
  );
  const [allowSubmit, setAllowSubmit] =
    useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { store } = useContext(Context);

  const changePass = () => {
    postRequest('v1/user/change-password', {
      oldPassword: oldPassword,
      newPassword: newPassword,
    })
      // axios({
      //   method: 'post',
      //   url: `${API_URL}/v1/user/change-password`,
      //   headers: {
      //     Authorization: `Bearer ${localStorage.getItem('token')}`,
      //     'Content-Type': 'application/json',
      //     Accept: 'application/json',
      //   },
      //   data: {
      //     oldPassword: oldPassword,
      //     password: newPassword,
      //   },
      // })
      .then((response) => {
        console.log(response);
        if (response?.status === 201) {
          setIsSuccessChanged(true);
          setIsErrorChanged(false);
          store.logout();
        } else {
          setIsSuccessChanged(false);
          setIsErrorChanged(true);
        }
      })
      .catch((e) => {
        if (e.response.status === 400) {
          setErrorOldPassword(
            e.response.data.message,
          );
        } else if (
          e.response.status !== 201 ||
          e.response.status !== 200
        ) {
          setIsSuccessChanged(false);
          setIsErrorChanged(true);
          setServerError([
            ...serverError,
            e.response.data.message,
          ]);
        }
      });
  };

  useEffect(() => {
    if (
      oldPassword !== '' &&
      !errorOldPassword.length &&
      newPassword !== '' &&
      !errorNewPassword.length &&
      repeatNewPassword !== '' &&
      !errorRepeatNewPassword.length
    ) {
      setAllowSubmit(true);
    } else {
      setAllowSubmit(false);
    }
  }, [
    oldPassword,
    errorOldPassword,
    newPassword,
    errorNewPassword,
    repeatNewPassword,
    errorRepeatNewPassword,
  ]);

  useEffect(() => {
    if (
      newPassword !== repeatNewPassword &&
      repeatNewPassword.length > 0
    ) {
      setErrorRepeatNewPassword(
        'Passwords must match',
      );
    } else {
      setErrorRepeatNewPassword('');
    }
  }, [newPassword, repeatNewPassword]);

  return (
    <div className={styles.modal}>
      {isSuccessChanged === false &&
        isErrorChanged === false && (
          <div className={styles.modal__inner}>
            <button
              className={styles.modal__close}
              onClick={() => close(false)}>
              <Cross />
            </button>
            <h3>{t('Change Password')}</h3>
            <Input
              type='password'
              label={t('Current password')}
              placeholder='••••••••••'
              value={oldPassword}
              onChange={(e) =>
                checkPass(
                  e.target.value,
                  setOldPassword,
                  setErrorOldPassword,
                )
              }
              error={errorOldPassword}
            />
            <Input
              type='password'
              label={t('New password')}
              placeholder='••••••••••'
              error={errorNewPassword}
              value={newPassword}
              onChange={(e) =>
                checkPass(
                  e.target.value,
                  setNewPassword,
                  setErrorNewPassword,
                )
              }
            />
            <Input
              type='password'
              label={t('Confirm password')}
              placeholder='••••••••••'
              error={errorRepeatNewPassword}
              value={repeatNewPassword}
              onChange={(e) =>
                checkPasswordAgain(
                  e.target.value,
                  newPassword,
                  setRepeatNewPassword,
                  setErrorRepeatNewPassword,
                )
              }
            />
            <Button
              text={t('Submit')}
              color='green'
              onClick={changePass}
              disabled={!allowSubmit}
            />
            <span
              onClick={() =>
                navigate('/reset-password')
              }>
              {t('I don’t remember the password')}
            </span>
          </div>
        )}
      {isSuccessChanged === true &&
        isErrorChanged === false && (
          <div className={styles.modal__success}>
            <button
              className={styles.modal__close}
              onClick={() => close(false)}>
              <Cross />
            </button>
            <h2>
              {t('Password changed successfully')}
            </h2>
            <Check />
          </div>
        )}
      {isSuccessChanged === false &&
        isErrorChanged === true && (
          <div className={styles.modal__error}>
            <button
              className={styles.modal__close}
              onClick={() => close(false)}>
              <Cross color='#CA4B15' />
            </button>
            <h2>{t('Something went wrong')}</h2>
            {serverError && serverError.length ? (
              <div
                className={
                  styles.modal__error_errors
                }>
                {serverError?.map(
                  (item, index) => {
                    return (
                      <div key={index}>
                        {item}
                      </div>
                    );
                  },
                )}
              </div>
            ) : null}
            <Triangle />
          </div>
        )}
    </div>
  );
};

export default ResetPassModal;
