import React, { memo } from "react";

const Check = memo(({ color = "#509c5b" }) => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M91.6667 46.1667V50C91.6615 58.9851 88.7521 67.7278 83.3722 74.9243C77.9923 82.1207 70.4303 87.3853 61.814 89.9329C53.1976 92.4805 43.9886 92.1746 35.5603 89.0608C27.132 85.9469 19.936 80.1921 15.0456 72.6544C10.1553 65.1168 7.83244 56.2003 8.42363 47.2346C9.01482 38.269 12.4883 29.7347 18.3261 22.9045C24.1639 16.0742 32.0532 11.3141 40.8174 9.33394C49.5816 7.35379 58.7511 8.25973 66.9583 11.9167"
        stroke={color}
        strokeWidth="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M91.6667 16.6667L50 58.375L37.5 45.875"
        stroke={color}
        strokeWidth="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export default Check;
