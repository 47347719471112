import React from 'react'
import styles from './Radio.module.scss'
import Triangle from '../../img/IconComponents/Triangle'
import { useTranslation } from 'react-i18next'

const Radio = ({ text, value, onChange, error }) => {
  const { t } = useTranslation()
  return (
    <label className={styles.container}>
      <div className={styles.container__text}>{t(text)}</div>
      <input type="radio" checked={value} onChange={onChange} />
      <span className={styles.checkmark}></span>
      {error && error.length && (
        <div className={styles.error}>
          <Triangle />
          <div>{error}</div>
        </div>
      )}
    </label>
  )
}

export default Radio
