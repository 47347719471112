import React, { useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Main.module.scss";

import RiskInformationTitleImage from "../../../img/RiskInformationTitleImage.webp";
import { Banner } from "../../MainPage/Components";
import { useTranslation } from "react-i18next";

export const Main = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  const riskInformationParagraphs = [
    {
      id: 1,
      text: t(
        "High Risk in Participation: Participation in the ICO is associated with significant risks for the buyers. In particular, there is the risk of a total loss, i.e., all amounts of money spent in the context of the ICO can be lost. Therefore, in the event of participation in the ICO, the buyer should not use a significant portion of his assets to purchase DECT-Tokens. Rather, the loss of the expended amounts of money should be financially manageable by the buyer. It is strongly advised against financing the purchase of DECT-Tokens through a loan. The obligations to pay interest and principal remain, even if the acquired DECT-Token becomes worthless. Buyers are expressly advised to participate in the ICO only if they already have experience with cryptocurrencies and blockchain technology. Also, buyers should be able to understand both the business activities of Anbaugesellschaft.de and the DECT-Token and its functionality. If a buyer does not have relevant experience, this does not constitute increased disclosure obligations of Anbaugesellschaft.de."
      ),
    },
    {
      id: 2,
      text: t(
        "Token is Not a Financial Instrument and Does Not Convey Ownership or Management Rights in Anbaugesellschaft.de: The DECT-Token is not a security, capital investment product, or financial instrument. The DECT-Token also does not have a payment function on the platform of Anbaugesellschaft.de. It does not convey ownership or management rights in the issuer or voting rights in the shareholders' meeting of the issuer. Also, the holder of the token is not involved in any way in the profit or loss or in the asset development of the issuer."
      ),
    },
    {
      id: 3,
      text: t(
        "Whitepaper is Neither a Prospectus Nor an Offer: The management of the issuer has created the Whitepaper made accessible on its website at its discretion and has not followed any specifications. There are no specific legal requirements for the content of such a whitepaper. Moreover, it has not been reviewed or approved by any authority or other official body. The whitepaper is descriptive in nature, is purely informative, and has no legal effect. It is intended to explain the project understandably and outline its peculiarities and advantages. Accordingly, the whitepaper does not constitute a securities and investment prospectus. Similarly, the whitepaper is not an offer for securities, capital investment products, or other financial instruments."
      ),
    },
    {
      id: 4,
      text: t(
        "Forward-Looking Statements are Non-Binding: The calculations presented in the whitepaper are based on forecasts about future development. They cannot be assured. Rather, the buyer bears the risk of deviating developments. Forward-looking statements are associated with uncertainties and risks. They are essentially based on the management's assessments of the issuer regarding future development. Moreover, they may include perception or assessment errors and prove to be inaccurate. It is also not excluded that events or developments that were not considered in the forecasts lead to significant deviations from the predicted development."
      ),
    },
    {
      id: 5,
      text: t(
        "Self-Responsible Risk Assessment: Furthermore, the whitepaper does not replace legal, tax, or other advice. Therefore, each buyer should check the associated risks independently before participating in the ICO and seek advice from an expert if necessary. This is particularly recommended concerning the supervisory and tax law consequences of purchasing DECT-Tokens. The issuer expressly assumes no liability for economic endeavors pursued with the purchase of DECT-Tokens."
      ),
    },
    {
      id: 6,
      text: t(
        "Regulatory Note on DECT-Tokens: The company takes the position that the issuance of DECT-Tokens by it is not subject to any specific regulation. Since the legal classification of tokens, blockchain technology, cryptocurrencies, smart contracts, and their applications is not yet sufficiently clarified, regulatory frameworks are also in flux. Therefore, it cannot be ruled out that due to court decisions, legislative changes, or regulatory measures, the use of tokens may be wholly or partially prohibited or only permitted under certain conditions. Such a development could have significant negative consequences for the business activities of the issuer; the DECT-Token could lose its utility, and in the worst case, the insolvency of the issuer could be threatened."
      ),
    },
    {
      id: 7,
      text: t(
        "No Exchange Right for the Buyer, Buyer Bears Usage Risk: A refund of the purchase price in any form by the issuer is excluded. The buyer bears the risk that he can continue to use the purchased DECT-Token as intended. He cannot argue that he cannot resell the DECT-Token or only at a different price than he planned. The buyer also cannot claim that the purpose of use has ceased to exist. The utility of the DECT-Token is limited exclusively to granting access to the functionalities of the platform, so it has no value for the buyer outside of this platform. Regardless, it cannot be excluded that the value of the DECT-Token changes due to the value development of other cryptocurrencies (e.g., BTC or ETH). Such value development can occur independently of the business development of the issuer. In addition, the buyer must consider that trading acquired DECT-Tokens will likely involve costs that the buyer must additionally bear, further burdening the value of acquired or traded DECT-Tokens."
      ),
    },
    {
      id: 8,
      text: t(
        "Risk of Loss of Access to DECT due to Loss of Private Keys, Custodial Errors, or Buyer's Errors: The buyer is liable for the security of the private keys and the security of the wallets necessary for holding DECT-Tokens, insofar as these are technically transferred from the user account to an ERC-20 wallet. Moreover, the buyer is liable for losses of DECT that can occur due to receiving or sending DECT if incorrect wallet addresses for sending or receiving DECT are provided that are not ERC-20 compatible. This can lead to the total loss of your investment."
      ),
    },
    {
      id: 9,
      text: t(
        "Risks Associated with the Ethereum Network: DECT is technically based on Ethereum, so any disruption, failure, or cessation of the Ethereum network can have significant negative impacts on the issuer's projects or on DECT. This can cause substantial damage up to the total loss of your investment."
      ),
    },
    {
      id: 10,
      text: t(
        "Risk of Mining Attacks: Like other cryptographic tokens based on the Ethereum network, DECT may be susceptible to attacks by miners in the course of validating DECT transactions on the Ethereum blockchain, including but not limited to double-spend attacks, majority mining power attacks, and selfish mining attacks. Successful attacks pose a risk to the issuer's projects and DECT, including but not limited to the correct execution and recording of transactions with DECT. This can cause substantial damage up to the total loss of your investment."
      ),
    },
    {
      id: 11,
      text: t(
        "Risk of Hacker Attacks and Security Vulnerabilities: Hackers or other malicious groups or organizations may try to disrupt the issuer's projects or DECT in various ways, including but not limited to malware attacks, denial-of-service attacks (DDOS), consensus-based attacks, Sybil attacks, smurfing, and spoofing. This can cause substantial damage up to the total loss of your investment."
      ),
    },
    {
      id: 12,
      text: t(
        "Risks Related to DECT Markets: If secondary trading of tokens is facilitated by third-party exchanges, such exchanges may be new and subject to little or no regulatory oversight, making them more susceptible to fraud or manipulation. Moreover, if third parties attribute an external exchange value to DECT (e.g., in the form of digital or fiat currency), this value may also be highly volatile. This can cause substantial damage up to the total loss of your investment."
      ),
    },
    {
      id: 13,
      text: t(
        "Risk of Uninsured Losses: Unlike bank accounts or accounts at other financial institutions, DECT are not insured unless you explicitly take out private insurance to insure them. In the event of a loss or a reduction in utility value, there is therefore no public insurer and no private insurance taken out by the issuer that you could fall back on. This can cause substantial damage up to the total loss of your investment."
      ),
    },
    {
      id: 14,
      text: t(
        "Risks Associated with the Development of the Software Solutions DECT-S2S (Blockchain-based Seed-to-Sale System) and DECT-Grow (AI-based Cultivation Management System): The software solutions described, to which the DECT-Token is intended to provide access, are currently still in the development and testing phase. If technical or other problems occur in the development of the software solutions, there is a risk that the intended system solutions can only be used with delays, only to a limited extent, or possibly not at all."
      ),
    },
    {
      id: 15,
      text: t(
        "Tax Risks: The tax classification of DECT is unclear. The acquisition and/or possession and/or sale of DECT may entail tax liabilities and be declarable. The buyer should seek tax advice in connection with the purchase and/or possession and/or sale of DECT."
      ),
    },
    {
      id: 16,
      text: t(
        "Risk of Competing Platforms: It is possible that companies will be founded that pursue the same concept underlying the issuer. Therefore, there is a risk that these companies offer services or products similar to those of the issuer. This can lead to the devaluation of DECT and the total loss of your investment."
      ),
    },
    {
      id: 17,
      text: t(
        "Risks Related to Legalization: The business model of DECT and Deutsche Anbaugesellschaft DAG GmbH is heavily dependent on the legalization and regulation of cannabis cultivation and consumption in Germany. If complete legalization does not occur or regulations are stricter than expected, this could negatively impact the business model of the issuer and the DECT-Token or impair or completely exclude the feasibility of the projects to be realized with the token. There is a risk that demand for DECT-Tokens and the associated services will be impaired by legal restrictions and/or the value of DECT could completely depreciate, leading to the total loss of your investment."
      ),
    },
    {
      id: 18,
      text: t(
        "Restrictions on Cannabis Cultivation: The issuer is dependent on a favorable regulatory environment for cannabis cultivation in Germany. If there are restrictions or changes in cultivation conditions, such as limitations on cultivation quantities, cultivation areas, or cultivation methods, this could negatively impact cannabis cultivation and thus the business model of the issuer and DECT. An unfavorable regulatory environment can lead to the devaluation of DECT and the total loss of your investment."
      ),
    },
    {
      id: 19,
      text: t(
        "Risks Related to Licenses: The issuer relies on obtaining certain licenses and permissions to fully implement its business model. There is a risk that the issuer will not receive all required licenses or that the issuance and maintenance of licenses will be associated with delays, costs, or other challenges. The lack of required licenses could impair the issuer's business operations and lead to the devaluation of DECT and the total loss of your investment."
      ),
    },
    {
      id: 20,
      text: t(
        "Consequences of Insufficient Funding of the Issuer: Due to its position as a young company in the market, 'Anbaugesellschaft.de' has financing needs. Its progress depends on receiving sufficient funding. The starting point of its financing is the revenue from the issuance of the DECT-Token. If the DECT-Token ICO does not raise sufficient funds for financing, it is not unlikely that Anbaugesellschaft.de will not be able to commence its business operations as planned and may have to declare insolvency. This would also have negative implications for the usability of the DECT-Token. If Anbaugesellschaft.de fails to establish the platform it envisions in the market, the DECT-Token will be worthless for the buyer."
      ),
    },
    {
      id: 21,
      text: t(
        "Unforeseen Risks: Crypto-tokens like DECT are a new and untested technology. In addition to the risks described in this section, there are further risks associated with the purchase, possession, use, and sale of DECT that cannot currently be foreseen. Each of the aforementioned risks, including unforeseen risks, can lead to the devaluation of DECT and the total loss of your investment."
      ),
    },
    {
      id: 22,
      text: t(
        "Cumulation of Risks: The risks presented in this chapter can occur individually or cumulatively. The risks could each lead to the project not being feasible and/or the assumptions made no longer occurring or not occurring as described. The maximum risk of acquiring the DECT-Token is therefore the total loss of the purchase price paid."
      ),
    },
  ];

  return (
    <div className={styles.main__wrapper}>
      <main className={styles.main}>
        <div className="container">
          <div className={styles.main__header}>
            <div className={styles.header__image_wrapper}>
              <img
                src={RiskInformationTitleImage}
                alt="RiskInformationTitleImage"
                className={styles.header__image}
              />
              <div className={styles.header__image_rectangle} />
            </div>
            <div className={styles.title__wrapper}>
              <h1 className={styles.title}>{t("Risk Notices")}</h1>
            </div>
          </div>

          <div className={styles.main__body}>
            <div className={styles.body}>
              <div className={styles.paragraph__text}>
                {t("PLEASE READ THESE IMPORTANT RISK NOTICES CAREFULLY AND ATTENTIVELY BEFORE PARTICIPATING IN THE DECT-TOKEN INITIAL COIN OFFERING (ICO). IF YOU HAVE QUESTIONS ABOUT THE ICO, THE DECT-TOKEN, THE BUSINESS MODEL OF THE DEUTSCHE ANBAUGESELLSCHAFT DAG GMBH (HEREINAFTER REFERRED TO AS 'Anbaugesellschaft.de' AND ISSUER) OR OTHER RELEVANT ASPECTS IN THIS CONTEXT, YOU SHOULD SEEK ADVICE FROM AN EXPERT (LAWYER, TAX ADVISOR, ETC.). IF THERE ARE DOUBTS OR UNCERTAINTIES, YOU SHOULD NOT PARTICIPATE IN THE ICO.")}
              </div>
              <br />
              <div className={styles.paragraph__text}>
                {t("By purchasing DECT, you as a buyer expressly acknowledge that you have read and understood the following risk notices and that you are willing to bear them:")}
              </div>
              <br />
              <div className={styles.paragraphs}>
                {riskInformationParagraphs?.map((item) => {
                  const { id, text } = item;
                  return (
                    <div key={id} className={styles.paragraph}>
                      <div className={styles.paragraph__text}>{`${id}.`}</div>
                      <div className={styles.paragraph__text}>{text}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <Banner />
      </main>
    </div>
  );
};
