import React from "react";
import HeaderCommon from "../../components/HeaderCommon/HeaderCommon";
import FooterCommon from "../../components/FooterCommon/FooterCommon";
import { Main } from "./components/Main";

export const RiskInformation = ({ currentLang, setCurrentLang }) => {
  return (
    <>
      <HeaderCommon currentLang={currentLang} setCurrentLang={setCurrentLang} />
      <Main />
      <FooterCommon />
    </>
  );
};
