/** @format */

import React, {
  useState,
  useEffect,
  useRef,
} from 'react';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';
import styles from './Checkout.module.scss';
import Header from '../../../../../../components/Header/Header';
import Aside from '../../../../../../components/Aside/Aside';
import SectionTitle from '../../../../../../components/SectionTitle/SectionTitle';
import Input from '../../../../../../components/Input/Input';
import Checkbox from '../../../../../../components/Checkbox/Checkbox';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import Button from '../../../../../../components/Button/Button';
import Dropdown from '../../../../../../components/Dropdown/Dropdown';
import FooterClient from '../../../../../../components/FooterClient/FooterClient';
import axios from 'axios';

import {
  checkFirstName,
  checkLastName,
  checkCity,
  checkStreet,
  checkPost,
  checkDoor,
  checkEmail,
  checkPhone,
  checkCountry,
} from '../../../../../../helpers/validation';
import { useTranslation } from 'react-i18next';
import Triangle from '../../../../../../img/IconComponents/Triangle';
import getRequest from '../../../../../../helpers/getRequest';
import postRequest from '../../../../../../helpers/postRequest';
import Loader from '../../../../../../components/Loader/Loader';
import Modal from '../../../../../../components/Modal/Modal';
import { useParams } from 'react-router-dom';
import {
  API_URL,
  FRONT_URL,
} from '../../../../../../http';
import ModalPolicy from '../../../../../../components/ModalPolicy/ModalPolicy';
import ModalRisk from '../../../../../../components/ModalRisk/ModalRisk';
const Checkout = ({
  amountPlants,
  sumOfPlants,
  enableHarvestSelling,
  periodicityHarvestSelling,
  type,
  currentLang,
  setCurrentLang,
}) => {
  const location = useLocation();

  const navigate = useNavigate();
  const [isCheckTerms, setIsCheckTerms] =
    useState(false);
  const [isCheckPolicy, setIsCheckPolicy] =
    useState(false);

  const [firstName, setFirstName] = useState('');
  const [errorFirstName, setErrorFirstName] =
    useState('');
  const [lastName, setLastName] = useState('');
  const [errorLastName, setErrorLastName] =
    useState('');
  const [company, setCompany] = useState('');
  const [country, setCountry] = useState('');
  const [errorCountry, setErrorCountry] =
    useState('');
  const [city, setCity] = useState('');
  const [errorCity, setErrorCity] = useState('');
  const [street, setStreet] = useState('');
  const [errorStreet, setErrorStreet] =
    useState('');
  const [postcode, setPostCode] = useState('');
  const [errorPostcode, setErrorPostCode] =
    useState('');
  const [appartment, setApartment] = useState('');
  const [errorAppartment, setErrorApartment] =
    useState('');
  const [email, setEmail] = useState('');
  const [errorEmail, setErrorEmail] =
    useState('');
  const [isCoinbase, setIsCoinbase] =
    useState(false);
  const [phone, setPhone] = useState('');
  const [errorPhone, setErrorPhone] =
    useState('');
  const [errorCheckTerms, setErrorCheckTerms] =
    useState('');
  const [errorCheckPolicy, setErrorCheckPolicy] =
    useState('');
  const [profiteCheckbox, setProfiteCheckbox] =
    useState(false);
  const [
    errorCheckProfite,
    setErrorCheckProfite,
  ] = useState('');
  const [orderId, setOrderId] = useState('');
  const [plantsAmount, setPlantsAmount] =
    useState('');
  const [euroAmount, setEuroAmount] =
    useState('');
  const [successModal, setSuccessModal] =
    useState(false);
  const [paymentCheckbox1, setPaymentCheckbox1] =
    useState(false);
  const [paymentCheckbox2, setPaymentCheckbox2] =
    useState(false);
  const [
    errorCheckPayments,
    setErrorCheckPayments,
  ] = useState(false);
  // const [questionaryError, setQuestionaryError] = useState(false)
  const [
    isShowPolicyModal,
    setIsShowPolicyModal,
  ] = useState(false);
  const [isShowRiskModal, setIsShowRiskModal] =
    useState(false);

  const [isLoad, setIsLoad] = useState(false);
  const [
    modalConfirmOrder,
    setModalConfirmOrder,
  ] = useState(false);
  const { orderId: orderContractConfirmedId } =
    useParams();
  const [orderStatus, setOrderStatus] =
    useState('bank');
  const ref = useRef(false);

  const [isKYC, setIsKYC] = useState(null);

  const [user, setUser] = useState();

  useEffect(() => {
    getRequest('v1/auth/whoami').then(
      (response) => {
        setIsKYC(response.data?.kyc_verified);
        setUser(response.data);
      },
    );
  }, []);

  const log = () => {
    axios({
      method: 'post',
      url: `${API_URL}/v1/logs/confirm-order`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          'token',
        )}`,
        Accept: 'application/json',
      },
    });
  };
  useEffect(() => {
    getRequest('v1/auth/checkout').then(
      (response) => {},
    );
  }, []);

  useEffect(() => {
    if (
      location.search.includes('log=true') &&
      location.pathname.includes('bestellung') &&
      !ref.current
    ) {
      log();
    }
    return () => {
      ref.current = true;
    };
  }, []);

  const getSummaryDetails = () => {
    getRequest(
      `v1/orders/${orderContractConfirmedId}`,
    )
      .then((response) => {
        if (
          (response?.data.data.status !==
            'DRAFT' &&
            response?.data.data.isCoinbase ===
              false) ||
          (response?.data.data.isCoinbase ===
            true &&
            !['PENDING', 'DRAFT'].includes(
              response?.data.data.status,
            ))
        ) {
          navigate('/dashboard');
        }
        const {
          city,
          company_name,
          country,
          door_no,
          email,
          first_name,
          last_name,
          phone_number,
          plants_count,
          postcode,
          street,
          total_amount,
          is_coinbase,
          status,
        } = response?.data.data;

        setFirstName(first_name || '');
        setPlantsAmount(plants_count || '');
        setEuroAmount(total_amount || '');
        setLastName(last_name || '');
        setCompany(company_name || '');
        setCountry(country || '');
        setCity(city || '');
        setStreet(street || '');
        setPostCode(postcode || '');
        setApartment(door_no || '');
        setIsCoinbase(is_coinbase || false);
        setEmail(email || '');
        setPhone(phone_number || '');
        setOrderStatus(status);
      })
      .catch(() => {
        window.location = '/dashboard';
      });
  };

  useEffect(() => {
    if (orderContractConfirmedId) {
      getSummaryDetails();
    }
  }, [plantsAmount, euroAmount]);

  const checkFields = () => {
    const isError = [];

    checkFirstName(
      firstName,
      () => {},
      (value) => {
        isError.push(Boolean(value));
        setErrorFirstName(value);
      },
    );
    checkLastName(
      lastName,
      () => {},
      (value) => {
        isError.push(Boolean(value));
        setErrorLastName(value);
      },
    );
    checkCity(
      city,
      () => {},
      (value) => {
        isError.push(Boolean(value));
        setErrorCity(value);
      },
    );
    checkStreet(
      street,
      () => {},
      (value) => {
        isError.push(Boolean(value));
        setErrorStreet(value);
      },
    );
    checkPost(
      postcode,
      () => {},
      (value) => {
        isError.push(Boolean(value));
        setErrorPostCode(value);
      },
    );
    checkDoor(
      appartment,
      () => {},
      (value) => {
        isError.push(Boolean(value));
        setErrorApartment(value);
      },
    );
    checkEmail(
      email,
      () => {},
      (value) => {
        isError.push(Boolean(value));
        setErrorEmail(value);
      },
    );
    // checkPhone(
    //   phone,
    //   () => {},
    //   (value) => {
    //     isError.push(Boolean(value));
    //     setErrorPhone(value);
    //   },
    // );
    checkCountry(
      country,
      () => {},
      (value) => {
        isError.push(Boolean(value));
        setErrorCountry(value);
      },
    );
    isError.push(!isCheckTerms);
    if (!isCheckTerms) {
      setErrorCheckTerms('Check');
    } else {
      setErrorCheckTerms('');
    }
    isError.push(!isCheckPolicy);
    if (!isCheckPolicy) {
      setErrorCheckPolicy('Check');
    } else {
      setErrorCheckPolicy('');
    }

    if (!paymentCheckbox1 && !paymentCheckbox2) {
      setErrorCheckPayments(true);
    } else {
      setErrorCheckPayments(false);
    }

    // if (!profiteCheckbox) {
    //   isError.push(Boolean(true));
    //   setErrorCheckProfite(t("Check"));
    // } else {
    //   setErrorCheckProfite("");
    // }
    return isError.some((el) => el);
  };

  const onCloseConfirmOrderModal = () => {
    setModalConfirmOrder(false);
    window.location.pathname = '/dashboard';
  };

  const getEmailPayment = () => {
    setIsLoad(true);
    checkoutOrder();
  };

  const confirmOrder = () => {
    if (checkFields()) return;
    setIsLoad(true);
    postRequest(
      'v1/orders',
      {
        first_name: firstName,
        last_name: lastName,
        company_name: company || '',
        country: country,
        city: city,
        street: street,
        postcode: postcode,
        door_no: appartment,
        email: email,
        // phone_number: phone,
        total_amount: sumOfPlants,
        plants_count: amountPlants,
        auto_harvest_selling:
          enableHarvestSelling,
        times_sell_year:
          periodicityHarvestSelling,
        is_coinbase: isCoinbase,
        browser: navigator.userAgent,
        screen_height: window.innerHeight,
        screen_width: window.innerWidth,
      },
      'application/json',
      localStorage.getItem('i18n').toLowerCase(),
    )
      .then((response) => {
        window.dataLayer.push({
          event: 'purchase',
          ecommerce: {
            transaction_id: response.data?.orderId
              ? response.data?.orderId.toString()
              : undefined,
            tippgeber: user.sale_id
              ? user.sale_id.toString()
              : undefined,
            value: sumOfPlants,
            tax: Number(
              sumOfPlants -
                (sumOfPlants / 119) * 100,
            ).toFixed(2),
            currency: 'EUR',
            coupon: undefined,
            items: [
              {
                item_id: '1',
                item_name: 'DEC_TOKEN',
                coupon: undefined,
                discount: 0,
                price: sumOfPlants / amountPlants,
                quantity: amountPlants,
              },
            ],
          },
          user: {
            customerBillingEmail:
              response.data?.emails[0],
            customerBillingFirstName:
              response.data?.user.first_name,
            customerBillingLastName:
              response.data?.user.last_name,
          },
        });

        navigate(
          `/order/${response.data?.orderId}`,
        );
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const checkoutOrder = () => {
    postRequest(
      `v1/orders/`,
      {
        first_name: firstName,
        last_name: lastName,
        company_name: company || '',
        country: country,
        city: city,
        street: street,
        postcode: postcode,
        door_no: appartment,
        email: email,
        // phone_number: phone,
        total_amount: euroAmount,
        plants_count: plantsAmount,
        is_coinbase: isCoinbase,
        auto_harvest_selling:
          enableHarvestSelling,
        times_sell_year:
          periodicityHarvestSelling,
      },
      'application/json',
      localStorage.getItem('i18n').toLowerCase(),
    )
      .then((response) => {
        if (response.status === 201) {
          window.location = `/order/${orderContractConfirmedId}`;
        }

        // setSuccessModal(true);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoad(false);
      });
  };

  useEffect(() => {
    if (type !== 'disabled') {
      getRequest('v1/user/billing-details').then(
        (response) => {
          const {
            city,
            company_name,
            country,
            door_no,
            email,
            first_name,
            last_name,
            postcode,
            street,
            phone_number,
          } = response?.data;

          setFirstName(first_name || '');
          setLastName(last_name || '');
          setCompany(company_name || '');
          setCountry(country || '');
          setCity(city || '');
          setStreet(street || '');
          setPostCode(postcode || '');
          setApartment(door_no || '');
          setEmail(email || '');
          setPhone(phone_number || '');
        },
      );
    }
  }, []);

  const { t } = useTranslation();

  const formatNumber = (num) => {
    return num.toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  return (
    <>
      {/* {       ( !(!isKYC&&user&& user.is_from_advertising)) ||user.advertise_id==='dect'?<> */}
      {/* {isLoad ? <Loader></Loader> : null} */}
      <div className={styles.wrapper}>
        <Aside />
        <div className={styles.container}>
          <Header
            currentLang={currentLang}
            setCurrentLang={setCurrentLang}
          />
          <section className={styles.checkout}>
            <SectionTitle
              text={
                type === 'disabled'
                  ? t('Order summary')
                  : t('Checkout')
              }
            />
            {type === 'disabled' ? (
              <p
                className={styles.checkout__text}>
                {t('Complete your order now:')}
              </p>
            ) : null}
            <div
              className={cn(
                styles.checkout__wrapper,
                type === 'disabled'
                  ? styles.checkout__wrapper_reverse
                  : '',
              )}>
              <div
                className={
                  styles.checkout__right
                }>
                <div>
                  <div
                    className={
                      styles.checkout__wrapper_title
                    }>
                    {t('Your Order')}
                  </div>
                  <div
                    className={
                      styles.checkout__right_table
                    }>
                    <div
                      className={
                        styles.checkout__right_table_row
                      }>
                      <div
                        className={cn(
                          styles.checkout__right_product,
                          styles.checkout__right_table_label,
                        )}>
                        {t('Product')}
                      </div>
                      <div
                        className={cn(
                          styles.checkout__right_quantity,
                          styles.checkout__right_table_label,
                        )}>
                        {t('Quantity')}
                      </div>
                      <div
                        className={cn(
                          styles.checkout__right_subtotal,
                          styles.checkout__right_table_label,
                        )}>
                        {t('Price per token')}
                      </div>
                    </div>
                    <div
                      className={
                        styles.checkout__right_table_row_values
                      }>
                      <div
                        className={cn(
                          styles.checkout__right_product,
                          styles.checkout__right_table_value,
                        )}>
                        {t('DECT-Tokens')}
                      </div>
                      <div
                        className={cn(
                          styles.checkout__right_quantity,
                          styles.checkout__right_table_value,
                        )}>
                        {type === 'disabled'
                          ? plantsAmount
                          : amountPlants}
                      </div>
                      <div
                        className={cn(
                          styles.checkout__right_subtotal,
                          styles.checkout__right_table_value,
                        )}>
                        {formatNumber(
                          (sumOfPlants / 119) *
                            100,
                        )}
                        €
                      </div>
                    </div>
                    <div
                      className={
                        styles.checkout__right_table_total
                      }>
                      <div
                        className={
                          styles.checkout__right_table_total_value
                        }>
                        {t('Total net amount')}
                      </div>
                      <div
                        className={
                          styles.checkout__right_table_total_value
                        }>
                        {type === 'disabled'
                          ? formatNumber(
                              (euroAmount / 119) *
                                100,
                            )
                          : formatNumber(
                              (sumOfPlants /
                                119) *
                                100,
                            )}
                        €
                      </div>
                    </div>
                    <div
                      className={
                        styles.checkout__right_table_stats
                      }>
                      <div
                        className={
                          styles.checkout__right_table_stats_value
                        }>
                        {t('USt.')}
                      </div>
                      <div
                        className={
                          styles.checkout__right_table_stats_value
                        }>
                        {type === 'disabled'
                          ? formatNumber(
                              euroAmount -
                                (euroAmount /
                                  119) *
                                  100,
                            )
                          : formatNumber(
                              sumOfPlants -
                                (sumOfPlants /
                                  119) *
                                  100,
                            )}
                        €
                      </div>
                    </div>
                    <div
                      className={
                        styles.checkout__right_table_stats
                      }>
                      <div
                        className={
                          styles.checkout__right_table_stats_value
                        }>
                        {t('Total ')}
                      </div>
                      <div
                        className={
                          styles.checkout__right_table_stats_value
                        }>
                        {type === 'disabled'
                          ? formatNumber(
                              euroAmount,
                            )
                          : formatNumber(
                              sumOfPlants,
                            )}
                        €
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    styles.checkout__right_bottom
                  }>
                  <div
                    className={cn(
                      styles.checkout__wrapper_title,
                      styles.checkout__wrapper_title_side,
                    )}>
                    {t('Payment Method')}
                  </div>
                  <label className={styles.radio}>
                    <input
                      type='radio'
                      name='payment'
                      value={!isCoinbase}
                      checked={!isCoinbase}
                      onChange={() =>
                        setIsCoinbase(false)
                      }
                      disabled={
                        type === 'disabled'
                          ? true
                          : false
                      }
                    />
                    <span
                      className={
                        styles.checkmark
                      }></span>
                    <div
                      className={
                        styles.radio__title
                      }>
                      {t('Direct Bank Transfer')}
                    </div>
                    <div
                      className={
                        styles.checkout__right_direct_note
                      }>
                      {t(
                        'Please use your Order ID as the payment reference.',
                      )}
                    </div>
                  </label>
                  <label className={styles.radio}>
                    <input
                      type='radio'
                      name='payment'
                      value={isCoinbase}
                      checked={isCoinbase}
                      onChange={() =>
                        setIsCoinbase(true)
                      }
                      disabled={
                        type === 'disabled'
                          ? true
                          : false
                      }
                    />
                    <span
                      className={
                        styles.checkmark
                      }></span>
                    <div
                      className={
                        styles.radio__title
                      }>
                      {t('Crypto Payment')}
                    </div>
                    <div
                      className={
                        styles.checkout__right_direct_note
                      }>
                      {t(
                        'Processing through Coinbase Commerce.',
                      )}
                    </div>
                  </label>
                  <div
                    className={cn(
                      styles.checkout__wrapper_title,
                      styles.checkout__wrapper_title_side,
                    )}>
                    {t('Confirm to proceed:')}
                  </div>
                  <div
                    className={
                      errorCheckTerms
                        ? cn(
                            styles.checkout__right_terms,
                            styles.checkout__right_terms__error,
                          )
                        : styles.checkout__right_terms
                    }>
                    <label>
                      <div
                        onClick={() =>
                          setIsShowRiskModal(true)
                        }>
                        <Checkbox
                          disabled
                          value={isCheckTerms}
                        />
                      </div>

                      <div
                        className={
                          errorCheckTerms
                            ? cn(
                                styles.checkout__right_terms_value,
                                styles.checkout__right_terms_value__error,
                              )
                            : styles.checkout__right_terms_value
                        }>
                        {localStorage.getItem(
                          'i18n',
                        ) != 'DE' ? (
                          <>
                            I have read the{' '}
                            <Link
                              to='/risk-information'
                              target='_blank'
                              className={
                                styles.text
                              }>
                              risk information
                            </Link>{' '}
                            and agree to its
                            validity
                          </>
                        ) : (
                          <>
                            Ich habe die{' '}
                            <Link
                              to='/risk-information'
                              target='_blank'>
                              Risikohinweise
                            </Link>{' '}
                            gelesen und bin mit
                            deren Geltung
                            einverstanden
                          </>
                        )}
                        {/* {t('I have read and agree to the terms and conditions')}{' '} */}
                        {/* <Link to="/agb" target="_blank">
                          {t('Terms & Conditions')}
                        </Link>{' '}
                        {t('and')}{' '}
                        <Link to="/privacy" target="_blank">
                          {t('Privacy Policy')}
                        </Link> */}
                      </div>
                    </label>
                    {errorCheckTerms &&
                    errorCheckTerms.length ? (
                      <div
                        className={
                          styles.checkout__right_terms_err_wrap
                        }>
                        <Triangle />
                        <div
                          className={
                            styles.checkout__right_terms_err
                          }>
                          {t('Must checked')}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={
                      errorCheckPolicy
                        ? cn(
                            styles.checkout__right_terms,
                            styles.checkout__right_terms__error,
                          )
                        : styles.checkout__right_terms
                    }>
                    <label>
                      <div
                        onClick={() =>
                          setIsShowPolicyModal(
                            true,
                          )
                        }>
                        <Checkbox
                          disabled
                          value={isCheckPolicy}
                        />
                      </div>
                      <div
                        className={
                          errorCheckPolicy
                            ? cn(
                                styles.checkout__right_terms_value,
                                styles.checkout__right_terms_value__error,
                              )
                            : styles.checkout__right_terms_value
                        }>
                        {localStorage.getItem(
                          'i18n',
                        ) != 'DE' ? (
                          <>
                            I have read the{' '}
                            <Link
                              to='/widerrufsrecht'
                              target='_blank'>
                              declaration of
                              revocation
                            </Link>{' '}
                            and agree to its
                            validity
                          </>
                        ) : (
                          <>
                            Ich habe die{' '}
                            <Link
                              to='/widerrufsrecht'
                              target='_blank'>
                              Widerrufserklärung
                            </Link>{' '}
                            gelesen und bin mit
                            deren Geltung
                            einverstanden
                          </>
                        )}
                      </div>
                    </label>
                    {errorCheckPolicy &&
                    errorCheckPolicy.length ? (
                      <div
                        className={
                          styles.checkout__right_terms_err_wrap
                        }>
                        <Triangle />
                        <div
                          className={
                            styles.checkout__right_terms_err
                          }>
                          {t('Must checked')}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <Button
                    // className={styles.checkout__right_btn}
                    text={
                      type === 'disabled'
                        ? t(
                            'Order now with costs',
                          )
                        : t('Order now')
                    }
                    color='checkout'
                    onClick={() => {
                      if (type === 'disabled') {
                        getEmailPayment();
                      } else {
                        confirmOrder();
                      }
                    }}
                  />
                  {/* {questionaryError && (
                    <p className={styles.checkout__error}>
                      {t(
                        'Please fill in the Questionnaire to be able to make a purchase!',
                      )}
                    </p>
                  )} */}
                </div>
              </div>
              <div
                className={styles.checkout__left}>
                <div
                  className={
                    styles.checkout__wrapper_title
                  }>
                  {t('Billing Details')}
                </div>
                <form
                  className={
                    styles.checkout__form
                  }>
                  <div
                    className={
                      styles.checkout__form_row
                    }>
                    <Input
                      disabled={
                        type === 'disabled'
                      }
                      label={t('First name')}
                      type='text'
                      name='firstname'
                      placeholder={t(
                        'First name',
                      )}
                      value={firstName}
                      onChange={(e) =>
                        checkFirstName(
                          e.target.value,
                          setFirstName,
                          setErrorFirstName,
                        )
                      }
                      error={errorFirstName}
                    />
                    <Input
                      disabled={
                        type === 'disabled'
                      }
                      label={t('Last name')}
                      type='text'
                      name='lastname'
                      placeholder={t('Surname')}
                      value={lastName}
                      onChange={(e) =>
                        checkLastName(
                          e.target.value,
                          setLastName,
                          setErrorLastName,
                        )
                      }
                      error={errorLastName}
                    />
                  </div>
                  <div
                    className={
                      styles.checkout__form_row
                    }>
                    <Input
                      disabled={
                        type === 'disabled'
                      }
                      label={t(
                        'Company Name (optional)',
                      )}
                      type='text'
                      name='company'
                      placeholder={t('Company')}
                      value={company}
                      onChange={(e) =>
                        setCompany(e.target.value)
                      }
                    />
                  </div>
                  <div
                    className={
                      styles.checkout__form_row
                    }>
                    <Input
                      disabled={
                        type === 'disabled'
                      }
                      label={t('Street')}
                      type='text'
                      name='street'
                      placeholder={t('Street')}
                      value={street}
                      // onChange={(e) => setStreet(e.target.value)}
                      onChange={(e) =>
                        checkStreet(
                          e.target.value,
                          setStreet,
                          setErrorStreet,
                        )
                      }
                      error={errorStreet}
                    />
                    <Input
                      disabled={
                        type === 'disabled'
                      }
                      label={t('House number')}
                      type='text'
                      name='apartment'
                      placeholder='#'
                      value={appartment}
                      // onChange={(e) => setApartment(e.target.value)}
                      onChange={(e) =>
                        checkDoor(
                          e.target.value,
                          setApartment,
                          setErrorApartment,
                        )
                      }
                      error={errorAppartment}
                    />
                  </div>
                  {/* <div className={styles.checkout__form_row}>
                    <Input
                      label={t("Street")}
                      type="text"
                      name="street"
                      placeholder={t("Street")}
                      value={street}
                      // onChange={(e) => setStreet(e.target.value)}
                      onChange={(e) => checkStreet(e.target.value, setStreet, setErrorStreet)}
                      error={errorStreet}
                    />
                  </div> */}
                  <div
                    className={
                      styles.checkout__form_row_light
                    }>
                    <Input
                      disabled={
                        type === 'disabled'
                      }
                      label={t('Postcode')}
                      type='number'
                      name='postcode'
                      placeholder='1234567'
                      value={postcode}
                      // onChange={(e) => setPostCode(e.target.value)}
                      onChange={(e) =>
                        checkPost(
                          e.target.value,
                          setPostCode,
                          setErrorPostCode,
                        )
                      }
                      error={errorPostcode}
                    />
                  </div>
                  <div
                    className={
                      styles.checkout__form_row
                    }>
                    <Input
                      disabled={
                        type === 'disabled'
                      }
                      label={t('City')}
                      type='text'
                      name='сity'
                      placeholder={t('City')}
                      value={city}
                      onChange={(e) =>
                        checkCity(
                          e.target.value,
                          setCity,
                          setErrorCity,
                        )
                      }
                      error={errorCity}
                    />
                    <Dropdown
                      value={country}
                      disabled={
                        type === 'disabled'
                      }
                      onChange={(country) =>
                        checkCountry(
                          country,
                          setCountry,
                          setErrorCountry,
                        )
                      }
                      error={errorCountry}
                    />
                  </div>
                  <div
                    className={
                      styles.checkout__form_row
                    }>
                    <Input
                      label={t('Email')}
                      type='email'
                      name='email'
                      disabled={
                        type === 'disabled'
                      }
                      placeholder={t('Email')}
                      value={email}
                      // onChange={(e) => setEmail(e.target.value)}
                      onChange={(e) =>
                        checkEmail(
                          e.target.value,
                          setEmail,
                          setErrorEmail,
                        )
                      }
                      error={errorEmail}
                    />
                    {/* <Input
                      label={t('Phone number')}
                      type='text'
                      name='phone'
                      placeholder='+490000000000'
                      value={phone}
                      disabled={
                        type === 'disabled'
                      }
                      // onChange={(e) => setPhone(e.target.value)}
                      onChange={(e) =>
                        checkPhone(
                          e.target.value,
                          setPhone,
                          setErrorPhone,
                        )
                      }
                      error={errorPhone}
                    /> */}
                  </div>
                </form>
              </div>
            </div>
            {modalConfirmOrder && (
              <Modal
                onClose={() =>
                  onCloseConfirmOrderModal(false)
                }
                text={t(
                  'We have sent you additional payment information by email.',
                )}
              />
            )}
          </section>
          <div className={styles.footer}>
            <FooterClient />
          </div>
        </div>
        {isShowPolicyModal ? (
          <ModalPolicy
            setOpen={setIsShowPolicyModal}
            setIsConfirmed={() => {
              setIsShowPolicyModal(false);
              setIsCheckPolicy(true);
              setErrorCheckPolicy(false);
            }}
          />
        ) : null}
        {isShowRiskModal ? (
          <ModalRisk
            setOpen={setIsShowRiskModal}
            setIsConfirmed={() => {
              setIsShowRiskModal(false);
              setIsCheckTerms(true);
              setErrorCheckTerms(false);
            }}
          />
        ) : null}
      </div>
      {/* </>: <div className={styles.wrapper}>
          <Aside />
          <div className={styles.block}>
            <Header currentLang={currentLang} setCurrentLang={setCurrentLang} />
            <div id="sumsub-websdk-container">
            <Verify user={user} />
            </div>
            <div className={styles.footer}>
              <FooterClient />
            </div>
          </div>
        </div>
      } */}
    </>
  );
};

export default Checkout;
