import React, { useContext, useEffect, useState } from "react";
import styles from "./EmailVerify.module.scss";
import "../../styling/common.css";
import HeaderCommon from "../../components/HeaderCommon/HeaderCommon";
import FooterCommon from "../../components/FooterCommon/FooterCommon";
import { useParams } from "react-router-dom";
import { Context } from "../../index";
import Check from "../../img/IconComponents/Check";
import login from "../../img/login.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ModalRegCompleted from "../../components/ModalRegCompleted/ModalRegCompleted";
import hands from "../../img/hands.svg";

const EmailVerify = ({ currentLang, setCurrentLang }) => {
  const { id } = useParams();
  const { store } = useContext(Context);
  const { t } = useTranslation();
  const [saleRegModal, setSaleRegModal] = useState(false);

  useEffect(() => {
    if (id) {
      setSaleRegModal(true);
      // store.verifyUser(sale_id)
    } else {
      setSaleRegModal(false);
    }
  }, [id]);

  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();

    // store.verifyUser(id)
    // navigate('/login')
  }, []);
  const verify = store.verifyUser(id);
  return (
    <>
      <HeaderCommon currentLang={currentLang} setCurrentLang={setCurrentLang} />
      {
        <ModalRegCompleted
          buttonText={t("Get Started")}
          img={hands}
          id={id}
          text={t(
            "Thank you for registration now you can login with your login and password"
          )}
          onClose={() => setSaleRegModal(false)}
        />
      }
      <section className={styles.verify}>
        <div className="container">
          <div className={styles.verify__info}>
            <img className={styles.verify__image} src={login} alt="login" />
            <div
              className={styles.verify__circle}
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              <Check />
            </div>
            <h2 className={styles.verify__title}>
              {t("Your email verified successfully")}
            </h2>
            <p className={styles.verify__noty}>
              {t("You can now log in with your access data")}
            </p>
            {/* <Button
              to="/login"
              text={t('Go to login')}
              color="green"
              // onClick={() => store.verifyUser(id)}
            /> */}
          </div>
        </div>
      </section>
      <FooterCommon />
    </>
  );
};

export default EmailVerify;
