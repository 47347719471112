import axios from "axios";
// import { API_URL } from "../http";
import $api from "../http";

const getRequest = (url, responseType = null, lang = '') => {
  return $api({
    method: 'get',
    url: `/${url}`,
    headers: {
      'Content-Type': 'application/json',
      "Accept": "application/json",
      // Authorization: `Bearer ${localStorage.getItem("token")}`,
      'content-language': lang.length === 0 ? '' : lang.toLowerCase()
    },
    ...(responseType && {responseType: 'blob'})
  })
}

export default getRequest;