import React from "react";
import styles from "./AdminBlock.module.scss";
import ArrowIcon from "../../img/IconComponents/ArrowIcon";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const AdminBlock = ({
  title,
  value,
  subValue = "",
  linkText = "",
  link,
  type = "",
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const linkOnClick = () => {
    if (link) {
      navigate(link);
    }
  };
  return (
    <div className={styles.block}>
      <div className={styles.block__title}>{title}</div>
      <div className={styles.block__value}>
        {type !== "int"
          ? value
            ? parseFloat(value).toLocaleString('de-DE', {minimumFractionDigits: 2,maximumFractionDigits: 2,})            
            : "0,00"
          : value
          ? Number(value)
          : "0"}
        <span>{subValue}</span>
      </div>
      {linkText && linkText.length ? (
        <div className={styles.block__link} onClick={linkOnClick}>
          {t("open detailed statistics")}
          <ArrowIcon />
        </div>
      ) : null}
    </div>
  );
};

export default AdminBlock;
