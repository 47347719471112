import React, { useEffect, useState } from "react";
import styles from "./Main.module.scss";
import BackButton from "../../../../components/BackButton/BackButton";
import SectionTitle from "../../../../components/SectionTitle/SectionTitle";
import TimerIcon from "../../../../img/IconComponents/TimerIcon";
import { useParams } from "react-router-dom";
import cn from "classnames";
import Status from "../../../../components/Status/Status";
import getRequest from "../../../../helpers/getRequest";
import moment from "moment";
import webcam from "../../../../img/camplant.webp";
import { useTranslation } from "react-i18next";

const cultivateData = [
  { date: "13.03.2023", time: "12:34 AM", id: 1 },
  { date: "13.03.2023", time: "12:34 AM", id: 2 },
  { date: "13.03.2023", time: "12:34 AM", id: 3 },
  { date: "13.03.2023", time: "12:34 AM", id: 4 },
  { date: "13.03.2023", time: "12:34 AM", id: 5 },
  { date: "13.03.2023", time: "12:34 AM", id: 6 },
  { date: "13.03.2023", time: "12:34 AM", id: 7 },
  { date: "13.03.2023", time: "12:34 AM", id: 8 },
  { date: "13.03.2023", time: "12:34 AM", id: 9 },
  { date: "13.03.2023", time: "12:34 AM", id: 10 },
  { date: "13.03.2023", time: "12:34 AM", id: 11 },
  { date: "13.03.2023", time: "12:34 AM", id: 12 },
  { date: "13.03.2023", time: "12:34 AM", id: 13 },
  { date: "13.03.2023", time: "12:34 AM", id: 14 },
  { date: "13.03.2023", time: "12:34 AM", id: 15 },
  { date: "13.03.2023", time: "12:34 AM", id: 16 },
  { date: "13.03.2023", time: "12:34 AM", id: 17 },
  { date: "13.03.2023", time: "12:34 AM", id: 18 },
];

const Main = () => {
  const { id } = useParams();
  const [data, setData] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    getRequest(`v1/plants/${id}`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  console.log(data);

  return (
    <section className={styles.main}>
      <BackButton />
      <div className={styles.main__header}>
        <SectionTitle text={`Token #${id}`} />
        <BackButton />
      </div>
      <div className={styles.main__info}>
        <div className={styles.main__info_image}>
          <img src={webcam} alt="webcam" />
          <div className={styles.main__web_info}>
            <div className={styles.main__web_info_row}>
              <span className={styles.main__web_info_text}>
                {t("Webcam")} 1234
              </span>
              <span className={styles.main__web_info_text}>
                {t("sector")} 002{" "}
              </span>
            </div>
            <div className={styles.main__web_info_row}>
              <span className={styles.main__web_info_text}>
                {t("local time")}
              </span>
              <span className={styles.main__web_info_text}>09:38 am</span>
            </div>
          </div>
        </div>
        <div className={styles.main__block_right}>
          <div className={styles.main__timer}>
            <div>
              <TimerIcon />
              <div className={styles.main__timer_label}>
                {t("Countdown to the harvest")}
              </div>
            </div>
            <div className={styles.main__timer_time}>
              {moment(data?.section.hall.harvest_date).format("YYYY-MM-DD")}
            </div>
          </div>
          <div className={styles.main__block}>
            <div className={styles.main__block_info}>
              <div className={styles.main__block_row}>
                <div className={styles.main__block_row_label}>
                  {t("Token ID")}
                </div>
                <div className={styles.main__block_row_value}>{id}</div>
              </div>
              <div className={styles.main__block_row}>
                <div className={styles.main__block_row_label}>
                  {t("Spot Number")}
                </div>
                <div className={styles.main__block_row_value}>
                  {data?.section.hall.building.building_name}
                </div>
              </div>
              <div className={styles.main__block_row}>
                <div className={styles.main__block_row_label}>
                  {t("Current Status")}
                </div>
                <div
                  className={cn(
                    styles.main__block_row_value,
                    styles.main__block_row_value_status
                  )}
                >
                  <Status text={data?.section.hall.status} />
                </div>
              </div>
            </div>
            <div className={styles.main__block_info}>
              <div className={styles.main__block_row}>
                <div className={styles.main__block_row_label}>
                  {t("Harvest Selling")}
                </div>
                <div className={styles.main__block_row_value}>
                  {data?.auto_sale ? t("automatic") : t("manual")}
                </div>
              </div>
              <div className={styles.main__block_row}>
                <div className={styles.main__block_row_label}>
                  {t("Genetics")}
                </div>
                <div className={styles.main__block_row_value}>XXXX</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.cultivate}>
        <div className={styles.cultivate__title}>
          {t("Cultivation Progress")}
        </div>
        <div className={styles.cultivate__table}>
          {cultivateData?.map((item) => {
            const { date, time, id } = item;
            return (
              <div key={id} className={styles.cultivate__row}>
                <div>
                  {date} <span>{time}</span>
                </div>
                <div>{t("Log details")}</div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Main;
