import React, { useState, useEffect } from "react";
import styles from "./News.module.scss";
import SectionTitle from "../../../../components/SectionTitle/SectionTitle";
import NewsElement from "./Components/NewsElement/NewsElement";
import getRequest from "../../../../helpers/getRequest";
import { useTranslation } from "react-i18next";
import tempImg from "../../../../img/temp/00.jpg";
import tempImg2 from "../../../../img/temp/temp-new0.png";

const News = ({ currentLang, role }) => {
  const [news, setNews] = useState(null);
  const { t } = useTranslation();

  // Request for news
  useEffect(() => {
    try {
      getRequest("v1/news", null, currentLang.lang).then(async (response) => {
        const data = await response.data;
        const sortedNewsArray = data.news.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        setNews(sortedNewsArray);
      });
    } catch (err) {
      console.log(err);
    }

    console.log(currentLang);
  }, [currentLang]);

  return (
    <section className={styles.news}>
      <SectionTitle text={t("Recent news")} />
      <a
        href={role !== "CLIENT" ? "/admin/news" : "/news"}
        className={styles.news__grid}
      >
        <div className={styles.card} key="30">
          <div
            className={styles.card__main}
            style={{ backgroundImage: `url(${tempImg2})` }}
          ></div>
          <div className={styles.card__overlay}>
            <h5>{t("Presentation of the DAG-GROW cultivation module")}</h5>
            <p>
              {t("On November 30, 2024, the German cultivation")}...
            </p>
            <span>
              {currentLang.lang == "EN"
                ? "December 5, 2024"
                : "5. Dezember 2024"}
            </span>
          </div>
        </div>

        <div className={styles.card} key="00">
          <div
            className={styles.card__main}
            style={{ backgroundImage: `url(${tempImg})` }}
          ></div>
          <div className={styles.card__overlay}>
            <h5>{t("Our “DAG-Grow” cultivation module nearing completion")}</h5>
            <p>
              {t("Our “DAG-Grow” cultivation module nearing completion")}...
            </p>
            <span>
              {currentLang.lang == "EN"
                ? "October 17, 2024"
                : "17. Oktober 2024"}
            </span>
          </div>
        </div>
        {news &&
          news.length !== 0 &&
          news.map((n) => (
            <div className={styles.card} key={n.id}>
              <div
                className={styles.card__main}
                style={{ backgroundImage: `url(${n.image})` }}
              ></div>
              <div className={styles.card__overlay}>
                <h5 dangerouslySetInnerHTML={{ __html: n.title }} />
                <p dangerouslySetInnerHTML={{ __html: n.title + "..." }} />
                <span>
                  {currentLang?.lang == "EN"
                    ? new Date(n.created_at).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })
                    : new Date(n.created_at).toLocaleDateString("de-DE", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                </span>
              </div>
            </div>
          ))}
      </a>
      <div className={styles.pseduo__track}></div>
    </section>
  );
};

export default News;
