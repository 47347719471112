import React, { useState } from "react";
import styles from "./TableRow.module.scss";
import ArrowDropdown from "../../../../img/IconComponents/ArrowDropdown";
import cn from "classnames";
import Status from "../Status/Status";
import moment from "moment";
import { useTranslation } from "react-i18next";

const TableRow = ({
  orderId,
  date,
  name,
  token,
  status,
  amount,
  role,
  h_first_name,
  h_last_name,
  s_first_name,
  s_last_name,
  s_level,
  user_id,
}) => {
  const { t } = useTranslation();
  const [isOpenRow, setIsOpenRow] = useState(false);
  console.log(orderId);

  return (
    <>
      {status == "DRAFT" ? null : (
        <div
          className={cn(
            styles.table__row,
            isOpenRow && role !== "SALE" ? styles.table__row_opened : ""
          )}
          key={orderId}
          onClick={() => setIsOpenRow((prev) => !prev)}
        >
          <div className={styles.table__row_info}>
            <div className={styles.table__order}>
              {role && role !== "SALE" && (
                <ArrowDropdown
                  color={isOpenRow !== "SALE" ? "#509C5B" : "#0F131E"}
                  rotate={isOpenRow ? "180deg" : "0deg"}
                />
              )}
              {orderId}
            </div>
            {user_id ? (
              <div className={styles.table__order}>{user_id}</div>
            ) : (
              ""
            )}
            <div className={styles.table__date}>
              {localStorage.getItem("i18n") == "EN"
                ? moment(date).format("DD/MM/YYYY HH:mm:ss")
                : moment(date).format("HH:mm [Uhr], DD.MM.YYYY")}
            </div>
            <div className={styles.table__name}>{name}</div>
            <div className={styles.table__token}>{token}</div>
            <div className={styles.table__status}>
              <Status
                title={
                  status == "PAID_OUT"
                    ? t("Paid")
                    : status === "COMPLETED"
                    ? t("Confirmed")
                    : status
                }
              />
            </div>
            <div className={styles.table__amount}>
              {parseFloat(amount).toLocaleString("de-DE", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              EUR
            </div>
            {s_first_name ? (
              <div className={styles.table__amount}>
                {s_first_name + " " + s_last_name}
              </div>
            ) : (
              ""
            )}
            {s_level ? (
              <div className={styles.table__amount}>{s_level}</div>
            ) : (
              ""
            )}
          </div>
          {isOpenRow && role !== "SALE" && (
            <div className={styles.table__row_extra}>
              {role && role === "MAIN_SALE" && (
                <div className={styles.table__row_extra_row}>
                  <div className={styles.table__row_extra_row_title}>
                    {t("Head of Sales")}
                  </div>
                  <div className={styles.table__row_extra_row_name}>
                    {h_first_name} {h_last_name}
                  </div>
                </div>
              )}
              <div className={styles.table__row_extra_row}>
                <div className={styles.table__row_extra_row_title}>
                  {t("Sales")}
                </div>
                <div className={styles.table__row_extra_row_name}>
                  {s_first_name} {s_last_name}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default TableRow;
