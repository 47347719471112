/** @format */

import React, {
  useState,
  useEffect,
} from 'react';
import styles from './LangSwitcher.module.scss';
import ArrowDropdown from '../../img/IconComponents/ArrowDropdown';
import cn from 'classnames';
import en from '../../img/en.svg';
import de from '../../img/de.svg';
import Element from './Components/Element/Element';
import { useTranslation } from 'react-i18next';

const langOption = [
  { lang: 'EN', icon: en, id: 1 },
  { lang: 'DE', icon: de, id: 2 },
];

const LangSwitcher = ({
  currentLang,
  setCurrentLang,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  // const [currentLang, setCurrentLang] = useState({
  //   lang: "DE",
  //   icon: de,
  //   id: 2,
  // });
  const { t, i18n } = useTranslation();

  // useEffect(() => {
  //   if (localStorage.getItem("i18n") === "EN") {
  //     setCurrentLang({ lang: "EN", image: en });
  //   } else {
  //     setCurrentLang({ lang: "DE", image: de });
  //   }
  // }, []);

  const setLang = (lang) => {
    setIsOpen(false);
    if (lang === 'EN') {
      setCurrentLang({ lang: 'EN', image: en });
    } else {
      setCurrentLang({ lang: 'DE', image: de });
    }
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setLang(lang);
    setIsOpen(false);
    localStorage.setItem('i18n', lang);
  };

  const language = localStorage.getItem('i18n');

  useEffect(() => {
    if (language == 'DE' || language == 'EN') {
      changeLanguage(language);
    } else {
      changeLanguage(
        navigator.language
          .toLowerCase()
          .includes('de')
          ? 'DE'
          : 'EN',
      );
    }
  }, []);

  return (
    <div
      className={cn(
        styles.lang,
        isOpen ? styles.lang__open : '',
      )}>
      <div
        className={styles.lang__info}
        onClick={() =>
          setIsOpen((prev) => !prev)
        }>
        <img src={currentLang.image} alt='en' />
        <div>{currentLang.lang}</div>
        <ArrowDropdown />
      </div>
      <div className={styles.lang__menu}>
        {langOption.map((lang) => (
          <Element
            text={lang.lang}
            icon={lang.icon}
            key={lang.id}
            action={(e) => changeLanguage(e)}
            active={
              currentLang.lang === lang.lang
            }
          />
        ))}
      </div>
    </div>
  );
};

export default LangSwitcher;
