/** @format */

import { useEffect } from "react";
import styles from "./MorePartners.module.scss";
import { useTranslation } from "react-i18next";
import partners1 from "../../../../img/ourPartners/partners1.webp";
import partners2 from "../../../../img/ourPartners/partners2.png";
import partners4 from "../../../../img/ourPartners/partners4.png";
import partners5 from "../../../../img/ourPartners/partners5.png";
import partners6 from "../../../../img/ourPartners/partners6.jpeg";
import partners7 from "../../../../img/ourPartners/partners7.webp";
import partners8 from "../../../../img/ourPartners/partners8.webp";
import partners9 from "../../../../img/ourPartners/partners9.webp";
import partners10 from "../../../../img/ourPartners/partners10.png";
import partners11 from "../../../../img/ourPartners/partners11.jpg";
import partners12 from "../../../../img/ourPartners/partners12.png";
import partners13 from "../../../../img/ourPartners/partners13.png";
import AOS from "aos";
import "aos/dist/aos.css";

const MorePartners = () => {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <section className={styles.morePartners}>
        <div className="container">
          <div className={styles.morePartners__inner}>
            <h2>{t("Our partners")}</h2>
            <div className={styles.morePartners__grid}>
              <a
                href="https://www.mertz-pharma-handel.de/"
                target="_blank"
                data-aos="zoom-in"
                data-aos-duration="500"
                rel="noreferrer"
              >
                <picture>
                  <source srcSet={partners10} type="image/webp" />
                  <img src={partners10} alt="partner-10" />
                </picture>
              </a>
              <a
                href="https://www.vonferber-langer.de/"
                target="_blank"
                data-aos="zoom-in"
                data-aos-duration="500"
                data-aos-delay="250"
                rel="noreferrer"
              >
                <picture>
                  <source srcSet={partners2} type="image/webp" />
                  <img src={partners2} alt="partner-2" />
                </picture>
              </a>
              <a
                href="https://swiss-cristal-lab.ch/de/"
                target="_blank"
                data-aos="zoom-in"
                data-aos-duration="500"
                data-aos-delay="500"
                rel="noreferrer"
              >
                <picture>
                  <source srcSet={partners4} type="image/webp" />
                  <img src={partners4} alt="partner-4" />
                </picture>
              </a>
              <a
                href="https://lab-authenticity.org/"
                target="_blank"
                data-aos="zoom-in"
                data-aos-duration="500"
                data-aos-delay="750"
                rel="noreferrer"
              >
                <picture>
                  <source srcSet={partners5} type="image/webp" />
                  <img src={partners5} alt="partner-5" />
                </picture>
              </a>
              <a
                href="https://ipi.ag/"
                target="_blank"
                data-aos="zoom-in"
                data-aos-duration="500"
                data-aos-delay="1000"
                rel="noreferrer"
              >
                <picture>
                  <source srcSet={partners6} type="image/webp" />
                  <img src={partners6} alt="partner-6" />
                </picture>
              </a>
              <a
                href="https://csc-anbauservice.de/"
                target="_blank"
                data-aos="zoom-in"
                data-aos-duration="500"
                data-aos-delay="1250"
                rel="noreferrer"
              >
                <picture>
                  <source srcSet={partners7} type="image/webp" />
                  <img src={partners7} alt="partner-7" />
                </picture>
              </a>
              <a
                href="https://www.pharma-lawyers.de/"
                target="_blank"
                data-aos="zoom-in"
                data-aos-duration="500"
                data-aos-delay="1500"
                rel="noreferrer"
              >
                <picture>
                  <source srcSet={partners8} type="image/webp" />
                  <img src={partners8} alt="partner-8" />
                </picture>
              </a>
              <a
                href="https://cebiolabs.io/"
                target="_blank"
                data-aos="zoom-in"
                data-aos-duration="500"
                data-aos-delay="1750"
                rel="noreferrer"
              >
                <picture>
                  <source srcSet={partners9} type="image/webp" />
                  <img src={partners9} alt="partner-9" />
                </picture>
              </a>
              <a
                href="https://cebiol-blockchain-solutions.de/"
                target="_blank"
                data-aos="zoom-in"
                data-aos-duration="500"
                data-aos-delay="2000"
                rel="noreferrer"
              >
                <picture>
                  <source srcSet={partners12} type="image/webp" />
                  <img src={partners12} alt="partner-12" />
                </picture>
              </a>
              <a
                href="https://cebiol.de/"
                target="_blank"
                data-aos="zoom-in"
                data-aos-duration="500"
                data-aos-delay="2250"
                rel="noreferrer"
              >
                <picture>
                  <source srcSet={partners13} type="image/webp" />
                  <img src={partners13} alt="partner-13" />
                </picture>
              </a>
              <a
                href="https://strafverteidigerhh.de/"
                target="_blank"
                data-aos="zoom-in"
                data-aos-duration="500"
                data-aos-delay="2500"
                rel="noreferrer"
              >
                <picture>
                  <source srcSet={partners11} type="image/webp" />
                  <img src={partners11} alt="partner-11" />
                </picture>
              </a>
              <a
                href="https://www.winheller.com/"
                target="_blank"
                data-aos="zoom-in"
                data-aos-duration="500"
                data-aos-delay="2750"
                rel="noreferrer"
              >
                <picture>
                  <source srcSet={partners1} type="image/webp" />
                  <img src={partners1} alt="partner-1" />
                </picture>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MorePartners;
