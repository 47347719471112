import { useState } from "react";
import styles from "./Contact.module.scss";
import HeaderCommon from "../../components/HeaderCommon/HeaderCommon";
import FooterCommon from "../../components/FooterCommon/FooterCommon";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import mailWhite from "../../img/mailWhite.svg";
import mapPin from "../../img/mapPin.svg";
import { t } from "i18next";
import {
  checkEmail,
  checkTextarea,
  checkName,
  checkSubject,
} from "../../helpers/validation";
import postRequest from "../../helpers/postRequest";
const Contact = ({ currentLang, setCurrentLang }) => {
  const [modal, setModal] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [messageSent, setMessageSent] = useState(false);

  const [errorName, setErrorName] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorSubject, setErrorSubject] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const checkFields = () => {
    const isError = [];

    checkEmail(
      email,
      () => {},
      (value) => {
        isError.push(Boolean(value));
        setErrorEmail(value);
      },
      t("Please enter correct email address and try again")
    );

    checkTextarea(
      message,
      () => {},
      (value) => {
        isError.push(Boolean(value));
        setErrorMessage(value);
      }
    );
    checkName(
      name,
      () => {},
      (value) => {
        isError.push(Boolean(value));
        setErrorName(value);
      }
    );
    checkSubject(
      subject,
      () => {},
      (value) => {
        isError.push(Boolean(value));
        setErrorSubject(value);
      }
    );

    return isError.some((el) => el);
  };

  const handleSubmit = (e) => {
    if (checkFields()) return;
    e.preventDefault();
    try {
      postRequest("v1/questions/contact", {
        name: name,
        email: email,
        subject: subject,
        message: message,
      }).then((response) => {
        if (response.status === 201) {
          setMessageSent(true);
          setName("");
          setEmail("");
          setSubject("");
          setMessage("");
          setTimeout(() => setMessageSent(false), 2000);
        }
      });
      setModal(true);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <HeaderCommon currentLang={currentLang} setCurrentLang={setCurrentLang} />
      <section className={styles.contact}>
        <div className="container">
          <div className={styles.contact__head}>
            <h2>{t("Contact Us")}</h2>
            <h3>{t("Leave us a message")}</h3>
          </div>
        </div>
        <div className={styles.contact__wrapper}></div>
        <div className="container">
          <div className={styles.contact__inner}>
            <div className={styles.contact__rect}>
              <div>
                <h3>
                  <img src={mailWhite} alt="mail" />
                  Email
                </h3>
                <p>info@anbaugesellschaft.de</p>
              </div>
              <div>
                <h3>
                  <img src={mapPin} alt="map-pin" />
                  {t("Office address")}
                </h3>
                <p>
                  Deutsche Anbaugesellschaft DAG GmbH <br />
                  Libnower Landstr. 1<br/>
                  17390 Murchin<br/>
                  {t("Germany")} <br />
                  Tel.: +49 40 5247 4001
                </p>
              </div>
            </div>
            <div className={styles.contact__form}>
              <h3>{t("Leave us a message")}</h3>

              <form>
                <Input
                  label={t("Name")}
                  placeholder={t("Enter your name")}
                  value={name}
                  error={errorName}
                  onChange={(e) => setName(e.target.value)}
                />
                <Input
                  type="email"
                  label={t("E-Mail")}
                  placeholder={t("Enter your e-mail")}
                  value={email}
                  error={errorEmail}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Input
                  label={t("Subject")}
                  placeholder={t("Enter Subject")}
                  value={subject}
                  error={errorSubject}
                  onChange={(e) => setSubject(e.target.value)}
                />
                <Input
                  label={t("Message")}
                  placeholder={t("Enter Message")}
                  type="textarea"
                  value={message}
                  error={errorMessage}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <Button text={t("Send")} color="green" onClick={handleSubmit} />
                {messageSent && <p>{t("Message Sent!")}</p>}
              </form>
            </div>
          </div>
        </div>
      </section>
      <FooterCommon />
    </>
  );
};

export default Contact;
