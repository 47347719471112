/** @format */

import React from 'react';
import styles from './Locations.module.scss';
import locations1 from '../../../../img/location1.webp';
import locations2 from '../../../../img/location2.webp';
import { useTranslation } from 'react-i18next';
const Locations = () => {
  const { t } = useTranslation();
  return (
    <section className={styles.locations}>
      <div className='container'>
        <h2>{t('Our locations')}</h2>
        <div className={styles.locations__inner}>
          <article className={styles.location}>
            <picture>
              <source
                srcSet={locations1}
                type='image/webp'
              />
              <img
                src={locations1}
                alt='location 1'
              />
            </picture>
            <div
              className={styles.location__body}>
              <h3>
                {t('Central office in Hamburg')}
              </h3>
              <h3>
                {t('Central office in Hamburg ')}
              </h3>
              <p>
                <span>
                  Deutsche Anbaugesellschaft DAG GmbH
                  <br />
                </span>
                Mittelweg 144 <br />
                20148 Hamburg, <br />
                {t('Germany')}
              </p>
              <p className={styles.email}>
                E-Mail: info@Anbaugesellschaft.de
              </p>
            </div>
          </article>
          <article className={styles.location}>
            <picture>
              <source
                srcSet={locations2}
                type='image/webp'
              />
              <img
                src={locations2}
                alt='location 2'
              />
            </picture>
            <div
              className={styles.location__body}>
              <h3>{t('Cultivation location')}</h3>
              <p>
                <span>
                  Deutsche Anbaugesellschaft DAG GmbH{' '}
                  <br />
                </span>
                Libnower Landstraße 1 <br />
                17390 Murchin,
                <br /> {t('Germany')}
              </p>
              <p className={styles.email}>
                E-Mail: info@Anbaugesellschaft.de
              </p>
            </div>
          </article>
        </div>
      </div>
    </section>
  );
};

export default Locations;
