import React, { useState, useEffect } from 'react'
import styles from './Main.module.scss'
import SectionTitle from '../../../../components/SectionTitle/SectionTitle'
import AdminCommision from '../../../../components/AdminCommision/AdminCommision'
import AdminBlock from '../../../../components/AdminBlock/AdminBlock'
import Button from '../../../../components/Button/Button'
import DatePicker from '../../../../components/DatePicker/DatePicker'
import Table from '../Table/Table'
import postRequest from '../../../../helpers/postRequest'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import getRequest from '../../../../helpers/getRequest'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { setMonth, setWeek } from '../../../../helpers/dateOfStats'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)
ChartJS.defaults.color = '#000000cc'

export const options = {
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
    },
  },
  barPercentage: 0.5,
}

const Main = ({ title, role }) => {
  const { t } = useTranslation()
  const [headCommision, setHeadCommision] = useState(0)
  const [headRegistred, setHeadRegistred] = useState(0)
  const [headTotalCommision, setHeadTotalCommision] = useState(0)
  const [activeBar, setActiveBar] = useState(null)

  const [salesCommision, setSalesCommision] = useState(null)
  const [salesRegitred, setSalesRegitred] = useState(null)
  const [salesTotalCommision, setSalesTotalCommision] = useState(null)
  const [isShowChart, setIsShowChart] = useState(false)
  const [name, setName] = useState('')
  const [id, setId] = useState(null)
  const [dates, setDates] = useState([])
  const [clientsAmount, setClientsAmount] = useState([])
  const [purchasesAmount, setPurchasesAmount] = useState([])
  const [salesAmount, setSalesAmount] = useState([])

  moment.locale('de', {
    months: `${t('January')}_${t('February')}_${t('March')}_${t('April')}_${t(
      'May',
    )}_${t('June')}_${t('July')}_${t('August')}_${t('September')}_${t(
      'October',
    )}_${t('November')}_${t('December')}`.split('_'),
    monthsShort: `${t('Jan')}_${t('Feb')}_${t('Mar')}_${t('Apr')}_${t(
      'May',
    )}_${t('Jun')}_${t('Jul')}_${t('Aug')}_${t('Sep')}_${t('Oct')}_${t(
      'Nov',
    )}_${t('Dec')}`.split('_'),
  })

  const location = useLocation()

  const data = {
    labels: dates,
    datasets: [
      {
        label: t('Sales'),
        data: salesAmount,
        backgroundColor: '#FA8A07',
      },
      {
        label: t('Clients'),
        data: clientsAmount,
        backgroundColor: '#079EA8',
      },
      {
        label: t('Purchases'),
        data: purchasesAmount,
        backgroundColor: '#067BE7',
      },
    ],
  }

  const navigate = useNavigate()

  const [dateOne, setDateOne] = useState(() =>
    moment().startOf('isoWeek').toISOString(),
  )
  const [dateTwo, setDateTwo] = useState(() => moment().toISOString())

  const setMonthPeriod = () => {
    const { firstDay, lastDay } = setMonth()

    setDateOne(firstDay.toISOString())
    setDateTwo(lastDay.toISOString())
    setActiveBar('month')
  }

  const setWeekPeriod = () => {
    const { firstDay, lastDay } = setWeek()
    setDateOne(firstDay.toISOString())
    setDateTwo(lastDay.toISOString())
    setActiveBar('week')
  }

  const setDayPeriod = () => {
    setDateOne(new Date().toISOString())
    setDateTwo(new Date().toISOString())
    setActiveBar('day')
  }

  const getUserData = (name, id) => {
    setName(name)
    setIsShowChart(true)
    setId(id)
  }

  useEffect(() => {
    setMonthPeriod()
  }, [])

  useEffect(() => {
    if (!id || !dateOne || !dateTwo) return
    setDates([])
    setClientsAmount([])
    setPurchasesAmount([])
    setSalesAmount([])

    if (role && role === 'MAIN_SALE') {
      if (location && location.pathname == '/admin/head-statistics') {
        postRequest(`v1/statistics/head-of-sale/${id}`, {
          start_date: moment(dateOne).format('YYYY-MM-DD'),
          end_date: moment(dateTwo).format('YYYY-MM-DD'),
        })
          .then((response) => {
            response.data?.map((item) => {
              setDates((dates) => [
                ...dates,
                moment(item?.date).format('MMM D'),
              ])
              setClientsAmount((clientAmount) => [
                ...clientAmount,
                item.clients,
              ])
              setPurchasesAmount((purchaseAmount) => [
                ...purchaseAmount,
                item.purchases,
              ])
              setSalesAmount((saleAmount) => [...saleAmount, item.sales])
            })
          })
          .catch((error) => {
            console.log(error)
          })
      } else if (location && location.pathname == '/admin/sales-statistics') {
        postRequest(`v1/statistics/sales/${id}`, {
          start_date: moment(dateOne).format('YYYY-MM-DD'),
          end_date: moment(dateTwo).format('YYYY-MM-DD'),
        })
          .then((response) => {
            response.data?.map((item) => {
              setDates((dates) => [
                ...dates,
                moment(item?.date).format('MMM D'),
              ])
              setClientsAmount((clientAmount) => [
                ...clientAmount,
                item.clients,
              ])
              setPurchasesAmount((purchaseAmount) => [
                ...purchaseAmount,
                item.purchases,
              ])
            })
          })
          .catch((error) => {
            console.log(error)
          })
      }
    } else {
      postRequest(`v1/statistics/sales/${id}`, {
        start_date: moment(dateOne).format('YYYY-MM-DD'),
        end_date: moment(dateTwo).format('YYYY-MM-DD'),
      })
        .then((response) => {
          response.data?.map((item) => {
            setDates((dates) => [...dates, moment(item?.date).format('MMM D')])
            setClientsAmount((clientAmount) => [...clientAmount, item.clients])
            setPurchasesAmount((purchaseAmount) => [
              ...purchaseAmount,
              item.purchases,
            ])
          })
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [dateOne, dateTwo, id])

  useEffect(() => {
    if (
      role &&
      role != 'SALE' &&
      location &&
      location.pathname == '/admin/sales-statistics'
    ) {
      postRequest(`v1/statistics/sales`).then((response) => {
        console.log(response.data?.commissionSum)
        setSalesTotalCommision(response.data?.commissionSum)
      })
    }
  }, [role])

  useEffect(() => {
    if (role === 'MAIN_SALE') {
      getRequest('v1/statistics/dashboard/upper-level')
        .then((response) => {
          setHeadCommision(response.data?.hs_rate)
          setHeadRegistred(response.data?.head_sale_count)
          setHeadTotalCommision(response.data?.hs_total_commission)
          setSalesCommision(response.data?.sale_rate)
          setSalesRegitred(response.data?.sale_count)
        })
        .catch((error) => {
          console.log(error)
        })
    }
    if (role === 'HEAD_OF_SALES') {
      getRequest('v1/statistics/dashboard/head-of-sale')
        .then((response) => {
          setSalesCommision(response.data?.sale_rate)
          setSalesRegitred(response.data?.sale_count)
        })
        .catch((error) => {
          console.log(error)
        })
    }
    if (role === 'SALE') {
      getRequest('v1/statistics/dashboard/upper-level')
        .then((response) => {})
        .catch((error) => {
          console.log(error)
        })
    }
  }, [])

  return (
    <section className={styles.stats}>
      <div className={styles.stats__back}>
        <Button
          text={t('Back')}
          color="lightGreen"
          onClick={() => navigate(-1)}
        />
      </div>
      <div className={styles.stats__subheader}>
        <SectionTitle text={title} />
        {role &&
          role === 'MAIN_SALE' &&
          location &&
          location.pathname == '/admin/head-statistics' && (
            <AdminCommision
              text={t('Head of Sales commission')}
              percent={headCommision || '-'}
            />
          )}
        {
          role &&
            role === 'MAIN_SALE' &&
            location &&
            location.pathname == '/admin/sales-statistics' && <div></div>
          // <AdminCommision text={t('Sales commission')} percent={salesCommision || "-"} />
        }
        {
          role &&
            role === 'HEAD_OF_SALES' &&
            location &&
            location.pathname == '/admin/sales-statistics' && <div></div>
          // <AdminCommision text={t('Sales commission')} percent={salesCommision || "-"} />
        }
      </div>
      <div className={styles.stats__wrapper}>
        {role &&
          role === 'MAIN_SALE' &&
          location &&
          location.pathname == '/admin/head-statistics' && (
            <>
              <AdminBlock
                type="int"
                title={
                  localStorage.getItem('i18n') === 'DE'
                    ? 'Gesamtzahl der registrierten Tippgeber'
                    : 'Total head of sales registered'
                }
                value={headRegistred}
              />
              <AdminBlock
                title={t('Total commission')}
                value={headTotalCommision}
                subValue="EUR"
              />
            </>
          )}
        {role &&
          role === 'MAIN_SALE' &&
          location &&
          location.pathname == '/admin/sales-statistics' && (
            <>
              <AdminBlock
                title={t('Total sales registered')}
                value={salesRegitred}
                type="int"
              />
              <AdminBlock
                title={t('Total commission')}
                value={salesTotalCommision}
                subValue="EUR"
              />
            </>
          )}
        {role &&
          role === 'HEAD_OF_SALES' &&
          location &&
          location.pathname == '/admin/sales-statistics' && (
            <>
              <AdminBlock
                title={t('Total number of registered')}
                value={salesRegitred}
                type="int"
              />
              <AdminBlock
                title={t('Total commission')}
                value={salesTotalCommision}
                subValue="EUR"
              />
            </>
          )}
        <div></div>
      </div>
      <Table role={role} action={getUserData} />
      {isShowChart ? (
        <div className={styles.stats__chart}>
          <h2 className={styles.stats__chart_title}>{name}</h2>
          <div className={styles.stats__chart_options}>
            <div className={styles.stats__chart_bars}>
              <div>
                {location && location.pathname !== '/admin/sales-statistics' && (
                  <>
                    <div></div> {t('Sales')}
                  </>
                )}
              </div>
              <div>
                <div></div>
                {t('Clients')}
              </div>
              <div>
                <div></div>
                {t('Purchases')}
              </div>
            </div>
            <div className={styles.stats__chart_date}>
              <div
                className={
                  activeBar === 'day'
                    ? styles.stats__chart_date_bar_active
                    : styles.stats__chart_date_bar
                }
                onClick={setDayPeriod}
              >
                <p>{t('Today')}</p>
              </div>
              <div
                className={
                  activeBar === 'week'
                    ? styles.stats__chart_date_bar_active
                    : styles.stats__chart_date_bar
                }
                onClick={setWeekPeriod}
              >
                <p>{t('This week')}</p>
              </div>
              <div
                className={
                  activeBar === 'month'
                    ? styles.stats__chart_date_bar_active
                    : styles.stats__chart_date_bar
                }
                onClick={setMonthPeriod}
              >
                <p>{t('This month')}</p>
              </div>
              <div onClick={() => setActiveBar(null)}>
                <DatePicker
                  value={dateOne}
                  datePickAction={setDateOne}
                  title={t('From')}
                  options={{ dateFormat: 'd.m.Y' }}
                />
              </div>
              <div onClick={() => setActiveBar(null)}>
                <DatePicker
                  value={dateTwo}
                  datePickAction={setDateTwo}
                  title={t('To')}
                  options={{ dateFormat: 'd.m.Y' }}
                />
              </div>
            </div>
          </div>
          <div className={styles.stats__chart_bar}>
            <Bar options={options} data={data} />
          </div>
        </div>
      ) : null}
    </section>
  )
}

export default Main
