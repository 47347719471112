import React, { useEffect, useState } from 'react'
import styles from './AdminHeader.module.scss'
import Stats from './Components/Stats/Stats'
import LangSwitcher from '../LangSwitcher/LangSwitcher'
import Logout from '../Logout/Logout'
import copyIcon from '../../img/copy.svg'
import { copyText } from '../../helpers/copyText'
import { useLocation } from 'react-router-dom'
import wallet from '../../img/wallet.svg'
import cn from 'classnames'
import Cross from '../../img/IconComponents/Cross'
import getRequest from '../../helpers/getRequest'
import { useTranslation } from 'react-i18next'
import logo from '../../img/logo.png'
import { Link } from 'react-router-dom'
import HelpIcon from '../../img/IconComponents/HelpIcon'
import GearIcon from '../../img/IconComponents/GearIcon'
import axios from 'axios'
import { API_URL } from '../../http'
import account from '../../img/account.png'
import postRequest from '../../helpers/postRequest'
import AlertTriangle from '../../img/IconComponents/AlertTriangle'
import ArrowDropdown from '../../img/IconComponents/ArrowDropdown'
import DashboardIcon from '../../img/IconComponents/DashboardIcon'
import FinancesIcon from '../../img/IconComponents/FinancesIcon'
import menu from '../../img/menu.svg'
import de from '../../img/de.svg'
import en from '../../img/en.svg'
import NewsIcon from '../../img/IconComponents/NewsIcon'

const AdminHeader = ({
  openModal,
  setIsOpenModal,
  role,
  referalValue,
  setReferalValue,
  currentLang,
  setCurrentLang,
}) => {
  const [firstName, setFirstName] = useState('')

  const [lastName, setLastName] = useState('')
  const [profilePhoto, setProfilePhoto] = useState(null)
  const [isCopied, setIsCopied] = useState(false)
  const [userRole, setUserRole] = useState('')
  const [isHeaderMobileOpen, setIsHeaderMobileOpen] = useState(false)
  const [profilePhotoUser, setProfilePhotoUser] = useState('')
  const [userName, setUserName] = useState('')
  const [userLastName, setUserLastName] = useState('')
  const [isOpenClientMenu, setIsOpenClientMenu] = useState(false)
  const [stats, setStats] = useState({
    icon: wallet,
    value: '0.00',
    type: 'EUR',
    id: 1,
  })
  const { t, i18n } = useTranslation()
  const location = useLocation()

  const handleCopy = () => {
    copyText(referalValue)
    setIsCopied(true)
    const timer = setTimeout(() => {
      setIsCopied(false)
    }, [2000])

    return () => {
      clearTimeout(timer)
    }
  }

  const copyAndClose = () => {
    handleCopy()
    const timer = setTimeout(() => {
      setIsOpenModal(false)
    }, 500)

    return () => {
      clearTimeout(timer)
    }
  }

  const changeLanguageDe = () => {
    i18n.changeLanguage('DE')
    setCurrentLang({ lang: 'DE', image: de })
    localStorage.setItem('i18n', 'DE')
  }

  const changeLanguageEn = () => {
    i18n.changeLanguage('EN')
    setCurrentLang({ lang: 'EN', image: en })
    localStorage.setItem('i18n', 'EN')
  }

  useEffect(() => {
    getRequest('v1/auth/whoami')
      .then((response) => {
        setFirstName(response.data?.first_name)
        setLastName(response.data?.last_name)
        setUserRole(response.data?.user_role)
      })
      .catch((error) => {
        console.log(error)
      })

    getRequest('v1/local-files/profile-picture', true).then((response) => {
      setProfilePhoto(response.data)
    })
  }, [])

  useEffect(() => {
    if (userRole && userRole === 'HEAD_OF_SALES') {
      postRequest(`v1/statistics/sales`).then((response) => {
        console.log(response.data?.commissionSum)
        setStats({
          icon: wallet,
          value: response.data?.commissionSum.toFixed(2) || '0.00',
          type: 'EUR',
          id: 1,
        })
      })
    }
  }, [userRole])

  useEffect(() => {
    if (userRole && userRole !== 'HEAD_OF_SALES') {
      getRequest('v1/finances/sales-area/data').then((response) => {
        setStats({
          icon: wallet,
          value: response.data?.current_balance.toFixed(2) || '0.00',
          type: 'EUR',
          id: 1,
        })
      })
    }
  }, [userRole])

  useEffect(() => {
    getRequest('v1/user/billing-details').then((response) => {
      setUserName(response.data?.first_name)
      setUserLastName(response.data?.last_name)
    })
    getRequest('v1/local-files/profile-picture', true).then((response) => {
      setProfilePhotoUser(response.data)
    })
  }, [])

  return (
    <>
      <header className={styles.header}>
        {/* <div>
          {userRole === "HEAD_OF_SALES" && (
            <>{t("Your status:") + " " + "Head of Sales"}</>
          )}
          {userRole === "SALE" && <>{t("Your status:") + " " + t("Sale")}</>}
        </div> */}
        <div className={styles.header__wrapper}>
          <div className={styles.header__stats}>
            <div className={styles.header__stats_info}>
              <div className={styles.header__stats_info__photo}>
                {profilePhoto?.size ? (
                  <img src={URL.createObjectURL(profilePhoto)} alt="" />
                ) : (
                  <img src={account} alt="admin" />
                )}
              </div>
              <div>
                <div className={styles.header__stats_info__name}>
                  <p>{firstName}</p>
                  <p>{lastName}</p>
                </div>
                {userRole === 'HEAD_OF_SALES' ? (
                  <div className={styles.header__role}>
                    {t('Your status:')} <span>Head of Sales</span>
                  </div>
                ) : null}
                {userRole === 'SALE' ? (
                  <div className={styles.header__role}>
                    {t('Your status:')} <span>{t('Sale')}</span>
                  </div>
                ) : null}
              </div>
            </div>

            {/* <div className={styles.header__stats_btns}>
              <Stats icon={stats.icon} value={stats.value} type={stats.type} />
            </div> */}
          </div>

          <div className={styles.header__actions}>
            <div className={styles.header__actions_logo}>
              <Link to={'/'}>
                <img src={logo} alt="logo" />
              </Link>
            </div>
            <div className={styles.header__actions_langLogout}>
              <LangSwitcher
                currentLang={currentLang}
                setCurrentLang={setCurrentLang}
              />
              <Logout />
              <div
                onClick={() => setIsHeaderMobileOpen(true)}
                className={styles.header__mobileBtn}
              >
                <img src={menu} alt="menu" />
              </div>
            </div>
          </div>
        </div>
        {isHeaderMobileOpen && (
          <div className={styles.header__mobile}>
            <div className={cn(styles.header__menu)}>
              <div className={styles.header__menu_overflow}>
                <div className={styles.header__menu_header}>
                  <img
                    className={styles.header__menu_header_logo}
                    src={logo}
                    alt="logo"
                  />
                  <div className={styles.header__menu_header_wrapper}>
                    <button onClick={() => setIsHeaderMobileOpen(false)}>
                      <Cross />
                    </button>
                  </div>
                </div>
                <div className={styles.header__menu_client}>
                  <div className={styles.header__menu_client_header}>
                    <div>
                      <div
                        className={styles.header__menu_client_header_wrapper}
                      >
                        <div className={styles.header__menu_client_image}>
                          {profilePhotoUser && profilePhotoUser.size ? (
                            <img
                              src={URL.createObjectURL(profilePhotoUser)}
                              alt="profile"
                            />
                          ) : (
                            <img
                              className={
                                styles.settings__account_info_pict_uploaded
                              }
                              src={account}
                              alt="profile"
                            />
                          )}
                        </div>
                        <div>
                          <div className={styles.header__menu_client_name}>
                            {userName} {userLastName}
                          </div>
                          <div className={styles.header__menu_client_status}>
                            {userRole === 'SALE' && t('Sale')}
                            {userRole === 'HEAD_OF_SALES' && 'Head of Sales'}
                            {userRole === 'MAIN_SALE' && t('Top sale')}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.header__menu_client_right}>
                      <div className={styles.header__menu_switcher}>
                        <div onClick={() => changeLanguageEn()}>
                          <img src={en} alt="en" />
                        </div>
                        <div onClick={() => changeLanguageDe()}>
                          <img src={de} alt="de" />
                        </div>
                      </div>
                      <div
                        onClick={() => setIsOpenClientMenu((prev) => !prev)}
                        className={cn(
                          styles.header__menu_client_btn,
                          isOpenClientMenu
                            ? styles.header__menu_client_btn_active
                            : '',
                        )}
                      >
                        <ArrowDropdown />
                      </div>
                    </div>
                  </div>
                  <div
                    className={cn(
                      styles.header__menu_add,
                      isOpenClientMenu && role != 'CLIENT'
                        ? styles.header__menu_add_active_sale
                        : '',
                      isOpenClientMenu && role === 'CLIENT'
                        ? styles.header__menu_add_active
                        : '',
                    )}
                  >
                    <Link
                      to={'/admin/settings'}
                      onClick={() => setIsHeaderMobileOpen(false)}
                      className={cn(
                        styles.header__menu_link,
                        location.pathname.includes('/admin/settings')
                          ? styles.header__menu_link_active
                          : '',
                      )}
                    >
                      <GearIcon />
                      <div>{t('Settings')}</div>
                    </Link>
                  </div>
                </div>
                <div className={styles.header__menu_menu}>
                  <Link
                    className={cn(
                      styles.header__menu_link,
                      location.pathname.includes('/admin/dashboard')
                        ? styles.header__menu_link_active
                        : '',
                    )}
                    to="/admin/dashboard"
                    onClick={() => setIsHeaderMobileOpen(false)}
                  >
                    <DashboardIcon />
                    <div>{t('Dashboard')}</div>
                  </Link>
                  <Link
                    className={cn(
                      styles.header__menu_link,
                      location.pathname.includes('/admin/finances')
                        ? styles.header__menu_link_active
                        : '',
                    )}
                    to="/admin/finances"
                    onClick={() => setIsHeaderMobileOpen(false)}
                  >
                    <FinancesIcon />
                    <div>{t('Finances')}</div>
                    <div className={styles.header__menu_link_finances}>
                      {stats?.value} EUR
                    </div>
                  </Link>
                  <Link
                    className={cn(
                      styles.header__menu_link,
                      location.pathname.includes('/admin/documents')
                        ? styles.header__menu_link_active
                        : '',
                    )}
                    to="/admin/documents"
                    onClick={() => setIsHeaderMobileOpen(false)}
                  >
                    <NewsIcon />
                    <div>{t('Sales Documents')}</div>
                  </Link>
                </div>
                <div className={styles.header__menu_footer}>
                  <Link
                    className={styles.header__menu_link}
                    to="/imprint"
                    onClick={() => setIsHeaderMobileOpen(false)}
                  >
                    <div>{t('Imprint')}</div>
                  </Link>
                  <Link
                    className={styles.header__menu_link}
                    to="/risk-information"
                    onClick={() => setIsHeaderMobileOpen(false)}
                  >
                    <div>{t('Risk information')}</div>
                  </Link>
                  <Link
                    className={styles.header__menu_link}
                    to="/agb"
                    onClick={() => setIsHeaderMobileOpen(false)}
                  >
                    <div>{t('AGB')}</div>
                  </Link>
                  <Link
                    className={styles.header__menu_link}
                    to="/privacy"
                    onClick={() => setIsHeaderMobileOpen(false)}
                  >
                    <div>{t('Privacy Policy')}</div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </header>
      {openModal ? (
        <div className={styles.modal}>
          <div className={styles.modal__inner}>
            <button
              className={styles.modal__inner_close}
              onClick={() => setIsOpenModal(false)}
            >
              <Cross />
            </button>
            <div className={styles.modal__inner_title}>
              {t('Your referral')}
            </div>
            <div className={styles.modal__inner_wrapper}>
              <div className={styles.modal__inner_code}>{referalValue}</div>
              <button
                className={styles.modal__copy}
                onClick={() => handleCopy()}
              >
                <img src={copyIcon} alt="copy" />
              </button>
            </div>
            <div className={styles.modal__copy_container}>
              <div
                className={cn(
                  styles.modal__copy_state,
                  isCopied ? styles.modal__copy_state_active : '',
                )}
              >
                {t('Copied to clipboard')}
              </div>
            </div>
            <div className={styles.modal__tip}>
              <AlertTriangle color="#FA8A07" />
              <div>
                {t(
                  `Only one ${
                    role === 'MAIN_SALE' ? 'Head Of Sales' : 'tipster'
                  } can be invited per link.`,
                )}
              </div>
            </div>
            <div className={styles.modal__btn} onClick={copyAndClose}>
              {t('Copy referral link and close window')}
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default AdminHeader
