import React, { memo } from "react";

const ArrowDropdown = memo(({ color = "#0F131E", rotate="0deg" }) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{rotate, transition: '.2s ease-in-out'}}
    >
      <path
        d="M4.64706 6L8.64706 10L12.6471 6"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export default ArrowDropdown;
