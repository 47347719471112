import React, { useState, useEffect } from "react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import "flatpickr/dist/plugins/monthSelect/style.css";
import monthSelect from "flatpickr/dist/plugins/monthSelect";
import styles from "./DatePicker.module.scss";
import ArrowTriangle from "../../../../img/IconComponents/ArrowDropdown";
import { German } from "flatpickr/dist/l10n/de";
import moment from "moment";
import cn from "classnames";

const DatePicker = ({ selectedMonth, setSelectedMonth, currentLang }) => {
  const [isShowMonth, setIsShomMonth] = useState(true);

  // Dynamically set the moment locale based on currentLang
  useEffect(() => {
    moment.locale(currentLang.lang.toLowerCase());
  }, [currentLang]);

  function handleMonthChange(month) {
    setSelectedMonth(month);
    setIsShomMonth(false);
  }

  return (
    <label className={styles.calendar}>
      <span
        className={cn(
          styles.calendar__first,
          isShowMonth ? styles.calendar__first_active : ""
        )}
      >
        {moment().format("MMMM YYYY")}
      </span>
      <Flatpickr
        key={currentLang.lang}
        value={selectedMonth}
        className={styles.calendar__inner}
        onChange={handleMonthChange}
        options={{
          plugins: [new monthSelect({ dateFormat: "F Y" })],
          locale: currentLang.lang === "DE" ? "de" : "en",
          disableMobile: "true",
        }}
      />
      <span className={styles.calendar__arrow}>
        <ArrowTriangle />
      </span>
    </label>
  );
};

export default DatePicker;
