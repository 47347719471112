/** @format */

import React from "react";
import styles from "./Stats.module.scss";
import Tile from "./Components/Tile/Tile";
import stats1 from "../../../../img/stats1.png";
import stats2 from "../../../../img/stats2.jpg";
import { useTranslation } from "react-i18next";
const Stats = () => {
  const { t } = useTranslation();
  return (
    <section id="location" className={styles.stats}>
      <div>
        <div className={`${styles.stats__wrapper} container`}>
          <div className={styles.stats__left}>
            <h2 className={styles.desktop}>
              {t("Germany's Largest and Most Modern Cannabis Cultivation")}
            </h2>
            <h2 className={styles.mobile}>
              {t("Our location")}
            </h2>
            <picture className={styles.mobile}>
              <source srcSet={stats1} type="image/webp" />
              <img src={stats1} alt={t("stats")} style={{ width: "100%" }}  width={441} height={253}/>
            </picture>
            <p className={styles.desktop}>
              {t(
                "Deutsche Anbaugesellschaft provides cultivation areas for cannabis clubs from all over Germany. With the specially developed cultivation management system 'DECT-Grow', cultivation can be easily controlled and monitored. The entire site meets the highest safety standards and is managed self-sufficiently and sustainably."
              )}
            </p>
            <div className={styles.stats__left_tiles}>
              <Tile qm="630.000" desc={t("Total area")} />
              <Tile
                qm="120.000"
                desc={t("Spread over 35 halls")}
                className={styles.desktop}
              />
              <Tile
                qm="120.000"
                desc={t("Hall area")}
                className={styles.mobile}
              />
              <Tile qm="2.500" desc={t("Office space")} />
              <Tile qm="400" desc={t("Laboratory")} />
            </div>
            <div className={`${styles.stats__adds} ${styles.desktop}`}>
              <div className={styles.stats__adds_block}>
                <svg
                  width="41"
                  height="40"
                  viewBox="0 0 41 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.4999 26.6667C24.1818 26.6667 27.1666 23.6819 27.1666 20C27.1666 16.3181 24.1818 13.3334 20.4999 13.3334C16.818 13.3334 13.8333 16.3181 13.8333 20C13.8333 23.6819 16.818 26.6667 20.4999 26.6667Z"
                    stroke="#509C5B"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M20.5 3.33337V6.66671"
                    stroke="#509C5B"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M20.5 33.3334V36.6667"
                    stroke="#509C5B"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.71655 8.21667L11.0666 10.5667"
                    stroke="#509C5B"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M29.9333 29.4333L32.2833 31.7833"
                    stroke="#509C5B"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3.83325 20H7.16659"
                    stroke="#509C5B"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M33.8333 20H37.1666"
                    stroke="#509C5B"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.0666 29.4333L8.71655 31.7833"
                    stroke="#509C5B"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M32.2833 8.21667L29.9333 10.5667"
                    stroke="#509C5B"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p>{t("Renewable energy")}</p>
              </div>
              <div className={styles.stats__adds_block}>
                <svg
                  width="41"
                  height="40"
                  viewBox="0 0 41 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.1667 27.1667C15.8333 27.1667 18.8333 24.1167 18.8333 20.4167C18.8333 18.4834 17.8833 16.65 15.9833 15.1C14.0833 13.55 12.65 11.25 12.1667 8.83337C11.6833 11.25 10.2667 13.5667 8.35 15.1C6.43333 16.6334 5.5 18.5 5.5 20.4167C5.5 24.1167 8.5 27.1667 12.1667 27.1667Z"
                    stroke="#509C5B"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M21.4332 11C22.5795 9.16853 23.3921 7.1484 23.8332 5.03333C24.6666 9.19999 27.1666 13.2 30.4999 15.8667C33.8332 18.5333 35.4999 21.7 35.4999 25.0333C35.5094 27.3371 34.8347 29.5919 33.5612 31.5118C32.2878 33.4317 30.4729 34.9302 28.3468 35.8175C26.2206 36.7047 23.8789 36.9407 21.6185 36.4955C19.3581 36.0502 17.2808 34.9439 15.6499 33.3167"
                    stroke="#509C5B"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p> {t("Self-sufficient water supply")}</p>
              </div>
              <div className={styles.stats__adds_block}>
                <svg
                  width="41"
                  height="40"
                  viewBox="0 0 41 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.83325 10C4.83325 10.8334 5.83325 11.6667 7.99992 11.6667C12.1666 11.6667 12.1666 8.33337 16.3333 8.33337C20.6666 8.33337 20.3333 11.6667 24.6666 11.6667C28.8333 11.6667 28.8333 8.33337 32.9999 8.33337C35.1666 8.33337 36.1666 9.16671 37.1666 10"
                    stroke="#509C5B"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3.83325 20C4.83325 20.8334 5.83325 21.6667 7.99992 21.6667C12.1666 21.6667 12.1666 18.3334 16.3333 18.3334C20.6666 18.3334 20.3333 21.6667 24.6666 21.6667C28.8333 21.6667 28.8333 18.3334 32.9999 18.3334C35.1666 18.3334 36.1666 19.1667 37.1666 20"
                    stroke="#509C5B"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3.83325 30C4.83325 30.8334 5.83325 31.6667 7.99992 31.6667C12.1666 31.6667 12.1666 28.3334 16.3333 28.3334C20.6666 28.3334 20.3333 31.6667 24.6666 31.6667C28.8333 31.6667 28.8333 28.3334 32.9999 28.3334C35.1666 28.3334 36.1666 29.1667 37.1666 30"
                    stroke="#509C5B"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p>{t("Own recycling facility")}</p>
              </div>
              <div className={styles.stats__adds_block}>
                <svg
                  width="41"
                  height="40"
                  viewBox="0 0 41 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.1666 31.6667H8.52494C7.99922 31.6682 7.48203 31.5338 7.02356 31.2766C6.56509 31.0193 6.1809 30.6479 5.90827 30.1984C5.64646 29.7469 5.50801 29.2345 5.50684 28.7126C5.50567 28.1907 5.64182 27.6777 5.9016 27.225L12.4933 15.8334"
                    stroke="#509C5B"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M18.8333 31.6667H32.5049C33.0281 31.6626 33.5415 31.524 33.9956 31.2643C34.4498 31.0045 34.8295 30.6322 35.0983 30.1833C35.3564 29.7331 35.4923 29.2231 35.4923 28.7042C35.4923 28.1852 35.3564 27.6752 35.0983 27.225L33.0549 23.6917"
                    stroke="#509C5B"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M23.8333 26.6666L18.8333 31.6666L23.8333 36.6666"
                    stroke="#509C5B"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.3217 22.66L12.4934 15.8334L5.66675 17.6634"
                    stroke="#509C5B"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.0732 9.68501L17.8949 6.53167C18.1565 6.07567 18.5315 5.69502 18.9835 5.42665C19.4356 5.15828 19.9493 5.01132 20.4749 5.00001C20.9965 4.99903 21.5091 5.13527 21.9614 5.39505C22.4136 5.65482 22.7896 6.02899 23.0516 6.48001L29.6232 17.885"
                    stroke="#509C5B"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M22.7966 16.055L29.6233 17.885L31.4516 11.0583"
                    stroke="#509C5B"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p>
                  100% <br />
                  Zero Waste
                </p>
              </div>
            </div>
          </div>
          <div className={`${styles.stats__right} ${styles.desktop}`}>
            <picture>
              <source srcSet={stats1} type="image/webp" />
              <img src={stats1} alt={t("stats")} width={441} height={253}/>
            </picture>
            <picture>
              <source srcSet={stats2} type="image/webp" />
              <img src={stats2} alt={t("stats")} width={441} height={253}/>
            </picture>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Stats;
