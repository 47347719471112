import { getNames } from "i18n-iso-countries";
import { countries as countriesList } from "countries-list";

// const countriesArray = Object.keys(countriesList).map((code) => {
//   return {
//     phoneCode: countriesList[code].phone[0],
//     code: code.toLowerCase(),
//     name: countriesList[code].name,
//   };
// });

export const getAllCountries = () => {
  const allCountries = getNames(
    localStorage.getItem("i18n") != "EN" ? "de" : "en"
  );

  let countriesArray = Object.keys(allCountries).map((countryCode) => ({
    code: countryCode,
    name: allCountries[countryCode],
    phoneCode: countriesList[countryCode].phone,
  }));
  const excludedCountries = [
    "China",
    "India",
    "Nepal",
    "Bangladesh",
    "Bolivia",
    "Ecuador",
    "Algeria",
    "North Macedonia",
    "United States",
    "North Korea",
    "Iran",
    "Syria",
    "Cuba",
    "Russia",
    "Belarus",
    // "Germany",
    // "Austria",
    // "Switzerland",
    "Kosovo",
  ];

  // const germany = {
  //   code: "DE",
  //   name: localStorage.getItem("i18n") != "EN" ? "Deutschland" : "Germany",
  //   phoneCode: '49'
  // };
  // const austria = {
  //   code: "AT",
  //   name: localStorage.getItem("i18n") != "EN" ? "Österreich" : "Austria",
  //   phoneCode: '43'
  // };
  // const switzerland = {
  //   code: "CH",
  //   name: localStorage.getItem("i18n") != "EN" ? "Schweiz" : "Switzerland",
  //   phoneCode: '41'
  // };
  const kosovo = {
    code: "XK",
    name: localStorage.getItem("i18n") != "EN" ? "Kosovo" : "Kosovo",
    phoneCode: '383'
  };

  countriesArray = [
    // germany,
    // austria,
    // switzerland,
    ...countriesArray.filter(
      (country) => !excludedCountries.includes(country.name)
    ),
    kosovo
  ];
  return countriesArray;
};

export const getFilteredCountries = (searchValue) => {
  const filteredCountries = getAllCountries().filter((country) => {
    return country.name.toLowerCase().includes(searchValue.toLowerCase());
  });
  return filteredCountries;
}

export const findCountryByPhoneNumber = (phoneNumber) => {
  // Remove the "+" if it exists
  if(!phoneNumber) return '+49'

  phoneNumber = phoneNumber.startsWith('+') ? phoneNumber.slice(1) : phoneNumber;

  // Find the country that matches the beginning of the phone number
  for (let i = 0; i < getAllCountries().length; i++) {
    const country = getAllCountries()[i];
    if (phoneNumber.startsWith(country.phoneCode)) {
      const countryPhoneCode = country.phoneCode;
      const actualPhoneNumber = phoneNumber.slice(countryPhoneCode.length);

      return {
        country: country.name,
        countryPhoneCode: countryPhoneCode,
        phoneNumber: actualPhoneNumber
      };
    }
  }

  // If no country code matches
  return '+49'
}
