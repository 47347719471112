import React from 'react'
import styles from './Stats.module.scss'

const Stats = ({icon, value, type}) => {
  
  return (
    <div className={styles.stats}>
      <img src={icon} alt="type" />
      <div className={styles.stats__wrapper}>
        <span>{value.toString().replace('.', ',')}</span>
        {type}
      </div>
    </div>
  )
}

export default Stats