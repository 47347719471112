import React from 'react'
import styles from './Switcher.module.scss'
import cn from 'classnames'


const Switcher = ({ value, onChange, colorful = false }) => {

  return (
    <div 
      className={colorful ? cn(styles.switcherColorful, (!value ? styles.switcherColorful__off : '' )) : cn(styles.switcher, (!value ? styles.switcher__off : '' ))}
      onClick={onChange}
    >
      <div className={colorful ? styles.switcherColorful__circle : styles.switcher__circle}></div>
    </div>
  )
}

export default Switcher;