import React, { useContext, useEffect } from "react";
import styles from "./ModalRegCompleted.module.scss";
import Button from "../Button/Button";
import x from "../../img/x.svg";
import { Context } from "../../index";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

const ModalRegCompleted = ({
  buttonText,
  img,
  text,
  buttonClick,
  onClose,
  id,
}) => {
  const navigate = useNavigate();

  const { store } = useContext(Context);
  const location = useLocation();
  const params = useParams();
  console.log(params);

  const action = () => {
    // window.location = ('http://localhost:3000/login')
    window.location = "/login";
    if (params?.id) {
      store.verifyUser(params?.id);
    } else if (params?.sale_id) {
      store.verifyUser(params?.sale_id);
    }
    // console.log("111")
    // console.log(params)
    // console.log("111")
    // store.verifyUser(params?.id)
    // navigate('/login')
  };

  return (
    <div className={styles.register__modal}>
      <div
        className={styles.register__modal_inner}
        style={{ maxWidth: "500px" }}
      >
        <img
          className={styles.register__modal_inner_close}
          src={x}
          alt="close"
          onClick={action}
        />
        <section className={styles.check}>
          <img
            src={img}
            alt={"img"}
            data-aos="fade-up"
            data-aos-duration="1000"
          />
          <p className={styles.register__modal_title}>{text}</p>
          <Button text={buttonText} color="green" onClick={() => action()} />
        </section>
      </div>
    </div>
  );
};

export default ModalRegCompleted;
