import axios from "axios";
import { makeAutoObservable } from "mobx";
import AuthService from "../services/AuthService";
import { API_URL } from "../http";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

export default class Store {
  isAuth = true;

  userId;

  registred = false;

  errorRegistraion = [];

  showPageCheckPost = false;
  loginError = [];
  code2faError = "";
  showMainPage = false;
  isLoadSignUp = false;
  registerResponse = {};
  show2fa = false;
  isSettingsChanged = false;
  destinationPath = null;
  profilePhoto = null;
  loading = true;

  showFirstModal = false;
  // questionnairePoints = null;
  // questionnaireAnswers = {};
  // questionnaireDone = this.getQuestionnaire();

  constructor() {
    makeAutoObservable(this);
  }

  setAuth(bool) {
    this.isAuth = bool;
  }

  setUserId(id) {
    this.userId = id;
  }
  // setQuestionnairePoints(int){
  //   this.questionnairePoints = int;
  // }
  setIsSettingsChanged(boolean) {
    this.isSettingsChanged = boolean;
  }

  setDestinationPath(path) {
    this.destinationPath = path;
  }

  // setQuestionnaireAnswers(object){
  //   this.questionnaireAnswers = object;
  // }

  async verifyUser(id) {
    try {
      const response = await AuthService.verifyUser(id);
      this.userId = id;
      this.showPageCheckPost = false;
      this.showMainPage = false;
      this.show2fa = false;
    } catch (e) {
      this.showPageCheckPost = false;
      this.showMainPage = false;
      this.show2fa = false;
    }
  }

  // done
  async login(email, password, screen_height, screen_width, browser) {
    this.isLoadSignUp = true;
    try {
      const response = await AuthService.login(
        email,
        password,
        screen_height,
        screen_width,
        browser
      );
      this.isLoadSignUp = false;
      if (!!response.data?.is_rules_accepted == false) {
        this.showFirstModal = true;
      } else {
        this.showFirstModal = false;
      }
      if (!!response.data?.accessToken) {
        localStorage.setItem("token", response.data.accessToken);
        localStorage.setItem("tokenRefresh", response.data.refreshToken);

        this.setAuth(true);
        this.show2fa = false;
      }
      if (
        !!response.data?.accessToken === false &&
        response.data?.isVerified != false
      ) {
        this.show2fa = true;
      }
      if (response.data?.isVerified == false) {
        this.setAuth(false);
        this.loginError = [];
        this.loginError.push(
          "Please check your mailbox and confirm your email address"
        );
      }
      axios({
        method: "get",
        url: `${API_URL}/v1/auth/whoami`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }).then((response) => {
        console.log(response.data?.user_role);
        localStorage.setItem("role", response.data?.user_role);
        if (response.data?.user_role === "SALE") {
          window.location.pathname = "/admin/dashboard";
        } else {
          window.location.pathname = "/dashboard";
        }
      });
      this.showPageCheckPost = false;
      this.showMainPage = false;
      // window.location.pathname = '/dashboard'
    } catch (e) {
      console.log(e);
      this.showPageCheckPost = false;
      this.showMainPage = false;
      this.show2fa = false;
      this.isLoadSignUp = false;
      if (e.response.status === 400) {
        this.loginError = [];
        // this.loginError = [...this.loginError, ...e.response.data.message]
        if (e.response.data?.message === "Bad Request Exception") {
          this.loginError.push("E-mail address or password is incorrect, please try again!");
        } else if (e.response.data?.message == "Code was already sent") {
          this.show2fa = true;
        } else {
          this.loginError.push(e.response.data.message);
        }
      }
      if (e.response.status === 401) {
        this.loginError = [];
        this.loginError.push("E-mail address or password is incorrect, please try again!");
      }
    }
  }

  async check2fa(twoFactorCode, screen_height, screen_width, browser) {
    try {
      const response = await AuthService.check2fa(
        twoFactorCode,
        screen_height,
        screen_width,
        browser
      );
      console.log(response.data?.accessToken);
      if (!!response.data?.accessToken) {
        axios({
          method: "get",
          url: `${API_URL}/v1/auth/whoami`,
          headers: {
            // Authorization: `Bearer ${localStorage.getItem('token')}`,
            Authorization: `Bearer ${response.data?.accessToken}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }).then((response) => {
          localStorage.setItem("role", response.data?.user_role);
          if (
            response.data?.user_role === "SALE" ||
            response.data?.user_role === "HEAD_OF_SALES" ||
            response.data?.user_role === "MAIN_SALE"
          ) {
            window.location.pathname = "/admin/dashboard";
          } else {
            window.location.pathname = "/dashboard";
          }
        });
        localStorage.setItem("token", response.data.accessToken);
        localStorage.setItem("tokenRefresh", response.data.refreshToken);
        this.setAuth(true);
        this.show2fa = false;
      }
      this.showPageCheckPost = false;
      this.showMainPage = false;
    } catch (e) {
      this.showPageCheckPost = false;
      this.showMainPage = false;
      console.log(e);
      if (e.response.status === 400) {
        this.code2faError = "";
        // this.loginError = [...this.loginError, ...e.response.data.message]
        this.code2faError = "Invalid code";
      }
      if (e.response.status === 401) {
        this.code2faError = "";
        this.code2faError = e.response.data.message;
      }
      if (e.response.status === 404) {
        this.code2faError = "";
        this.code2faError = "Incorrect 2FA code";
      }
    }
  }

  // done
  async signup(
    firstName,
    lastName,
    email,
    password,
    repeat_password,
    phone_number,
    inviteCode,
    referalId,
    lang,
    isMale,
    setError,
    street,
    appartment,
    postCode,
    city,
    country,
    dob,
    companyName,
    mlm_code,
    client_id,
    advert_id,
    referral_code_for_client
  ) {
    this.isLoadSignUp = true;
    this.show2fa = false;
    try {
      const response = await AuthService.signup(
        firstName,
        lastName,
        email,
        password,
        repeat_password,
        phone_number,
        inviteCode,
        referalId,
        lang,
        isMale,
        street,
        appartment,
        postCode,
        city,
        country,
        dob,
        companyName,
        mlm_code,
        client_id,
        advert_id,
        referral_code_for_client
      );

      console.log("Try 2", firstName, lastName);
      this.registred = true;
      this.registerResponse = response;

      this.errorRegistraion = [];
      this.acceptNavigate = true;
      this.showPageCheckPost = true;
      this.showMainPage = false;
      this.isLoadSignUp = false;
      localStorage.removeItem("token");
      localStorage.removeItem("tokenRefresh");
      localStorage.removeItem("intern");
     
          localStorage.removeItem('role')
          localStorage.removeItem('inviteRefCode')
          localStorage.removeItem('invite_code')
          localStorage.removeItem('referralCode')


          localStorage.removeItem('ref')
      
    } catch (e) {
      console.log("Catch", firstName, lastName);
      this.showPageCheckPost = false;
      this.showMainPage = false;
      this.isLoadSignUp = false;
      if (e.response.data?.status === 400) {
        if (
          e.response.data?.message.includes("You should provide invite_code")
        ) {
          this.errorRegistraion = [];
          this.errorRegistraion = [t("You should provide special code")];
        } else {
          this.errorRegistraion = [];
          // this.errorRegistraion = [t("This email is already taken")];
        }
      }
      if (e.response.data?.status === 404) {
        this.errorRegistraion = [];
        setError(t("Invalid referral code!"));
      }
      if (e.response.data?.status === 409) {
        this.errorRegistraion = [];
        this.errorRegistraion = [t("You are already registered")];
      }
      if (e.response.data.error) {
        this.errorRegistraion = [];
        this.errorRegistraion = [...[], e.response.data.error];
      }
      if (e.response.status == 422) {
        this.errorRegistraion = [];
        this.errorRegistraion.push(e.response.data.error);
      }
    }
  }

  async logout() {
    try {
      const response = await AuthService.logout();
      localStorage.removeItem("token");
      localStorage.removeItem("tokenRefresh");
      localStorage.removeItem("role");
      this.setAuth(false);
      this.show2fa = false;
      this.showPageCheckPost = false;
      this.showMainPage = true;
    } catch (e) {
      this.showPageCheckPost = false;
      this.showMainPage = false;
      this.show2fa = false;
      localStorage.removeItem("token");
      localStorage.removeItem("tokenRefresh");
      localStorage.removeItem("role");
      this.setAuth(false);
    }
  }

  async checkAuth(location) {
    try {
      const response = await axios({
        method: "get",
        url: `${API_URL}/v1/auth/refresh`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tokenRefresh")}`,
          "Content-Type": "application/json",
        },
      });

      localStorage.setItem("token", response.data.accessToken);
      localStorage.setItem("tokenRefresh", response.data.refreshToken);
      if (location === "login") {
        window.location = "/";
      }
      this.setAuth(true);
      this.setUserId(response.data.user);
      this.showPageCheckPost = false;
      this.showMainPage = false;
      this.show2fa = false;
      this.loading = false;
      this.getProfilePhoto();
    } catch (e) {
      this.setAuth(false);
      this.showPageCheckPost = false;
      this.showMainPage = false;
      this.show2fa = false;
      this.loading = false;
    }
  }

  async getProfilePhoto() {
    axios({
      method: "get",
      url: `${API_URL}/v1/local-files/profile-picture`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((response) => {
      if (response.status === 200) {
        this.profilePhoto = response.data;
      }
    });
  }

  // async getQuestionnaire() {
  //   axios({
  //     method: 'get',
  //     url: `${API_URL}/v1/auth/whoami`,
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem('token')}`,
  //     },
  //   }).then((response) => {
  //     if (response.status === 200) {
  //       this.questionnaireDone = response.data.is_questionnaire_completed;
  //     }
  //   })
  // }
}
