import React from 'react'
import styles from './Element.module.scss'
import "/node_modules/flag-icons/css/flag-icons.min.css";
import CheckLang from '../../../../img/IconComponents/CheckLang';
import cn from 'classnames';

const Element = ({text, action, code, phoneCode, active}) => {
  return (
    <div 
      onClick={(e) => action(e)} 
      className={styles.element}
    >
      <span className={`fi fi-${code.toLowerCase()}`}></span>
      <div className={cn(styles.element__text, active ? styles.element__text_active : null)}>
        {text}
      </div>
      <div className={cn(styles.element__code, active ? styles.element__code_active : null)}>
        +{phoneCode}
      </div>
      {
        active ? 
        <div className={styles.element__check}>
          <CheckLang />
        </div>
        :
        null
      }
    </div>
  )
}

export default Element;