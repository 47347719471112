import React from 'react'
import styles from './Textarea.module.scss'
import Triangle from '../../img/IconComponents/Triangle'
import cn from 'classnames'

const Textarea = ({
  label,
  type,
  placeholder,
  name,
  value,
  onChange,
  error,
  rows,
  cols,
}) => {
  return (
    <label className={styles.input}>
      <div className={styles.input__title}>{label}</div>
      <textarea
        className={cn(
          styles.input__input,
          error && error.length ? styles.input__input_error : '',
        )}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        rows={rows}
        cols={cols}
      />
      {error && error.length && (
        <div className={styles.input__error}>
          <Triangle />
          <div>{error}</div>
        </div>
      )}
    </label>
  )
}

export default Textarea
