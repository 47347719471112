import React, { useState } from "react";
import styles from "./Marketplace.module.scss";
import Header from "../../components/Header/Header";
import Aside from "../../components/Aside/Aside";
import Main from "./Components/Main/Main";
import FooterClient from "../../components/FooterClient/FooterClient";
import axios from "axios";
import { API_URL } from "../../http";

const Marketplace = ({ currentLang, setCurrentLang }) => {
  const [max, setMax]  =useState(0)
  const [harvest, setHarvest]  =useState(0)
  const [profit, setProfit]  =useState(0)


  const reloadData = () => {
    axios({
      method: 'get',
      url: `${API_URL}/v1/harvest/available`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem(
          'token',
        )}`,
      },
    }).then(async (response) => {
      setMax(+response.data.unsold_harvest);
      setHarvest(+response.data.unsold_harvest);
      setProfit(+response.data.profit);

    });
  };

  return (
    <div className={styles.wrapper}>
      <Aside />
      <div className={styles.container}>
        <Header harvest={harvest} profit={profit} currentLang={currentLang} setCurrentLang={setCurrentLang} />
        <Main reloadData={reloadData}  max={max} harvest={harvest} currentLang={currentLang}/>
        <div className={styles.footer}>
          <FooterClient />
        </div>
      </div>
    </div>
  );
};

export default Marketplace;
