/** @format */

import React from 'react';

import styles from './Item.module.scss';
import { useTranslation } from 'react-i18next';

const Item = ({
  header,
  price,
  discount,
  img,
  link,
  isActive,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.item}>
      <div className={styles.item__discount}>
        -50%
      </div>
      <img
        src={img}
        className={styles.item__icon}
      />
      <h3>{header}</h3>
      <div className={styles.item__body}>
        <div>
          <p>{t('Price')}</p>
          <h6>
            <span>{price} €</span>
            <span>{discount} €</span>
          </h6>
        </div>
        {isActive ? (
          <a
            className={styles.active}
            target='_blank'
            href={link}
            >
            {t('Buy now')}
          </a>
        ) : (
          <a className={styles.disabled}>
            {t('Buy now')}
          </a>
        )}
      </div>
    </div>
  );
};

export default Item;
