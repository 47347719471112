import React, { useEffect, useState } from "react";
import styles from "./CoinbaseResult.module.scss";
import "../../styling/common.css";
import HeaderCommon from "../../components/HeaderCommon/HeaderCommon";
import FooterCommon from "../../components/FooterCommon/FooterCommon";
import { useParams } from "react-router-dom";
import Check from "../../img/IconComponents/Check";
import login from "../../img/login.svg";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import getRequest from "../../helpers/getRequest";
import Loader from "../../components/Loader/Loader";

const CoinbaseResult = ({ type, currentLang, setCurrentLang }) => {
  const { t } = useTranslation();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const sendSuccessResult = () => {
    getRequest(`v1/orders/payment/success/${params.orderId}`)
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        window.location = "/dashboard";
      });
  };

  const sendCancelResult = () => {
    getRequest(`v1/orders/payment/cancel/${params.orderId}`)
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        window.location = "/dashboard";
      });
  };

  useEffect(() => {
    setIsLoading(true);
    if (type === "success") {
      sendSuccessResult();
    }
    if (type === "cancel") {
      sendCancelResult();
    }
  }, [type]);

  return (
    <>
      {isLoading && <Loader />}
      <HeaderCommon currentLang={currentLang} setCurrentLang={setCurrentLang} />
      <section className={styles.verify}>
        <div className="container">
          <div className={styles.verify__info}>
            <img className={styles.verify__image} src={login} alt="login" />
            <div
              className={styles.verify__circle}
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              <Check />
            </div>
            <h2 className={styles.verify__title}>
              {t(
                `Your order was ${
                  type === "cancel" ? "canceled" : "paid"
                } successfully`
              )}
            </h2>
          </div>
        </div>
      </section>
      <FooterCommon />
    </>
  );
};

export default CoinbaseResult;
