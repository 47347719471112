import React from "react";
import styles from "./Counter.module.scss";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";

const Counter = ({ number, title, suffix = "", decimals ="0" }) => {
    const { t } = useTranslation()
  return (
    <div className={styles.counter}>
      <div>
        <CountUp duration={5} end={number} suffix={t(suffix)} decimals={decimals} decimal=","/>
      </div>
      <span>{t(title)}</span>
    </div>
  );
};

export default Counter;
