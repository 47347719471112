import React, { useState, useEffect } from "react";
import styles from "./Table.module.scss";

import Select from "../../../../components/Select/Select";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Pagination from "../../../../components/Pagination/Pagination";
import postRequest from "../../../../helpers/postRequest";
import cn from "classnames";
import copyIcon from "../../../../img/copy.svg";
import { copyText } from "../../../../helpers/copyText";
import DatePicker from "../../../../components/DatePicker/DatePicker";
import moment from "moment";
import getRequest from "../../../../helpers/getRequest";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
ChartJS.defaults.color = "#D7D3C1";

export const options = {
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
    },
  },
  barPercentage: 0.5,
};

const selectOptions = [
  { label: 1, value: 1 },
  { label: 5, value: 5 },
  { label: 10, value: 10 },
  { label: 15, value: 15 },
  { label: 20, value: 20 },
];

const Table = ({ role, action }) => {
  const { t } = useTranslation();
  const [activeLine, setActiveLine] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 662);
  const [headCommision, setHeadCommision] = useState(0);
  const [headRegistred, setHeadRegistred] = useState(0);
  const [headTotalCommision, setHeadTotalCommision] = useState(0);
  const [salesCommision, setSalesCommision] = useState(null);
  const [salesRegitred, setSalesRegitred] = useState(null);
  const [salesTotalCommision, setSalesTotalCommision] = useState(null);
  const [dateOne, setDateOne] = useState(() =>
    moment().startOf("isoWeek").toISOString()
  );
  const [dateTwo, setDateTwo] = useState(() => moment().toISOString());
  const [selectedOption, setSelectedOption] = useState({
    label: 10,
    value: 10,
  });
  const [users, setUsers] = useState(null);
  const [page, setPage] = useState(1);
  const [totalUser, setTotalUser] = useState(0);
  const location = useLocation();
  const [isCopied, setIsCopied] = useState();
  const [dates, setDates] = useState([]);
  const [clientsAmount, setClientsAmount] = useState([]);
  const [purchasesAmount, setPurchasesAmount] = useState([]);
  const [salesAmount, setSalesAmount] = useState([]);
  moment.locale("de", {
    months: `${t("January")}_${t("February")}_${t("March")}_${t("April")}_${t(
      "May"
    )}_${t("June")}_${t("July")}_${t("August")}_${t("September")}_${t(
      "October"
    )}_${t("November")}_${t("December")}`.split("_"),
    monthsShort: `${t("Jan")}_${t("Feb")}_${t("Mar")}_${t("Apr")}_${t(
      "May"
    )}_${t("Jun")}_${t("Jul")}_${t("Aug")}_${t("Sep")}_${t("Oct")}_${t(
      "Nov"
    )}_${t("Dec")}`.split("_"),
  });

  const data = {
    labels: dates,
    datasets: [
      {
        label: t("Sales"),
        data: salesAmount,
        backgroundColor: "#FA8A07",
      },
      {
        label: t("Clients"),
        data: clientsAmount,
        backgroundColor: "#079EA8",
      },
      {
        label: t("Purchases"),
        data: purchasesAmount,
        backgroundColor: "#067BE7",
      },
    ],
  };
  const handleActiveLine = (id) => {
    if (isMobile) {
      if (id === activeLine) {
        setActiveLine(null);
      } else {
        setActiveLine(id);
      }
    }
  };
  const handleCopy = (code) => {
    copyText(code);
    setIsCopied(code);
    const timer = setTimeout(() => {
      setIsCopied(null);
    }, [2000]);

    return () => {
      clearTimeout(timer);
    };
  };

  const handleChange = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 662);
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (
      role &&
      role === "MAIN_SALE" &&
      location &&
      location.pathname == "/admin/head-statistics"
    ) {
      postRequest("v1/statistics/head-of-sale")
        .then((response) => {
          setTotalUser(response.data?.amountNestedUsers);
        })
        .catch((error) => {
          console.log(error);
        });
      postRequest("v1/statistics/head-of-sale", { page: page, perPage: 10 })
        .then((response) => {
          setUsers(response.data?.allNestedUserArray);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (
      role &&
      role != "SALE" &&
      location &&
      location.pathname == "/admin/sales-statistics"
    ) {
      postRequest(`v1/statistics/sales`).then((response) => {
        setTotalUser(response.data?.amountNestedUsers);
        setSalesTotalCommision(response.data?.total);
      });

      postRequest(`v1/statistics/sales`, {
        page: page,
        perPage: selectedOption.value,
      })
        .then((response) => {
          setUsers(response.data?.allNestedUserArray);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [role, page, selectedOption]);
  useEffect(() => {
    if (role === "MAIN_SALE") {
      getRequest("v1/statistics/dashboard/upper-level")
        .then((response) => {
          setHeadCommision(response.data?.hs_rate);
          setHeadRegistred(response.data?.head_sale_count);
          setHeadTotalCommision(response.data?.hs_total_commission);

          setSalesCommision(response.data?.sale_rate);
          setSalesRegitred(response.data?.sale_count);
          // setSalesTotalCommision(response.data?.sale_commission)
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (role === "HEAD_OF_SALES") {
      getRequest("v1/statistics/dashboard/head-of-sale")
        .then((response) => {
          setSalesCommision(response.data?.sale_rate);
          setSalesRegitred(response.data?.sale_count);
          // setSalesTotalCommision(response.data?.sale_commission)
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // if(role === "SALE"){
    //   getRequest('v1/statistics/dashboard/upper-level')
    //   .then(response => {})
    //   .catch(error => {
    //     console.log(error)
    //   })
    // }
  }, []);
  return (
    <div className={styles.table}>
      <div className={styles.table__entries}>
        <div className={styles.table__entries_settings}>
          <p>{t("Show")}</p>
          <Select
            options={selectOptions}
            value={selectedOption}
            onChange={handleChange}
            placeholder={t("Select")}
          />
          <p>{t("Entries")}</p>
        </div>
        <div className={styles.table__entries_info}>
          {t("Showing 1 to")}{" "}
          {selectedOption.value > totalUser ? totalUser : selectedOption.value}{" "}
          {} {t("of")} {totalUser} {t("entries")}
        </div>
      </div>
      <div className={styles.table__table}>
        <div className={styles.table__table_head}>
          <div className={styles.table__id}>ID</div>
          <div className={styles.table__name}>{t("Full Name")}</div>
          <div className={styles.table__country}>{t("Country")}</div>
          <div className={styles.table__city}>{t("City")}</div>
          <div className={styles.table__postcode}>{t("Postcode")}</div>
          {location && location.pathname != "/admin/sales-statistics" && (
            <div className={styles.table__sale}>
              {(role === "MAIN_SALE" || role === "HEAD_OF_SALES") &&
              localStorage.getItem("i18n") === "DE"
                ? "Tippgeber"
                : "Sales"}
            </div>
          )}
          <div className={styles.table__clients}>
            {(role === "MAIN_SALE" || role === "HEAD_OF_SALES") &&
            localStorage.getItem("i18n") === "DE"
              ? "Registrierungen"
              : "Clients"}
          </div>
          <div className={styles.table__total}>{t("Commissions")}</div>
          {location &&
            location.pathname != "/admin/head-statistics" &&
            role === "MAIN_SALE" && (
              <div className={styles.table__hs}>{t("Head of sales")}</div>
            )}
          <div className={styles.table__purchase}>{t("Purchases")}</div>
        </div>
        <div className={styles.table__table_body}>
          {users && users.length
            ? users.map((entry) => {
                const {
                  u_id,
                  u_first_name,
                  u_last_name,
                  u_country,
                  u_city,
                  u_postcode,
                  sale,
                  clients,
                  purchases,
                  total,
                  head_of_sale_first_name,
                  head_of_sale_last_name,
                } = entry;
                return (
                  <div className={styles.line} key={u_id}>
                    <div
                      className={styles.line__data}
                      onClick={() => handleActiveLine(u_id)}
                    >
                      <div className={styles.line__id}>
                        <div className={styles.table__inner_wrapper}>
                          <div className={styles.table__id_copy}>
                            <button
                              className={styles.table__copy}
                              onClick={() => handleCopy(u_id)}
                            >
                              <img src={copyIcon} alt="copy" />
                            </button>
                            {u_id}
                          </div>
                          {isCopied == u_id ? (
                            <div className={styles.table__id_copy_text}>
                              {t("Copied")}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div
                        className={cn(
                          styles.table__name,
                          styles.table__name_row
                        )}
                        onClick={() =>
                          action(`${u_first_name} ${u_last_name}`, u_id)
                        }
                      >
                        {u_first_name} {u_last_name}
                      </div>
                      <div className={styles.line__country}>
                        {isMobile
                          ? `${u_country}, ${u_city}, Postcode - ${u_postcode}`
                          : u_country || "-"}
                      </div>
                      <div className={styles.line__city}>{u_city || "-"}</div>
                      <div className={styles.line__postcode}>
                        {u_postcode || "-"}
                      </div>
                      {location &&
                        location.pathname != "/admin/sales-statistics" && (
                          <div className={styles.table__sale}>{sale}</div>
                        )}
                      <div className={styles.line__clients}>{clients}</div>

                      <div>
                        {new Intl.NumberFormat("hi-IN").format(total) + " EUR"}
                      </div>
                      {location &&
                        location.pathname != "/admin/head-statistics" &&
                        role === "MAIN_SALE" && (
                          <div className={styles.table__hs}>
                            {head_of_sale_first_name} {head_of_sale_last_name}
                          </div>
                        )}
                      <div className={styles.line__purchases}>{purchases}</div>
                    </div>
                    <div
                      className={styles.line__chart}
                      style={
                        u_id !== null && u_id === activeLine
                          ? { display: "block" }
                          : {}
                      }
                    >
                      <div className={styles.line__chart_date}>
                        <div>
                          <DatePicker
                            value={dateOne}
                            datePickAction={setDateOne}
                            placeholder="2023-01-01"
                            title="From"
                            options={{ dateFormat: "d-m-Y" }}
                          />
                        </div>
                        <div>
                          <DatePicker
                            value={dateTwo}
                            datePickAction={setDateTwo}
                            placeholder="2023-01-01"
                            title="To"
                            options={{ dateFormat: "d-m-Y" }}
                          />
                        </div>
                      </div>
                      <div className={styles.line__chart_bars}>
                        <div>
                          <div></div> Sales
                        </div>
                        <div>
                          <div></div>Clients
                        </div>
                        <div>
                          <div></div>Purchases
                        </div>
                      </div>
                      <Bar options={options} data={data} />
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
      <Pagination
        page={page}
        perPage={selectedOption.value}
        setPage={setPage}
        totalItems={totalUser}
      />
    </div>
  );
};

export default Table;
