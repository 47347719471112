import React, { useEffect } from "react";
import styles from "./PassChanged.module.scss";
import "../../styling/common.css";
import HeaderCommon from "../../components/HeaderCommon/HeaderCommon";
import FooterCommon from "../../components/FooterCommon/FooterCommon";
import Button from "../../components/Button/Button";
import Check from "../../img/IconComponents/Check";
import AOS from "aos";
import "aos/dist/aos.css";
import passReset from "../../img/passReset.webp";
import { useTranslation } from "react-i18next";

const PassChanged = ({ currentLang, setCurrentLang }) => {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <HeaderCommon currentLang={currentLang} setCurrentLang={setCurrentLang} />
      <section className={styles.verify}>
        <div className="container">
          <div className={styles.verify__wrapper}>
            <div className={styles.verify__left}>
              <div
                className={styles.verify__circle}
                data-aos="zoom-in"
                data-aos-duration="1000"
              >
                <Check />
              </div>
              <div className={styles.verify__title}>
                {t("Password changed successfully!")}
              </div>
              <Button to="/login" text={t("Go to login")} color="green" />
            </div>
            <div
              className={styles.verify__right}
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <img src={passReset} alt="passReset" />
            </div>
          </div>
          {/* <div className={styles.verify__info}>
            <img className={styles.verify__image} src={login} alt={t("login)}/>
            <div className={styles.verify__circle} data-aos="zoom-in" data-aos-duration="1000">
              <Check />
            </div>
            <h2 className={styles.verify__title}>{t("Your password changed successfully")}</h2>
          </div> */}
        </div>
      </section>
      <FooterCommon />
    </>
  );
};

export default PassChanged;
