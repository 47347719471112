import React, { useState, useEffect } from 'react'
import styles from './Modal.module.scss'
import Cross from '../../../../img/IconComponents/Cross'
import Button from '../../../../components/Button/Button'
import { API_URL } from '../../../../http'
import { useTranslation } from 'react-i18next'
import $api from '../../../../http'

const Modal = ({
  harvest,
  close,
  pricePerGram,
  amountWillGet,
  harvestWillGet,
  reloadData,
}) => {
  const [current, setCurrent] = useState(harvest)
  const [earned, setEarned] = useState(0)
  const { t } = useTranslation()

  const exchange = async () => {
    $api({
      method: 'patch',
      url: `${API_URL}/v1/finances/exchange/all`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    .then((response) => {
      console.log(response)
      reloadData()
    })
  }

  useEffect(() => {
    setEarned(current * pricePerGram)
  }, [current, pricePerGram])

  return (
    <div className={styles.modal}>
      <div className={styles.modal__inner}>
        <button onClick={() => close()}>
          <Cross />
        </button>
        <h3>{t("Sell Harvest")}</h3>
        <div className={styles.modal__harvest}>
          <div className={styles.modal__harvest_input}>
            <p>{t("Your harvest")}</p>
            <span>{harvestWillGet}g</span>
          </div>
        </div>
        <p>{amountWillGet} {t("EUR will be added to your balance.")}</p>
        <Button text={t("Sell Harvest")} color="green" onClick={() => exchange()} />
      </div>
    </div>
  )
}

export default Modal
