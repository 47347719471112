import React from "react";
import styles from "./FooterCommon.module.scss";
import "../../styling/common.css";
import logo from "../../img/logo.png";
import { Link } from "react-router-dom";
import footerIcon1 from "../../img/footerIcon1.png";
import footerIcon3 from "../../img/footerIcon3.svg";
import { useTranslation } from "react-i18next";
import fbIcon from "../../img/fb.svg";
import instIcon from "../../img/inst.svg";
import linkedinIcon from "../../img/linkedin.svg";
import twitterIcon from "../../img/twitter.svg";
import youtubeIcon from "../../img/youtube.svg";

const FooterCommon = () => {
  const { t } = useTranslation();
  return (
    <div className="footerContainer">
      <footer className={styles.footer}>
        <div className={styles.footer__wrapper}>
          <div className={styles.footer__wrapper_left}>
            <picture>
              <source srcSet={logo} type="image/svg+xml" />
              <img src={logo} alt="logo" />
            </picture>
            <ul>
              <li>
                <a href="https://www.facebook.com/Anbaugesellschaft">
                  <img src={fbIcon} alt="fb" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/deutsche.anbaugesellschaft.dag/"
                  target="_blanc"
                >
                  <img src={instIcon} alt="inst" />
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/deutsche-anbaugesellschaft">
                  <img src={linkedinIcon} alt="linkedin" />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/DAG_DECT">
                  <img src={twitterIcon} alt="twitter" />
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/@Deutsche.Anbaugesellschaft">
                  <img src={youtubeIcon} alt="youtube" />
                </a>
              </li>
            </ul>
          </div>
          <div className={styles.footer__right}>
            <ul className={styles.footer__list}>
              <li>
                <Link to="/imprint">{t("Imprint")}</Link>
              </li>
              <li>
                <Link to="/risk-information">{t("Risk information")}</Link>
              </li>
              <li>
                <Link to="/agb">{t("AGB")}</Link>
              </li>
              <li>
                <Link to="/privacy">{t("Privacy Policy")}</Link>
              </li>
              <li>
                <Link to="/widerrufsrecht">{t("Right of withdrawal")}</Link>
              </li>
              {/* if current page is /register or /login display link */}
              {(window.location.pathname === "/register" ||
                window.location.pathname === "/login") && (
                <li>
                  <Link to="/contact">{t("Contact Us")}</Link>
                </li>
              )}
            </ul>
            <div className={styles.footer__wrapper_right}>
              <img src={footerIcon1} alt="footer icon" />
              <img src={footerIcon3} alt="footer icon" />
            </div>
          </div>
        </div>

        <div className={styles.footer__copy}>
          Copyright {new Date().getFullYear()}© All rights reserved
          
        </div>
      </footer>
    </div>
  );
};

export default FooterCommon;
