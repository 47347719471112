import React from 'react'
import styles from './Element.module.scss'
import CheckLang from '../../../../img/IconComponents/CheckLang';
import cn from 'classnames';

const Element = ({text, action, icon, active}) => {
  return (
    <div 
      onClick={(e) => action(text)} 
      className={styles.element}
    >
      <img className={styles.element__icon} src={icon} alt={text} />
      <div className={cn(styles.element__text, active ? styles.element__text_active : null)}>
        {text}
      </div>
      {
        active ? 
        <div className={styles.element__check}>
          <CheckLang />
        </div>
        :
        null
      }
    </div>
  )
}

export default Element;