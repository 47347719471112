import styles from "./News.module.scss";
import { useTranslation } from "react-i18next";
import {useState, useEffect} from 'react'
import getRequest from "../../../../helpers/getRequest";
import tempImg from '../../../../img/temp/00.jpg'
const News = ({currentLang}) => {
  const [news, setNews] = useState([])
  const PER_PAGE = 2;
  const PAGE = 1;
  const { t } = useTranslation()
  useEffect(() => {
    try {
      getRequest(`v1/articles`,null,currentLang.lang)
      .then(async (response) => {
        const data = await response.data
       
        setNews(data.articles.slice((PAGE-1)*PER_PAGE, (PAGE)*PER_PAGE))
      })
    } catch (err) {
      console.log(err)
    }
  }, [currentLang])

  return (
    <section className={styles.news}>
      <div className="container">
        <h2>{t("Recent news")}</h2>
        <div className={styles.news__grid}>
          <div className={styles.card}>
            <a href="/news/00">
            <div className={styles.card__main} style={{backgroundImage: `url(${tempImg})`}}></div>
                  <div className={styles.card__overlay}>
                    <h5>{t("Our “DAG-Grow” cultivation module nearing completion")}</h5>
                    <p dangerouslySetInnerHTML={{ __html: "Our “DAG-Grow” cultivation module nearing completion"+'...' }}></p>
                    <span>{currentLang.lang=='EN'?"October 17, 2024":"17. Oktober 2024"}</span>
                  </div>
            </a>
          </div>
          {news &&
            news.length !== 0 &&
            news.map((n) => (
              <div className={styles.card} key={n.id}>
                <a href={`/news/${n.id}`}>
                  <div className={styles.card__main} style={{backgroundImage: `url(${n.image})`}}></div>
                  <div className={styles.card__overlay}>
                    <h5 >{n.title}</h5>
                    <p dangerouslySetInnerHTML={{ __html: n.title+'...' }}></p>
                    <span>{currentLang.lang=='EN'?new Date(n.created_at).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }):new Date(n.created_at).toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' })}</span>
                  </div>
                </a>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default News;
