import styles from "./Exhibitors.module.scss";
import { useTranslation } from "react-i18next";
const Exhibitors = () => {
  const { t } = useTranslation();
  return (
    <section className={styles.exhibitors}>
      <div className="container">
        <div className={styles.exhibitors__inner}>
          <div>
            <p className={styles.exhibitors__subtitle}>Mary Jane 2024 in Berlin</p>
            <h2>
              {t(
                "We were exhibitors at Europe's largest cannabis trade fair"
              )}
            </h2>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Exhibitors;
