/** @format */

import './polyfill';
import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import Store from './store/store';
import './i18n';

const store = new Store();

export const Context = createContext({
  store,
});

const root = ReactDOM.createRoot(
  document.getElementById('root'),
);

root.render(
  // <React.StrictMode>
  <Context.Provider value={{ store }}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Context.Provider>,
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
