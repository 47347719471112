import React, { useEffect, useMemo, useState } from 'react'
import styles from './Finances.module.scss'
import Tab from './Components/Tab/Tab'
import Subheader from './Components/Subheader/Subheader'
import Table from './Components/Table/Table'
import Pagination from '../../../../components/Pagination/Pagination'
import axios from 'axios'
import { API_URL } from '../../../../http'
import { useTranslation } from 'react-i18next'
import getRequest from '../../../../helpers/getRequest'

const FinancesTabs = () => {
  const [tab, setTab] = useState(1)
  const [perPage, setPerPage] = useState(5)
  const [page, setPage] = useState(1)
  const [tableData, setTableData] = useState(null)
  const { t } = useTranslation()

  const tabHeads = [
    { text: t('Harvest Sale'), id: 1 },
    { text: t('Payout History'), id: 2 },
  ]

  useEffect(() => {
    getRequest(`v1/finances/${tab === 1 ? 'harvest/exchanges' : 'withdraws/history'}`)
    .then((response) => {
      const { status, data } = response
      if (status === 200) {
        setTableData(data)
      } else console.log(data?.message)
    })
  }, [tab])

  const tableDataFrom = (page - 1) * parseInt(perPage)

  return (
    <div className={styles.finances}>
      {
        tableData&&tableData[0] &&
        <div className={styles.finances__header}>
          {tabHeads.map((item) => {
            const { text, id } = item
            return (
              <Tab
                key={id}
                text={text}
                active={tab === id ? true : false}
                action={() => setTab(id)}
              />
            )
          })}
        </div>
      }
      {tableData&&tableData[0] && (
        <>
          <div className={styles.finances__subheader}>
            <Subheader value={perPage} setValue={setPerPage} />
            <div className={styles.finances__subheader_info}>{`${t(
              'Showing',
            )} ${tableDataFrom + 1} ${t('to')} ${
              tableDataFrom + parseInt(perPage) > tableData?.length
                ? tableData?.length
                : tableDataFrom + parseInt(perPage)
            } ${t('of')} ${tableData?.length || 0} ${t('entries')}`}</div>
          </div>
          <Table
            currentTab={tab}
            tableData={tableData?.slice(
              tableDataFrom,
              tableDataFrom + parseInt(perPage),
            )}
          />
          <br />
          <Pagination
            page={page}
            perPage={perPage}
            setPage={setPage}
            totalItems={tableData?.length}
          />
        </>
      )}
    </div>
  )
}

export default FinancesTabs
