import React, { useState, useEffect, useRef, useContext } from "react";
import styles from "./ModalSaleUnderHack.module.scss";
import { useTranslation } from "react-i18next";
import ArrowDropdown from "../../img/IconComponents/ArrowDropdown";
import cn from "classnames";
import Checkbox from "../Checkbox/Checkbox";
import { Context } from "../../index";
import { FRONT_URL } from "../../http";
import { useNavigate } from "react-router-dom";

const ModalSaleUnderHack = ({ sendAccept, lvl }) => {
  const { store } = useContext(Context);
  const { t, i18n } = useTranslation();
  const [isEndReached, setIsEndReached] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const navigate = useNavigate();
  const bottomRef = useRef(null);

  const scrollToBottom = () => {
    bottomRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;

    if (scrollTop + clientHeight >= scrollHeight - 1) {
      setIsEndReached(true);
    } else {
      setIsEndReached(false);
    }
  };

  useEffect(() => {
    const scrollContainer = document.getElementById("scroll-container");
    scrollContainer.addEventListener("scroll", handleScroll);
    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={styles.modal}>
      <div className={styles.modal__inner}>
        {/* <div className={styles.modal__title}>
          {t("We have updated our contract for Sales.")}
        </div> */}
        <div className={styles.modal__content} id="scroll-container">
          <div className={styles.modal__content_bold}>
            ERGÄNZUNG ZUM TIPPGEBERVERTRAG
          </div>
          <div className={styles.modal__content_bold}>
            zur Nutzung des Tippgeberprogramms auf der Internetpräsenz
          </div>
          <div className={styles.modal__content_bold}>
            “www.Anbaugesellschaft.de“
          </div>
          <div className={styles.modal__content_bold}>der</div>
          <div className={styles.modal__content_bold}>
            Deutsche Anbaugesellschaft DAG GmbH
          </div>
          <div className={styles.modal__content_mainTitle}>
            Bestandspflegeprovision
          </div>
          {lvl !== 1 ? (
            <>
              <div className={styles.modal__content_title}>1. Allgemeines</div>
              <div className={styles.modal__content_text}>
                Diese Ergänzung regelt die Bedingungen der
                Bestandspflegeprovision im Rahmen des Tippgeberprogramms der
                Deutschen Anbaugesellschaft DAG GmbH. Die nachfolgenden
                Regelungen sind verbindlich und gelten zusätzlich zu den im
                Tippgebervertrag festgelegten Bestimmungen. Der Vertrag wird
                durch die digitale Bestätigung des Tippgebers auf der
                Internetpräsenz „www.Anbaugesellschaft.de“ wirksam.
              </div>

              <div className={styles.modal__content_title}>
                2. Bestandspflegeprovision
              </div>
              <div className={styles.modal__content_title}>
                2.1. Vergütung für Tippgeber-Level
              </div>
              <div className={styles.modal__content_text}>
                Für jeden erfolgreich vermittelten DECT-Token erhalten Tippgeber
                eine Bestandspflegeprovision in Höhe von 5 € pro Token je
                erfolgreiche Ernte. Voraussetzung dafür ist, dass der Tippgeber
                mindestens 50 vermittelte DECT-Token erreicht hat.
              </div>
              <div className={styles.modal__content_text}>
                Wird diese Mindestanzahl nicht erreicht, besteht kein Anspruch
                auf die Bestandspflegeprovision.
              </div>

              <div className={styles.modal__content_title}>
                2.2 Übertragbarkeit der Vergütungsansprüche
              </div>
              <div className={styles.modal__content_text}>
                Tippgeber, die weniger als 50 vermittelte DECT-Token erreicht
                haben, sind nicht berechtigt, die Vergütungsansprüche aus der
                Bestandspflegeprovision auf andere Personen oder Institutionen
                zu übertragen.
              </div>
              <div className={styles.modal__content_text}>
                Der Anspruch auf die Bestandspflegeprovision bleibt während der
                gesamten Vertragslaufzeit des Tippgebervertrags bestehen, bis
                die erforderliche Anzahl an vermittelten DECT-Token erreicht
                wurde.
              </div>

              <div className={styles.modal__content_title}>
                2.3 Dauer der Auszahlung der Bestandspflegeprovision
              </div>
              <div className={styles.modal__content_text}>
                Die Bestandspflegeprovision wird unabhängig von der Dauer des
                Tippgebervertrags gewährt und bleibt auch nach einer Auflösung
                des Tippgebervertrags bestehen. Sie endet jedoch im Falle einer
                Insolvenz des Unternehmens, einer Liquidation oder wenn der
                Tippgeber selbst in die Insolvenz geht. Des Weiteren kann die
                Auszahlung der Bestandspflegeprovision eingestellt werden, wenn
                sich die rechtlichen Rahmenbedingungen für das Tippgeberprogramm
                so wesentlich ändern, dass eine Fortführung der Auszahlung nicht
                mehr möglich oder zumutbar ist. In solchen Fällen wird das
                Unternehmen die Tippgeber zeitnah über die Gründe der
                Einstellung informieren.
              </div>
              <div className={styles.modal__content_text}>
                Die Bestandspflegeprovision bleibt auch dann bestehen, wenn in
                einer der unteren Stufen bereits der erforderliche Mindestumsatz
                von 50 Token erreicht wurde und Kunden dieser Stufe ihre Token
                ganz oder teilweise veräußern. Im Falle eines Verkaufs bleibt
                der Anspruch auf die Bestandspflegeprovision für das betreffende
                Level in voller Höhe bestehen. Hiervon ausgenommen sind jedoch
                Tokenverkäufe, die aufgrund eines Widerrufs oder einer
                Rückerstattung rückgängig gemacht werden.
              </div>

              <div className={styles.modal__content_title}>
                2.4 Rückerstattungen und Bestandspflegeprovision
              </div>
              <div className={styles.modal__content_text}>
                Wird eine Transaktion aufgrund unzutreffender Angaben des
                Tippgebers, die zum Verkauf von DECT-Token führen, rückgängig
                gemacht (z. B. durch Rückerstattung des Kaufbetrags), entfällt
                die Auszahlung der dazugehörigen Bestandspflegeprovision.
              </div>

              <div className={styles.modal__content_title}>
                3. Auszahlung der Bestandspflegeprovision
              </div>
              <div className={styles.modal__content_text}>
                3.1 Die Bestandspflegeprovision wird frühestens ab dem ersten
                Quartal 2026 ausgezahlt.
              </div>
              <div className={styles.modal__content_text}>
                3.2 Die Auszahlung erfolgt quartalsweise auf das auf der
                Plattform hinterlegte Bankkonto.
              </div>

              <div className={styles.modal__content_title}>
                4. Schlussbestimmungen
              </div>
              <div className={styles.modal__content_text}>
                Diese Ergänzung zur Bestandspflegeprovision tritt mit der
                Zustimmung beider Parteien in Kraft. Änderungen bedürfen der
                Schriftform. Es gilt das Recht der Bundesrepublik Deutschland.
                Für alle Streitigkeiten aus diesem Vertrag ist das zuständige
                Gericht am Sitz der Deutschen Anbaugesellschaft DAG GmbH
                zuständig.
              </div>
            </>
          ) : (
            <>
              <div className={styles.modal__content_title}>1. Allgemeines</div>
              <div className={styles.modal__content_text}>
                Diese Ergänzung regelt die Bedingungen der
                Bestandspflegeprovision im Rahmen des Tippgeberprogramms der
                Deutschen Anbaugesellschaft DAG GmbH. Die nachfolgenden
                Regelungen sind verbindlich und gelten zusätzlich zu den im
                Tippgebervertrag festgelegten Bestimmungen. Der Vertrag wird
                durch die digitale Bestätigung des Tippgebers auf der
                Internetpräsenz „www.Anbaugesellschaft.de“ wirksam.
              </div>

              <div className={styles.modal__content_title}>
                2. Bestandspflegeprovision
              </div>
              <div className={styles.modal__content_title}>
                2.1. Vergütung für Tippgeber-Level
              </div>
              <div className={styles.modal__content_text}>
                Für jeden vermittelten ganzen DECT-Token wird eine
                Bestandspflegeprovision in Höhe von 10 € pro erfolgreiche Ernte
                gewährt
              </div>
              <div className={styles.modal__content_text}>
                Tippgeber der Stufe 1 erhalten die volle Bestandspflegeprovision
                von 10 € pro Quartal für jeden direkt vermittelten DECT-Token,
                sobald sie 50 vermittelte DECT-Token erreicht haben.
              </div>
              <div className={styles.modal__content_text}>
                Sollten Tippgeber der Stufe 1 einen weiteren Tippgeber (Stufe 2,
                3 oder 4) gewinnen und dieser einen DECT-Token vermitteln, wird
                die Bestandspflegeprovision von 10 € anteilig zwischen dem
                Tippgeber der Stufe 1 (5€) und dem direkt verkaufenden Tippgeber
                (Stufe 2, 3 oder 4) (5€) aufgeteilt.
              </div>
              <div className={styles.modal__content_text}>
                Tippgeber der Stufen 2, 3 und 4 erhalten eine
                Bestandspflegeprovision nur, wenn sie selbst 50 vermittelte
                DECT-Token erreicht haben.
              </div>
              <div className={styles.modal__content_text}>
                Sollte der Tippgeber der unteren Stufen (2, 3 oder 4) die
                erforderliche Mindestanzahl von 50 Token nicht erreichen, erhält
                der Tippgeber der Stufe 1 dennoch die vollständige
                Bestandspflegeprovision in Höhe von 5 € pro Token pro Quartal,
                die durch den Verkauf durch die unteren Stufen generiert wurde.
              </div>

              <div className={styles.modal__content_title}>
                2.2 Übertragbarkeit der Vergütungsansprüche
              </div>
              <div className={styles.modal__content_text}>
                Tippgeber, die weniger als 50 vermittelte DECT-Token erreicht
                haben, sind nicht berechtigt, die Vergütungsansprüche aus der
                Bestandspflegeprovision auf andere Personen oder Institutionen
                zu übertragen.
              </div>
              <div className={styles.modal__content_text}>
                Der Anspruch auf die Bestandspflegeprovision bleibt während der
                gesamten Vertragslaufzeit des Tippgebervertrags bestehen, bis
                die erforderliche Anzahl an vermittelten DECT-Token erreicht
                wurde.
              </div>

              <div className={styles.modal__content_title}>
                2.3 Dauer der Auszahlung der Bestandspflegeprovision
              </div>
              <div className={styles.modal__content_text}>
                Die Bestandspflegeprovision wird unabhängig von der Dauer des
                Tippgebervertrags gewährt und bleibt auch nach einer Auflösung
                des Tippgebervertrags bestehen. Sie endet jedoch im Falle einer
                Insolvenz des Unternehmens, einer Liquidation oder wenn der
                Tippgeber selbst in die Insolvenz geht. Des Weiteren kann die
                Auszahlung der Bestandspflegeprovision eingestellt werden, wenn
                sich die rechtlichen Rahmenbedingungen für das Tippgeberprogramm
                so wesentlich ändern, dass eine Fortführung der Auszahlung nicht
                mehr möglich oder zumutbar ist. In solchen Fällen wird das
                Unternehmen die Tippgeber zeitnah über die Gründe der
                Einstellung informieren.
              </div>
              <div className={styles.modal__content_text}>
                Die Bestandspflegeprovision bleibt auch dann bestehen, wenn in
                einer der unteren Stufen (z. B. Stufe 4) bereits der
                erforderliche Mindestumsatz von 50 Token erreicht wurde und
                Kunden dieser Stufe ihre Token ganz oder teilweise veräußern. Im
                Falle eines Verkaufs bleibt der Anspruch auf die
                Bestandspflegeprovision für das betreffende Level in voller Höhe
                bestehen. Hiervon ausgenommen sind jedoch Tokenverkäufe, die
                aufgrund eines Widerrufs oder einer Rückerstattung rückgängig
                gemacht werden.
              </div>

              <div className={styles.modal__content_title}>
                2.4 Rückerstattungen und Bestandspflegeprovision
              </div>
              <div className={styles.modal__content_text}>
                Wird eine Transaktion aufgrund unzutreffender Angaben des
                Tippgebers, die zum Verkauf von DECT-Token führen, rückgängig
                gemacht (z. B. durch Rückerstattung des Kaufbetrags), entfällt
                die Auszahlung der dazugehörigen Bestandspflegeprovision.
              </div>

              <div className={styles.modal__content_title}>
                3. Auszahlung der Bestandspflegeprovision
              </div>
              <div className={styles.modal__content_text}>
                3.1 Die Bestandspflegeprovision wird frühestens ab dem ersten
                Quartal 2026 ausgezahlt.
              </div>
              <div className={styles.modal__content_text}>
                3.2 Die Auszahlung erfolgt quartalsweise auf das auf der
                Plattform hinterlegte Bankkonto.
              </div>

              <div className={styles.modal__content_title}>
                4. Schlussbestimmungen
              </div>
              <div className={styles.modal__content_text}>
                Diese Ergänzung zur Bestandspflegeprovision tritt mit der
                Zustimmung beider Parteien in Kraft. Änderungen bedürfen der
                Schriftform. Es gilt das Recht der Bundesrepublik Deutschland.
                Für alle Streitigkeiten aus diesem Vertrag ist das zuständige
                Gericht am Sitz der Deutschen Anbaugesellschaft DAG GmbH
                zuständig.
              </div>
            </>
          )}

          <label className={styles.modal__check}>
            <Checkbox
              value={isCheck}
              onChange={(e) => setIsCheck(e.target.checked)}
            />
            <div>
              {t(
                "I have read, understood and fully accepted the updated Tipster Agreement."
              )}
            </div>
          </label>
          <div ref={bottomRef}></div>
        </div>
        <div className={styles.modal__footer}>
          <div className={styles.modal__footer_btns}>
            <button
              className={cn(
                styles.modal__footer_btn,
                styles.modal__footer_btn_accept,
                isCheck ? styles.modal__footer_btn_accept_active : ""
              )}
              onClick={sendAccept}
            >
              {t("Accept")}
            </button>
            <button
              className={styles.modal__footer_btn}
              onClick={() => store.logout()}
            >
              {t("Decline")}
            </button>
          </div>
        </div>
        <div className={styles.modal__footer_row}>
          <a
            href={lvl !== 1 ? `${FRONT_URL}/files/Tippgebervertrag Erganzung(2,3,4).pdf` :`${FRONT_URL}/files/Tippgebervertrag Erganzung.pdf`}
            className={styles.modal__download}
            download
          >
            {t("Download contract")}
          </a>

          <div
            className={cn(
              styles.modal__footer_scroll,
              isEndReached ? styles.modal__footer_scroll_hide : ""
            )}
            onClick={scrollToBottom}
          >
            {t("Scroll to the bottom")}
            <ArrowDropdown color="#509c5a" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalSaleUnderHack;
