/** @format */

import React, {
  useEffect,
  useState,
} from 'react';
import styles from './Main.module.scss';
import { useTranslation } from 'react-i18next';
import SectionTitle from '../../../../components/SectionTitle/SectionTitle';
import Item1 from '../../../../img/shop/1.jpeg';
import Item2 from '../../../../img/shop/2.jpeg';
import Item3 from '../../../../img/shop/3.jpeg';
import Item4 from '../../../../img/shop/4.jpeg';
import Item5 from '../../../../img/shop/5.jpeg';

import './Main.module.scss';
import Item from '../Item/Item';
import getRequest from '../../../../helpers/getRequest';
const Main = ({currentLang}) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    getRequest('v1/finances/data').then(
      (response) => {
        if (response.data.plantsDetails.length) {
          setIsActive(true);
        }
      },
    );
  }, []);

  return (
    <div className={styles.main}>
      <SectionTitle text={t('Exclusive shop for DECT token holders only')} />
      {/* <div className={styles.main__empty}>
        <img src={empty} alt='empty' />
        <h2>
          {t(
            'Currently there are no products in the store.',
          )}
        </h2>

      </div> */}
      <div className={styles.main__container}>
        <Item
          isActive={isActive}
          link={
            'https://cebiol.de/5-Bio-CBD-OEl-10ml-DAG-exklusiv/SW10000'
          }
          discount={'17,45'}
          img={Item1}
          header={t('5% Bio CBD Oil - 10ml')}
          price={'34,90'}
        />
        <Item
          isActive={isActive}
          link={
            'https://cebiol.de/10-Bio-CBD-OEl-10ml-DAG-exklusiv/SW10001'
          }
          discount={'29,95'}
          img={Item2}
          header={t('10% Bio CBD Oil - 10ml')}
          price={'59,90'}
        />
        <Item
          isActive={isActive}
          link={
            'https://cebiol.de/15-Bio-CBD-OEl-10ml-DAG-exklusiv/SW10002'
          }
          discount={'39,95'}
          img={Item3}
          header={t('15% Bio CBD Oil - 10ml')}
          price={'79,90'}
        />
        <Item
          isActive={isActive}
          link={
            'https://cebiol.de/20-Bio-CBD-OEl-10ml-DAG-exklusiv/SW10003'
          }
          discount={'49,95'}
          img={Item4}
          header={t('20% Bio CBD Oil - 10ml')}
          price={'99,90'}
        />
        <Item
          isActive={isActive}
          link={
            'https://cebiol.de/30-Bio-CBD-OEl-10ml-DAG-exklusiv/SW10004'
          }
          discount={'64,95'}
          img={Item5}
          header={t('30% Bio CBD Oil - 10ml')}
          price={'129,90'}
        />
      </div>
    </div>
  );
};

export default Main;
