/** @format */

import React, {
  useEffect,
  useState,
} from 'react';
import moment from 'moment';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import postRequest from '../../../../helpers/postRequest';
import getRequest from '../../../../helpers/getRequest';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      min: 0,
      ticks: {
        stepSize: 1,
      },
    },
  },
};

const Chart = ({ selectedMonth, role }) => {
  const [daysInMonth, setDaysInMonth] =
    useState(null);
  const [purchases, setPurchases] = useState([]);
  const [id, setId] = useState(null);

  const data = {
    labels: daysInMonth,
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
    datasets: [
      {
        data: purchases,
        borderColor: '#509C5B',
        backgroundColor: '#ffffff',
      },
    ],
  };

  useEffect(() => {
    getRequest('v1/auth/whoami').then(
      (response) => setId(response.data?.id),
    );
  }, [role]);

  useEffect(() => {
    if (!selectedMonth) return;

    // let selected = selectedMonth;
    let selected = moment()
      .startOf('month')
      .format('YYYY-MM-DD');
    const dateString = Array.isArray(
      selectedMonth,
    )
      ? selectedMonth[0]
      : moment(selected)
          .startOf('month')
          .format('YYYY-MM-DD');

    let endDateMonth;
    let startMonth;
    let endMonth;

    if (Array.isArray(selectedMonth)) {
      endDateMonth =
        moment(dateString).daysInMonth();
      startMonth = moment(dateString)
        .startOf('month')
        .format('YYYY-MM-DD');
      endMonth = moment(dateString)
        .endOf('month')
        .format('YYYY-MM-DD');
    } else {
      endDateMonth =
        moment(selected).daysInMonth();
      startMonth = moment(selected)
        .startOf('month')
        .format('YYYY-MM-DD');
      endMonth = moment(selected)
        .endOf('month')
        .format('YYYY-MM-DD');
    }

    const endpoints = {
      MAIN_SALE: 'v1/statistics/main-sale',
      HEAD_OF_SALES: `v1/statistics/head-of-sale/${id}`,
      SALE: `v1/statistics/sales/${id}`,
    };

    const endpoint = endpoints[role];

    if (endpoint) {
      if (id != null) {
        postRequest(endpoint, {
          start_date: startMonth,
          end_date: endMonth,
        }).then((response) => {
          console.log(response);
          setPurchases(
            response.data.map(
              (item) => item?.purchases,
            ),
          );
        });
      }
    }

    let arr = [];
    for (let i = 1; i <= endDateMonth; i++) {
      arr.push(i);
    }
    setDaysInMonth(arr);

  }, [selectedMonth, role, id]);

  return <Line options={options} data={data} />;
};

export default Chart;
