import styles from "./StartNow.module.scss";
import { useTranslation } from "react-i18next";

import canabis from "../../../../img/coins_2.png";
import nft from "../../../../img/canabis1.png";
import thumbsUp from "../../../../img/Layer_1.svg";

const StartNow = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.startnow}>
      <div className="container">
        <h2 className={styles.startnow__title}>
          {t("Become part of the German cannabis market now")}
        </h2>
        <div className={styles.startnow__inner}>
          <div>
            <div>
              <img src={canabis} alt="canabis" />1
            </div>
            <p>{t("Purchase the German Cannabis Token (DECT) now")}</p>
          </div>
          <div>
            <div>
              <img src={nft} alt="nft" />2
            </div>
            <p>
              {t("Allocation of a real")}
              <br />
              {t("plant")}
            </p>
          </div>
          <div>
            <div>
              <img src={thumbsUp} alt="canabis" />3
            </div>
            <p id="cannabisToken">{t("Lifetime harvest yields received")}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StartNow;
