import React, { useState, useEffect } from "react";
import styles from "./Main.module.scss";
import Table from "../Table/Table";
import Modal from "../Modal/Modal";
import { API_URL } from "../../../../http";
import token from "../../../../img/tokenNew.webp";
import Button from "../../../../components/Button/Button";
import SectionTitle from "../../../../components/SectionTitle/SectionTitle";
import AOS from "aos";
import "aos/dist/aos.css";
import FinancesTabs from "../FinancesTabs/FinancesTabs";
import getRequest from "../../../../helpers/getRequest";
import postRequest from "../../../../helpers/postRequest";
import { useTranslation } from "react-i18next";
import PaymentTable from "../PaymentTable/PaymentTable";
import Loader from "../../../../components/Loader/Loader";
import $api from "../../../../http";
import Webcam from '../../../Dashboard/Components/Webcam/Webcam'

const Main = () => {
  const [openedModal, setOpenedModal] = useState(false);
  const [profit, setProfit] = useState(null);
  const [plants, setPlants] = useState(null);
  const [pricePerGram, setPricePerGram] = useState(null);
  const [plantsData, setPlantsData] = useState([]);
  const [amountWillGet, setAmountWillGet] = useState(0);
  const [harvestWillGet, setHarvestWillGet] = useState(0);
  const [clientsAmount, setClientsAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalComission, setTotalComission] = useState(0);
  const { t } = useTranslation();

  const getAmountAndGramsAvailableToGet = () => {
    setLoading(true);
    getRequest("v1/finances/data")
      .then((response) => {
        setAmountWillGet(response.data.euro_balance);
        setHarvestWillGet(response.data.harvest_balance);
        setLoading(false);
      })
      .catch(() => setLoading(false));

    getRequest("v1/finances/data")
      .then((response) => {
        setProfit(response.data.euro_balance);
        setPlants(response.data.plants_count);
        setPricePerGram(response.data.price_per_gram);
        setPlantsData(response.data.plantsDetails);
        setClientsAmount(response.data.nested_clients);
        console.log(response.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    postRequest("v1/orders/referral-orders?page=1&perPage=1000")
      .then((res) => {
        setTotalComission(
          res.data.data.reduce(
            (n, { plants_count_commission }) => n + +plants_count_commission,
            0
          )
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAmountAndGramsAvailableToGet();
  }, []);

  useEffect(() => {
    AOS.init();
  }, []);

  const withdrawHarvest = () => {
    setLoading(true);
    $api({
      method: "patch",
      url: `${API_URL}/v1/finances/withdraw`,
      headers: {
        "Content-Type": "application/json",
        "Content-Language": localStorage.getItem("i18n").toLowerCase(),
      },
      data: { amount: profit },
    })
      .then(() => {
        getAmountAndGramsAvailableToGet();
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  return (
    <>
      {loading && <Loader />}
      <section className={styles.finances}>
        <div className={styles.finances__info}>
          <div className={styles.card}>
            {Number(plants) ? (
              <>
                <div className={styles.text}>
                  <SectionTitle text={t("My tokens")} />
                  <div className={styles.card__value}>
                    {localStorage.getItem("i18n") !== "DE"
                      ? plants
                      : plants.replace(".", ",")}
                    <span>
                      {localStorage.getItem("i18n") !== "DE"
                        ? plants == 1
                          ? "Token"
                          : "Tokens"
                        : "Token"}
                    </span>
                  </div>
                </div>
                <div className={styles.card__additional}>
                  <div className={styles.card__myPlants_empty}>
                    <Button text={t("Buy Tokens")} to="/buy" color="green" />
                  </div>
                </div>
              </>
            ) : (
              <>
                <SectionTitle text={t("My Tokens")} />
                <div className={styles.card__plants_emptyText}>
                  {t("You have not purchased any tokens yet.")}
                </div>
                <div className={styles.card__myPlants_empty}>
                  <Button text={t("Buy Tokens")} to="/buy" color="green" />
                </div>
              </>
            )}
          </div>

          <div className={styles.card}>
            <div className={styles.card__info}>
              <SectionTitle text={t("My harvest")} />
              {plantsData && plantsData.length ? (
                <div className={styles.card__value}>
                  {harvestWillGet}
                  <span>g</span>
                </div>
              ) : (
                <div className={styles.card__empty_cost}>
                  0.00
                  <span>g</span>
                </div>
              )}
            </div>
            <div className={styles.card__additional}>
              <div className={styles.card__additional_price}>
                {/* <img src={cannabis} alt="cannabis" />
                {t('Current price per gram')} */}
                {/* {plantsData && plantsData.length ? (
                  <span>{pricePerGram.toString().replace('.', ',')} €</span>
                ) : (
                  <span className={styles.card__harvest_empty}>0,00€</span>
                )} */}
              </div>
              {plantsData && plantsData.length ? (
                <Button
                  text={t("Sell Harvest")}
                  onClick={() => setOpenedModal(true)}
                  color="green"
                  disabled={harvestWillGet == 0 ? true : false}
                />
              ) : null}
            </div>
          </div>

          <div className={styles.card}>
            <div className={styles.card__info}>
              <SectionTitle text={t("My result")} />
              {plantsData && plantsData.length ? (
                <div className={styles.card__value}>
                  {profit}
                  <span>€</span>
                </div>
              ) : (
                <div className={styles.card__empty_cost}>
                  0.00
                  <span>g</span>
                </div>
              )}
            </div>
            <div className={styles.card__additional}>
              {plantsData && plantsData.length ? (
                <div className={styles.card__auto}>
                  {/* <div className={styles.card__auto_noty}>
                    <Noty />
                    <div>
                      {t('Auto-withdrawal every 15th of the following month')}
                    </div>
                  </div> */}
                  <Button
                    text={t("Withdraw")}
                    color="green"
                    onClick={withdrawHarvest}
                    disabled={profit == 0 ? true : false}
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.card__info}>
              <SectionTitle text={t("My referred friends")} />
              {clientsAmount && clientsAmount != 0 ? (
                <div className={styles.card__value}>
                  {clientsAmount}
                  <span>
                    {t("client")}
                    {clientsAmount == 1 ? "" : t("s")}
                  </span>
                </div>
              ) : (
                <div className={styles.card__value}>
                  0 <span>{t("clients")}</span>
                </div>
              )}
            </div>
            <div className={styles.card__additional}>
              <div className={styles.card__value}>
                {totalComission}
                <span>{t("tokens")}</span>
              </div>
              <div className={styles.card__auto}>
                <Button
                  text={t("Detailed statistics")}
                  color="home"
                  to="/finances/statistics"
                />
              </div>
            </div>
          </div>
        </div>
        <PaymentTable statuses={["PENDING"]} title={t("Open orders")} />
        <PaymentTable statuses={["PAID_OUT", "COMPLETED"]} title={t("Completed orders")} />
        <div className={styles.finances__plants}>
          {/* <h2 className={styles.finances__plants_title}>{t("My Tokens")}</h2> */}
          {plantsData && plantsData.length ? (
            <>
              <Webcam />
            </>
          ) : (
            <div className={styles.finances__empty}>
              <picture>
                <source srcSet={token} type="image/webp" />
                <img
                  src={token}
                  alt="empty"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                />
              </picture>

              <div className={styles.finances__empty_title}>
                {t("You have not purchased any tokens yet.")}
              </div>
              <Button text={t("Buy tokens")} to="/buy" color="green" />
            </div>
          )}
        </div>

        <FinancesTabs />
      </section>
      {openedModal && (
        <Modal
          harvest={harvestWillGet}
          close={() => setOpenedModal(false)}
          pricePerGram={pricePerGram}
          amountWillGet={amountWillGet}
          harvestWillGet={harvestWillGet}
          reloadData={getAmountAndGramsAvailableToGet}
        />
      )}
    </>
  );
};

export default Main;
